import produce from 'immer';
import { isOnTheSamePage } from 'utils/helper';
import { LOCATION_CHANGE } from 'containers/TopPage/constants';
import {
  AMH_LOGIN_SUCCEED,
  LOGIN_SUCCEED,
  LOGIN_FAILED,
  LOGOUT_SUCCEED,
  LOGOUT_FAILED,
  RESET_VALUES,
} from 'containers/LoginPage/constants';
import {
  AMH_REGISTRATION_SUCCEEDED,
  REGISTRATION_SUCCEEDED,
  REGISTRATION_FAILED,
  CONFIRMATION_EMAIL_SENT_SUCCESSFULLY,
  CONFIRMATION_EMAIL_NOT_SENT,
} from './constants';

export const initialState = {
  errors: '',
  isLoggedin: false,
  user: null,
};
export const emailConfirmationState = {
  emailWasSent: null,
  errorMessages: [],
  successMessages: [],
};

export const RegistrationReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case REGISTRATION_SUCCEEDED:
        draft.isLoggedIn = true;
        draft.user = action.payload.user;
        draft.isAmhLoggedIn = false;
        draft.amhUser = null;
        draft.auth_token = action.payload.auth_token;
        draft.errors = [];
        break;
      case AMH_REGISTRATION_SUCCEEDED:
        draft.isLoggedIn = false;
        draft.user = null;
        draft.isAmhLoggedIn = true;
        draft.amhUser = action.payload.amh_user;
        draft.auth_token = action.payload.auth_token;
        draft.errors = [];
        break;
      case REGISTRATION_FAILED:
        draft.isLoggedIn = false;
        draft.user = null;
        draft.isAmhLoggedIn = false;
        draft.amhUser = null;
        draft.errors = action.payload.errors;
        break;
      case LOGIN_SUCCEED:
        draft.isLoggedIn = true;
        draft.user = action.payload.user;
        draft.isAmhLoggedIn = false;
        draft.amhUser = null;
        draft.auth_token = action.payload.auth_token;
        draft.errors = '';
        break;
      case AMH_LOGIN_SUCCEED:
        draft.isLoggedIn = false;
        draft.user = null;
        draft.isAmhLoggedIn = true;
        draft.amhUser = action.payload.user;
        draft.auth_token = action.payload.auth_token;
        draft.errors = '';
        break;
      case LOGIN_FAILED:
        draft.isLoggedIn = false;
        draft.user = null;
        draft.isAmhLoggedIn = false;
        draft.amhUser = null;
        draft.errors = action.payload.messages;
        break;
      case LOGOUT_SUCCEED:
        draft.isLoggedIn = false;
        draft.user = null;
        draft.isAmhLoggedIn = false;
        draft.amhUser = null;
        draft.token = null;
        draft.errors = '';
        break;
      case LOGOUT_FAILED:
        draft.isLoggedIn = false;
        draft.user = null;
        draft.isAmhLoggedIn = false;
        draft.amhUser = null;
        draft.token = null;
        draft.errors = '';
        break;
      case RESET_VALUES:
        draft.isLoggedin = null;
        draft.user = '';
        break;
      default:
        break;
    }
  });

export const EmailConfirmationReducer = (
  state = emailConfirmationState,
  action,
) =>
  produce(state, draft => {
    switch (action.type) {
      case CONFIRMATION_EMAIL_SENT_SUCCESSFULLY:
        draft.emailWasSent = true;
        draft.successMessages = action.payload.messages.map(elm => elm);
        draft.errorMessages = [];
        break;
      case CONFIRMATION_EMAIL_NOT_SENT:
        draft.emailWasSent = false;
        draft.successMessages = [];
        if (action.payload.errors)
          draft.errorMessages = action.payload.errors.map(elm => elm);
        break;
      case LOCATION_CHANGE:
        if (!isOnTheSamePage(action)) {
          draft.emailWasSent = false;
          draft.errorMessages = [];
          draft.successMessages = [];
        }
        break;
      default:
        draft.emailWasSent = state.emailWasSent;
        draft.errorMessages = state.errorMessages;
        draft.successMessages = state.successMessages;
    }
  });
