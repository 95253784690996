import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
export const DataNotFoundMessage = ({ message }) => (
  <div style={{ textAlign: 'center', padding: 14, paddingBottom: 0 }}>
    <Icon name="warning" style={{ fontSize: 48 }} />
    <p>
      {message !== ''
        ? message
        : `対象のデータが見つかりませんでした。絞り込み条件を変更してください`}
    </p>
  </div>
);
DataNotFoundMessage.propTypes = {
  message: PropTypes.string,
};
DataNotFoundMessage.defaultProps = {
  message: '',
};
