import React, { useEffect, useState } from 'react';
import logo from 'assets/H60.png';
import PropTypes from 'prop-types';
import {
  Header,
  HeaderTop,
  AbnormalHeaderTop,
} from 'components/TopMenu/HeaderTop';
import { Icon } from 'semantic-ui-react';

import { Margin } from 'components/Atoms';
import { NestedBackIndicator } from 'components/Molecules';
import TopMenuIcon from 'components/TopMenu/TopMenuIcon';
import { DIMMER_COLOR } from 'utils/defaults';
import Wrapper from 'components/TopMenu/Wrapper';
import { connect } from 'react-redux';
import { checkIfNestedRoute, getPageLabel } from 'utils/helper';
import { GUEST_ROUTES } from 'components/TopMenu/constants';

const TopMenu = ({
  // clinicName,
  location,
  pathname,
  toggleSideBar,
  isSpecialRoute,
  sidebarIsOpened,
}) => {
  const [isNestedRoute, setIsNestedRoute] = useState(false);
  const [alias, setAlias] = useState(['']);
  useEffect(() => {
    if (checkIfNestedRoute(pathname, GUEST_ROUTES)) {
      setIsNestedRoute(true);
      const label = getPageLabel(pathname);
      setAlias(label);
    } else {
      setIsNestedRoute(false);
    }
  }, [pathname]);
  if (pathname === '/ninpath-check' || pathname === '/ninpath-check/registration') {
    return (
      <Wrapper backgroundColor="#fff" justifyContent="center">
        <HeaderTop>
          <img src={logo} alt="ninpath__logo" />
        </HeaderTop>
      </Wrapper>
    );
  }
  return (
    <Wrapper
      backgroundColor={sidebarIsOpened ? DIMMER_COLOR : '#fff'}
      justifyContent={isNestedRoute ? 'flex-start' : 'center'}
    >
      {!isSpecialRoute ? (
        <>
          {!isNestedRoute ? (
            <>
              <TopMenuIcon>
                <Icon
                  onClick={() => {
                    toggleSideBar();
                  }}
                  name="bars"
                />
              </TopMenuIcon>

              <HeaderTop>
                <img src={logo} alt="ninpath__logo" />
              </HeaderTop>
            </>
          ) : (
            <>
              <Margin marginLeft="14px">
                <NestedBackIndicator
                  title={alias}
                  location={location}
                  pageLabel=""
                />
              </Margin>

              <AbnormalHeaderTop>
                <img src={logo} alt="ninpath__logo" />
              </AbnormalHeaderTop>
            </>
          )}
        </>
      ) : (
        <>
          <Header>
            <img src={logo} alt="ninpath__logo" />
          </Header>
        </>
      )}
    </Wrapper>
  );
};

const mapStateToProps = state => ({
  clinicName: state.clinic.clinicName,
  pathname: state.router.location.pathname,
  location: state.router.location,
});

TopMenu.propTypes = {
  isSpecialRoute: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
  sidebarIsOpened: PropTypes.bool.isRequired,
  toggleSideBar: PropTypes.func.isRequired,
};
export default connect(mapStateToProps)(TopMenu);
