import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Modal } from 'semantic-ui-react';
import Cookies from 'universal-cookie';
import request from '../../utils/request';

const cycleDeleteReducer = (state, action) => {
  switch (action.type) {
    case 'CONFIG_CLOSE_ON_DIMMER_CLICK':
      return { ...state, closeOnDimmerClick: action.value };
    case 'CONFIG_CLOSE_ON_ESCAPE':
      return { ...state, closeOnEscape: action.value };
    case 'OPEN_MODAL':
      return { ...state, open: true };
    case 'CLOSE_MODAL':
      return { ...state, open: false };
    default:
      throw new Error();
  }
};

const CycleDeleteModal = ({ type, cycleId, sequentialId, refresh }) => {
  const cookies = new Cookies();
  const [state, dispatch] = React.useReducer(cycleDeleteReducer, {
    closeOnEscape: true,
    closeOnDimmerClick: true,
    open: false,
    dimmer: undefined,
  });
  const { open, closeOnEscape, closeOnDimmerClick } = state;
  const deleteHandler = () => {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/${type}/${cycleId}`;
    const promise = request(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        charset: 'UTF-8',
        Authorization: `Bearer ${cookies.get('user_token')}`,
      },
    });
    promise.then(() => {
      refresh();
    });
    dispatch({ type: 'CLOSE_MODAL' });
  };
  return (
    <Modal
      closeOnEscape={closeOnEscape}
      closeOnDimmerClick={closeOnDimmerClick}
      open={open}
      onOpen={() => dispatch({ type: 'OPEN_MODAL' })}
      onClose={() => dispatch({ type: 'CLOSE_MODAL' })}
      trigger={<Icon name="trash alternate" />}
    >
      <Modal.Header>周期削除</Modal.Header>
      <Modal.Content>
        <p>{sequentialId} の周期を削除しますか</p>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => dispatch({ type: 'CLOSE_MODAL' })} color="grey">
          キャンセル
        </Button>
        <Button onClick={deleteHandler} color="red">
          削除する
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

CycleDeleteModal.propTypes = {
  type: PropTypes.string.isRequired,
  cycleId: PropTypes.number.isRequired,
  sequentialId: PropTypes.string.isRequired,
  refresh: PropTypes.func.isRequired,
};
export default CycleDeleteModal;
