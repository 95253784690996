/* File of translations from  japanese to english,
 ** necessary to make some requests (This may change later) */
export const DICTIONARY = {
  '': '',
  卵巣刺激薬剤: 'ovarian_stimulant_medicine',
  排卵抑制薬剤: 'ovarian_inhibitor_medicine',
  LHサージ誘起薬剤: 'lh_surge_inducer_medicine',
  採卵数: 'collected_eggs',
  'IVF/ICSI施行率': 'times_of_ivf_or_icsi',
  正常受精率: 'normal_fertilization',
  分割胚到達率: 'early_embryos',
  胚盤胞到達率: 'blastocyst',
  治療開始年齢: 'age',
  'IVF/ICSI施行数': 'times_of_ivf_or_icsi',
  正常受精数: 'normal_fertilization',
  分割胚到達数: 'early_embryos',
  胚盤胞到達数: 'blastocyst',
  治療開始年数: 'age',
  '29歳以下': 'age_under_29',
  '30～33歳': 'age_from_30_to_33',
  '34～37歳': 'age_from_34_to_37',
  '38～41歳': 'age_from_38_to_41',
  '42歳以上': 'age_over_42',
  ステータス: 'status',
  治療中: 'status_treatment_now',
  特になし: '',
  '妊娠中・出産済み': 'status_pregnant_achieved',
  '終了・お休み中': 'status_inactive_finished',
  卵巣子宮等の病歴: 'ovulation_history',
  '卵巣の疾患歴・排卵機能の異常': 'disease_history_ovarian',
  子宮の疾患歴: 'disease_history_uterine',
  甲状腺機能の異常: 'unusual_history_thyroid_function',
  AMH: 'amh',
  '3.0以上': 'amh_over_3',
  '1.0～3.0': 'amh_from_1_to_3',
  '1.0以下': 'amh_under_1',
  '不明・未計測': 'amh_null',
  不妊治療歴: 'treatment_history',
  人工受精まで: 'best_treatment_to_aih',
  体外受精以上: 'best_treatment_ivf_icsi',
  女性: 'woman',
  男性: 'man',
  女性の受診状況: 'woman_status',
  女性の結果: 'woman_result',
  男性の受診状況: 'man_status',
  男性の結果: 'man_result',
  '移植オプション(BT)': 'blastocyst',
  '移植オプション(ET)': 'early',
  胚のグレード: 'grade',
  移植方法: 'method',
};

export const J_ENG_DICTIONARY = {
  age_under_29: '29歳以下',
  age_from_30_to_33: '30～33歳',
  age_from_34_to_37: '34～37歳',
  age_from_38_to_41: '38～41歳',
  age_over_42: '42歳以上',
  status_treatment_now: '治療中',
  status_pregnant_achieved: '妊娠中・出産済み',
  status_inactive_finished: '終了・お休み中',
  disease_history_ovarian: '卵巣の疾患歴・排卵機能の異常',
  disease_history_uterine: '子宮の疾患歴',
  unusual_history_thyroid_function: '甲状腺機能の異常',
  aih: 'AIH回数',
  amh_over_3: '3.0以上',
  amh_from_1_to_3: '1.0～3.0',
  amh_under_1: '1.0以下',
  amh_null: '不明・未計測',
  '': '',
  best_treatment_to_aih: '人工受精まで',
  best_treatment_ivf_icsi: '体外受精以上',
  timing: 'タイミング法回数',
  egg_collecting: '採卵回数',
  transplant: '移植回数',
  anti_sperm_antibody_test: '抗精子抗体',
  chlamydia_antibody_test: 'クラミジア抗原',
  chlamydia_antigen_test: 'クラミジア抗体（採血)',
  hysterosalpingography: '卵管造影',
  sonohysterography: '通気・通水',
  huehner_test: 'フーナーテスト',
  ultrasonic_echo: '超音波エコー',
  hysteroscopy: '子宮鏡検査',
  laparoscopy: '腹腔鏡検査',
  semen_examination: '精液検査',
  mens_inspection_and_palpation: '視診・触診',
  mens_ultrasound: 'エコー検査',
  mens_hormone_levels: 'ホルモン値',
  kruger_test: 'クルーガー',
  dfi: 'DFI',
  orp: 'ORP',
  男性: 'man',
  // clinic_items
  address: '住所',
  parking_lot: '駐車場台数',
  transportation: '交通機関',
  nearest_station: '最寄駅',
  time_required: '所要時間',
  name_kana: 'フリガナ',
  telephone: '電話番号',
  url: 'URL',
  morning: '午前',
  afternoon: '午後',
  others: 'その他',
  off_days: '休診日',
  supported_card: 'カード',
  supported_language: '外国語対応',
  supported_art: '体外受精（ART有無）',
  name: '名前',
  childcare: '託児施設',
  supported_icsi: '顕微授精',
  supported_egg_freezing: '医学的卵子凍結・保存の登録施設',
  supported_embryo_freeing: '医学的胚（受精卵）凍結・保存の登録施設',
  supported_ovarian_freezing: '医学的卵巣組織凍結・保存の登録施設',
  supported_aid: '提供精子を用いた人工授精の登録施設',
  supported_ivfd: '提供精子を用いた体外授精の実施施設',
  jisart: 'JISART加盟施設',
  seet: 'SEET法',
  timelapse: 'タイムラプス',
  scratch: '子宮内膜スクラッチ法',
  picsi: 'PICSI',
  era: 'ERA',
  ema_alice: 'EMMA/ALICE',
  imsi: 'IMSI',
  two_step_et: '二段階胚移植',
  intrauterine_flora: '子宮内フローラ',
  erpeak: 'ERPeak',
  zymot: 'ZyMot',
  cve: '絨毛染色体検査',
  pgta: 'PGT-A（先進医療B）	',
  tacrolimus: 'タクロリムス（先進医療B）',
  pgta_as_self: 'PGT-A/SR（自費診療）',
  pgtm_as_self: 'PGT-M（自費診療）',
  prp_as_self: 'PRP療法（自費診療）',
  pfcfd_as_self: 'PFC-FD（自費診療）',
  number_of_et: '移植件数（2021年実績）'
};
