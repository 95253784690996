import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { LayoutGrid, LayoutContainer } from 'utils/layouts';
import { Card, Form, Grid, Message } from 'semantic-ui-react';
import { areSameValues, isValidField } from 'utils/errors';
import {
  logoutSuccesful,
  showLoader,
  hideLoader,
  ninpathCheckLoginSuccessful,
} from 'containers/TopPage/actions';
import history from 'utils/history';
import request from 'utils/request';
import { ExpiredTokenPage } from 'containers/ErrorPage/ExpiredToken';

const NinpathCheckResetPasswordPage = ({
  location,
  ninpathCheckLogin,
  logout,
  finishLoading,
  startLoading,
}) => {
  const cookies = new Cookies();
  const [pageErrorMessage, setPageErrorMessage] = useState('');
  const [hasTokenExpired, setShowErrorPage] = useState(undefined);
  const [errors, setErrors] = useState([]);
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [resetPasswordToken, setResetPasswordToken] = useState(null);
  useEffect(() => {
    const matches = location.search.match('/?token=(.*)');
    const token = matches && matches.length > 1 ? matches[1] : null;
    const url = `${process.env.REACT_APP_API_URL}/api/v1/amh_password/verify_token`;
    const promise = request(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        charset: 'UTF-8',
        'X-PASSWORD-Token': token,
      },
    });
    promise.then(response => {
      if (!response.valid) {
        setPageErrorMessage(response.messages[0]);
        setShowErrorPage(true);
      } else {
        setResetPasswordToken(token);
        setShowErrorPage(false);
      }
    });
  }, [location]);
  const resetPassword = () => {
    startLoading({ value: true });
    const requestBody = {
      password,
      password_confirmation: passwordConfirm,
      reset_password_token: resetPasswordToken,
    };
    const url = `${process.env.REACT_APP_API_URL}/api/v1/amh_password`;
    const promise = request(url, {
      method: 'PATCH',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json',
        charset: 'UTF-8',
      },
    });
    promise.then(response => {
      if (response.status === 401) logout();
      if (response.status === 503 || response.status === 500) {
        logout(response.status);
      }

      finishLoading({ value: true });
      if (response === undefined || response.messages) {
        setErrors(response.messages);
      } else {
        cookies.set('user_token', response.auth_token, { path: '/' });
        cookies.set('user_name', response.amh_user.name, { path: '/' });
        cookies.set('amh_user_id', response.amh_user.id, { path: '/' });
        ninpathCheckLogin();
        history.push('/ninpath-check/info');
      }
    });
  };
  if (hasTokenExpired !== undefined && !hasTokenExpired) {
    finishLoading({ value: true });
    return (
      <LayoutContainer
        style={{
          marginTop: '60px',
          height: 'calc(100vh - 61px)',
        }}
      >
        <LayoutGrid>
          <Grid.Row>
            <Grid.Column
              mobile={16}
              computer={6}
              tablet={8}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Form
                error
                onSubmit={evt => {
                  evt.preventDefault();
                  resetPassword();
                }}
              >
                <Card style={{ width: '100%' }}>
                  <Card.Content
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <Card.Header>ninpathチェック　パスワードの再設定</Card.Header>
                  </Card.Content>
                  <Card.Content>
                    <p style={{ marginBottom: 14 }}>
                      パスワードは、英字の大文字・小文字、数字を含み、8文字以上である必要があります。
                    </p>
                    <Form.Input
                      label="パスワード (英数字大文字含む, 8文字以上)"
                      placeholder="パスワード (英数字大文字含む, 8文字以上)"
                      type="password"
                      required
                      value={password}
                      onChange={(evt, { value }) => {
                        setPassword(value);
                      }}
                    />
                    {!isValidField('password', password) && (
                      <Message
                        error
                        header="エラー"
                        content="パスワード (英数字大文字含む, 8文字以上)"
                      />
                    )}
                    <Form.Input
                      className="form__password form__password__confirmation"
                      placeholder="確認用パスワード"
                      type="password"
                      required
                      width={16}
                      value={passwordConfirm}
                      onChange={(evt, { value }) => {
                        setPasswordConfirm(value);
                      }}
                    />
                    {!areSameValues(password, passwordConfirm) && (
                      <Message
                        error
                        header="エラー"
                        className="confirmation__error"
                        content="パスワードが一致しません"
                      />
                    )}
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Form.Button
                        className="ninpath__form__container"
                        style={{ minWidth: '180px', maxWidth: '200px' }}
                        disabled={
                          passwordConfirm === '' ||
                          password === '' ||
                          password !== passwordConfirm
                        }
                      >
                        パスワードを新しく設定する
                      </Form.Button>
                    </div>
                    {errors.length > 0 && (
                      <Message negative>
                        {errors.map(error => (
                          <p key={error}>{error}</p>
                        ))}
                      </Message>
                    )}
                  </Card.Content>
                </Card>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </LayoutGrid>
      </LayoutContainer>
    );
  }
  if (hasTokenExpired !== undefined && hasTokenExpired) {
    finishLoading({ value: true });
    return (
      <ExpiredTokenPage
        errorMessage={pageErrorMessage}
        tokenType="amh_password"
      />
    );
  }
  if (hasTokenExpired === undefined) {
    startLoading({ loading: true });
  }
  return <div />;
};
const mapDispatchToProps = dispatch => ({
  ninpathCheckLogin: () => dispatch(ninpathCheckLoginSuccessful()),
  logout: (errorCode = null) => dispatch(logoutSuccesful(errorCode)),
  startLoading: payload => dispatch(showLoader(payload)),
  finishLoading: payload => dispatch(hideLoader(payload)),
});

NinpathCheckResetPasswordPage.propTypes = {
  location: PropTypes.object.isRequired,
  ninpathCheckLogin: PropTypes.any.isRequired,
  logout: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};
export default connect(
  null,
  mapDispatchToProps,
)(withRouter(NinpathCheckResetPasswordPage));
