import styled from 'styled-components';

export const HorizontalLine = styled.hr`
  border-color: rgba(34, 36, 38, 0.1);
  border-top: 0.01px;
  position: relative;
  bottom: 8px;
`;

export const TableUnit = styled.p`
  text-align: right;
  font-size: 0.8rem;
  padding-right: 25px;
  position: relative;
  bottom: 55px;
`;
