import React from 'react';
import PropTypes from 'prop-types';
import { Container, Grid, Segment, Header } from 'semantic-ui-react';
import ClinicItem from 'containers/ClinicDetails/ClinicItem';

const InformationCard = ({ informationHeader, clinic }) => (
  <Container text style={{ paddingTop: 14, paddingBottom: 0 }}>
    <Header
      as="h3"
      style={{
        fontWeight: 400,
        border: 'none',
        display: 'inline-flex',
        borderBottom: '2px solid #000',
        marginBottom: 14,
      }}
      attached="top"
    >
      {informationHeader}
    </Header>
    <Segment attached style={{ border: 'none', paddingBottom: 0 }} as={Grid}>
      {Object.keys(clinic).map(key => {
        if (key !== 'id' && clinic[key] !== null)
          return <ClinicItem key={key} itemKey={key} itemValue={clinic[key]} />;
        return <React.Fragment key={key}></React.Fragment>;
      })}
    </Segment>
  </Container>
);
InformationCard.propTypes = {
  checkPerformance: PropTypes.func.isRequired,
  clinic: PropTypes.object,
  informationHeader: PropTypes.string.isRequired,
  mode: PropTypes.string,
};
InformationCard.defaultProps = {
  mode: 'basic',
};

export default InformationCard;
