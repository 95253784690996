import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { MAIN_COLOR } from 'containers/ChartPage/colors';

import { Button, Header, Segment } from 'semantic-ui-react';
import { LayoutContainer } from 'utils/layouts';
import { ReConfirmExpiredTokenPage } from 'containers/ErrorPage/ReConfirmExpiredToken';
import {
  loginSuccesful,
  showLoader,
  hideLoader,
} from 'containers/TopPage/actions';
import request from 'utils/request';

const ReConfirmationPage = ({ location, finishLoading, startLoading }) => {
  const [pageErrorMessage, setPageErrorMessage] = useState('');
  const [hasTokenExpired, setShowErrorPage] = useState(undefined);
  // here will be the api that will have the user

  useEffect(() => {
    const matches = location.search.match('/?token=(.*)');
    const token = matches && matches.length > 1 ? matches[1] : null;
    const url = `${process.env.REACT_APP_API_URL}/api/v1/email_confirmation/verify_token`;
    const promise = request(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        charset: 'UTF-8',
        'X-Confirmation-Token': token,
      },
    });
    promise.then(response => {
      if (!response.valid || !response) {
        setPageErrorMessage(
          response.errors && response.errors.length > 0
            ? response.errors[0]
            : 'エラー',
        );
        setShowErrorPage(true);
      } else {
        setShowErrorPage(false);
      }
    });
  }, [location]);
  if (hasTokenExpired !== undefined && hasTokenExpired) {
    finishLoading({ value: true });
    return (
      <ReConfirmExpiredTokenPage
        errorMessage={pageErrorMessage}
        tokenType="registration"
      />
    );
  }
  if (hasTokenExpired === undefined) {
    startLoading({ loading: true });
  }
  if (hasTokenExpired !== undefined && !hasTokenExpired) {
    finishLoading({ value: true });

    return (
      <LayoutContainer style={{ marginTop: '70px', height: '100%' }}>
        <div>
          <Segment
            placeholder
            style={{ height: 'calc(100vh - 70px)', paddingTop: 0 }}
            textAlign="center"
          >
            <Header as="h2" style={{ fontWeight: 500 }}>
              <div className="message_container">
                <p style={{ fontSize: '2rem' }}>メールアドレス認証完了</p>
                <p style={{ textAlign: 'center', fontSize: '1rem' }}>
                  利用されるメールアドレスの変更が完了しました。
                </p>
              </div>
            </Header>
            <Button
              primary
              style={{
                backgroundColor: MAIN_COLOR,
                color: '#fff',
                marginTop: 14,
              }}
              as={Link}
              to="/"
            >
              ホームに戻る
            </Button>
          </Segment>
        </div>
      </LayoutContainer>
    );
  }
  return <div />;
};
const mapDispatchToProps = dispatch => ({
  login: () => dispatch(loginSuccesful()),
  startLoading: payload => dispatch(showLoader(payload)),
  finishLoading: payload => dispatch(hideLoader(payload)),
});

export default connect(
  null,
  mapDispatchToProps,
)(React.memo(withRouter(ReConfirmationPage)));

ReConfirmationPage.propTypes = {
  location: PropTypes.object.isRequired,
  login: PropTypes.any.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};
