import { createSelector } from 'reselect';
import { initialState } from './reducers';

const selectProfile = state => state.profile;

const makeSelectErrors = () =>
  createSelector(
    selectProfile,
    profileState => profileState.errors || initialState.errors,
  );
const makeSelectEditSuccessMessage = () =>
  createSelector(
    selectProfile,
    profileState => profileState.editSuccessful,
  );

const makeSelectFetchedData = () =>
  createSelector(
    selectProfile,
    profileState => profileState.userProfileData,
  );

const makeSelectUsername = () =>
  createSelector(
    selectProfile,
    profileState =>
      profileState.userProfileData &&
      profileState.userProfileData.basic_information
        ? profileState.userProfileData.basic_information.name
        : '',
  );
export {
  makeSelectEditSuccessMessage,
  makeSelectFetchedData,
  makeSelectErrors,
  makeSelectUsername,
};
