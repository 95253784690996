export const GET_USER_PROFILE_DATA =
  'ninpath/containers/ProfilePage/GET_USER_PROFILE_DATA';
export const GET_USER_PROFILE_DATA_WAS_SUCCESSFUL =
  'ninpath/containers/ProfilePage/GET_USER_PROFILE_DATA_WAS_SUCCESSFUL';
export const GET_USER_PROFILE_DATA_FAILED =
  'ninpath/containers/ProfilePage/GET_USER_PROFILE_DATA_FAILED';
export const EDIT_USER_PROFILE =
  'ninpath/containers/ProfilePage/EDIT_USER_PROFILE';
export const USER_EDITED_SUCCESSFULLY =
  'ninpath/containers/ProfilePage/USER_EDITED_SUCCESSFULLY';
export const USER_EDITING_FAILED =
  'ninpath/containers/ProfilePage/USER_EDITING_FAILED';
