import styled from 'styled-components';
const FilterText = styled.p`
  display: -webkit-box;
  font-size: 0.85rem;
  max-height: 100%;
  overflow: hidden;
  text-align: center;
  width: 100%;
  /* white-space: nowrap; */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  -webkit-tap-highlight-color: transparent !important;
`;
export default FilterText;
