import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'semantic-ui-react';
import { DICTIONARY, J_ENG_DICTIONARY } from 'utils/dictionary';
import { FilterSelect } from './CardElements';
import './card.css';

const FilterMenu = ({
  activeFilters,
  filterNames,
  subFilters,
  onUpdateSubfilters,
}) => {
  const handleChange = (subFilterIndex, value) => {
    onUpdateSubfilters(subFilterIndex, value);
  };
  return (
    <Menu
      vertical
      fluid
      className="borderless ninpath__filter__list"
      style={{ border: 'none' }}
    >
      {filterNames.map((elm, index) => (
        <Menu.Item key={elm}>
          <span>{elm}</span>
          <FilterSelect
            className="ninpath__filter"
            style={{ textAlign: 'right' }}
            value={
              activeFilters[index] === '特になし'
                ? 'nothing'
                : DICTIONARY[activeFilters[index]]
            }
            placeholder=""
            clearable
            options={subFilters[index]}
            onChange={(evt, { value }) => {
              handleChange(
                index,
                value === 'nothing' ? '特になし' : J_ENG_DICTIONARY[value],
              );
            }}
          />
        </Menu.Item>
      ))}
    </Menu>
  );
};
FilterMenu.propTypes = {
  activeFilters: PropTypes.array.isRequired,
  filterNames: PropTypes.array.isRequired,
  subFilters: PropTypes.array.isRequired,
  onUpdateSubfilters: PropTypes.func.isRequired,
};

export default React.memo(FilterMenu);
