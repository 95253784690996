import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PopupInfo from 'components/PopupInfo';

import { Form, Grid, Tab } from 'semantic-ui-react';
import { validateRequiredFields } from 'utils/errors';
import {
  FINDING_STATUS,
  SPOUSE_EXPERIENCE,
  STATUS_OPTIONS,
  YES_NO_QUESTION,
} from 'utils/defaultOptions';
import MultipleSelectNothingSpecial from './MultipleSelectNothingSpecial';
import { FormField, FormLabel, FormPane, FormSelect } from '../formElements';

const InfertilityResultsManForm = ({
  isFormValid,
  updateFormValues,
  defaultFormValues,
}) => {
  const [formValues, setFormValues] = useState(null);
  const updateFormField = (
    label,
    value,
    isPrimitiveType = true,
    isMultipleSelect = false,
  ) => {
    const newFormValues = JSON.parse(JSON.stringify(formValues));
    if (isPrimitiveType) newFormValues[label] = value;
    else newFormValues[label] = value.map(elm => elm);

    if (isMultipleSelect && newFormValues[label].includes(0)) {
      newFormValues[label] = [0];
      setFormValues(newFormValues);
    } else {
      setFormValues(newFormValues);
    }

    const isValid = validateRequiredFields(newFormValues);
    updateFormValues('man_infertility_test', newFormValues);
    isFormValid(3, isValid);
  };
  useEffect(() => {
    if (defaultFormValues) {
      const newFormValues = JSON.parse(JSON.stringify(defaultFormValues));
      setFormValues(newFormValues);
    }
  }, [defaultFormValues]);
  return (
    <Form.Group>
      {formValues && (
        <Grid centered>
          <Grid.Row>
            <Grid.Column mobile={16} computer={7} tablet={16} floated="left">
              <FormField required>
                <FormLabel htmlFor="urologist_examination">
                  泌尿器科の医師による診察か否か
                </FormLabel>
                <FormSelect
                  className="ninpath__select"
                  id="urologist_examination"
                  placeholder="選択してください"
                  options={YES_NO_QUESTION}
                  value={formValues.urologist_examination}
                  onChange={(evt, { value }) => {
                    updateFormField('urologist_examination', value);
                  }}
                />
              </FormField>
            </Grid.Column>
            <Grid.Column mobile={16} computer={7} tablet={16} floated="right">
              <FormField required>
                <FormLabel htmlFor="semen_examination">
                  <span>精液検査</span>
                  <PopupInfo fieldName="semenExamination" />
                </FormLabel>
                <FormSelect
                  className="ninpath__select"
                  id="semen_examination"
                  placeholder="選択してください"
                  options={STATUS_OPTIONS}
                  value={formValues.semen_examination}
                  onChange={(evt, { value }) => {
                    updateFormField('semen_examination', value);
                  }}
                />
              </FormField>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} computer={7} tablet={16} floated="left">
              <FormField required>
                <FormLabel htmlFor="men_inspection">視診・触診</FormLabel>
                <FormSelect
                  id="men_inspection"
                  className="ninpath__select"
                  placeholder="選択してください"
                  options={STATUS_OPTIONS}
                  value={formValues.mens_inspection_and_palpation}
                  onChange={(evt, { value }) => {
                    updateFormField('mens_inspection_and_palpation', value);
                  }}
                />
              </FormField>
            </Grid.Column>
            <Grid.Column mobile={16} computer={7} tablet={16} floated="right">
              <FormField inline required>
                <FormLabel htmlFor="ultrasound_results">
                  エコー（超音波検査)
                </FormLabel>
                <FormSelect
                  id="ultrasound_results"
                  placeholder="選択してください"
                  className="ninpath__select"
                  options={STATUS_OPTIONS}
                  value={formValues.mens_ultrasound}
                  onChange={(evt, { value }) => {
                    updateFormField('mens_ultrasound', value);
                  }}
                />
              </FormField>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} computer={7} tablet={16} floated="left">
              <FormField inline required>
                <FormLabel htmlFor="kruger_results">
                  クルーガーテスト（精子形態の検査)
                </FormLabel>
                <FormSelect
                  id="kruger_results"
                  className="ninpath__select"
                  placeholder="選択してください"
                  options={STATUS_OPTIONS}
                  value={formValues.kruger_test}
                  onChange={(evt, { value }) => {
                    updateFormField('kruger_test', value);
                  }}
                />
              </FormField>
            </Grid.Column>
            <Grid.Column mobile={16} computer={7} tablet={16} floated="right">
              <FormField inline required>
                <FormLabel htmlFor="dfi">
                  DFI検査（DNA断片化指数検査）
                </FormLabel>
                <FormSelect
                  id="dfi"
                  className="ninpath__select"
                  placeholder="選択してください"
                  options={STATUS_OPTIONS}
                  value={formValues.dfi}
                  onChange={(evt, { value }) => {
                    updateFormField('dfi', value);
                  }}
                />
              </FormField>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} computer={7} tablet={16} floated="left">
              <FormField required>
                <FormLabel htmlFor="orp">
                  ORP検査（精液中酸化還元電位測定）
                </FormLabel>
                <FormSelect
                  id="orp"
                  className="ninpath__select"
                  placeholder="選択してください"
                  options={STATUS_OPTIONS}
                  value={formValues.orp}
                  onChange={(evt, { value }) => {
                    updateFormField('orp', value);
                  }}
                />
              </FormField>
            </Grid.Column>
            <Grid.Column mobile={16} computer={7} tablet={16} floated="right">
              <FormField required>
                <FormLabel htmlFor="men_hormone_levels">
                  ホルモン値（採血)
                </FormLabel>
                <FormSelect
                  id="men_hormone_levels"
                  className="ninpath__select"
                  placeholder="選択してください"
                  options={STATUS_OPTIONS}
                  value={formValues.mens_hormone_levels}
                  onChange={(evt, { value }) => {
                    updateFormField('mens_hormone_levels', value);
                  }}
                />
              </FormField>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} computer={7} tablet={16} floated="left">
              <FormField inline required>
                <FormLabel htmlFor="finding_results">
                  不妊原因となり得る異常
                </FormLabel>

                <MultipleSelectNothingSpecial
                  id="finding_results"
                  name="abnormal_findings"
                  options={FINDING_STATUS}
                  submitValue={formValues.abnormal_findings}
                  changeFunction={updateFormField}
                />
              </FormField>
            </Grid.Column>
            <Grid.Column mobile={16} computer={7} tablet={16} floated="right">
              <FormField inline required>
                <FormLabel htmlFor="men_surgery_experience">
                  手術などの治療歴
                </FormLabel>
                <MultipleSelectNothingSpecial
                  id="men_surgery_experience"
                  name="mens_surgery_experience"
                  options={SPOUSE_EXPERIENCE}
                  submitValue={formValues.mens_surgery_experience}
                  changeFunction={updateFormField}
                />
              </FormField>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </Form.Group>
  );
};

const getForms = (isFormValid, updateFormValues, defaultFormValues) => [
  {
    menuItem: '男性側の不妊検査について',
    render: () => (
      <FormPane attached={false}>
        <InfertilityResultsManForm
          defaultFormValues={defaultFormValues}
          isFormValid={isFormValid}
          updateFormValues={updateFormValues}
        />
      </FormPane>
    ),
  },
];
const InfertilityResultsMan = ({
  isFormValid,
  updateFormValues,
  defaultFormValues,
}) => (
  <Tab
    panes={getForms(isFormValid, updateFormValues, defaultFormValues)}
    menu={{ secondary: true, pointing: true }}
  />
);

InfertilityResultsManForm.propTypes = {
  isFormValid: PropTypes.func.isRequired,
  updateFormValues: PropTypes.func.isRequired,
  defaultFormValues: PropTypes.any.isRequired,
};
InfertilityResultsMan.propTypes = {
  defaultFormValues: PropTypes.any.isRequired,
  isFormValid: PropTypes.func.isRequired,
  updateFormValues: PropTypes.func.isRequired,
};
export default InfertilityResultsMan;
