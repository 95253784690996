import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import history from 'utils/history';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

import { Card, Grid, Tab, Table } from 'semantic-ui-react';
import { LayoutContainer, LayoutGrid } from 'utils/layouts';
import { Padding } from 'components/Atoms';

import { fetchClinicsByPrefecture } from 'containers/ClinicsPage/actions';
import {
  makeSelectFetchedData,
  makeSelectErrors,
} from 'containers/ClinicsPage/selectors';

import SearchForm from 'containers/ClinicsPage/SearchForm';

const searchPanes = (
  fetchData,
  selectedPrefectureId,
  setSelectedPrefectureId,
  setSelectedPrefecture,
  searchByName,
  setIsLoading,
) => [
  {
    menuItem: '検索',
    render: () => (
      <Tab.Pane
        style={{ border: 'none', boxShadow: 'none', padding: 5 }}
        attached={false}
      >
        <SearchForm
          fetchClinics={fetchData}
          selectedPrefectureId={selectedPrefectureId}
          setSelectedPrefecture={setSelectedPrefecture}
          setSelectedPrefectureId={setSelectedPrefectureId}
          searchByName={searchByName}
          setIsLoading={setIsLoading}
        />
      </Tab.Pane>
    ),
  },
];

const resultPanes = (prefectureId, prefectureName, foundClinics, isLoading) => [
  {
    menuItem: '検索結果',
    render: () => (
      <Tab.Pane
        style={{ border: 'none', boxShadow: 'none', padding: 5 }}
        attached={false}
        loading={isLoading}
      >
        {foundClinics && foundClinics.clinics && (
          <Table compact unstackable columns={2} selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>都道府県</Table.HeaderCell>
                <Table.HeaderCell>名前</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body style={{ overflow: 'auto' }}>
              {foundClinics.clinics.map(clinic => (
                <Table.Row
                  key={clinic.id}
                  onClick={() => {
                    history.push({
                      pathname: `/clinics/details/${clinic.id}`,
                      state: { prefectureId },
                    });
                  }}
                >
                  <Table.Cell>{prefectureName}</Table.Cell>
                  <Table.Cell>{clinic.name}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </Tab.Pane>
    ),
  },
];
const ClinicsPage = ({ clinicsData, fetchData, location }) => {
  const [selectedPrefecture, setSelectedPrefecture] = useState('');
  const [selectedPrefectureId, setSelectedPrefectureId] = useState(null);
  const [filteredClinics, setFilteredClinics] = useState(null);
  const [filteredClinicsInitial, setFilteredClinicsInitial] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const searchByName = name => {
    if (name === '') {
      setFilteredClinics(filteredClinicsInitial);
    } else {
      const results = filteredClinicsInitial.clinics.filter(clinic => {
        if (clinic.name.trim().match(name)) {
          return true;
        }
        return false;
      });
      setFilteredClinics({ clinics: results });
    }
  };
  useEffect(() => {
    setFilteredClinics(clinicsData);
    setFilteredClinicsInitial(clinicsData);
    setIsLoading(false);
  }, [clinicsData]);
  useEffect(() => {
    if (location.state !== null) {
      setSelectedPrefectureId(location.state);
    }
  }, [location, setSelectedPrefectureId]);
  return (
    <Padding paddingTop="1rem">
      <LayoutContainer
        style={{
          height: '100%',
        }}
      >
        <LayoutGrid>
          <Grid.Row columns={1}>
            <Grid.Column mobile={16} tablet={10} computer={8}>
              <Card
                style={{ width: '100%', padding: 14 }}
                className="card__registration search_filter"
              >
                <Card.Header>
                  <h3
                    style={{
                      paddingLeft: 14,
                      paddingBottom: 14,
                      fontWeight: 500,
                    }}
                  >
                    クリニック実績
                  </h3>
                </Card.Header>
                <Card.Content
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Tab
                    panes={searchPanes(
                      fetchData,
                      selectedPrefectureId,
                      setSelectedPrefectureId,
                      setSelectedPrefecture,
                      searchByName,
                      setIsLoading,
                    )}
                    menu={{ secondary: true, pointing: true }}
                  ></Tab>
                  <Tab
                    panes={resultPanes(
                      selectedPrefectureId,
                      selectedPrefecture,
                      filteredClinics,
                      isLoading,
                    )}
                    menu={{ secondary: true, pointing: true }}
                  ></Tab>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
        </LayoutGrid>
      </LayoutContainer>
    </Padding>
  );
};

const mapDispatchToProps = dispatch => ({
  fetchData: (payload, params) =>
    dispatch(fetchClinicsByPrefecture(payload, params)),
});

const mapStateToProps = createStructuredSelector({
  errors: makeSelectErrors(),
  clinicsData: makeSelectFetchedData(),
});

ClinicsPage.propTypes = {
  fetchData: PropTypes.func.isRequired,
  clinicsData: PropTypes.object,
  location: PropTypes.object.isRequired,
};
ClinicsPage.defaultProps = {
  clinicsData: {
    clinics: [],
  },
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ClinicsPage));
