import produce from 'immer';
import {
  CYCLE_REGISTRATION_FAILED,
  CYCLE_REGISTRATION_SUCCEEDED,
  CYCLE_REGISTRATION_RESET,
} from './constants';

export const initialState = {
  errors: [],
  registrationSuccess: null,
  attempts: 0,
};

export const CyclesReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case CYCLE_REGISTRATION_SUCCEEDED:
        draft.registrationSuccess = true;
        draft.errors = [];
        draft.attempts += 1;
        break;
      case CYCLE_REGISTRATION_FAILED:
        draft.registrationSuccess = false;
        draft.errors = action.payload ? action.payload.errors : ['エラー'];
        draft.attempts += 1;
        break;
      case CYCLE_REGISTRATION_RESET:
        draft.errors = [];
        draft.registrationSuccess = null;
        draft.attempts = 0;
        break;
      default:
        break;
    }
  });
