import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { BOX_SHADOW } from 'utils/defaults';
import {
  MAIN_COLOR,
  SUB_COLOR,
  SUB_COLOR_2,
} from 'containers/ChartPage/colors';
import { Mask } from 'components/Atoms';

import { Icon } from 'semantic-ui-react'; // add icon

import OptionCircle from 'components/ChartCard/OptionCircle';
const EtTable = ({ tableData }) => {
  const tableRef = useRef(null);
  const [tableOffset, setTableOffset] = useState(0);
  const [showShadow, setShowShadow] = useState(false);
  const [initialWidth, setInitialWidth] = useState(null);
  const [initialWidthInPx, setInitialWidthInPx] = useState('100%');
  const [tableWidth, setTableWidth] = useState(0);

  const onScroll = evt => {
    const newWidth = evt.target.scrollLeft + initialWidth;
    if (newWidth <= tableWidth) {
      setInitialWidthInPx(`${newWidth}px`);
    } else {
      setInitialWidthInPx(`${tableWidth}px`);
    }
    if (evt.target.scrollLeft >= tableOffset) {
      if (showShadow) setShowShadow(false);
    } else if (!showShadow) setShowShadow(true);
  };
  useEffect(() => {
    const originalWidth = tableRef.current.offsetParent.offsetWidth;
    const tableOriginalWidth = tableRef.current.offsetWidth;
    setInitialWidth(originalWidth);
    setTableWidth(tableOriginalWidth);
    setInitialWidthInPx(`${originalWidth}px`);
    const offset = tableOriginalWidth - originalWidth;
    if (offset > 0) setShowShadow(true);
    setTableOffset(offset);
  }, [tableRef]);
  return (
    <div
      className="table-wraper"
      style={{
        overflowX: 'auto',
        overflowY: 'hidden',
        position: 'relative',
        boxShadow: showShadow ? BOX_SHADOW : 'none',
      }}
      onScroll={evt => onScroll(evt)}
    >
      <Mask
        style={{
          boxShadow: showShadow ? BOX_SHADOW : 'none',
          width: initialWidthInPx,
        }}
      />
      {tableData && tableData.columns && tableData.rows && (
        <table style={{ maxWidth: '100vw', borderTop: `1px solid ${SUB_COLOR_2}`, borderBottom: `1px solid ${SUB_COLOR_2}` }} ref={tableRef}>
          <thead>
            <tr>
              {tableData.columns.map((elm, indexCol) => (
                <th
                  key={elm}
                  style={{
                    borderRight:
                      indexCol === 0 ? `1px solid ${SUB_COLOR_2}` : 'none',
                  }}
                >
                  {elm}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.rows.map(row => (
              <tr key={`${row[0]}${Math.random}`}>
                {row.map((col, index) => {
                  if (index === 2) {
                    return (
                      <td key={col} style={{ whiteSpace: 'nowrap' }}>
                        {col.map(egg => (
                          <p key={`${col}${egg}`}>{egg}</p>
                        ))}
                      </td>
                    );
                  }
                  if (index < 4)
                    return (
                      <td
                        key={col}
                        style={{
                          borderRight:
                            index === 0 ? `1px solid ${SUB_COLOR_2}` : 'none',
                          paddingLeft: 17,
                        }}
                      >
                        <p>{col}</p>
                      </td>
                    );
                  if (index === 4) {
                    return (
                      <td
                        style={{
                          color: col === '+' ? MAIN_COLOR : SUB_COLOR_2,
                          fontSize: '18px',
                        }}
                        key={col}
                      >
                        <Icon name={col === '+' ? 'plus' : 'minus'} />
                      </td>
                    );
                  }
                  return (
                    <td key={`key-${Math.random()}`}>
                      {Object.keys(col).map(key => (
                        <OptionCircle
                          key={`select-${Math.random()}`}
                          style={{
                            backgroundColor: col[key] ? MAIN_COLOR : SUB_COLOR,
                            color: col[key] ? '#fff' : '#000',
                          }}
                        >
                          <span style={{ fontSize: '10px' }}>{key}</span>
                        </OptionCircle>
                      ))}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

EtTable.propTypes = {
  tableData: PropTypes.object,
};

EtTable.defaultProps = {
  tableData: {
    columns: [],
  },
};
export default EtTable;
