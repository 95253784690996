import React, { useEffect, useState } from 'react';
import { separateDate } from 'utils/helper';
import PropTypes from 'prop-types';
import {
  areSameValues,
  isValidField,
  validatePersonalInfo,
} from 'utils/errors';
import {
  Form,
  Grid,
  Message,
  Dropdown,
  Responsive,
  Tab,
  Segment,
  Transition,
} from 'semantic-ui-react';
import {
  GENDER_OPTIONS,
  MONTHS,
  PREFECTURE_OPTIONS,
  PROFESSION_OPTIONS,
} from 'utils/defaultOptions';
import {
  BUILD_PI_PAYLOAD,
  GET_VALID_YEARS,
  GET_SPOUSE_VALID_YEARS,
} from 'containers/RegistrationPage/constants';

import {
  MAIN_COLOR,
  SUB_COLOR_3,
  SUB_COLOR_4,
} from 'containers/ChartPage/colors';
import request from 'utils/request';
import Cookies from 'universal-cookie';
import {
  FormField,
  FormInput,
  FormLabel,
  FormPane,
  FormSelect,
} from '../formElements';

export const PersonalInfoForm = ({
  isFormValid,
  updateFormValues,
  showName,
  showEmail,
  showPassword,
  defaultFormValues,
}) => {
  const [hasSucceded, setHasSucceeded] = useState(false);
  const [responseMessages, setResponseMessages] = useState([]);

  const [formValues, setFormValues] = useState(null);
  const [firstRender, setFirstRender] = useState(true);
  const cookies = new Cookies();

  const resendConfirmationMail = () => {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/email_confirmation/reconfirmation_request`;
    const promise = request(url, {
      method: 'POST',
      body: JSON.stringify(),
      headers: {
        'Content-Type': 'application/json',
        charset: 'UTF-8',
        Authorization: `Bearer ${cookies.get('user_token')}`,
      },
    });
    promise.then(response => {
      if (response.email_sent) {
        setHasSucceeded(true);
      } else {
        setHasSucceeded(false);
      }
      setResponseMessages(response.messages);
    });
  };

  const updateFormField = (label, value) => {
    const newFormValues = JSON.parse(JSON.stringify(formValues));
    newFormValues[label] = value;
    setFormValues(newFormValues);

    const isValid = validatePersonalInfo(newFormValues, showName, showEmail);
    if (isValid) {
      const requestPayload = BUILD_PI_PAYLOAD(
        newFormValues,
        showName,
        showEmail,
      );
      updateFormValues('basic_information', requestPayload);
    }
    isFormValid(0, isValid);
  };
  useEffect(() => {
    if (defaultFormValues && firstRender) {
      if (showPassword) {
        setFormValues({
          ...defaultFormValues,
          passwordConfirm: showPassword ? defaultFormValues.password : true,
          password: showPassword ? defaultFormValues.password : true,
        });
      } else {
        const birthday = separateDate(defaultFormValues.birthday);
        const spousesBirthday = separateDate(
          defaultFormValues.spouses_birthday,
        );
        setFormValues({
          name: defaultFormValues.name,
          email: defaultFormValues.email,
          emailConfirm: defaultFormValues.email,
          unconfirmed_email: defaultFormValues.unconfirmed_email,
          gender: defaultFormValues.gender,
          profession: defaultFormValues.profession,
          prefectures: defaultFormValues.prefectures,
          passwordConfirm: showPassword ? defaultFormValues.password : true,
          password: showPassword ? defaultFormValues.password : true,
          birthday,
          spouses_birthday: spousesBirthday,
        });
      }
      setFirstRender(false);
    }
  }, [defaultFormValues, showPassword, firstRender]);
  return (
    <Form.Group>
      {formValues && (
        <Grid centered>
          {showEmail && (
            <Grid.Row style={{ marginBottom: 15 }}>
              {!(formValues.unconfirmed_email === null) && (
                <Grid.Column mobile={16} computer={16} tablet={16}>
                  <Segment
                    style={{ backgroundColor: SUB_COLOR_3, color: SUB_COLOR_4 }}
                  >
                    承認待ちのメールアドレスがあります。
                    <br />
                    {formValues.unconfirmed_email}
                    <Form
                      error
                      onSubmit={evt => {
                        evt.preventDefault();
                        resendConfirmationMail();
                      }}
                    >
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <Form.Button
                          className="ninpath__form__container"
                          style={{
                            maxWidth: '150px',
                            fontSize: 12,
                            marginTop: 10,
                          }}
                        >
                          検証メール再送信
                        </Form.Button>
                      </div>
                      <Transition
                        visible={hasSucceded && responseMessages.length > 0}
                        animation="fade"
                        duration={500}
                      >
                        <Message
                          width={{ style: '50%' }}
                          compact
                          floating
                          success
                          visible={hasSucceded === true}
                          content={responseMessages[0]}
                        />
                      </Transition>
                    </Form>
                  </Segment>
                </Grid.Column>
              )}
            </Grid.Row>
          )}
          {showName && (
            <Grid.Row>
              <Grid.Column mobile={16} computer={16} tablet={16} floated="left">
                <FormField required>
                  <FormLabel htmlFor="name">名前 (ニックネーム)</FormLabel>
                  <FormInput
                    id="name"
                    type="text"
                    placeholder="名前"
                    value={formValues.name}
                    onChange={evt => updateFormField('name', evt.target.value)}
                  />
                </FormField>
              </Grid.Column>
            </Grid.Row>
          )}

          <Grid.Row>
            <Grid.Column mobile={16} computer={16} tablet={16}>
              <FormField required>
                <FormLabel htmlFor="gender">性別</FormLabel>
                <FormSelect
                  id="gender"
                  placeholder="選択してください"
                  options={GENDER_OPTIONS}
                  className="ninpath__select"
                  value={formValues.gender}
                  onChange={(evt, { value }) => {
                    updateFormField('gender', value);
                  }}
                />
              </FormField>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} computer={16} tablet={16} floated="left">
              <FormField required>
                <FormLabel htmlFor="prefectures">都道府県</FormLabel>
                <FormSelect
                  id="prefectures"
                  placeholder="選択してください"
                  className="ninpath__select"
                  search
                  options={PREFECTURE_OPTIONS}
                  value={formValues.prefectures}
                  onChange={(evt, { value }) => {
                    updateFormField('prefectures', value);
                  }}
                />
              </FormField>
            </Grid.Column>
            <Grid.Column mobile={16} computer={16} tablet={16} floated="right">
              <FormField required>
                <FormLabel htmlFor="professions">職業</FormLabel>
                <FormSelect
                  id="professions"
                  placeholder="選択してください"
                  options={PROFESSION_OPTIONS}
                  className="ninpath__select"
                  value={formValues.profession}
                  onChange={(evt, { value }) => {
                    updateFormField('profession', value);
                  }}
                />
              </FormField>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column
              mobile={16}
              computer={16}
              tablet={16}
              floated="left"
              style={{ marginBottom: 14 }}
            >
              <Responsive maxWidth={480}>
                <Form.Field required>
                  <FormLabel htmlFor="birthday">生年月</FormLabel>
                  <div style={{ display: 'flex' }}>
                    <Dropdown
                      placeholder="年"
                      selection
                      className="date"
                      value={formValues.birthday.year}
                      options={GET_VALID_YEARS()}
                      onChange={(evt, { value }) => {
                        const newFormValues = JSON.parse(
                          JSON.stringify(formValues),
                        );
                        newFormValues.birthday.year = value;
                        setFormValues(newFormValues);
                        const isValid = validatePersonalInfo(newFormValues);
                        if (isValid) {
                          const requestPayload = BUILD_PI_PAYLOAD(
                            newFormValues,
                          );
                          updateFormValues('basic_information', requestPayload);
                        }
                        isFormValid(0, isValid);
                      }}
                      style={{ marginRight: 10, minWidth: '30% !important' }}
                    />
                    <Dropdown
                      placeholder="月"
                      selection
                      className="date"
                      value={formValues.birthday.month}
                      options={MONTHS}
                      onChange={(evt, { value }) => {
                        // setBirthdayMonth(value);
                        const newFormValues = JSON.parse(
                          JSON.stringify(formValues),
                        );
                        newFormValues.birthday.month = value;
                        newFormValues.birthday.day = '01';
                        setFormValues(newFormValues);
                        const isValid = validatePersonalInfo(newFormValues);
                        if (isValid) {
                          const requestPayload = BUILD_PI_PAYLOAD(
                            newFormValues,
                          );
                          updateFormValues('basic_information', requestPayload);
                        }
                        isFormValid(0, isValid);
                      }}
                      style={{ marginRight: 10, minWidth: '30% !important' }}
                    />
                  </div>
                </Form.Field>
              </Responsive>
              <Responsive minWidth={481}>
                <FormField required>
                  <FormLabel htmlFor="birthday">生年月</FormLabel>
                  <div className="date__group">
                    <Dropdown
                      placeholder="年"
                      selection
                      className="date"
                      value={formValues.birthday.year}
                      options={GET_VALID_YEARS()}
                      onChange={(evt, { value }) => {
                        const newFormValues = JSON.parse(
                          JSON.stringify(formValues),
                        );
                        newFormValues.birthday.year = value;
                        setFormValues(newFormValues);
                        const isValid = validatePersonalInfo(newFormValues);
                        if (isValid) {
                          const requestPayload = BUILD_PI_PAYLOAD(
                            newFormValues,
                          );
                          updateFormValues('basic_information', requestPayload);
                        }
                        isFormValid(0, isValid);
                      }}
                      style={{ marginRight: 10 }}
                    />

                    <Dropdown
                      placeholder="月"
                      selection
                      className="date"
                      value={formValues.birthday.month}
                      options={MONTHS}
                      onChange={(evt, { value }) => {
                        const newFormValues = JSON.parse(
                          JSON.stringify(formValues),
                        );
                        newFormValues.birthday.month = value;
                        newFormValues.birthday.day = '01';
                        setFormValues(newFormValues);
                        const isValid = validatePersonalInfo(newFormValues);
                        if (isValid) {
                          const requestPayload = BUILD_PI_PAYLOAD(
                            newFormValues,
                          );
                          updateFormValues('basic_information', requestPayload);
                        }
                        isFormValid(0, isValid);
                      }}
                      style={{ marginRight: 10 }}
                    />
                  </div>
                </FormField>
              </Responsive>
            </Grid.Column>
            <Grid.Column
              mobile={16}
              computer={16}
              tablet={16}
              floated="right"
              style={{ marginBottom: 14 }}
            >
              <Responsive maxWidth={480}>
                <Form.Field required>
                  <FormLabel htmlFor="birthday">配偶者の生年月</FormLabel>
                  <div style={{ display: 'flex' }}>
                    <Dropdown
                      placeholder="年"
                      selection
                      className="date"
                      value={formValues.spouses_birthday.year}
                      options={GET_SPOUSE_VALID_YEARS()}
                      onChange={(evt, { value }) => {
                        const newFormValues = JSON.parse(
                          JSON.stringify(formValues),
                        );
                        newFormValues.spouses_birthday.year = value;
                        setFormValues(newFormValues);
                        const isValid = validatePersonalInfo(newFormValues);
                        if (isValid) {
                          const requestPayload = BUILD_PI_PAYLOAD(
                            newFormValues,
                          );
                          updateFormValues('basic_information', requestPayload);
                        }
                        isFormValid(0, isValid);
                      }}
                      style={{ marginRight: 10, minWidth: '30% !important' }}
                    />
                    <Dropdown
                      placeholder="月"
                      selection
                      className="date"
                      value={formValues.spouses_birthday.month}
                      options={MONTHS}
                      onChange={(evt, { value }) => {
                        const newFormValues = JSON.parse(
                          JSON.stringify(formValues),
                        );
                        newFormValues.spouses_birthday.month = value;
                        newFormValues.spouses_birthday.day = '01';
                        setFormValues(newFormValues);
                        const isValid = validatePersonalInfo(newFormValues);
                        if (isValid) {
                          const requestPayload = BUILD_PI_PAYLOAD(
                            newFormValues,
                          );
                          updateFormValues('basic_information', requestPayload);
                        }
                        isFormValid(0, isValid);
                      }}
                      style={{ marginRight: 10, minWidth: '30% !important' }}
                    />
                  </div>
                </Form.Field>
              </Responsive>
              <Responsive minWidth={481}>
                <FormField required>
                  <FormLabel htmlFor="birthday">配偶者の生年月</FormLabel>
                  <div className="date__group">
                    <Dropdown
                      placeholder="年"
                      selection
                      className="date"
                      value={formValues.spouses_birthday.year}
                      options={GET_SPOUSE_VALID_YEARS()}
                      onChange={(evt, { value }) => {
                        const newFormValues = JSON.parse(
                          JSON.stringify(formValues),
                        );
                        newFormValues.spouses_birthday.year = value;
                        setFormValues(newFormValues);
                        const isValid = validatePersonalInfo(newFormValues);
                        if (isValid) {
                          const requestPayload = BUILD_PI_PAYLOAD(
                            newFormValues,
                          );
                          updateFormValues('basic_information', requestPayload);
                        }
                        isFormValid(0, isValid);
                      }}
                      style={{ marginRight: 10 }}
                    />
                    <Dropdown
                      placeholder="月"
                      selection
                      className="date"
                      value={formValues.spouses_birthday.month}
                      options={MONTHS}
                      onChange={(evt, { value }) => {
                        const newFormValues = JSON.parse(
                          JSON.stringify(formValues),
                        );
                        newFormValues.spouses_birthday.month = value;
                        newFormValues.spouses_birthday.day = '01';
                        setFormValues(newFormValues);
                        const isValid = validatePersonalInfo(newFormValues);
                        if (isValid) {
                          const requestPayload = BUILD_PI_PAYLOAD(
                            newFormValues,
                          );
                          updateFormValues('basic_information', requestPayload);
                        }
                        isFormValid(0, isValid);
                      }}
                      style={{ marginRight: 10 }}
                    />
                  </div>
                </FormField>
              </Responsive>
            </Grid.Column>
          </Grid.Row>
          {showEmail && (
            <Grid.Row>
              <Grid.Column mobile={16} computer={16} tablet={16} floated="left">
                <Form.Input
                  name="form__email"
                  label="メールアドレス"
                  className="form__email"
                  placeholder="メールアドレス"
                  type="email"
                  value={formValues.email}
                  onChange={evt => updateFormField('email', evt.target.value)}
                  width={16}
                  required
                  id="change_user__form__email"
                />
                {!isValidField('email', formValues.email) && (
                  <Message
                    error
                    header="エラー"
                    content="メールアドレスの形は'xxxxxx@xxxxx.xxxxx'"
                    style={{ marginBottom: 15 }}
                  />
                )}
              </Grid.Column>
              <Grid.Column mobile={16} computer={16} tablet={16}>
                <Form.Input
                  className="form__email form__email__confirmation"
                  placeholder="確認用メールアドレス"
                  type="email"
                  width={16}
                  value={formValues.emailConfirm}
                  onChange={evt =>
                    updateFormField('emailConfirm', evt.target.value)
                  }
                />
                {!areSameValues(formValues.email, formValues.emailConfirm) && (
                  <Message
                    error
                    header="エラー"
                    content="メールアドレスが一致しません"
                    className="confirmation__error"
                  />
                )}
                <div
                  style={{
                    color: MAIN_COLOR,
                    padding: 15,
                  }}
                >
                  メールアドレスを変更する場合は、メールアドレスに送信された検証メールを承認するまで反映されません。
                </div>
              </Grid.Column>
            </Grid.Row>
          )}
          {showPassword && (
            <Grid.Row>
              <Grid.Column mobile={16} computer={16} tablet={16} floated="left">
                <Form.Input
                  label="パスワード (英数字大文字含む, 8文字以上)"
                  placeholder="パスワード (英数字大文字含む, 8文字以上)"
                  type="password"
                  value={formValues.password}
                  onChange={evt =>
                    updateFormField('password', evt.target.value)
                  }
                  width={16}
                  className="form__password"
                  style={{
                    marginBottom: 14,
                  }}
                  required
                />
                {!isValidField('password', formValues.password) && (
                  <Message
                    error
                    header="エラー"
                    content="パスワード (英数字大文字含む, 8文字以上)"
                  />
                )}
              </Grid.Column>
              <Grid.Column
                mobile={16}
                computer={16}
                tablet={16}
                floated="right"
                id="password__confirmation"
              >
                <Form.Input
                  className="form__password form__password__confirmation"
                  placeholder="確認用パスワード"
                  type="password"
                  width={16}
                  value={formValues.passwordConfirm}
                  onChange={evt =>
                    updateFormField('passwordConfirm', evt.target.value)
                  }
                />
                {!areSameValues(
                  formValues.password,
                  formValues.passwordConfirm,
                ) && (
                  <Message
                    error
                    header="エラー"
                    className="confirmation__error"
                    content="パスワードが一致しません"
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      )}
    </Form.Group>
  );
};

const getForms = (isFormValid, updateFormValues, defaultFormValues) => [
  {
    menuItem: 'ユーザー情報',
    render: () => (
      <FormPane attached={false}>
        <PersonalInfoForm
          defaultFormValues={defaultFormValues}
          isFormValid={isFormValid}
          updateFormValues={updateFormValues}
          showPassword
        />
      </FormPane>
    ),
  },
];

const PersonalInfo = ({ isFormValid, updateFormValues, defaultFormValues }) => (
  <Tab
    panes={getForms(isFormValid, updateFormValues, defaultFormValues)}
    menu={{ secondary: true, pointing: true }}
  />
);

PersonalInfoForm.propTypes = {
  isFormValid: PropTypes.func.isRequired,
  showName: PropTypes.bool,
  showEmail: PropTypes.bool,
  showPassword: PropTypes.bool,
  updateFormValues: PropTypes.func.isRequired,
  defaultFormValues: PropTypes.any,
};
PersonalInfoForm.defaultProps = {
  showPassword: true,
  showName: false,
  showEmail: false,
  defaultFormValues: null,
};
PersonalInfo.propTypes = {
  defaultFormValues: PropTypes.any.isRequired,
  isFormValid: PropTypes.func.isRequired,
  updateFormValues: PropTypes.func.isRequired,
};

export default PersonalInfo;
