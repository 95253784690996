import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Grid, Tab } from 'semantic-ui-react';
import { validateRequiredFields } from 'utils/errors';

import PopupInfo from 'components/PopupInfo';
import {
  STATUS_OPTIONS,
  HORMONE_LEVELS,
  OTHER_TESTS,
  SURGERY_EXPERIENCE,
  HYSTEROSALPINGOGRAPHY_OPTIONS,
  SONOHYSTEROGRAPHY_OPTIONS,
  ULTRASONIC_ECHO_OPTIONS,
  HYSTEROSCOPY_OPTIONS,
  LAPAROSCOPY_OPTIONS,
} from 'utils/defaultOptions';
import MultipleSelectNothingSpecial from './MultipleSelectNothingSpecial';
import { FormField, FormLabel, FormPane, FormSelect } from '../formElements';

const InfertilityResultsWomanForm = ({
  defaultFormValues,
  isFormValid,
  updateFormValues,
}) => {
  const [formValues, setFormValues] = useState(null);
  const updateFormField = (
    label,
    value,
    isPrimitiveType = true,
    isMultipleSelect = false,
  ) => {
    const newFormValues = JSON.parse(JSON.stringify(formValues));
    if (isPrimitiveType) newFormValues[label] = value;
    else newFormValues[label] = value.map(elm => elm);

    if (isMultipleSelect && newFormValues[label].includes(0)) {
      newFormValues[label] = [0];
      setFormValues(newFormValues);
    } else {
      setFormValues(newFormValues);
    }
    const isValid = validateRequiredFields(newFormValues);
    updateFormValues('woman_infertility_test', newFormValues);
    isFormValid(2, isValid);
  };
  useEffect(
    () => {
      if (defaultFormValues) {
        const newFormValues = JSON.parse(JSON.stringify(defaultFormValues));
        setFormValues(newFormValues);
      }
    },
    // eslint-disable-next-line
    [],
  );
  return (
    <Form.Group>
      {formValues && (
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} computer={7} tablet={16} floated="left">
              <FormField inline required>
                <FormLabel htmlFor="ultrasonic_echo">
                  超音波エコーで疑われた異常
                </FormLabel>
                <MultipleSelectNothingSpecial
                  id="ultrasonic_echo"
                  name="ultrasonic_echo"
                  options={ULTRASONIC_ECHO_OPTIONS}
                  submitValue={formValues.ultrasonic_echo}
                  changeFunction={updateFormField}
                  additionalExclusionNumber={1}
                />
              </FormField>
            </Grid.Column>
            <Grid.Column mobile={16} computer={7} tablet={16} floated="right">
              <FormField inline required>
                <FormLabel htmlFor="hysterosalpingography">
                  卵管造影検査で疑われた異常
                </FormLabel>
                <MultipleSelectNothingSpecial
                  id="hysterosalpingography"
                  name="hysterosalpingography"
                  options={HYSTEROSALPINGOGRAPHY_OPTIONS}
                  submitValue={formValues.hysterosalpingography}
                  changeFunction={updateFormField}
                  additionalExclusionNumber={1}
                />
              </FormField>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} computer={7} tablet={16} floated="left">
              <FormField required>
                <FormLabel htmlFor="sonohysterography">
                  卵管通気・通水検査で疑われた異常
                </FormLabel>
                <MultipleSelectNothingSpecial
                  id="sonohysterography"
                  name="sonohysterography"
                  options={SONOHYSTEROGRAPHY_OPTIONS}
                  submitValue={formValues.sonohysterography}
                  changeFunction={updateFormField}
                  additionalExclusionNumber={1}
                />
              </FormField>
            </Grid.Column>
            <Grid.Column mobile={16} computer={7} tablet={16} floated="right">
              <FormField required>
                <FormLabel htmlFor="hysteroscopy">
                  子宮鏡検査で疑われた異常
                </FormLabel>
                <MultipleSelectNothingSpecial
                  id="hysteroscopy"
                  name="hysteroscopy"
                  options={HYSTEROSCOPY_OPTIONS}
                  submitValue={formValues.hysteroscopy}
                  changeFunction={updateFormField}
                  additionalExclusionNumber={1}
                />
              </FormField>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} computer={7} tablet={16} floated="left">
              <FormField required>
                <FormLabel htmlFor="laparoscopy">
                  腹腔鏡検査で疑われた異常
                </FormLabel>
                <MultipleSelectNothingSpecial
                  id="laparoscopy"
                  name="laparoscopy"
                  options={LAPAROSCOPY_OPTIONS}
                  submitValue={formValues.laparoscopy}
                  changeFunction={updateFormField}
                  additionalExclusionNumber={1}
                />
              </FormField>
            </Grid.Column>
            <Grid.Column mobile={16} computer={7} tablet={16} floated="right">
              <FormField required>
                <FormLabel htmlFor="huehner_test">フーナーテスト</FormLabel>
                <FormSelect
                  id="huehner_test"
                  className="ninpath__select"
                  placeholder="選択してください"
                  options={STATUS_OPTIONS}
                  value={formValues.huehner_test}
                  onChange={(evt, { value }) => {
                    updateFormField('huehner_test', value);
                  }}
                />
              </FormField>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} computer={7} tablet={16} floated="left">
              <FormField inline required>
                <FormLabel htmlFor="anti_sperm_antibody_test">
                  抗精子抗体検査(採血)
                </FormLabel>
                <FormSelect
                  id="anti_sperm_antibody_test"
                  className="ninpath__select"
                  placeholder="選択してください"
                  options={STATUS_OPTIONS}
                  value={formValues.anti_sperm_antibody_test}
                  onChange={(evt, { value }) => {
                    updateFormField('anti_sperm_antibody_test', value);
                  }}
                />
              </FormField>
            </Grid.Column>
            <Grid.Column mobile={16} computer={7} tablet={16} floated="right">
              <FormField inline required>
                <FormLabel htmlFor="cervical_cancer_screening">
                  子宮頸がん検診
                </FormLabel>
                <FormSelect
                  id="cervical_cancer_screening"
                  className="ninpath__select"
                  placeholder="選択してください"
                  options={STATUS_OPTIONS}
                  value={formValues.cervical_cancer_screening}
                  onChange={(evt, { value }) => {
                    updateFormField('cervical_cancer_screening', value);
                  }}
                />
              </FormField>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} computer={7} tablet={16} floated="left">
              <FormField required>
                <FormLabel htmlFor="chlamydia_antigen_test">
                  クラミジア抗原検査
                </FormLabel>
                <FormSelect
                  id="chlamydia_antigen_test"
                  className="ninpath__select"
                  placeholder="選択してください"
                  options={STATUS_OPTIONS}
                  value={formValues.chlamydia_antigen_test}
                  onChange={(evt, { value }) => {
                    updateFormField('chlamydia_antigen_test', value);
                  }}
                />
              </FormField>
            </Grid.Column>
            <Grid.Column mobile={16} computer={7} tablet={16} floated="right">
              <FormField required>
                <FormLabel htmlFor="chlamydia_antibody_test">
                  クラミジア抗体検査（採血)
                </FormLabel>
                <FormSelect
                  id="chlamydia_antibody_test"
                  className="ninpath__select"
                  placeholder="選択してください"
                  options={STATUS_OPTIONS}
                  value={formValues.chlamydia_antibody_test}
                  onChange={(evt, { value }) => {
                    updateFormField('chlamydia_antibody_test', value);
                  }}
                />
              </FormField>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} computer={7} tablet={16} floated="left">
              <FormField required>
                <FormLabel htmlFor="womans_hormone_levels">
                  <span>異常を指摘されたホルモン値</span>
                  <PopupInfo fieldName="womenHormoneLevels" />
                </FormLabel>

                <MultipleSelectNothingSpecial
                  id="womans_hormone_levels"
                  name="womans_hormone_levels"
                  options={HORMONE_LEVELS}
                  submitValue={formValues.womans_hormone_levels}
                  changeFunction={updateFormField}
                />
              </FormField>
            </Grid.Column>
            <Grid.Column mobile={16} computer={7} tablet={16} floated="right">
              <FormField inline>
                <FormLabel htmlFor="other_test_of_ovarian_function">
                  異常を指摘された卵巣機能等を示すその他の検査
                </FormLabel>
                <MultipleSelectNothingSpecial
                  id="other_test_of_ovarian_function"
                  name="other_test_of_ovarian_function"
                  options={OTHER_TESTS}
                  submitValue={formValues.other_test_of_ovarian_function}
                  changeFunction={updateFormField}
                />
              </FormField>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} computer={7} tablet={16} floated="left">
              <FormField required>
                <FormLabel htmlFor="womans_surgery_experience">
                  手術などの治療歴
                </FormLabel>

                <MultipleSelectNothingSpecial
                  id="womans_surgery_experience"
                  name="womans_surgery_experience"
                  options={SURGERY_EXPERIENCE}
                  submitValue={formValues.womans_surgery_experience}
                  changeFunction={updateFormField}
                />
              </FormField>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </Form.Group>
  );
};
const getForms = (defaultFormValues, isFormValid, updateFormValues) => [
  {
    menuItem: '女性側の不妊検査について',
    render: () => (
      <FormPane attached={false}>
        <InfertilityResultsWomanForm
          isFormValid={isFormValid}
          updateFormValues={updateFormValues}
          defaultFormValues={defaultFormValues}
        />
      </FormPane>
    ),
  },
];
const InfertilityResultsWoman = ({
  isFormValid,
  updateFormValues,
  defaultFormValues,
}) => (
  <Tab
    panes={getForms(defaultFormValues, isFormValid, updateFormValues)}
    menu={{ secondary: true, pointing: true }}
  />
);

InfertilityResultsWomanForm.propTypes = {
  isFormValid: PropTypes.func.isRequired,
  defaultFormValues: PropTypes.any,
  updateFormValues: PropTypes.func.isRequired,
};
InfertilityResultsWoman.propTypes = {
  defaultFormValues: PropTypes.any.isRequired,
  isFormValid: PropTypes.func.isRequired,
  updateFormValues: PropTypes.func.isRequired,
};
export default InfertilityResultsWoman;
