import React from 'react';
import PropTypes from 'prop-types';

import { Container } from 'semantic-ui-react';
import { DataNotFoundMessage } from 'containers/ErrorPage/DataNotFound';
import { Margin, Padding } from 'components/Atoms';
import { CLINICS_TABLE, CLINICS_BARS } from 'utils/defaults';
import { CLINICS_BARS_OPTIONS } from 'containers/ChartPage/options';

import Graph from 'components/Graph';

const ClinicGraphs = ({ clinicGraphs }) => (
  <Container>
    {clinicGraphs && clinicGraphs.table && (
      <Container>
        <Graph
          dataToPlot={clinicGraphs.table}
          pageType="stats"
          type={CLINICS_TABLE}
        />
      </Container>
    )}
    {clinicGraphs && clinicGraphs.bar && (
      <Margin marginTop="14px">
        <Padding paddingBottom="14px">
          <Container>
            {!clinicGraphs.bar.empty ? (
              <Graph
                dataToPlot={JSON.parse(JSON.stringify(clinicGraphs.bar))}
                pageType="stats"
                type={CLINICS_BARS}
                options={CLINICS_BARS_OPTIONS}
              />
            ) : (
              <DataNotFoundMessage message="対象のデータが見つかりませんでした" />
            )}
          </Container>
        </Padding>
      </Margin>
    )}
  </Container>
);

ClinicGraphs.propTypes = {
  clinicGraphs: PropTypes.object,
};
ClinicGraphs.defaultProps = {
  clinicGraphs: {},
};
export default ClinicGraphs;
