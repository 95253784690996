import React from 'react';
import { Icon } from 'semantic-ui-react';

export const BrowserNotSupported = () => (
  <div style={{ textAlign: 'center', height: '100vh', padding: 15 }}>
    <div className="centered__block">
      <Icon name="warning" style={{ fontSize: 48 }} />
      <h1 style={{ marginTop: 0 }}>Browser not supported</h1>
      <p>
        ご利用のブラウザはサポート対象外です。Edge、
        <a href="https://www.google.com/chrome/">Chrome</a>、
        <a href="https://support.apple.com/downloads/safari">Safari</a>、
        <a href="https://www.mozilla.org/en-US/firefox/new/">Firefox</a>
        でアクセスお願いいたします.
      </p>
    </div>
  </div>
);
