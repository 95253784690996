import Cookies from 'universal-cookie';
import request from 'utils/request';

import { call } from 'redux-saga/effects';

import { handleResponse } from 'utils/sagasHelpers';

import {
  FETCH_CLINICS_SUCCEEDED,
  FETCH_CLINICS_FAILED,
} from 'containers/ClinicsPage/constants';

import { urls } from 'utils/urls';

export function* fetchClinicsDataByPrefecture(action) {
  const cookies = new Cookies();
  const url = `${process.env.REACT_APP_API_URL}${urls.clinics}?prefecture=${action.params}`;
  const response = yield call(request, url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      charset: 'UTF-8',
      Authorization: `Bearer ${cookies.get('user_token')}`,
    },
  });
  yield handleResponse(response, FETCH_CLINICS_SUCCEEDED, FETCH_CLINICS_FAILED);
}
