import { Card, Menu, Select } from 'semantic-ui-react';
import styled from 'styled-components';

export const TypeContainer = styled.div`
  padding: 10px;
  height: 36px;
  display: flex;
  align-items: center;
`;

export const FilterContainer = styled.div`
  float: right;
  width: 80%;
  text-align: right;
  overflow: hidden;
`;

export const FilterNamesList = styled.p`
  font-size: 1.2rem;
  max-width: 100px;
  margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 70%;
  margin-bottom: 0px !important;
  display: inline-block;
`;

export const ChartName = styled.h5`
  margin-top: 0px;
  font-weight: 400;
  font-size: 1.3rem;
  padding-left: 14px;
`;

export const CardHeader = styled(Card.Header)`
  font-size: 2.2rem !important;
  font-weight: 400 !important;
`;

export const FilterSelect = styled(Select)`
  float: right !important;
  border: none !important;
  min-width: 120px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
`;

export const FilterMenu = styled(Menu)`
  width: 100% !important;
  margin: 0px;
  border: none !important;
  box-shadow: none !important;
`;

export const Circle = styled.div`
  border-radius: 50%;
  width: 10px;
  display:inline-block
  height: 10px;
`;
