import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Card, Grid, Header, Segment, Tab } from 'semantic-ui-react';

import { logoutSuccesful, showLoader } from 'containers/TopPage/actions';
import {
  makeSelectErrorMessages,
  makeSelectMessageSent,
  makeSelectSuccessMessages,
} from 'containers/RegistrationPage/selectors';
import { sendAmhConfirmationMail } from 'containers/RegistrationPage/actions';
import { LayoutGrid, LayoutContainer } from 'utils/layouts';
import { MAIN_COLOR } from 'containers/ChartPage/colors';
import AmhUserRegistrationForm from './forms/AmhUserRegistrationForm';

const userPane = (startLoading, sendRegistrationEmail, errorMessages) => [
  {
    menuItem: 'ninpathチェック　新規登録',
    render: () => (
      <Tab.Pane
        style={{ border: 'none', boxShadow: 'none', padding: 5 }}
        attached={false}
      >
        <AmhUserRegistrationForm
          startLoading={startLoading}
          sendEmail={sendRegistrationEmail}
          errorMessages={errorMessages}
        />
      </Tab.Pane>
    ),
  },
];
const NinpathCheckRegistrationPage = ({
  errorMessages,
  emailWasSent,
  sendRegistrationEmail,
  successMessages,
  startLoading,
}) => (
  <LayoutContainer style={{ marginTop: '70px', height: '100%' }}>
    {!emailWasSent ? (
      <LayoutGrid>
        <Grid.Row columns={1}>
          <Grid.Column mobile={16} tablet={10} computer={6}>
            <Card style={{ width: '100%' }}>
              <Card.Content>
                <Tab
                  panes={userPane(
                    startLoading,
                    sendRegistrationEmail,
                    errorMessages,
                  )}
                  menu={{ secondary: true, pointing: true }}
                />
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </LayoutGrid>
    ) : (
      <div>
        <Segment
          placeholder
          style={{ height: 'calc(100vh - 70px)', paddingTop: 0 }}
          textAlign="center"
        >
          <Header as="h2" style={{ fontWeight: 500 }}>
            {successMessages[0]}
            <br />
            <br />
            {successMessages.map((elm, index) => {
              if (index === 0) return <template />;
              return <span>{elm}</span>;
            })}
          </Header>
          <Button
            primary
            style={{
              backgroundColor: MAIN_COLOR,
              color: '#fff',
              marginTop: 14,
            }}
            as={Link}
            to="/"
          >
            ホームに戻る
          </Button>
        </Segment>
      </div>
    )}
  </LayoutContainer>
);

const mapDispatchToProps = dispatch => ({
  logout: (errorCode = null) => dispatch(logoutSuccesful(errorCode)),
  startLoading: payload => dispatch(showLoader(payload)),
  sendRegistrationEmail: payload => dispatch(sendAmhConfirmationMail(payload)),
});

const mapStateToProps = createStructuredSelector({
  emailWasSent: makeSelectMessageSent(),
  errorMessages: makeSelectErrorMessages(),
  successMessages: makeSelectSuccessMessages(),
});

NinpathCheckRegistrationPage.propTypes = {
  emailWasSent: PropTypes.bool.isRequired,
  errorMessages: PropTypes.array,
  successMessages: PropTypes.array,
  startLoading: PropTypes.func.isRequired,
  sendRegistrationEmail: PropTypes.func.isRequired,
};
NinpathCheckRegistrationPage.defaultProps = {
  errorMessages: [],
  successMessages: [],
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NinpathCheckRegistrationPage);
