export const NESTED_ROUTES = [
  '/clinics/details',
  '/cycles/opuCycle',
  '/cycles/etCycle',
  '/cycles/timingCycle',
  '/cycles/offCycle',
  '/cycles/aihCycle',
  '/drugUsage',
];

export const GUEST_ROUTES = ['/registration', '/forgotPassword', '/amh'];
export const ROUTE_LABELS = {
  clinicDetails: 'クリニック',
  opuCycle: 'IVF/ICSI採卵',
  forgotPassword: 'パスワードの再設定',
  etCycle: 'IVF/ICSI移植',
  registration: '新規登録',
  timingCycle: 'タイミング法',
  aihCycle: '人工授精',
  profile: 'プロフィール編集',
  offCycle: '治療お休み周期',
  drugUsage: '使用した薬剤リスト',
};

export const SPECIAL_ROUTES = [
  '/user/confirmation',
  '/user/reconfirmation',
  '/user/amh_confirmation',
  '/user/amh_reconfirmation',
];
