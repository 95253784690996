export const EDIT_OPU_CYCLE = 'EDIT_OPU_CYCLE';
export const EDIT_ET_CYCLE = 'EDIT_ET_CYCLE';
export const EDIT_TIMING_CYCLE = 'EDIT_TIMING_CYCLE';
export const EDIT_AIH_CYCLE = 'EDIT_AIH_CYCLE';
export const EDIT_OFF_CYCLE = 'EDIT_OFF_CYCLE';
export const REGISTER_OPU_CYCLE = 'REGISTER_OPU_CYCLE';
export const REGISTER_ET_CYCLE = 'REGISTER_ET_CYCLE';
export const REGISTER_TIMING_CYCLE = 'REGISTER_TIMING_CYCLE';
export const REGISTER_AIH_CYCLE = 'REGISTER_AIH_CYCLE';
export const REGISTER_TRANSPLANT_CYCLE = 'REGISTER_TRANSPLANT_CYCLE';
export const REGISTER_TREATMENT_CYCLE = 'REGISTER_TREATMENT_CYCLE';
export const REGISTER_OFF_CYCLE = 'REGISTER_OFF_CYCLE';

export const CYCLE_EDIT_SUCCEEDED = 'CYCLE_EDIT_SUCCEEDED';
export const CYCLE_EDIT_FAILED = 'CYCLE_EDIT_FAILED';

export const CYCLE_REGISTRATION_SUCCEEDED = 'CYCLE_REGISTRATION_SUCCEEDED';
export const CYCLE_REGISTRATION_FAILED = 'CYCLE_REGISTRATION_FAILED';
export const CYCLE_REGISTRATION_RESET = 'CYCLE_REGISTRATION_FAILED';

export const BLASTOCYST = 4;
export const CYCLE_LABELS = ['採卵', '移植', 'AIH', 'タイミング', 'お休み'];
export const CYCLE_DATA = [
  {
    tableLabels: ['周期', '治療期間', '採卵数', true],
    data: [
      [6, '10/1 - 10/31', 3, ''],
      [5, '10/1 - 10/31', 3, ''],
      [4, '10/1 - 10/31', 3, ''],
    ],
  },
  {
    tableLabels: ['周期', '治療期間', '移植数', true],
    data: [
      [6, '10/1 - 10/31', 3, true],
      [5, '10/1 - 10/31', 3, true],
      [4, '10/1 - 10/31', 3, true],
    ],
  },
  {
    tableLabels: ['周期', '治療期間', true],
    data: [
      [6, '10/1 - 10/31', true],
      [5, '10/1 - 10/31', true],
      [4, '10/1 - 10/31', true],
    ],
  },
  {
    tableLabels: ['周期', '治療期間', true],
    data: [
      [6, '10/1 - 10/31', true],
      [5, '10/1 - 10/31', true],
      [4, '10/1 - 10/31', true],
    ],
  },
  {
    tableLabels: ['周期', '治療期間', true],
    data: [
      [6, '10/1 - 10/31', true],
      [5, '10/1 - 10/31', true],
      [4, '10/1 - 10/31', true],
    ],
  },
];

export const CYCLE_API_URLS = {
  採卵: 'opu_cycles',
  移植: 'et_cycles',
  AIH: 'aih_cycles',
  タイミング: 'timing_cycles',
  お休み: 'off_cycles',
};
export const CYCLE_BROWSER_URLS = {
  採卵: 'opuCycle',
  移植: 'etCycle',
  AIH: 'aihCycle',
  タイミング: 'timingCycle',
  お休み: 'offCycle',
};
export const BUILD_STIMULATION_MEDICINE_PAYLOAD = (
  stimulationMethod,
  ovarianStimulantMedicine,
  ovarianInhibitorMedicine,
  lhSurgeInducerMedicine,
  otherMedicine,
  otherOvarianStimulantMedicine,
  otherOvarianInhibitorMedicine,
  otherLHMedicine,
) => ({
  stimulation_method: stimulationMethod,
  ovarian_stimulant_medicine: ovarianStimulantMedicine,
  ovarian_inhibitor_medicine: ovarianInhibitorMedicine,
  lh_surge_inducer_medicine: lhSurgeInducerMedicine,
  other_medicine: otherMedicine,
  other_ovarian_stimulant_medicine: otherOvarianStimulantMedicine,
  other_ovarian_inhibitor_medicine: otherOvarianInhibitorMedicine,
  other_lh_surge_inducer_medicine: otherLHMedicine,
});
export const BUILD_SEMEN_RESULTS_PAYLOAD = (
  semenVolume,
  spermConcentration,
  spermMotility,
  pmsc,
  normalForms,
  totalAmountOfSperm,
  memo,
) => ({
  semen_volume: semenVolume,
  sperm_concentration: spermConcentration,
  sperm_motility: spermMotility,
  pmsc,
  normal_forms: normalForms,
  total_amount_of_sperm: totalAmountOfSperm,
  memo,
});

export const BUILD_OPU_RESULTS_PAYLOAD = (
  opuDay,
  numCollectedEggs,
  ivfTimes,
  fertilizationMethod,
  embryoCultureOption,
  normalFertilizationTimes,
  numEarlyEmbryos,
  numBlastocysts,
  endometrialThickness,
) => ({
  day_of_opu: opuDay,
  number_of_collected_eggs: numCollectedEggs,
  times_of_ivf_or_icsi: ivfTimes,
  fertilization_method: fertilizationMethod,
  embryo_culture_option: embryoCultureOption,
  number_of_normal_fertilization: normalFertilizationTimes,
  number_of_early_embryos: numEarlyEmbryos,
  number_of_blastocysts: numBlastocysts,
  endometrial_thickness: endometrialThickness,
});

export const BUILD_TREATMENT_RESULTS_PAYLOAD = (
  startedAt,
  endedAt,
  selectedClinicId,
  selectedClinicName,
) => ({
  started_at: startedAt,
  ended_at: endedAt,
  clinic_id: selectedClinicId,
  clinic_name: selectedClinicName,
});

export const BUILD_TIMING_DRUG__PAYLOAD = (
  ovarianStimulantMedicine,
  hormoneReplacement,
  treatmentPeriodResults,
  otherStimulation,
  otherOvarianMedicine,
  otherHormoneMedicine,
  otherLHMedicine,
) => ({
  ovarian_stimulant_medicine: ovarianStimulantMedicine,
  hormone_replacement_medicine: hormoneReplacement,
  lh_surge_inducer_medicine: treatmentPeriodResults,
  other_medicine: otherStimulation,
  other_ovarian_stimulant_medicine: otherOvarianMedicine,
  other_hormone_replacement_medicine: otherHormoneMedicine,
  other_lh_surge_inducer_medicine: otherLHMedicine,
});
export const BUILD_DRUG_USAGE_PAYLOAD = (
  hormoneReplacement,
  treatmentPeriodResults,
  otherStimulation,
  otherHormoneMedicine,
  otherLhMedicine,
) => ({
  hormone_replacement_medicine: hormoneReplacement,
  other_hormone_replacement_medicine: otherHormoneMedicine,
  lh_surge_inducer_medicine: treatmentPeriodResults,
  other_lh_surge_inducer_medicine: otherLhMedicine,
  other_medicine: otherStimulation,
});

export const BUILD_TRASPLANT_PAYLOAD = (
  transferMethod,
  transferOptions,
  eggAttributes,
  endometrialThickness,
  memo,
) => ({
  transfer_method: transferMethod,
  transfer_options: transferOptions,
  eggs_attributes: eggAttributes,
  endometrial_thickness: endometrialThickness,
  memo,
});
export const BUILD_OFF_MEDICINE_PAYLOAD = (
  hormoneDrugs,
  otherMedicine,
  otherHormoneDrugs,
  memo,
) => ({
  hormone_drugs: hormoneDrugs,
  other_medicine: otherMedicine,
  other_hormone_drugs: otherHormoneDrugs,
  memo,
});
export const DEFAULT_STIMULATION_MEDICINE_VALUES = [
  null,
  [],
  [],
  [],
  '',
  '',
  '',
  '',
];
export const DEFAULT_OPU_VALUES = ['', '', '', null, '', '', '', '', ''];
export const DEFAULT_SEMEN_VALUES = ['', '', '', '', '', ''];
export const DEFAULT_TIMING_DRUG_VALUES = [[], [], [], '', '', '', ''];
export const DEFAULT_TRANSPLANT_CYCLE_VALUES = [
  '',
  '',
  null,
  null,
  '',
  null,
  null,
];
export const TRANSPLANT_STEP = () => ({
  stage_of_transferred_embryo: '',
  grade_of_transferred_embryo_number: null,
  grade_of_transferred_embryo_icm: null,
  grade_of_transferred_embryo_te: null,
  day_of_et: '',
  pgt_a_result: null,
});
export const DEFAULT_TRANSPLANT_VALUES = () => [
  null,
  [],
  [
    {
      stage_of_transferred_embryo: '',
      grade_of_transferred_embryo_number: null,
      grade_of_transferred_embryo_icm: null,
      grade_of_transferred_embryo_te: null,
      day_of_et: '',
      pgt_a_result: null,
    },
  ],
  '',
];
