import 'App.css';
import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import history from 'utils/history';
import 'chartjs-plugin-datalabels';
import { Dimmer, Loader, Sidebar } from 'semantic-ui-react';
import { Chart as ChartJsInstance } from 'react-chartjs-2';
import Cookies from 'universal-cookie';

import {
  AmhRoute,
  AuthenticatedRoute,
  CommonRoute,
} from 'components/Authentication/AuthenticatedRoute';
import TopMenu from 'components/TopMenu';
import MenuBar from 'components/MenuBar';
import SidebarMenu from 'components/Sidebar';
import { NESTED_ROUTES, SPECIAL_ROUTES } from 'components/TopMenu/constants';
import AmhUserResultPage from 'containers/AmhUserResultPage';
import ChartPage from 'containers/ChartPage';
import DrugUsagePage from 'containers/DrugUsagePage';
import ErrorPage from 'containers/ErrorPage';
import ExaminationResultsPage from 'containers/ExaminationResultsPage';
import WomanAndManExaminationResultsPage from 'containers/ExaminationResultsPage/WomanAndMan';
import ForgotPasswordPage from 'containers/ForgotPassword';
import LoginPage from 'containers/LoginPage';
import ProfilePage from 'containers/ProfilePage';
import ResetPasswordPage from 'containers/ResetPassword';
import RegistrationPage from 'containers/RegistrationPage';
import RegistrationConfirmationPage from 'containers/RegistrationPage/RegistrationConfirmation';
import ReConfirmationPage from 'containers/RegistrationPage/ReConfirmation';
import TreatmentCyclePage from 'containers/TreatmentCyclePage';
import WithdrawalPage from 'containers/WithdrawalPage';
import ClinicDetails from 'containers/ClinicDetails';
import ClinicsPage from 'containers/ClinicsPage';
import InseminationCycle from 'containers/TreatmentCyclePage/InseminationCycle';
import OpuCycle from 'containers/TreatmentCyclePage/OpuCycle';
import TimingCycle from 'containers/TreatmentCyclePage/TimingCycle';
import TransplantCycle from 'containers/TreatmentCyclePage/TransplantCycle';
import TreatmentRecess from 'containers/TreatmentCyclePage/TreatmentRecess';
import TopPage from 'containers/TopPage';
import { MY_CARDS, STATS_CARDS } from 'utils/defaults';
import {
  ninpathCheckLoginSuccessful,
  loginSuccesful,
  logoutSuccesful,
} from 'containers/TopPage/actions';
import { checkIfNestedRoute, getPageLabel } from 'utils/helper';
import 'moment/locale/ja';

import AmhRegistrationConfirmationPage from './containers/RegistrationPage/AmhRegistrationConfirmationPage';
import AmhReConfirmationPage from './containers/RegistrationPage/AmhReConfirmationPage';
import AmhInfoPage from './containers/AmhUserResultPage/info';
import AmhMenuBar from './components/MenuBar/AmhMenuBar';
import NinpathCheckLoginPage from './containers/LoginPage/NinpathCheck';
import NinpathCheckRegistrationPage from './containers/RegistrationPage/NinpathCheck';
import NinpathCheckForgotPasswordPage from './containers/ForgotPassword/NinpathCheck';
import NinpathCheckResetPasswordPage from './containers/ResetPassword/NinpathCheck';
import TagManager from 'react-gtm-module';

const cookies = new Cookies();
window.recaptchaOptions = {
  useRecaptchaNet: true,
};
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alias: '',
      isNestedRoute: false,
      isSpecialRoute: false,
      routeLoaded: false,
      visible: false,
    };
    this.checkIfRouteIsNested = this.checkIfRouteIsNested.bind(this);
    this.showNavMenu = this.showNavMenu.bind(this);
    this.toggleSideBar = this.toggleSideBar.bind(this);
  }

  componentDidMount() {
    if (cookies.get('user_token')) {
      if (cookies.get('user_id')) {
        this.props.login();
      } else if (cookies.get('amh_user_id')) {
        this.props.amhLogin();
      }
    }
    this.checkIfRouteIsNested(this.props.location.pathname);

    this.unlisten = history.listen(location => {
      this.checkIfRouteIsNested(location.pathname);
    });
    this.setState({ routeLoaded: true });
    // scrolling logic
    ChartJsInstance.pluginService.register({
      afterRender: chart => {
        if (!chart.$rendered && this.props.location.pathname === '/stats') {
          if (this.props.location.search) {
            const urlParam = this.props.location.search.replace('?', '');
            const matches = urlParam.match('(.*)=(.*)');
            if (matches.length > 2) {
              const key = matches[1];
              const value = matches[2];
              if (!isNaN(value)) {
                if (key === 'infertility') window.scrollTo(0, 0);
                if (key === 'stepup') {
                  window.scrollTo(0, window.innerHeight * 0.9);
                }
                if (key === 'barMixed' || key === 'drugTable') {
                  window.scrollTo(0, window.innerHeight * 1.7);
                }
                if (key === 'etMatrix') {
                  window.scrollTo(0, window.innerHeight * 2.8);
                }
              }
            }
          }
          chart.$rendered = true;
        }
      },
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  checkIfRouteIsNested(pathname) {
    const label = getPageLabel(pathname);
    this.setState({ alias: label });
    if (checkIfNestedRoute(pathname, NESTED_ROUTES)) {
      this.setState({ isNestedRoute: true });
    } else this.setState({ isNestedRoute: false });
    if (checkIfNestedRoute(pathname, SPECIAL_ROUTES)) {
      this.setState({ isSpecialRoute: true });
    } else this.setState({ isSpecialRoute: false });
  }

  toggleSideBar() {
    let { visible } = this.state;
    if (!visible) {
      document.body.style.overflow = 'hidden';
      window.scrollTo(0, 0);
      visible = true;
    } else {
      document.body.style.overflow = 'visible';
      visible = false;
    }
    this.setState({ visible });
  }

  showTopMenu() {
    return (
      this.props.pathname !== '/login' &&
      this.props.pathname !== '/' &&
      !this.props.errorCode
    );
  }

  showNavMenu() {
    return (
      this.props.isLoggedIn &&
      !this.props.errorCode &&
      !this.state.isSpecialRoute
    );
  }

  showAmhMenu() {
    return (
      this.props.isAmhLoggedIn &&
      !this.props.errorCode &&
      !this.state.isSpecialRoute
    );
  }

  render() {
    return (
      <>
        {this.showTopMenu() && (
          <TopMenu
            toggleSideBar={this.toggleSideBar}
            sidebarIsOpened={this.state.visible || this.props.showLoading}
            isSpecialRoute={this.state.isSpecialRoute}
          />
        )}
        {this.showNavMenu() && (
          <MenuBar
            sidebarIsOpened={this.state.visible || this.props.showLoading}
          />
        )}
        {this.showAmhMenu() && (
          <AmhMenuBar
            sidebarIsOpened={this.state.visible || this.props.showLoading}
          />
        )}
        <Sidebar.Pushable>
          <Sidebar.Pusher
            dimmed={this.state.visible || this.props.showLoading}
            style={{
              height:
                this.state.visible || this.props.showLoading ? '100vh' : 'auto',
            }}
          >
            {this.state.routeLoaded ? (
              <div
                style={{
                  marginTop:
                    this.props.isLoggedIn &&
                    !this.props.errorCode &&
                    !this.state.isSpecialRoute
                      ? 140
                      : 0,
                }}
              >
                <Switch>
                  <AuthenticatedRoute
                    exact
                    path="/"
                    component={TopPage}
                    isLoggedIn={this.props.isLoggedIn}
                    isAmhLoggedIn={this.props.isAmhLoggedIn}
                  />
                  <AuthenticatedRoute
                    exact
                    path="/login"
                    component={LoginPage}
                    isLoggedIn={this.props.isLoggedIn}
                    isAmhLoggedIn={this.props.isAmhLoggedIn}
                  />

                  <AuthenticatedRoute
                    exact
                    path="/registration"
                    component={RegistrationPage}
                    isLoggedIn={this.props.isLoggedIn}
                    isAmhLoggedIn={this.props.isAmhLoggedIn}
                  />
                  <AuthenticatedRoute
                    exact
                    path="/user/reconfirmation"
                    component={ReConfirmationPage}
                    isAmhLoggedIn={this.props.isAmhLoggedIn}
                  />
                  <AuthenticatedRoute
                    exact
                    path="/amh_user/reconfirmation"
                    component={AmhReConfirmationPage}
                    isAmhLoggedIn={this.props.isAmhLoggedIn}
                  />
                  <AuthenticatedRoute
                    exact
                    path="/user/confirmation"
                    component={RegistrationConfirmationPage}
                    isAmhLoggedIn={this.props.isAmhLoggedIn}
                  />
                  <AuthenticatedRoute
                    exact
                    path="/amh_user/confirmation"
                    component={AmhRegistrationConfirmationPage}
                    isAmhLoggedIn={this.props.isAmhLoggedIn}
                  />
                  <AuthenticatedRoute
                    exact
                    path="/forgotPassword"
                    component={ForgotPasswordPage}
                    isLoggedIn={this.props.isLoggedIn}
                    isAmhLoggedIn={this.props.isAmhLoggedIn}
                  />
                  <AuthenticatedRoute
                    exact
                    path="/ninpath-check/forgotPassword"
                    component={NinpathCheckForgotPasswordPage}
                    isLoggedIn={this.props.isLoggedIn}
                    isAmhLoggedIn={this.props.isAmhLoggedIn}
                  />
                  <AuthenticatedRoute
                    exact
                    path="/amh_user/verify"
                    component={NinpathCheckResetPasswordPage}
                  />
                  <Route
                    exact
                    path="/user/verify"
                    component={ResetPasswordPage}
                  />
                  <AuthenticatedRoute
                    exact
                    path="/ninpath-check"
                    component={NinpathCheckLoginPage}
                    isLoggedIn={this.props.isLoggedIn}
                    isAmhLoggedIn={this.props.isAmhLoggedIn}
                  />
                  <AuthenticatedRoute
                    exact
                    path="/ninpath-check/registration"
                    component={NinpathCheckRegistrationPage}
                    isLoggedIn={this.props.isLoggedIn}
                    isAmhLoggedIn={this.props.isAmhLoggedIn}
                  />
                  <AmhRoute
                    exact
                    path="/ninpath-check/result"
                    component={AmhUserResultPage}
                    errorCode={this.props.errorCode}
                    isLoggedIn={this.props.isLoggedIn}
                    isAmhLoggedIn={this.props.isAmhLoggedIn}
                  />
                  <AmhRoute
                    exact
                    path="/ninpath-check/info"
                    component={AmhInfoPage}
                    errorCode={this.props.errorCode}
                    isLoggedIn={this.props.isLoggedIn}
                    isAmhLoggedIn={this.props.isAmhLoggedIn}
                  />
                  <CommonRoute
                    exact
                    path="/profile"
                    component={ProfilePage}
                    errorCode={this.props.errorCode}
                    isLoggedIn={this.props.isLoggedIn}
                    isAmhLoggedIn={this.props.isAmhLoggedIn}
                  />
                  <CommonRoute
                    exact
                    path="/clinics"
                    component={ClinicsPage}
                    errorCode={this.props.errorCode}
                    isLoggedIn={this.props.isLoggedIn}
                    isAmhLoggedIn={this.props.isAmhLoggedIn}
                  />
                  <CommonRoute
                    exact
                    path="/clinics/details/:id"
                    component={ClinicDetails}
                    errorCode={this.props.errorCode}
                    isLoggedIn={this.props.isLoggedIn}
                    isAmhLoggedIn={this.props.isAmhLoggedIn}
                  />
                  <CommonRoute
                    exact
                    path="/cycles"
                    component={TreatmentCyclePage}
                    errorCode={this.props.errorCode}
                    isLoggedIn={this.props.isLoggedIn}
                    isAmhLoggedIn={this.props.isAmhLoggedIn}
                  />
                  <CommonRoute
                    exact
                    path="/cycles/opuCycle/register"
                    component={OpuCycle}
                    errorCode={this.props.errorCode}
                    isLoggedIn={this.props.isLoggedIn}
                    isAmhLoggedIn={this.props.isAmhLoggedIn}
                  />
                  <CommonRoute
                    exact
                    path="/cycles/etCycle/register"
                    component={TransplantCycle}
                    errorCode={this.props.errorCode}
                    isLoggedIn={this.props.isLoggedIn}
                    isAmhLoggedIn={this.props.isAmhLoggedIn}
                  />
                  <CommonRoute
                    exact
                    path="/cycles/timingCycle/register"
                    component={TimingCycle}
                    errorCode={this.props.errorCode}
                    isLoggedIn={this.props.isLoggedIn}
                    isAmhLoggedIn={this.props.isAmhLoggedIn}
                  />
                  <CommonRoute
                    exact
                    path="/cycles/aihCycle/register"
                    component={InseminationCycle}
                    errorCode={this.props.errorCode}
                    isLoggedIn={this.props.isLoggedIn}
                    isAmhLoggedIn={this.props.isAmhLoggedIn}
                  />
                  <CommonRoute
                    exact
                    path="/cycles/offCycle/register"
                    component={TreatmentRecess}
                    errorCode={this.props.errorCode}
                    isLoggedIn={this.props.isLoggedIn}
                    isAmhLoggedIn={this.props.isAmhLoggedIn}
                  />
                  <CommonRoute
                    exact
                    path="/cycles/opuCycle/edit/:id"
                    component={OpuCycle}
                    errorCode={this.props.errorCode}
                    isLoggedIn={this.props.isLoggedIn}
                    isAmhLoggedIn={this.props.isAmhLoggedIn}
                  />
                  <CommonRoute
                    exact
                    path="/cycles/etCycle/edit/:id"
                    component={TransplantCycle}
                    errorCode={this.props.errorCode}
                    isLoggedIn={this.props.isLoggedIn}
                  />
                  <CommonRoute
                    exact
                    path="/cycles/timingCycle/edit/:id"
                    component={TimingCycle}
                    errorCode={this.props.errorCode}
                    isLoggedIn={this.props.isLoggedIn}
                    isAmhLoggedIn={this.props.isAmhLoggedIn}
                  />
                  <CommonRoute
                    exact
                    path="/cycles/aihCycle/edit/:id"
                    component={InseminationCycle}
                    errorCode={this.props.errorCode}
                    isLoggedIn={this.props.isLoggedIn}
                    isAmhLoggedIn={this.props.isAmhLoggedIn}
                  />
                  <CommonRoute
                    exact
                    path="/cycles/offCycle/edit/:id"
                    component={TreatmentRecess}
                    errorCode={this.props.errorCode}
                    isLoggedIn={this.props.isLoggedIn}
                    isAmhLoggedIn={this.props.isAmhLoggedIn}
                  />
                  <Route
                    exact
                    key="user_data"
                    path="/myData"
                    render={() => (
                      <ChartPage
                        pageData={MY_CARDS}
                        pageType="me"
                        errorCode={this.props.errorCode}
                      />
                    )}
                  />
                  <Route
                    exact
                    key="users_stats"
                    path="/stats"
                    render={() => (
                      <ChartPage
                        pageData={STATS_CARDS}
                        pageType="stats"
                        errorCode={this.props.errorCode}
                      />
                    )}
                  />
                  <CommonRoute
                    exact
                    path="/examinationResults"
                    component={ExaminationResultsPage}
                    errorCode={this.props.errorCode}
                    isLoggedIn={this.props.isLoggedIn}
                    isAmhLoggedIn={this.props.isAmhLoggedIn}
                  />
                  <CommonRoute
                    exact
                    path="/womanAndManExaminationResults"
                    component={WomanAndManExaminationResultsPage}
                    errorCode={this.props.errorCode}
                    isLoggedIn={this.props.isLoggedIn}
                    isAmhLoggedIn={this.props.isAmhLoggedIn}
                  />
                  <CommonRoute
                    exact
                    path="/drugUsage"
                    component={DrugUsagePage}
                    errorCode={this.props.errorCode}
                    isLoggedIn={this.props.isLoggedIn}
                    isAmhLoggedIn={this.props.isAmhLoggedIn}
                  />
                  <CommonRoute
                    exact
                    path="/withdrawal"
                    component={WithdrawalPage}
                    errorCode={this.props.errorCode}
                    isLoggedIn={this.props.isLoggedIn}
                    isAmhLoggedIn={this.props.isAmhLoggedIn}
                  />

                  <Route component={ErrorPage} />
                </Switch>
              </div>
            ) : null}

            {this.props.showLoading && (
              <Dimmer active>
                <Loader
                  size="big"
                  style={{
                    position: 'fixed',
                    left: '50%',
                    top: '50%',
                    transform: 'translateX(-50%)',
                  }}
                >
                  Loading
                </Loader>
              </Dimmer>
            )}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
        <SidebarMenu
          visible={this.state.visible}
          toggleSideBar={this.toggleSideBar}
        />
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  login: () => dispatch(loginSuccesful()),
  amhLogin: () => dispatch(ninpathCheckLoginSuccessful()),
  logout: () => dispatch(logoutSuccesful()),
});

const mapStateToProps = ({ AuthenticationReducer, HomeReducer, router }) => {
  if (HomeReducer.isLoading) document.body.style.overflow = 'hidden';
  else document.body.style.overflow = 'visible';
  // This is finely tuned according to Redux guidelines , remember the correct order of redux
  if (
    AuthenticationReducer.isLoggedIn === false &&
    AuthenticationReducer.isAmhLoggedIn === false &&
    cookies.get('user_token')
  ) {
    cookies.remove('user_token');
    cookies.remove('user_id');
    cookies.remove('user_name');
    cookies.remove('age_group');
    cookies.remove('amh_user_id');
    TagManager.dataLayer({
      userId: null,
    });
  }
  let { errorCode } = AuthenticationReducer;
  if (HomeReducer.errorCode) {
    errorCode = HomeReducer.errorCode;
  }
  return {
    pathname: router.location.pathname,
    errorCode,
    isLoggedIn: AuthenticationReducer.isLoggedIn,
    isAmhLoggedIn: AuthenticationReducer.isAmhLoggedIn,
    showLoading: HomeReducer.isLoading,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(App));

App.propTypes = {
  isLoggedIn: PropTypes.any,
  isAmhLoggedIn: PropTypes.any,
  errorCode: PropTypes.any,
  location: PropTypes.any,
  login: PropTypes.any.isRequired,
  amhLogin: PropTypes.any.isRequired,
  pathname: PropTypes.string.isRequired,
  showLoading: PropTypes.bool,
};
App.defaultProps = {
  showLoading: false,
  isLoggedIn: undefined,
  isAmhLoggedIn: undefined,
  errorCode: null,
};
