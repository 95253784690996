// interfaces of the proyect
export class ChartCard {
  constructor(
    chartName,
    chartTypes,
    chartDescription,
    chartParameters,
    externalLinks,
    showTutorial,
    showFilters,
    httpMethods,
    placeholder,
  ) {
    this.chartName = chartName;
    this.chartTypes = chartTypes.map(elm => elm);
    this.chartDescription = chartDescription;
    this.chartParameters = chartParameters.map(elm => elm);
    this.externalLinks = externalLinks;
    this.showTutorial = showTutorial;
    this.showFilters = showFilters;
    this.httpMethods = httpMethods;
    this.placeholder = placeholder;
  }
}
