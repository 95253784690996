import Cookies from 'universal-cookie';
import request from 'utils/request';

import { call } from 'redux-saga/effects';
import { handleResponse } from 'utils/sagasHelpers';

import {
  CONFIRMATION_EMAIL_SENT_SUCCESSFULLY,
  CONFIRMATION_EMAIL_NOT_SENT,
} from './constants';

export function* sendConfirmationEmail(action) {
  const cookies = new Cookies();
  const url = `${process.env.REACT_APP_API_URL}/api/v1/email_confirmation/registration_request`;
  const response = yield call(request, url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      charset: 'UTF-8',
      Authorization: `Bearer ${cookies.get('user_token')}`,
    },
    body: JSON.stringify(action.payload),
  });
  yield handleResponse(
    response,
    CONFIRMATION_EMAIL_SENT_SUCCESSFULLY,
    CONFIRMATION_EMAIL_NOT_SENT,
  );
}

export function* sendAmhConfirmationEmail(action) {
  const cookies = new Cookies();
  const url = `${process.env.REACT_APP_API_URL}/api/v1/amh_email_confirmation/registration_request`;
  const response = yield call(request, url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      charset: 'UTF-8',
      Authorization: `Bearer ${cookies.get('user_token')}`,
    },
    body: JSON.stringify(action.payload),
  });
  yield handleResponse(
    response,
    CONFIRMATION_EMAIL_SENT_SUCCESSFULLY,
    CONFIRMATION_EMAIL_NOT_SENT,
  );
}
