export const FETCH_CLINIC_DATA = 'containers/ClinicsDetails/FETCH_CLINIC_DATA';

export const FETCH_CLINIC_DATA_SUCCESSFUL =
  'containers/ClinicsDetails/FETCH_CLINIC_DATA_SUCCESSFUL';

export const FETCH_CLINIC_DATA_FAILED =
  'containers/ClinicsDetails/FETCH_CLINIC_DATA_FAILED';

export const FETCH_CLINIC_STATS_DATA =
  'containers/ClinicsDetails/FETCH_CLINIC_STATS_DATA';

export const FETCH_CLINIC_STATS_DATA_SUCCESSFUL =
  'containers/ClinicsDetails/FETCH_CLINIC_STATS_DATA_SUCCESSFUL';

export const FETCH_CLINIC_STATS_DATA_FAILED =
  'containers/ClinicsDetails/FETCH_CLINIC_STATS_DATA_FAILED';
