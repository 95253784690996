import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PopupInfo from 'components/PopupInfo';

import { Form } from 'semantic-ui-react';
import { FormField, FormLabel } from 'containers/RegistrationPage/formElements';
import { DateInput } from 'semantic-ui-calendar-react';

import {
  EMBRYO_CULTURE_OPTION,
  FERTILIZATION_METHODS,
} from 'utils/defaultOptions';
import {
  BUILD_OPU_RESULTS_PAYLOAD,
  DEFAULT_OPU_VALUES,
} from 'containers/TreatmentCyclePage/constants';
import { validateOpuResults } from 'utils/errors';
import MultipleSelectNothingSpecial from '../../RegistrationPage/forms/MultipleSelectNothingSpecial';

const OpuStatsForm = ({ loadedData, isFormValid, updateFormValues }) => {
  const [formValues, setFormValues] = useState(DEFAULT_OPU_VALUES);
  useEffect(() => {
    if (loadedData) {
      const newFormValues = [];
      newFormValues.push(loadedData.day_of_opu);
      newFormValues.push(loadedData.number_of_collected_eggs);
      newFormValues.push(loadedData.times_of_ivf_or_icsi);
      newFormValues.push(loadedData.fertilization_method);
      newFormValues.push(loadedData.embryo_culture_option);
      newFormValues.push(loadedData.number_of_normal_fertilization);
      newFormValues.push(loadedData.number_of_early_embryos);
      newFormValues.push(loadedData.number_of_blastocysts);
      newFormValues.push(loadedData.endometrial_thickness || '');
      setFormValues(newFormValues);
    }
  }, [loadedData]);
  return (
    <>
      <Form.Group>
        <FormField style={{ paddingRight: 0 }}>
          <FormLabel htmlFor="opu_date">採卵日</FormLabel>
          <DateInput
            hideMobileKeyboard
            autoComplete="off"
            animation="none"
            closable
            name="opu_date"
            dateFormat="YYYY-MM-DD"
            placeholder="2019-01-01"
            iconPosition="right"
            startMode="year"
            clearable
            localization="ja"
            value={formValues[0]}
            onChange={(evt, { value }) => {
              const newValues = formValues.map(formValue => formValue);
              newValues[0] = value;
              setFormValues(newValues);
              // const isValid = validateOpuResults(newValues);
              const requestPayload = BUILD_OPU_RESULTS_PAYLOAD(...newValues);
              updateFormValues('opu', requestPayload);
              isFormValid(2, true);
            }}
          ></DateInput>
        </FormField>
      </Form.Group>
      <Form.Group>
        <FormField style={{ paddingRight: 0 }}>
          <FormLabel htmlFor="number_collected_eggs">採卵個数</FormLabel>
          <Form.Input
            fluid
            name="number_collected_eggs"
            type="number"
            placeholder="0"
            min="0"
            step="1"
            value={formValues[1]}
            onChange={(evt, { value }) => {
              const newValues = formValues.map(formValue => formValue);
              newValues[1] = value;
              setFormValues(newValues);
              const isValid = validateOpuResults(newValues);
              if (isValid) {
                const requestPayload = BUILD_OPU_RESULTS_PAYLOAD(...newValues);
                updateFormValues('opu', requestPayload);
              }
              isFormValid(2, isValid);
            }}
          />
        </FormField>
      </Form.Group>
      <Form.Group>
        <FormField style={{ paddingRight: 0 }}>
          <FormLabel htmlFor="number_ivf_enforcements">
            <span>IVF/ICSI施行数</span>
            <PopupInfo fieldName="numberOfIvfEnforcements" />
          </FormLabel>
          <Form.Input
            fluid
            name="number_ivf_enforcements"
            type="number"
            min="0"
            step="1"
            placeholder="0"
            value={formValues[2]}
            onChange={(evt, { value }) => {
              const newValues = formValues.map(formValue => formValue);
              newValues[2] = value;
              setFormValues(newValues);
              const isValid = validateOpuResults(newValues);
              if (isValid) {
                const requestPayload = BUILD_OPU_RESULTS_PAYLOAD(...newValues);
                updateFormValues('opu', requestPayload);
              }
              isFormValid(2, isValid);
            }}
          />
        </FormField>
      </Form.Group>
      <Form.Group>
        <FormField inline style={{ paddingRight: 0 }}>
          <FormLabel htmlFor="fertilization_methods" style={{ marginRight: 0 }}>
            <span>受精方法</span>
            <PopupInfo fieldName="fertilizationMethods" />
          </FormLabel>
          <Form.Select
            placeholder="選択してください"
            clearable
            options={FERTILIZATION_METHODS}
            value={formValues[3]}
            onChange={(evt, { value }) => {
              const newValues = formValues.map(formValue => formValue);
              newValues[3] = value;
              setFormValues(newValues);
              const isValid = validateOpuResults(newValues);
              if (isValid) {
                const requestPayload = BUILD_OPU_RESULTS_PAYLOAD(...newValues);
                updateFormValues('opu', requestPayload);
              }
              isFormValid(2, isValid);
            }}
          />
        </FormField>
      </Form.Group>
      <Form.Group>
        <FormField inline style={{ paddingRight: 0 }}>
          <FormLabel htmlFor="embryo_culture_option" style={{ marginRight: 0 }}>
            胚培養時のオプション
          </FormLabel>
          <MultipleSelectNothingSpecial
            id="embryo_culture_option"
            name="embryo_culture_option"
            options={EMBRYO_CULTURE_OPTION}
            submitValue={formValues[4] ? formValues[4] : []}
            changeFunction={(evt, value) => {
              const newValues = formValues.map(formValue => formValue);
              newValues[4] = value;
              setFormValues(newValues);
              const isValid = validateOpuResults(newValues);
              if (isValid) {
                const requestPayload = BUILD_OPU_RESULTS_PAYLOAD(...newValues);
                updateFormValues('opu', requestPayload);
              }
              isFormValid(2, isValid);
            }}
          />
        </FormField>
      </Form.Group>
      <Form.Group>
        <FormField style={{ paddingRight: 0 }}>
          <FormLabel htmlFor="number_normal_fertilization">
            正常受精数
          </FormLabel>
          <Form.Input
            fluid
            name="number_normal_fertilization"
            type="number"
            placeholder="0"
            min="0"
            step="1"
            value={formValues[5]}
            onChange={(evt, { value }) => {
              const newValues = formValues.map(formValue => formValue);
              newValues[5] = value;
              setFormValues(newValues);
              const isValid = validateOpuResults(newValues);
              if (isValid) {
                const requestPayload = BUILD_OPU_RESULTS_PAYLOAD(...newValues);
                updateFormValues('opu', requestPayload);
              }
              isFormValid(2, isValid);
            }}
          />
        </FormField>
      </Form.Group>
      <Form.Group>
        <FormField style={{ paddingRight: 0 }}>
          <FormLabel htmlFor="number_of_early_embryos">初期胚到達数</FormLabel>
          <Form.Input
            fluid
            name="number_of_early_embryos"
            type="number"
            placeholder="0"
            min="0"
            step="1"
            value={formValues[6]}
            onChange={(evt, { value }) => {
              const newValues = formValues.map(formValue => formValue);
              newValues[6] = value;
              setFormValues(newValues);
              const isValid = validateOpuResults(newValues);
              if (isValid) {
                const requestPayload = BUILD_OPU_RESULTS_PAYLOAD(...newValues);
                updateFormValues('opu', requestPayload);
              }
              isFormValid(2, isValid);
            }}
          />
        </FormField>
      </Form.Group>
      <Form.Group>
        <FormField style={{ paddingRight: 0 }}>
          <FormLabel htmlFor="number_of_blastocysts">胚盤胞到達数</FormLabel>
          <Form.Input
            fluid
            name="number_of_blastocysts"
            type="number"
            placeholder="0"
            min="0"
            step="1"
            value={formValues[7]}
            onChange={(evt, { value }) => {
              const newValues = formValues.map(formValue => formValue);
              newValues[7] = value;
              setFormValues(newValues);
              const isValid = validateOpuResults(newValues);
              if (isValid) {
                const requestPayload = BUILD_OPU_RESULTS_PAYLOAD(...newValues);
                updateFormValues('opu', requestPayload);
              }
              isFormValid(2, isValid);
            }}
          />
        </FormField>
      </Form.Group>
      <Form.Group>
        <FormField style={{ paddingRight: 0 }}>
          <FormLabel htmlFor="endometrial_thickness">
            子宮内膜の厚み (mm)
          </FormLabel>
          <Form.Input
            fluid
            name="endometrial_thickness"
            type="number"
            placeholder="0"
            value={formValues[8]}
            onChange={(evt, { value }) => {
              const newValues = formValues.map(formValue => formValue);
              newValues[8] = value;
              setFormValues(newValues);
              const isValid = validateOpuResults(newValues);
              if (isValid) {
                const requestPayload = BUILD_OPU_RESULTS_PAYLOAD(...newValues);
                updateFormValues('opu', requestPayload);
              }
              isFormValid(2, isValid);
            }}
          />
        </FormField>
      </Form.Group>
    </>
  );
};
OpuStatsForm.propTypes = {
  isFormValid: PropTypes.func.isRequired,
  loadedData: PropTypes.object,
  updateFormValues: PropTypes.func.isRequired,
};

OpuStatsForm.defaultProps = {
  loadedData: null,
};
export default OpuStatsForm;
