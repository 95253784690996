import history from 'utils/history';
/* This file contains functions that may be used in more than
 ** one side of the project */
export const isLeapYear = year =>
  (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0 ? 29 : 28;
export const separateDate = date => {
  const dateArr = date.split('-');
  if (dateArr.length !== 3) {
    return { day: null, month: null, year: null };
  }
  return {
    day: dateArr[2],
    month: dateArr[1],
    year: dateArr[0],
  };
};

export const isOnTheSamePage = action =>
  action.payload &&
  action.payload.location &&
  action.payload.location.pathname === '/pathname';

export const isValueInArray = (arr, valueToSearch) =>
  arr.filter(elm => elm === valueToSearch).length > 0;

export const getPageLabel = pathname => {
  let matches = pathname.match('/(.*)');
  const matchesTemp = pathname.match('/(.*)');

  if (matches.length < 2) return null;
  if (matches[1].match('/(.*)')) {
    const matchesResult = matches[1].match('cycles/(.*)/[a-zA-z]+/.*');
    if (!matchesResult) {
      matches = matches[1].match('cycles/(.*)/[a-zA-z]+');
    } else matches = matchesResult;
  }
  if (!matches || matches.length < 1) {
    const matchesResult = matchesTemp[1].match('clinics/details/(.*)');
    matches = matchesResult;
    if (matches && matches.length > 1) matches[1] = 'clinicDetails';
  }
  const title = matches && matches.length > 1 ? matches[1] : '';
  return title;
};

export const navigateBack = (title, location) => {
  if (title === 'etCycle') history.push('/cycles?type=1');
  else if (title === 'aihCycle') history.push('/cycles?type=2');
  else if (title === 'timingCycle') history.push('/cycles?type=3');
  else if (title === 'offCycle') history.push('/cycles?type=4');
  else if (title === 'opuCycle') history.push('/cycles?type=0');
  else if (title === 'drugUsage') history.push('/stats?drugTable=0');
  else if (title === 'clinicDetails') {
    history.push({
      pathname: '/clinics',
      state: location.state ? location.state.prefectureId : null,
    });
  } else history.goBack();
};

export const checkIfNestedRoute = (pathname, nestedRoutes) =>
  nestedRoutes.reduce((isPresent, route) => {
    isPresent = isPresent || pathname === route;
    return isPresent;
  }, false);

export const checkIfRelatedRoutes = (pathname, route) => {
  if (pathname.includes(route)) return true;
  if (pathname === '/drugUsage' && route === '/stats') return true;
  return false;
};
