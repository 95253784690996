import React from 'react';
import banner from '../../assets/img/ninpath-care-result-banner/banner.png';

const styles = {
  container: {
    padding: 16,
    textAlign: 'center',
  },
};

const BANNER_URL =
  'https://reserva.be/ninpath/reserve?mode=service_staff&search_evt_no=2feJwztjA3MLYEAARbAT8';

const NinpathCheckResultBanner = () => (
  <div style={styles.container}>
    <a href={BANNER_URL} target="_blank" rel="noopener">
      <img src={banner} alt="ninpathケア" />
    </a>
  </div>
);

export default NinpathCheckResultBanner;
