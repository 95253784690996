import React from 'react';
import PropTypes from 'prop-types';
import { MAIN_COLOR, SUB_COLOR } from 'containers/ChartPage/colors';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { ErrorLayoutContainer } from 'utils/layouts';
export const ReConfirmExpiredTokenPage = ({ errorMessage }) => (
  <ErrorLayoutContainer bgColor={SUB_COLOR}>
    <div
      className="message_container"
      style={{ transform: 'translateY(-50%)' }}
    >
      <p style={{ fontSize: '2.5rem' }}>{errorMessage}</p>
      <p style={{ textAlign: 'center', fontSize: '1rem' }}></p>
      <div style={{ width: 210, margin: 'auto' }}>
        <Button
          primary
          style={{
            textAlign: 'center',
            backgroundColor: MAIN_COLOR,
            color: '#fff',
            marginTop: 14,
          }}
          as={Link}
          to="/"
        >
          ホームに戻る
        </Button>
      </div>
    </div>
  </ErrorLayoutContainer>
);

ReConfirmExpiredTokenPage.propTypes = {
  errorMessage: PropTypes.string,
  tokenType: PropTypes.string,
};
ReConfirmExpiredTokenPage.defaultProps = {
  errorMessage: 'リンクの有効期限が切れています',
  tokenType: 'password',
};
