import { createSelector } from 'reselect';

const selectEmailConfirmation = state => state.emailConfirmation;

const makeSelectMessageSent = () =>
  createSelector(
    selectEmailConfirmation,
    registrationState => registrationState.emailWasSent,
  );

const makeSelectErrorMessages = () =>
  createSelector(
    selectEmailConfirmation,
    registrationState => registrationState.errorMessages,
  );

const makeSelectSuccessMessages = () =>
  createSelector(
    selectEmailConfirmation,
    registrationState => registrationState.successMessages,
  );
export {
  makeSelectErrorMessages,
  makeSelectMessageSent,
  makeSelectSuccessMessages,
};
