import request from './request';

const baseUrl = `https://release.ninpath.com`;
const apiV2Url = `${baseUrl}/wp-json/wp/v2`;
const postsUrl = `${apiV2Url}/posts`;

const fields = ['id', 'link', 'date', 'title', 'excerpt'];
const params = {
  _fields: fields.join(','),
  per_page: 3, // 最新 3 件を表示
  page: 1,
};
const query = Object.keys(params)
  .map(key => `${key}=${params[key]}`)
  .join('&');
const url = `${postsUrl}?${query}`;
const options = {
  method: 'GET',
  headers: { 'Content-Type': 'application/json; charset=utf-8' },
};
export const getNewsList = setNewsList => {
  const promise = request(url, options);
  promise.then(response => {
    setNewsList(response);
  });
};
