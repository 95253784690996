import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import { MAIN_COLOR } from 'containers/ChartPage/colors';
import request from 'utils/request';
import { logoutSuccesful } from 'containers/TopPage/actions';
import Cookies from 'universal-cookie';
import { CYCLE_BROWSER_URLS, CYCLE_API_URLS } from './constants';
import CycleDeleteModal from '../../components/CycleDeleteModal';

const TreatmentCycle = ({ logout, activeIndex, tabLabel, tableLabels }) => {
  const [tableData, setTableData] = useState([]);
  const refresh = () => {
    const abortController = new AbortController();
    const url = `${process.env.REACT_APP_API_URL}/api/v1/${CYCLE_API_URLS[tabLabel]}`;
    const cookies = new Cookies();
    const promise = request(url, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
        charset: 'UTF-8',
        Authorization: `Bearer ${cookies.get('user_token')}`,
      },
      signal: abortController.signal,
    });
    promise.then(response => {
      if (response && response.data && response.status !== 401) {
        setTableData(response.data);
      } else if (response.status === 401) logout();
      else if (response.status === 503 || response.status === 500) {
        logout(response.status);
      }
    });
    return () => {
      abortController.abort();
    };
  };
  useEffect(() => {
    refresh();
  }, [logout, tabLabel]); // added logout

  return (
    <>
      <div
        className="button_container"
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: 14,
        }}
      >
        <Button
          as={Link}
          to={`/cycles/${CYCLE_BROWSER_URLS[tabLabel]}/register`}
          style={{
            backgroundColor: MAIN_COLOR,
            color: '#fff',
            fontWeight: 500,
          }}
        >
          新しい周期を追加
        </Button>
      </div>
      {tableData.length > 0 && (
        <table>
          <thead>
            <tr>
              {tableLabels.map((label, index) => (
                <th key={`label${index + 1}`}>{label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, index) => (
              <tr key={`row ${index + 1}`}>
                <td key={`row ${index + 1} column 1`}>
                  <div>
                    <span> {row.sequential_id}</span>
                  </div>
                </td>
                <td key={`row ${index + 1} column 2`}>
                  <div>
                    <span>
                      {' '}
                      {row.started_at} - {row.ended_at}
                    </span>
                  </div>
                </td>
                {activeIndex < 2 && (
                  <td key={`row ${index + 1} column 3`}>
                    <div>
                      <span>
                        {activeIndex === 0
                          ? row.number_of_collected_eggs
                          : row.egg_count}
                      </span>
                    </div>
                  </td>
                )}
                <td key={`row ${index + 1} column 4`}>
                  <Link
                    to={`/cycles/${CYCLE_BROWSER_URLS[tabLabel]}/edit/${row.id}`}
                    style={{ color: '#000' }}
                  >
                    <Icon name="pencil alternate" />
                  </Link>
                </td>
                <td key={`row ${index + 1} column 5`}>
                  <CycleDeleteModal
                    type={CYCLE_API_URLS[tabLabel]}
                    cycleId={row.id}
                    sequentialId={row.sequential_id}
                    refresh={refresh}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

TreatmentCycle.propTypes = {
  activeIndex: PropTypes.number.isRequired,
  logout: PropTypes.func.isRequired,
  tabLabel: PropTypes.string.isRequired,
  tableLabels: PropTypes.array,
};

TreatmentCycle.defaultProps = {
  tableLabels: [],
};

const mapDispatchToProps = dispatch => ({
  logout: (errorCode = null) => dispatch(logoutSuccesful(errorCode)),
});

export default connect(null, mapDispatchToProps)(TreatmentCycle);
