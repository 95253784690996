import { createSelector } from 'reselect';

const selectDrugUsage = state => state.drugUsage;

const makeSelectFetchedData = () =>
  createSelector(
    selectDrugUsage,
    drugUsageState => drugUsageState.drugUsageData,
  );

const makeSelectErrors = () =>
  createSelector(
    selectDrugUsage,
    drugUsageState => drugUsageState.errors,
  );

const makeSelectEmpty = () =>
  createSelector(
    selectDrugUsage,
    drugUsageState => drugUsageState.empty,
  );
export { makeSelectFetchedData, makeSelectErrors, makeSelectEmpty };
