import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Mask } from 'components/Atoms';
import { SUB_COLOR_2 } from 'containers/ChartPage/colors';
import { BOX_SHADOW } from 'utils/defaults';

const calculatePadding = (indexRow, indexCol) => {
  if (indexCol === 0) return 8;
  if (indexRow > 1 && indexRow < 5) return 0;
  return 13;
};
const SemenExaminationTable = ({ tableData }) => {
  const tableRef = useRef(null);
  const [tableOffset, setTableOffset] = useState(0);
  const [showShadow, setShowShadow] = useState(false);
  const [initialWidth, setInitialWidth] = useState(null);
  const [initialWidthInPx, setInitialWidthInPx] = useState('100%');
  const [tableWidth, setTableWidth] = useState(0);
  const onScroll = evt => {
    const newWidth = evt.target.scrollLeft + initialWidth;
    if (newWidth <= tableWidth) {
      setInitialWidthInPx(`${newWidth}px`);
    } else {
      setInitialWidthInPx(`${tableWidth}px`);
    }
    if (evt.target.scrollLeft >= tableOffset) {
      if (showShadow) setShowShadow(false);
    } else if (!showShadow) setShowShadow(true);
  };
  useEffect(() => {
    if (tableRef.current) {
      const originalWidth = tableRef.current.offsetParent.offsetWidth;
      const tableOriginalWidth = tableRef.current.offsetWidth;
      setInitialWidth(originalWidth);
      setTableWidth(tableOriginalWidth);
      setInitialWidthInPx(`${originalWidth}px`);
      const offset = tableOriginalWidth - originalWidth;
      if (offset > 0) setShowShadow(true);
      setTableOffset(offset);
    }
  }, [tableRef]);
  return (
    <div
      className="table-wraper"
      style={{
        overflowX: 'auto',
        overflowY: 'hidden',
        position: 'relative',
        marginTop: 14,
        boxShadow: showShadow ? BOX_SHADOW : 'none',
      }}
      onScroll={evt => onScroll(evt)}
    >
      <Mask
        style={{
          boxShadow: showShadow ? BOX_SHADOW : 'none',
          width: initialWidthInPx,
        }}
      />
      {tableData && tableData.columns && tableData.rows && (
        <table style={{ maxWidth: '100vw', borderTop: `1px solid ${SUB_COLOR_2}`, borderBottom: `1px solid ${SUB_COLOR_2}` }} ref={tableRef}>
          <thead>
            <tr>
              {tableData.columns.map((elm, indexCol) => (
                <th
                  key={elm}
                  style={{
                    borderRight:
                      indexCol === 0 ? `1px solid ${SUB_COLOR_2}` : 'none',
                  }}
                >
                  {elm}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {tableData.rows.map((row, indexRow) => (
              <tr key={`${indexRow + 1}`}>
                {row.map((col, indexCol) => (
                  <td
                    key={`${col} ${indexCol + 1}`}
                    style={{
                      textAlign: indexCol > 0 ? 'right' : 'left',
                      paddingRight: calculatePadding(indexRow, indexCol),
                      justifyContent: 'flex-end',
                      borderRight:
                        indexCol === 0 ? `1px solid ${SUB_COLOR_2}` : 'none',
                    }}
                  >
                    {col}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

SemenExaminationTable.propTypes = {
  tableData: PropTypes.object.isRequired,
};

export default SemenExaminationTable;
