import {
  FETCH_CLINIC_DATA,
  FETCH_CLINIC_STATS_DATA,
} from 'containers/ClinicDetails/constants';
export const fetchClinicData = (payload, params) => ({
  type: FETCH_CLINIC_DATA,
  payload,
  params,
});

export const fetchClinicStatsData = (payload, params) => ({
  type: FETCH_CLINIC_STATS_DATA,
  payload,
  params,
});
