import React, { useEffect, useState } from 'react';
import { Button, Menu, Sidebar } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import request from 'utils/request';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import { logoutSuccesful } from 'containers/TopPage/actions';
import Submenu from 'components/Sidebar/Submenu';
import Avatar from './Avatar';
import SidebarFooter from './SidebarFooter';

import {
  AvatarContainer,
  HeaderText,
  ItemContent,
  ItemText,
  MenuHeader,
  MenuIcon,
} from './MenuItems';

const SidebarMenu = ({ isAmhLoggedIn, logout, toggleSideBar, visible }) => {
  const [showMore, toggleShowMore] = useState(false);
  const [username, setUsername] = useState('');
  const [closedStatus, setClosedStatus] = useState(false);

  const cookies = new Cookies();
  const onMenuLinkClick = () => {
    setClosedStatus(true);
    toggleSideBar();
  };
  useEffect(() => {
    setUsername(cookies.get('user_name'));
  }, [cookies]);
  const attemptLogout = () => {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/amh_auth/logout`;
    const promise = request(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        charset: 'UTF-8',
        Authorization: `Bearer ${cookies.get('user_token')}`,
      },
    });
    promise.then(() => {
      logout();
    });
  };
  if (isAmhLoggedIn) {
    return (
      <Sidebar
        as={Menu}
        animation="overlay"
        icon="labeled"
        vertical
        visible={visible}
        onHide={() => {
          toggleShowMore(false);
          if (!closedStatus) toggleSideBar();
          else setClosedStatus(false);
        }}
        className="ninpath__sidebar"
        style={{ zIndex: 10010 }}
      >
        <Menu.Item name="header">
          <MenuHeader>
            <AvatarContainer>
              <Avatar circular name="user" size="large" />
              <HeaderText>{username}</HeaderText>
            </AvatarContainer>
          </MenuHeader>
        </Menu.Item>
        <Menu.Item
          name="ninpath-check-result"
          className="borderless"
          as={Link}
          to="/ninpath-check/result"
          onClick={() => onMenuLinkClick()}
        >
          <ItemContent>
            <MenuIcon name="chart line" size="large" color="grey" />
            <ItemText>検査結果</ItemText>
          </ItemContent>
        </Menu.Item>
        <Menu.Item
          name="ninpath-check-info"
          className="borderless"
          as={Link}
          to="/ninpath-check/info"
          onClick={() => onMenuLinkClick()}
        >
          <ItemContent>
            <MenuIcon name="book" size="large" color="grey" />
            <ItemText>AMHについて</ItemText>
          </ItemContent>
        </Menu.Item>
        <Submenu>
          <Menu.Item
            name="contact_us"
            className="borderless"
            href="https://forms.gle/jSctkbwfkShMz29z7"
            target="_blank"
          >
            <ItemContent>
              <MenuIcon name="envelope" size="large" color="grey" />
              <ItemText>お問い合わせ</ItemText>
            </ItemContent>
          </Menu.Item>
          <Menu.Item
            className="borderless"
            href="https://dist.ninpath.com/tou.html"
            target="_blank"
          >
            <Menu.Header>
              <ItemContent>
                <MenuIcon
                  name="file alternate outline"
                  size="large"
                  color="grey"
                />
                <ItemText>利用規約</ItemText>
              </ItemContent>
            </Menu.Header>
          </Menu.Item>
          <Menu.Item
            as={Button}
            name="logout"
            className="borderless"
            onClick={() => {
              attemptLogout(cookies.user_token);
              onMenuLinkClick();
            }}
          >
            <ItemContent>
              <MenuIcon name="sign-out" size="large" color="grey" />
              <ItemText>ログアウト</ItemText>
            </ItemContent>
          </Menu.Item>
        </Submenu>
        <SidebarFooter position={!showMore ? 'absolute' : 'relative'}>
          運営会社：株式会社ninpath
        </SidebarFooter>
      </Sidebar>
    );
  }
  return (
    <Sidebar
      as={Menu}
      animation="overlay"
      icon="labeled"
      vertical
      visible={visible}
      onHide={() => {
        toggleShowMore(false);
        if (!closedStatus) toggleSideBar();
        else setClosedStatus(false);
      }}
      className="ninpath__sidebar"
      style={{ zIndex: 10010 }}
    >
      <Menu.Item name="header">
        <MenuHeader>
          <AvatarContainer>
            <Avatar circular name="user" size="large" />
            <HeaderText>{username}</HeaderText>
          </AvatarContainer>
        </MenuHeader>
      </Menu.Item>
      <Menu.Item
        name="profile"
        className="borderless"
        as={Link}
        to="/profile"
        onClick={() => onMenuLinkClick()}
      >
        <ItemContent>
          <MenuIcon name="pencil" size="large" color="grey" />
          <ItemText>プロフィール</ItemText>
        </ItemContent>
      </Menu.Item>
      <Menu.Item
        name="contact_us"
        className="borderless"
        as={Link}
        to="/examinationResults"
        onClick={() => onMenuLinkClick()}
      >
        <ItemContent>
          <MenuIcon name="edit" size="large" color="grey" />
          <ItemText>不妊治療歴の編集</ItemText>
        </ItemContent>
      </Menu.Item>
      <Menu.Item
        className="borderless"
        as={Link}
        to="/womanAndManExaminationResults"
        onClick={() => onMenuLinkClick()}
      >
        <ItemContent>
          <MenuIcon name="history" size="large" color="grey" />
          <ItemText>不妊検査結果の編集</ItemText>
        </ItemContent>
      </Menu.Item>
      <Submenu>
        <Menu.Item
          name="contact_us"
          className="borderless"
          href="https://forms.gle/fdqpVwMv3hS87HNi9"
          target="_blank"
        >
          <ItemContent>
            <MenuIcon name="envelope" size="large" color="grey" />
            <ItemText>お問い合わせ</ItemText>
          </ItemContent>
        </Menu.Item>
        <Menu.Item
          className="borderless"
          href="https://dist.ninpath.com/tou.html"
          target="_blank"
        >
          <Menu.Header>
            <ItemContent>
              <MenuIcon
                name="file alternate outline"
                size="large"
                color="grey"
              />
              <ItemText>利用規約</ItemText>
            </ItemContent>
          </Menu.Header>
        </Menu.Item>
        <Menu.Item
          as={Button}
          name="logout"
          className="borderless"
          onClick={() => {
            attemptLogout(cookies.user_token);
            onMenuLinkClick();
          }}
        >
          <ItemContent>
            <MenuIcon name="sign-out" size="large" color="grey" />
            <ItemText>ログアウト</ItemText>
          </ItemContent>
        </Menu.Item>
      </Submenu>
      <SidebarFooter position={!showMore ? 'absolute' : 'relative'}>
        運営会社：株式会社ninpath
      </SidebarFooter>
    </Sidebar>
  );
};
SidebarMenu.propTypes = {
  logout: PropTypes.any.isRequired,
  toggleSideBar: PropTypes.func,
  visible: PropTypes.bool,
};

SidebarMenu.defaultProps = {
  toggleSideBar: () => {},
  visible: false,
};

const mapStateToProps = ({ AuthenticationReducer }) => ({
  isLoggedIn: AuthenticationReducer.isLoggedIn,
  isAmhLoggedIn: AuthenticationReducer.isAmhLoggedIn,
});

const mapDispatchToProps = dispatch => ({
  logout: token => dispatch(logoutSuccesful(token)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SidebarMenu);
