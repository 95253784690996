import { ChartCard } from 'utils/interfaces';
import infertilityExaminationMeImage from 'assets/img/sample/infertility_examination_me.png';
import infertilityExaminationStatsImage from 'assets/img/sample/infertility_treatment_stats.png';

// Other constants
export const SPOUSE_MIN_BIRTHDAY_YEAR = 1930;
export const MIN_BIRTHDAY_YEAR = 1940;
export const MAX_BIRTHDAY_YEAR = 2010;
export const DIMMER_COLOR = 'rgba(0,0,0,.04)';
export const BOX_SHADOW = 'inset -9px 0 9px -7px rgba(0,0,0,0.1)';
export const GUEST_ROUTES_WITH_MENU = ['/registration', 'user/confirmation'];
export const SWIPER_OPTIONS = {
  slidesPerView: 4,
  freeMode: 4,
  breakpoints: {
    480: {
      slidesPerView: 4,
      freeMode: 4,
    },
  },
};
/* This file contains the initial values of multiple forms,
 ** which are used in more than one page
 */
export const DEFAULT_REGISTRATION_FORM_PERSONAL_INFO_VALUES = {
  name: '',
  birthday: {
    year: '1985',
    month: null,
    day: null,
  },
  spouses_birthday: {
    year: '1985',
    month: null,
    day: null,
  },
  email: '',
  gender: null,
  prefectures: null,
  password: '',
  profession: null,
};

export const TREATMENT_RESULTS_FIELDS = {
  started_at: '',
  clinic_id: null,
  prefecture_id: null,
  ended_at: '',
  pregnancy_test_result: null,
  treatment_result_of_the_cycle: null,
  clinic_name: '',
};

export const DEFAULT_TREATMENT_HISTORY_FIELD_VALUES = {
  primary_infertility: null,
  previous_pregnancy: null,
  woman_age_started_to_go_clinic: '',
  most_advanced_treatment_experience: [],
  current_status: null,
  woman_age_stopped_treatment: '',
  number_of_timing_method: '',
  number_of_aih: '',
  number_of_egg_collection: '',
  number_of_transfer: '',
  number_of_miscarriage: '',
  stability_of_menstrual_cycle: null,
  history_of_ovarian_disease: [],
  history_of_uterine_disease: [],
  thyroid_function: [],
  amh: '',
};

export const DEFAULT_WOMAN_INFERTILITY_TEST_VALUES = {
  ultrasonic_echo: [],
  hysterosalpingography: [],
  sonohysterography: [],
  hysteroscopy: [],
  laparoscopy: [],
  huehner_test: null,
  anti_sperm_antibody_test: null,
  cervical_cancer_screening: null,
  chlamydia_antigen_test: null,
  chlamydia_antibody_test: null,
  womans_hormone_levels: [],
  womans_surgery_experience: [],
  other_test_of_ovarian_function: [],
};

export const DEFAULT_MAN_INFERTILITY_TEST_VALUES = {
  abnormal_findings: [],
  dfi: null,
  kruger_test: null,
  mens_hormone_levels: null,
  mens_inspection_and_palpation: null,
  mens_surgery_experience: [],
  mens_ultrasound: null,
  orp: null,
  semen_examination: null,
  urologist_examination: null,
};

export const DEFAULT_IMPLANTATION_PROBLEM_FIELD_VALUES = {
  implantation_window: null,
  chronic_endometritis: null,
  bacterial_environment: null,
  uterine_contraction: null,
  abnormal_immune: null,
  chromosome_examination: null,
  blood_coagulation_function: [],
  antiphospholipid_antibody_syndrome: [],
  abnormal_glucose_tolerance: [],
};

export const DEFAULT_DRUG_TYPES = [
  '卵巣刺激薬剤',
  '排卵抑制薬剤',
  'LHサージ誘起薬剤',
];

// Graph Types section
export const CLINICS_TABLE = 'clinicsTable';
export const CLINICS_BARS = 'clinicsBars';

// Tutorial explanations section
export const TUTORIAL_EXPLANATIONS = {
  miscarriages: '流産は胎嚢確認以降のものをカウントしてください。',
  menstrualCycleStability:
    'およそ25日～38日周期で定期的に月経がある場合が「安定している」の目安ですが、産婦人科で問題ないと診断されている場合は「安定している」を選択してください。',
  womenHormoneLevels:
    '原則として不妊検査（未受診の場合はホルモン剤の投薬を行っていない周期）の血液検査結果で、異常が出たことのある項目を選択してください。',
  amh:
    '*AMH=抗ミュラーリアンホルモンは、卵巣予備能の目安となる指標で血液検査で調べることができます。',
  semenExamination:
    '原則として初回の精液検査の結果を登録してください。すべての項目で異常がない場合のみ「異常なし」をご選択ください。',
  fertilizationMethods:
    'ふりかけ法とP-ICSIの場合など複数方法の組み合わせの場合には、最も個数が多かったものを選択してください。',
  numberOfIvfEnforcements:
    '成熟卵・未成熟卵に関わらず、最終的に受精（授精）に挑戦した卵子の数を記載してください。不明な場合は成熟卵数を記載してください。',
  eggGrades: `以下の内細胞塊と栄養外胚葉について, クリニックで提示される胚盤胞のグレードがガードナー分類ではない場合には以下の通り変換し入力してください。
・A→ AA
・B→ BB
・C→ AC
・D→ BC
・E→ CC`,
  resultMatrix: `ご自身が受けた不妊検査項目を一覧で確認できます。
  ご夫婦の検査結果の見直しの際にご覧ください。 \n
  利用方法の例
  ・ご夫婦の検査結果の確認
  ・未検査項目のチェック`,
  radar: `ご自身の治療歴と治療ステップ毎の平均実施回数のチャートです。
  平均と比較することでステップアップする目安の検討材料のひとつとしてご覧ください。\n
  利用方法の例
  ・ご自身の治療ステップ毎の実施回数の確認
  ・他のユーザーがステップするまでの回数の確認
  `,
  semenExaminationRadar: `最新の精液検査の結果をWHO基準値、WHO中央値と比較できるチャートです。
  項目毎に確認することで、改善点の理解などが可能になります。`,
  barMixed: `採卵結果からご自身に適した刺激法を客観的に確認し、受精卵の発育段階においてつまづきやすいポイントを可視化したチャートです。
  刺激法を検討する際の材料としてや、受精方法・培養期間などの治療法の選択における検討材料としてご覧ください。`,
  drugMixed: `採卵周期毎に使用した薬剤を記録・確認することができます。
  治療中の振り返りや、採卵周期における医師とのコミュニケーション材料としてお使いください。`,
  etMatrix: `移植周期毎のデータを記録・確認することができます。
  治療中の振り返りや、移植周期における医師とのコミュニケーション材料としてお使いください。
  `,
  sterilityLabel: `ninpathユーザーの各種不妊検査の受診状況や異常の有無をまとめたチャートです。
  `,
  stepupLabel: `ninpathユーザーが各ステップ毎に取り組んだ回数を可視化したチャートです。
  ステップアップする回数の目安や今度の治療スケジュールの参考材料としてご覧ください。
  `,
  egLabel: `ninpathに登録されている刺激法別の採卵結果を俯瞰できるチャートです。
  ご自身と似た状況のninpathユーザーがどの刺激法でどのような結果を得ているのかを知ることができます。 \n
  また、人数の数字をクリックすると使用した薬剤の組み合わせが表示されます。
  ご自身と似た状況のninpathユーザーが使用している薬剤の傾向（併用の組み合わせなど）を参考に、医師とのコミュニケーション材料等としてご覧ください。
  `,
  transplantLabel: `移植方法や胚のグレードなどを集計したチャートです。 \n
  ご自身の移植の際の参考情報として、また、各オプションの選択の必要性を検討する材料としてご覧ください。`,
  clinicsTutorialInformation:
    'この実績はninpath上で登録された患者数・周期数実績をカウントしているデータのため、実際の通院患者実績とは異なります。',
  treatmentStatus:
    '胎嚢確認〜出産までの間の方は「妊娠中」を選択してください。治療中で陽性判定後などのその他のステータス場合は「治療中」を選択して頂き、ステータスに変更があれば編集をお願いします。',
};

export const MY_CARDS = [
  new ChartCard(
    '不妊検査',
    ['resultMatrix'],
    ['resultMatrix'],
    ['女性', '男性'],
    ['全体の受診状況', '全体の検査結果'],
    true,
    false,
    ['POST'],
    infertilityExaminationMeImage,
  ),
  new ChartCard(
    'ステップアップ',
    ['radar'],
    ['radar'],
    [],
    [{ text: '全体の治療実施回数', value: 0 }],
    true,
    false,
    ['POST'],
    null,
  ),
  new ChartCard(
    '精液検査結果',
    ['semenExaminationRadar', 'semenExaminationTable'],
    ['semenExaminationRadar'],
    [],
    [],
    true,
    false,
    ['GET', 'GET'],
    null,
  ),
  new ChartCard(
    '採卵周期（刺激法)',
    ['barMixed', 'eggCollectionTable'],
    ['barMixed'],
    ['採卵数', 'IVF/ICSI施行数', '正常受精数', '分割胚到達数', '胚盤胞到達数'],
    [
      { text: '刺激法別の採卵数', value: 0 },
      { text: '刺激法別のIVF/ICSI施行率', value: 1 },
      { text: '刺激法別の正常受精率', value: 2 },
      { text: '刺激法別の分割胚到達率', value: 3 },
      { text: '刺激法別の胚盤胞到達率', value: 4 },
    ],
    true,
    false,
    ['POST', 'GET'],
    null,
  ),
  new ChartCard(
    '採卵周期（薬剤)',
    ['drugByPeriodTable'],
    ['drugMixed'],
    [],
    [{ text: '刺激法別の採卵数（使用薬剤)', value: 0 }],
    true,
    false,
    ['GET'],
    null,
  ),
  new ChartCard(
    '移植周期',
    ['etMatrix'],
    ['etMatrix'],
    [],
    [
      { text: '移植方法 と 移植タイミング', value: 0 },
      { text: '初期胚移植時のオプション', value: 1 },
      { text: '胚盤胞移植時のオプション', value: 2 },
    ],
    true,
    false,
    ['GET'],
    null,
  ),
];
export const STATS_CARDS = [
  new ChartCard(
    '不妊検査',
    ['stackedBarLabeled'],
    ['sterilityLabel'],
    ['女性の受診状況', '女性の結果', '男性の受診状況', '男性の結果'],
    [],
    true,
    true,
    ['POST'],
    infertilityExaminationStatsImage,
  ),
  new ChartCard(
    'ステップアップ',
    ['stackedBarV2'],
    ['stepupLabel'],
    [],
    [],
    true,
    true,
    ['POST'],
    null,
  ),
  new ChartCard(
    '採卵周期（刺激法)',
    ['heatmap'],
    ['egLabel'],
    ['採卵数', 'IVF/ICSI施行率', '正常受精率', '分割胚到達率', '胚盤胞到達率'],
    [],
    true,
    true,
    ['POST'],
    null,
  ),
  new ChartCard(
    '移植周期',
    ['stackedBar'],
    ['transplantLabel'],
    ['移植方法', '移植オプション(ET)', '移植オプション(BT)', '胚のグレード'],
    [],
    true,
    true,
    ['POST'],
    null,
  ),
  new ChartCard(
    'あなたのクリニックの実績',
    [CLINICS_TABLE, CLINICS_BARS],
    [],
    [],
    [],
    false,
    false,
    ['GET', 'GET'],
    null,
  ),
];

export const CHART_TITLES = {
  女性の受診状況: '女性の受診状況',
  女性の結果: '女性の結果',
  男性の受診状況: '男性の受診状況',
  男性の結果: '男性の結果',
  治療実施回数: '治療実施回数',
  採卵数: {
    me: '周期別の採卵数',
    stats: '刺激法の採卵数',
  },
  'IVF/ICSI施行数': {
    me: '周期別のIVF/ICI施行数',
    stats: '刺激法のIVF/ICI施行率',
  },
  'IVF/ICSI施行率': {
    me: '周期別のIVF/ICI施行数',
    stats: '刺激法のIVF/ICI施行率',
  },
  正常受精率: {
    me: '周期別の正常受精数',
    stats: '刺激法の正常受精率',
  },
  正常受精数: {
    me: '周期別の正常受精数',
    stats: '刺激法の正常受精率',
  },
  分割胚到達率: {
    me: '周期別の分割胚到達数',
    stats: '刺激法の分割胚到達率',
  },
  分割胚到達数: {
    me: '周期別の分割胚到達数',
    stats: '刺激法の分割胚到達率',
  },
  胚盤胞到達率: {
    me: '周期別の胚盤胞到達数',
    stats: '刺激法の胚盤胞到達率',
  },
  胚盤胞到達数: {
    me: '周期別の胚盤胞到達数',
    stats: '刺激法の胚盤胞到達数',
  },
  移植方法: '移植方法と移植タイミング',
  '移植オプション(ET)': '初期胚移植時のオプション',
  '移植オプション(BT)': '胚盤胞移植時のオプション',
  胚のグレード: '移植した胚のグレード',
};

export const INFERTILITY_SUMMARY_ITEMS = {
  女性: [
    '超音波エコー',
    '卵管造影',
    '通気・通水',
    '子宮鏡',
    '腹腔鏡',
    'フーナーテスト',
    '抗精子抗体',
    '子宮頸がん',
    'クラミジア抗原',
    'クラミジア抗体（採血)',
  ],
  男性: [
    '精液検査',
    '視診・触診',
    'エコー検査',
    'クルーガー',
    'DFI',
    'ORP',
    'ホルモン値',
  ],
};
