import Cookies from 'universal-cookie';
import request from 'utils/request';

import { call } from 'redux-saga/effects';

import { handleResponse } from 'utils/sagasHelpers';

import {
  DRUG_USAGE_DATA_FETCH_SUCCESSFUL,
  DRUG_USAGE_DATA_FETCH_FAILED,
} from 'containers/DrugUsagePage/constants';

export function* fetchDrugUsageData(action) {
  const cookies = new Cookies();
  const url = `${process.env.REACT_APP_API_URL}/api/v1/table/opu_medicine_stats?target=${action.params}`;
  const response = yield call(request, url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      charset: 'UTF-8',
      Authorization: `Bearer ${cookies.get('user_token')}`,
    },
    body: JSON.stringify(action.payload),
  });
  yield handleResponse(
    response,
    DRUG_USAGE_DATA_FETCH_SUCCESSFUL,
    DRUG_USAGE_DATA_FETCH_FAILED,
  );
}
