import React, { useEffect, useState } from 'react';
import 'react-dynamic-swiper/lib/styles.css';
import { Icon } from 'semantic-ui-react';
import { getNewsList } from '../../utils/newsHelper';

const News = () => {
  const [newsList, setNewsList] = useState([]);
  useEffect(() => {
    getNewsList(setNewsList);
  }, []);
  // NEW マークを出す基準日時
  const limitDateOfNewFlag = new Date();
  // 30日以内に公開されたニュース記事に NEW マークを出す
  limitDateOfNewFlag.setDate(limitDateOfNewFlag.getDate() - 30);
  return (
    <section className="news">
      <div className="inner">
        <div className="news-area">
          <h2 className="news-ttl">最新情報</h2>
          <ul>
            {newsList.length > 0 &&
              newsList.map(news => (
                <li key={news.id}>
                  <a
                    className="link"
                    href={news.link}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <dl className="news-block">
                      <dt className="news-header">
                        <div className="text-wrapper">
                          <time className="date" dateTime={news.date}>
                            {news.date.split('T')[0].replace(/-/g, '.')}
                            {new Date(news.date) > limitDateOfNewFlag ? (
                              <span className="new-mark">NEW</span>
                            ) : (
                              ''
                            )}
                          </time>
                          <p className="news-header-ttl">
                            {news.title.rendered.substr(0, 46)}
                            {news.title.rendered.length > 46 ? '...' : ''}
                          </p>
                        </div>
                      </dt>
                    </dl>
                  </a>
                </li>
              ))}
          </ul>
          <div className="news-all">
            <a
              className="link"
              href="https://release.ninpath.com/press/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Icon name="arrow right" />
              一覧へ
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default News;
