import produce from 'immer';
import { LOCATION_CHANGE } from 'containers/TopPage/constants';
import {
  GET_USER_PROFILE_DATA_WAS_SUCCESSFUL,
  GET_USER_PROFILE_DATA_FAILED,
  USER_EDITED_SUCCESSFULLY,
  USER_EDITING_FAILED,
} from './constants';
export const initialState = {
  editSuccessful: false,
  errors: [],
  userProfileData: null,
};
export const ProfileReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_USER_PROFILE_DATA_WAS_SUCCESSFUL:
        draft.userProfileData = action.payload.data;
        break;
      case GET_USER_PROFILE_DATA_FAILED:
        draft.userProfileData = {};
        draft.errors = action.payload.errors;
        break;
      case USER_EDITED_SUCCESSFULLY:
        draft.userProfileData = action.payload.user;
        draft.editSuccessful = true;
        draft.errors = [];
        break;
      case USER_EDITING_FAILED:
        draft.editSuccessful = false;
        draft.errors = action.payload.errors;
        break;
      case LOCATION_CHANGE:
        if (
          action.payload &&
          action.payload.location &&
          action.payload.location.pathname !== '/pathname'
        ) {
          draft.editSuccessful = false;
          draft.errors = [];
          draft.userProfileData = {};
        }
        break;
      default:
        draft.editSuccessful = state.editSuccessful;
        draft.errors = state.errors;
        draft.userProfileData = state.userProfileData;
        break;
    }
  });
