import {
  REGISTER_USER,
  SEND_AMH_CONFIRMATION_EMAIL,
  SEND_CONFIRMATION_EMAIL,
} from './constants';

export const registerUser = payload => ({
  type: REGISTER_USER,
  payload,
});

export const sendConfirmationMail = payload => ({
  type: SEND_CONFIRMATION_EMAIL,
  payload,
});

export const sendAmhConfirmationMail = payload => ({
  type: SEND_AMH_CONFIRMATION_EMAIL,
  payload,
});
