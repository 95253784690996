import React from 'react';
import PropTypes from 'prop-types';

import 'components/NinpathButton/styles.css';

const ButtonContainer = ({ children }) => (
  <div className="ninpath_button__container">{children}</div>
);
ButtonContainer.propTypes = {
  children: PropTypes.object.isRequired,
};
export default ButtonContainer;
