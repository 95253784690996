import {
  NINPATH_CHECK_LOGGED_IN,
  LOADING_STARTS,
  LOADING_ENDS,
  LOGGED_IN,
  LOGGED_OUT,
} from './constants';

export const showLoader = payload => ({
  type: LOADING_STARTS,
  payload,
});

export const hideLoader = payload => ({
  type: LOADING_ENDS,
  payload,
});

export const loginSuccesful = () => ({
  type: LOGGED_IN,
});
export const ninpathCheckLoginSuccessful = () => ({
  type: NINPATH_CHECK_LOGGED_IN,
});
export const logoutSuccesful = errorCode => ({
  type: LOGGED_OUT,
  errorCode,
});
