import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import { DEFAULT_REGISTRATION_FORM_PERSONAL_INFO_VALUES } from 'utils/defaults';
import { createStructuredSelector } from 'reselect';
import { logoutSuccesful, showLoader } from 'containers/TopPage/actions';
import { connect } from 'react-redux';
import { LayoutGrid, LayoutContainer } from 'utils/layouts';
import { Card, Form, Grid, Message, Transition } from 'semantic-ui-react';
import { ErrorMessage } from 'utils/errors';
import { Padding, TopLabel } from 'components/Atoms';
import { PersonalInfoForm } from 'containers/RegistrationPage/forms/PersonalInfo';
import { MAIN_COLOR } from 'containers/ChartPage/colors';
import {
  makeSelectEditSuccessMessage,
  makeSelectFetchedData,
  makeSelectErrors,
  makeSelectUsername,
} from './selectors';
import { getUserProfileData, editUserProfile } from './actions';

const ProfilePage = ({
  editHasSucceded,
  editProfile,
  getProfileData,
  errors,
  fetchedUserData,
  startLoading,
  username,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [formStatus, setStatus] = useState([true]);
  const [userProfileData, setUserProfileData] = useState(
    DEFAULT_REGISTRATION_FORM_PERSONAL_INFO_VALUES,
  );
  const validateForm = (index, newStatus) => {
    const newStatuses = formStatus.map(status => status);
    newStatuses[index] = newStatus;
    setStatus(newStatuses);
  };
  const updateFormValues = (index, formValues) => {
    const newValues = JSON.parse(JSON.stringify(userProfileData));
    newValues[index] = formValues;
    setUserProfileData(newValues);
  };
  const updateProfile = evt => {
    evt.preventDefault();
    startLoading({ value: true });
    const body = {
      user: userProfileData,
    };
    editProfile(body);
  };
  useEffect(() => {
    setIsLoading(true);
    getProfileData();
  }, [getProfileData]);

  useEffect(() => {
    setUserProfileData(fetchedUserData || {});
    setIsLoading(false);
  }, [fetchedUserData]);

  useEffect(() => {
    setShowSuccessMessage(editHasSucceded);
  }, [editHasSucceded]);

  useEffect(() => {
    const cookies = new Cookies();
    if (username !== '') {
      cookies.set('user_name', username, {
        path: '/',
      });
    }
  }, [username]);

  if (!isLoading && userProfileData)
    return (
      <Padding paddingTop="14px">
        <LayoutContainer style={{ height: '100%' }}>
          <LayoutGrid>
            <Grid.Row style={{ paddingTop: 0 }}>
              <Grid.Column mobile={16} computer={6} tablet={8}>
                <Form error onSubmit={evt => updateProfile(evt)}>
                  <Card
                    style={{ width: '100%', paddingTop: 14, paddingBottom: 14 }}
                    className="card__registration"
                  >
                    <Card.Header>
                      <Padding paddingLeft="14px" paddingBottom="14px">
                        <TopLabel>プロフィール編集</TopLabel>
                      </Padding>
                    </Card.Header>
                    <Card.Content>
                      <div style={{ paddingTop: 14 }}>
                        <PersonalInfoForm
                          showName
                          showEmail
                          isFormValid={validateForm}
                          updateFormValues={updateFormValues}
                          showPassword={false}
                          defaultFormValues={userProfileData.basic_information}
                        />
                        <div className="button_form_container">
                          <Form.Button
                            className="ninpath__form__container"
                            style={{ minWidth: '120px', maxWidth: '150px' }}
                            disabled={!formStatus[0]}
                          >
                            保存
                          </Form.Button>
                          {!formStatus[0] && (
                            <ErrorMessage color={MAIN_COLOR}>
                              必須項目が未入力の場合はボタンが押せません
                            </ErrorMessage>
                          )}
                          <Transition
                            visible={showSuccessMessage}
                            animation="fade"
                            duration={500}
                          >
                            <Message
                              width={{ style: '50%' }}
                              compact
                              floating
                              success
                              visible={showSuccessMessage}
                              content="プロフィールを更新しました"
                            />
                          </Transition>
                          {errors.length > 0 && (
                            <Transition>
                              <Message
                                negative
                                style={{ borderLeft: 0, borderRight: 0 }}
                              >
                                <Message.Header>エラー</Message.Header>
                                {errors.map(error => (
                                  <p key={error}>{error}</p>
                                ))}
                              </Message>
                            </Transition>
                          )}
                        </div>
                      </div>
                    </Card.Content>
                  </Card>
                </Form>
              </Grid.Column>
            </Grid.Row>
          </LayoutGrid>
        </LayoutContainer>
      </Padding>
    );
  return <div />;
};
const mapStateToProps = createStructuredSelector({
  editHasSucceded: makeSelectEditSuccessMessage(),
  fetchedUserData: makeSelectFetchedData(),
  username: makeSelectUsername(),
  errors: makeSelectErrors(),
});
const mapDispatchToProps = dispatch => ({
  editProfile: payload => dispatch(editUserProfile(payload)),
  getProfileData: () => dispatch(getUserProfileData()),
  logout: (errorCode = null) => dispatch(logoutSuccesful(errorCode)),
  startLoading: payload => dispatch(showLoader(payload)),
});

ProfilePage.propTypes = {
  editHasSucceded: PropTypes.any.isRequired,
  editProfile: PropTypes.func.isRequired,
  errors: PropTypes.any,
  getProfileData: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  fetchedUserData: PropTypes.object,
  username: PropTypes.string,
};
ProfilePage.defaultProps = {
  errors: [],
  fetchedUserData: null,
  username: '',
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(ProfilePage));
