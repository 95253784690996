import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import ChartCard from 'components/ChartCard';
import PropTypes from 'prop-types';

import { Redirect, withRouter } from 'react-router-dom';
import { Grid, Message } from 'semantic-ui-react';
import { logoutSuccesful, showLoader } from 'containers/TopPage/actions';

import CycleAdvice from 'containers/ChartPage/CycleAdvice';

import request from 'utils/request';

import { ALL_CYCLES } from 'utils/urls';
import { Padding } from 'components/Atoms';
import { StatsPageMessage } from 'containers/ChartPage/formElements';
import { MESSAGES } from './constants';

import './chart.css';

const ChartPage = ({ isLoggedIn, errorCode, pageData, pageType, logout }) => {
  const [hasCycles, setCyclesFlag] = useState(null);
  const [userClinicNames, setuserClinicNames] = useState([]);
  const [userClinicIds, setuserClinicIds] = useState(undefined);

  useEffect(() => {
    const headerCookies = new Cookies();
    const url = `${process.env.REACT_APP_API_URL}${ALL_CYCLES}`;
    const promise = request(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        charset: 'UTF-8',
        Authorization: `Bearer ${headerCookies.get('user_token')}`,
      },
    });
    promise.then(response => {
      if (response && response.clinics) {
        setCyclesFlag(response.has_cycle);
        setuserClinicNames(response.clinics.map(elm => elm.name));
        setuserClinicIds(response.clinics.map(elm => elm.id));
      } else if (response.errors && response.errors.length > 0) {
        logout();
      }
    });
    window.scrollTo(0, 0);
  }, [logout]);
  if (errorCode) {
    return <Redirect push to={{ pathName: '/', state: { errorCode } }} />;
  }
  if (!isLoggedIn) return <Redirect push to="/" />;
  return (
    <Padding paddingTop="1rem">
      <Grid centered>
        {userClinicIds && userClinicIds.length !== undefined && (
          <Grid.Row style={{ paddingTop: 0 }}>
            <Grid.Column mobile={16} tablet={10} computer={6}>
              <Message>
                <StatsPageMessage>
                  <span>{MESSAGES[pageType][0]}</span>
                  {pageType === 'stats' && <span>{MESSAGES.stats[1]}</span>}
                </StatsPageMessage>
              </Message>
              {hasCycles === false && pageType === 'me' && <CycleAdvice />}
              {pageData.map((elm, index) => (
                <ChartCard
                  chartName={elm.chartName}
                  chartTypes={elm.chartTypes}
                  chartDescription={elm.chartDescription}
                  chartParameters={elm.chartParameters}
                  footerValues={elm.externalLinks}
                  httpMethods={elm.httpMethods}
                  showTutorial={elm.showTutorial}
                  showFilters={elm.showFilters}
                  placeholder={elm.placeholder}
                  key={`graph ${index + 1}`}
                  userClinicIds={userClinicIds}
                  cardType={pageType}
                  userClinicNames={userClinicNames}
                />
              ))}
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </Padding>
  );
};

const mapStateToProps = ({ AuthenticationReducer }) => ({
  isLoggedIn: AuthenticationReducer.isLoggedIn,
});
const mapDispatchToProps = dispatch => ({
  logout: (errorCode = null) => dispatch(logoutSuccesful(errorCode)),
  startLoading: payload => dispatch(showLoader(payload)),
});

ChartPage.propTypes = {
  errorCode: PropTypes.any,
  isLoggedIn: PropTypes.bool.isRequired,
  logout: PropTypes.func,
  pageData: PropTypes.array.isRequired,
  pageType: PropTypes.string.isRequired,
};

ChartPage.defaultProps = {
  errorCode: null,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ChartPage));
