import React from 'react';
import { Icon } from 'semantic-ui-react';
const MENU_ITEMS = [
  {
    name: 'myData',
    route: '/myData',
    content: (
      <>
        {<Icon name="chart line" size="large"></Icon>}
        <span>マイデータ</span>
      </>
    ),
  },
  {
    name: 'stats',
    route: '/stats',
    content: (
      <>
        {<Icon name="pie chart" size="large"></Icon>}
        <span>みんなのデータ</span>
      </>
    ),
  },
  {
    name: 'cycles',
    route: '/cycles',
    content: (
      <>
        {<Icon name="pencil alternate" size="large" />}
        <span>治療記録入力</span>
      </>
    ),
  },
  {
    name: '/clinics',
    route: '/clinics',
    content: (
      <>
        {<Icon name="hospital outline" size="large" />}
        <span>クリニック実績</span>
      </>
    ),
  },
];

export default MENU_ITEMS;
