import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import BarMixedSampleImg from 'assets/bar_mixed_sample.png';
import OpuTableMixedSample from 'assets/opu_table_mixed_sample.png';
import DrugTableSample from 'assets/drug_table_sample.png';
import EtMatrixSampleImg from 'assets/et_matrix_sample.png';

import styled from 'styled-components';
import {
  BAR_LINE,
  RADAR,
  ET_MATRIX,
  CLINICS_TABLE,
  SEMEN_EXAMINATION_RADAR,
  DRUG_TABLE,
} from 'components/Graph/constants';
const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const checkChartType = chartType =>
  chartType === BAR_LINE || chartType === SEMEN_EXAMINATION_RADAR;
const isChartExcluded = chartType =>
  chartType !== RADAR &&
  chartType !== BAR_LINE &&
  chartType !== ET_MATRIX &&
  chartType !== SEMEN_EXAMINATION_RADAR;
const CycleDataNotRegistered = ({ chartType, pageType }) => (
  <ErrorContainer>
    {isChartExcluded(chartType) && (
      <>
        {chartType === CLINICS_TABLE && (
          <h4 style={{ textAlign: 'center' }}>
            クリニックを登録すると実績が表示されます
          </h4>
        )}
      </>
    )}
    {pageType !== 'stats' && chartType === RADAR && (
      <>
        <img
          src={BarMixedSampleImg}
          alt="data not found"
          style={{ maxWidth: '100%' }}
        />
        <p>
          今はどの治療を行なっていますか？情報を登録することでグラフ・表が表示されます。
        </p>
        <Button
          className="ninpath__button__main"
          as={Link}
          to="/cycles/opuCycle/register"
        >
          体外受精・採卵
        </Button>
        <Button
          className="error_button ninpath__button__main"
          as={Link}
          to="/cycles/etCycle/register"
        >
          体外受精・移植
        </Button>
        <Button
          className="error_button ninpath__button__main"
          as={Link}
          to="/cycles/aihCycle/register"
        >
          人工受精
        </Button>
        <Button
          className="error_button ninpath__button__main"
          as={Link}
          to="/cycles/timingCycle/register"
        >
          タイミング法
        </Button>
        <Button
          className="error_button ninpath__button__main"
          as={Link}
          to="/cycles/offCycle/register"
        >
          おやすみ中
        </Button>
      </>
    )}
    {pageType !== 'stats' && checkChartType(chartType) && (
      <>
        <img
          src={
            chartType !== SEMEN_EXAMINATION_RADAR
              ? OpuTableMixedSample
              : BarMixedSampleImg
          }
          alt="data not found"
          style={{ maxWidth: '100%' }}
        />
        <p>以下の情報を登録することでグラフ・表が表示されます。</p>
        <Button
          as={Link}
          to="/cycles/opuCycle/register"
          className="error_button  ninpath__button__main"
          color="blue"
        >
          {chartType !== SEMEN_EXAMINATION_RADAR
            ? '「体外受精：採卵周期」の治療結果を入力する'
            : '「採卵周期」の精液検査の結果を入力する'}
        </Button>
      </>
    )}
    {pageType !== 'stats' && chartType === DRUG_TABLE && (
      <>
        <img
          src={DrugTableSample}
          alt="data not found"
          style={{ maxWidth: '100%' }}
        />
        <p>以下の情報を登録することでグラフ・表が表示されます</p>
        <Button
          as={Link}
          to="/cycles/opuCycle/register"
          className="error_button  ninpath__button__main"
          color="blue"
        >
          「採卵周期」の薬剤のデータを入力する
        </Button>
      </>
    )}
    {pageType !== 'stats' && chartType === ET_MATRIX && (
      <>
        <img
          src={EtMatrixSampleImg}
          alt="data not found"
          style={{ maxWidth: '100%' }}
        />
        <p>以下の情報を登録することでグラフ・表が表示されます</p>
        <Button
          as={Link}
          to="/cycles/etCycle/register"
          className="error_button  ninpath__button__main"
        >
          「体外受精：移植周期」の治療結果を入力する
        </Button>
      </>
    )}
  </ErrorContainer>
);

CycleDataNotRegistered.propTypes = {
  chartType: PropTypes.string.isRequired,
  pageType: PropTypes.string.isRequired,
};

export default CycleDataNotRegistered;
