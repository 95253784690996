import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Grid, Message, Tab } from 'semantic-ui-react';
import { LayoutGrid, LayoutContainer } from 'utils/layouts';
import { withRouter } from 'react-router-dom';
import { MESSAGES } from 'containers/ChartPage/constants';
import { CyclesPageMessage } from 'containers/TreatmentCyclePage/formElements';
import { Padding } from 'components/Atoms';
import { CYCLE_DATA, CYCLE_LABELS } from './constants';
import './cycles.css';

import TreatmentCycle from './TreatmentCycle';
const getPanes = activeIndex =>
  CYCLE_LABELS.map((label, index) => ({
    menuItem: label,
    render: () => (
      <Tab.Pane style={{ border: 'none', padding: 5 }}>
        <TreatmentCycle
          activeIndex={activeIndex}
          tabLabel={label}
          tableLabels={CYCLE_DATA[index].tableLabels}
          key={`label${index + 1}`}
        />
      </Tab.Pane>
    ),
  }));

const TreatmentCyclePage = ({ location }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    const matches = location.search.match('/?type=(.*)');
    if (matches && matches.length > 1) setActiveIndex(parseInt(matches[1], 10));
  }, [location]);

  return (
    <Padding paddingTop="14px">
      <LayoutContainer style={{ height: '100%' }} className="treatment__cycles">
        <LayoutGrid style={{ height: 'auto' }} centered>
          <Grid.Row columns={1} style={{ paddingTop: 0 }}>
            <Grid.Column mobile={16} tablet={10} computer={6}>
              <Message>
                <CyclesPageMessage>{MESSAGES.cycles[0]}</CyclesPageMessage>
              </Message>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column mobile={16} tablet={10} computer={6}>
              <Tab
                onTabChange={(e, target) => {
                  setActiveIndex(target.activeIndex);
                }}
                activeIndex={activeIndex}
                panes={getPanes(activeIndex)}
                menu={{ secondary: true, pointing: true }}
              />
            </Grid.Column>
          </Grid.Row>
        </LayoutGrid>
      </LayoutContainer>
    </Padding>
  );
};

TreatmentCyclePage.propTypes = {
  location: PropTypes.object.isRequired,
};
export default withRouter(TreatmentCyclePage);
