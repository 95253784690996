import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import request from 'utils/request';
import { connect } from 'react-redux';
import { Form, Grid, Responsive } from 'semantic-ui-react';
import { FormField, FormLabel } from 'containers/RegistrationPage/formElements';
import Cookies from 'universal-cookie';
import { DateInput } from 'semantic-ui-calendar-react';
import { OPU_RESULTS, PREFECTURE_OPTIONS } from 'utils/defaultOptions';
import { BUILD_TREATMENT_RESULTS_PAYLOAD } from 'containers/TreatmentCyclePage/payloads';
import { logoutSuccesful } from 'containers/TopPage/actions';
import { validateTreatmentResults } from 'utils/errors';
import { TREATMENT_RESULTS_FIELDS } from 'utils/defaults';

// const OPU_RESULTS_POSITIVE = OPU_RESULTS.reduce((results, elm) => {
//   if (elm.value !== 0) results.push(elm);
//   return results;
// }, []);
const TreatmentResultsForm = ({
  loadedData,
  logout,
  isFormValid,
  showCycleResults,
  showPregnancyDetermination,
  updateFormValues,
  presetData,
}) => {
  const [firstRender, setFirstRender] = useState(true);
  const [formValues, setFormValues] = useState(TREATMENT_RESULTS_FIELDS);
  // const [treatmentResultsOptions, setTreatmentResultOptions] = useState(
  //   OPU_RESULTS,
  // );
  const [clinics, setClinics] = useState([]);

  const updateFormField = (fieldName, value) => {
    const prevLoadedData = JSON.parse(JSON.stringify(formValues));
    prevLoadedData[fieldName] = value;
    if (fieldName === 'clinic_id') prevLoadedData.clinic_name = '';
    if (fieldName === 'clinic_name') prevLoadedData.clinic_id = null;
    if (fieldName === 'prefecture_id') prevLoadedData.clinic_id = null;

    // if (fieldName === 'pregnancy_test_result' && value === 0) {
    //   if (prevLoadedData.treatment_result_of_the_cycle !== 0)
    //     setTreatmentResultOptions(OPU_RESULTS);
    //   prevLoadedData.treatment_result_of_the_cycle = 0;
    // } else if (fieldName === 'pregnancy_test_result' && value === 1) {
    //   setTreatmentResultOptions(OPU_RESULTS_POSITIVE);
    // }
    // there is no negative result if the pregnancy was positive
    // if (
    //   fieldName === 'treatment_result_of_the_cycle' &&
    //   prevLoadedData.treatment_result_of_the_cycle === 0 &&
    //   prevLoadedData.pregnancy_test_result === 1
    // ) {
    //   prevLoadedData.treatment_result_of_the_cycle = null;
    // }
    setFormValues(prevLoadedData);
    const isValid = validateTreatmentResults(prevLoadedData);
    if (isValid) {
      const requestPayload = BUILD_TREATMENT_RESULTS_PAYLOAD(prevLoadedData);
      updateFormValues('cycle', requestPayload);
    }
    isFormValid(0, isValid);
  };

  useEffect(() => {
    const cookies = new Cookies();
    if (formValues.prefecture_id !== '') {
      const promise = request(
        `${process.env.REACT_APP_API_URL}/api/v1/clinics?prefecture=${formValues.prefecture_id}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${cookies.get('user_token')}`,
          },
        },
      );
      promise.then(response => {
        if (response.status === 401) logout();
        if (response.status === 503 || response.status === 500) {
          logout(response.status);
        }
        if (response && !response.errors) {
          const clinicsTemp = response.clinics.map(clinic => ({
            value: clinic.id,
            key: clinic.id,
            text: clinic.name,
          }));
          setClinics([...clinicsTemp]);
        }
      });
    }
  }, [logout, formValues.prefecture_id]);
  useEffect(() => {
    if (loadedData && firstRender) {
      const jsonData = JSON.parse(JSON.stringify(loadedData));
      setFormValues(jsonData);
      setFirstRender(false);
    }
  }, [loadedData, firstRender]);
  useEffect(() => {
    if (presetData && firstRender) {
      const presetHashData = Object.create(TREATMENT_RESULTS_FIELDS);
      presetHashData.clinic_id = presetData.id;
      presetHashData.prefecture_id = presetData.prefecture_id;
      setFormValues(presetHashData);
      setFirstRender(false);
    }
  }, [presetData, firstRender]);
  return (
    <>
      <Form.Group>
        <Form.Field
          required
          style={{ paddingRight: 0, paddingLeft: 5 }}
          width={16}
        >
          <FormLabel htmlFor="treatment_period">治療周期 (D1 ~)</FormLabel>
          <Grid columns={3} style={{ width: '100%' }} padded>
            <Grid.Row>
              <Grid.Column mobile={7}>
                <DateInput
                  hideMobileKeyboard
                  autoComplete="off"
                  closable
                  name="treatment_start_date"
                  dateFormat="YYYY-MM-DD"
                  clearable
                  placeholder="YYYY-MM-DD"
                  iconPosition="right"
                  animation="none"
                  startMode="year"
                  localization="ja"
                  value={formValues.started_at}
                  onChange={(evt, { value }) => {
                    updateFormField('started_at', value);
                  }}
                ></DateInput>
              </Grid.Column>
              <Grid.Column
                mobile={2}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <span>~</span>
              </Grid.Column>
              <Grid.Column mobile={7} floated="right">
                <DateInput
                  hideMobileKeyboard
                  autoComplete="off"
                  startMode="year"
                  name="treatment_end_date"
                  closable
                  dateFormat="YYYY-MM-DD"
                  placeholder="YYYY-MM-DD"
                  iconPosition="right"
                  animation="none"
                  clearable
                  localization="ja"
                  value={formValues.ended_at}
                  onChange={(evt, { value }) => {
                    updateFormField('ended_at', value);
                  }}
                ></DateInput>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form.Field>
      </Form.Group>
      <Form.Group>
        <Responsive
          {...Responsive.onlyMobile}
          style={{ width: '100%', paddingLeft: 5, marginBottom: 14 }}
        >
          <Form.Field required style={{ paddingRight: 0, marginBottom: 5 }}>
            <FormLabel htmlFor="prefectures" style={{ marginRight: 0 }}>
              クリニックの都道府県
            </FormLabel>
            <Form.Select
              placeholder="都道府県"
              search
              clearable
              value={formValues.prefecture_id}
              onChange={(evt, { value }) => {
                updateFormField('prefecture_id', value);
              }}
              options={PREFECTURE_OPTIONS}
            />
          </Form.Field>
        </Responsive>
        <Responsive
          minWidth={768}
          style={{ width: '100%', paddingLeft: 5, marginBottom: 14 }}
        >
          <FormField inline required style={{ paddingRight: 0 }}>
            <FormLabel htmlFor="prefectures" style={{ marginRight: 0 }}>
              クリニックの都道府県
            </FormLabel>
            <Form.Select
              placeholder="都道府県"
              search
              clearable
              value={formValues.prefecture_id}
              onChange={(evt, { value }) => {
                updateFormField('prefecture_id', value);
              }}
              options={PREFECTURE_OPTIONS}
            />
          </FormField>
        </Responsive>
      </Form.Group>
      <Form.Group>
        <Form.Field required style={{ paddingRight: 0, paddingLeft: 5 }}>
          <FormLabel htmlFor="clinics">クリニック名</FormLabel>
          <Grid columns={1} style={{ width: '100%' }}>
            <Grid.Row>
              <Grid.Column width={16}>
                <Form.Select
                  placeholder="選択してください"
                  search
                  clearable
                  disabled={
                    formValues.prefecture_id === '' ||
                    formValues.prefecture_id === null
                  }
                  value={formValues.clinic_id}
                  onChange={(evt, { value }) => {
                    updateFormField('clinic_id', value);
                  }}
                  options={clinics}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ paddingTop: 0 }}>
              <Grid.Column width={16}>
                <Form.Input
                  fluid
                  disabled={
                    formValues.clinic_id !== null && formValues.clinic_id !== ''
                  }
                  name="other_clinic"
                  value={formValues.clinic_name ? formValues.clinic_name : ''}
                  onChange={(evt, { value }) => {
                    updateFormField('clinic_name', value);
                  }}
                  placeholder="選択肢にない場合はこちらに入力してください"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form.Field>
      </Form.Group>
      {showCycleResults && (
        <>
          <Form.Group>
            <FormField inline style={{ paddingRight: 0 }}>
              <FormLabel htmlFor="treatment_period_results">治療結果</FormLabel>
              <Form.Select
                clearable
                placeholder="選択してください"
                options={OPU_RESULTS}
                value={formValues.treatment_result_of_the_cycle}
                onChange={(evt, { value }) => {
                  // 治療結果に応じて妊娠判定の値を入れる
                  if (value === 0) {
                    updateFormField('pregnancy_test_result', 0);
                  } else {
                    updateFormField('pregnancy_test_result', 1);
                  }
                  updateFormField('treatment_result_of_the_cycle', value);
                }}
              />
            </FormField>
          </Form.Group>
        </>
      )}
    </>
  );
};
const mapDispatchToProps = dispatch => ({
  logout: (errorCode = null) => dispatch(logoutSuccesful(errorCode)),
});
TreatmentResultsForm.propTypes = {
  loadedData: PropTypes.object,
  logout: PropTypes.func.isRequired,
  isFormValid: PropTypes.func.isRequired,
  showCycleResults: PropTypes.bool,
  showPregnancyDetermination: PropTypes.bool,
  updateFormValues: PropTypes.func.isRequired,
};
TreatmentResultsForm.defaultProps = {
  loadedData: null,
  showCycleResults: true,
  showPregnancyDetermination: false,
};
export default connect(null, mapDispatchToProps)(TreatmentResultsForm);
