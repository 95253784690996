import {
  GET_EXAMINATION_RESULTS,
  UPDATE_EXAMINATION_RESULTS,
} from './constants';
export const updateExaminationResultsData = payload => ({
  type: UPDATE_EXAMINATION_RESULTS,
  payload,
});

export const getExaminationResultsData = () => ({
  type: GET_EXAMINATION_RESULTS,
  payload: {},
});
