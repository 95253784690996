import produce from 'immer';

import { isOnTheSamePage } from 'utils/helper';
import { LOCATION_CHANGE } from 'containers/TopPage/constants';
import {
  FETCH_CLINIC_DATA_SUCCESSFUL,
  FETCH_CLINIC_DATA_FAILED,
  FETCH_CLINIC_STATS_DATA_SUCCESSFUL,
  FETCH_CLINIC_STATS_DATA_FAILED,
} from 'containers/ClinicDetails/constants';

export const initialState = {
  clinicData: null,
  clinicName: '',
  errors: [],
  getDataWasSuccessful: false,
};

export const initialStatsState = {
  chartData: null,
  errors: [],
  getDataWasSuccessful: false,
};

export const ClinicDataReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case FETCH_CLINIC_DATA_SUCCESSFUL:
        draft.clinicData = JSON.parse(JSON.stringify(action.payload));
        if (action.payload.basic_information)
          draft.clinicName = action.payload.basic_information.name;
        draft.errors = [];
        draft.getDataWasSuccessful = false;
        break;
      case FETCH_CLINIC_DATA_FAILED:
        draft.clinicData = null;
        draft.clinicName = '';
        draft.errors = action.payload.errors;
        draft.getDataWasSuccessful = false;
        break;
      case LOCATION_CHANGE:
        if (!isOnTheSamePage(action)) {
          draft.clinicData = null;
          draft.clinicName = '';
          draft.errors = [];
          draft.getDataWasSuccessful = false;
        }
        break;
      default:
        draft.clinicData = state.clinicData;
        draft.clinicName = state.clinicName;
        draft.errors = state.errors;
        draft.empty = state.empty;
        draft.getDataWasSuccessful = state.getDataWasSuccessful;
    }
  });
export const ClinicStatsReducer = (state = initialStatsState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case FETCH_CLINIC_STATS_DATA_SUCCESSFUL:
        draft.chartData = JSON.parse(JSON.stringify(action.payload));
        draft.errors = [];
        draft.getDataWasSuccessful = false;
        break;
      case FETCH_CLINIC_STATS_DATA_FAILED:
        draft.chartData = null;
        draft.errors = action.payload.errors;
        draft.getDataWasSuccessful = false;
        break;
      case LOCATION_CHANGE:
        if (!isOnTheSamePage(action)) {
          draft.chartData = null;
          draft.errors = [];
          draft.getDataWasSuccessful = false;
        }
        break;
      default:
        draft.chartData = state.chartData;
        draft.errors = state.errors;
        draft.empty = state.empty;
        draft.getDataWasSuccessful = state.getDataWasSuccessful;
    }
  });
