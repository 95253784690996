/* This file contains the list of base urls from he API */
export const ET_STATS = '/api/v1/graph/et_stats?target=';
export const ET_ME = '/api/v1/table/et_me';

export const INFERTILITY_ME = '/api/v1/graph/infertility_me?gender=';
export const INFERTILITY_STATS = '/api/v1/graph/infertility_stats?condition=';

export const OPU_DRUGS = '/api/v1/table/opu_medicine_me';

export const STEPUP_ME = '/api/v1/graph/stepup_me';
export const STEPUP_STATS = '/api/v1/graph/stepup_stats';

export const OPU_STIMULATION_ME = '/api/v1/graph/opu_stimulation_me?target=';
export const OPU_STIMULATION_STATS =
  '/api/v1/graph/opu_stimulation_stats?target=';

export const OPU_TABLE_ME = '/api/v1/table/opu_stimulation_me?target=';

export const SEMEN_EXAMINATION_TABLE = '/api/v1/table/semen_examination_me';
export const SEMEN_EXAMINATION_RADAR = '/api/v1/graph/semen_examination_me';

export const ALL_CYCLES = '/api/v1/all_cycle';

export const CLINICS_TABLE_URL = '/api/v1/table/clinic_stats/';
export const CLINICS_BAR_URL = '/api/v1/graph/clinic_stats/';
export const CLINICS_URL = '/api/v1/clinics';

export const AMH_RESULT = '/api/v1/amh_users';

export const urls = {
  resultMatrix: INFERTILITY_ME,
  stackedBarLabeled: INFERTILITY_STATS,

  eggCollectionTable: OPU_TABLE_ME,

  radar: STEPUP_ME,
  stackedBarV2: STEPUP_STATS,

  semenExaminationRadar: SEMEN_EXAMINATION_RADAR,
  semenExaminationTable: SEMEN_EXAMINATION_TABLE,

  barMixed: OPU_STIMULATION_ME,
  heatmap: OPU_STIMULATION_STATS,

  drugByPeriodTable: OPU_DRUGS,

  etMatrix: ET_ME,
  stackedBar: ET_STATS,

  clinicsTable: CLINICS_TABLE_URL,
  clinicsBars: CLINICS_BAR_URL,
  clinics: CLINICS_URL,
};
