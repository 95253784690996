import styled from 'styled-components';
export const IndicatorContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const Mask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  pointer-events: none;
`;
export const TopLabel = styled.h3`
  && {
    font-weight: 500;
    margin-top: 0;
  }
`;
export const SubTitleLabel = styled.h4`
  && {
    font-weight: bold;
    margin-top: 0;
  }
`;

export const Padding = styled.div`
&&{
  padding-top: ${props => (props.paddingTop ? props.paddingTop : 0)}
  padding-bottom:${props => (props.paddingBottom ? props.paddingBottom : 0)}
  padding-left:${props => (props.paddingLeft ? props.paddingLeft : 0)}
  padding-right: ${props => (props.paddingRight ? props.paddingRight : 0)}}`;

export const Margin = styled.div`
  &&{
  margin-top: ${props => (props.marginTop ? props.marginTop : 0)}
  margin-bottom:${props => (props.marginBottom ? props.marginBottom : 0)}
  margin-left:${props => (props.marginLeft ? props.marginLeft : 0)}
  margin-right: ${props => (props.marginRight ? props.marginRight : 0)}
}`;
