import React from 'react';
import { Menu } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { SUBFILTER_COLORS } from 'containers/ChartPage/colors';
import { Circle, FilterMenu } from 'components/ChartCard/CardElements';

const InfertilitySummary = ({ filters, activeSubFilters }) => (
  <FilterMenu
    vertical
    className="borderless"
    style={{ paddingLeft: 14, paddingRight: 14 }}
  >
    {filters.map((filter, index) => (
      <Menu.Item
        className="borderless"
        key={filter}
        style={{
          paddingLeft: 0,
          borderLeft: 0,
          borderRight: 0,
          borderBottom: '1px solid #f4f4f4',
        }}
      >
        <span>{filter}</span>
        <div style={{ float: 'right', textAlign: 'right' }}>
          <Circle
            style={{
              backgroundColor: SUBFILTER_COLORS[activeSubFilters[index]],
            }}
          />
          <div style={{ width: '65px', display: 'inline-block' }}>
            <span>{activeSubFilters[index]}</span>
          </div>
        </div>
      </Menu.Item>
    ))}
  </FilterMenu>
);

InfertilitySummary.propTypes = {
  activeSubFilters: PropTypes.any.isRequired,
  filters: PropTypes.array.isRequired,
};
export default React.memo(InfertilitySummary);
