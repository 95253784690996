import { createSelector } from 'reselect';
import { initialState } from './reducers';

const selectExaminationResults = state => state.examinationResults;

const makeSelectErrors = () =>
  createSelector(
    selectExaminationResults,
    examinationResultsState =>
      examinationResultsState.errors || initialState.errors,
  );

const makeSelectGetDataSuccessMessage = () =>
  createSelector(
    selectExaminationResults,
    examinationResultsState => examinationResultsState.getDataWasSuccessful,
  );
const makeSelectUpdateSuccessMessage = () =>
  createSelector(
    selectExaminationResults,
    examinationResultsState => examinationResultsState.updateSuccessful,
  );

const makeSelectFetchedData = () =>
  createSelector(
    selectExaminationResults,
    examinationResultsState => examinationResultsState.examinationResultsData,
  );
export {
  makeSelectGetDataSuccessMessage,
  makeSelectUpdateSuccessMessage,
  makeSelectFetchedData,
  makeSelectErrors,
};
