import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Filter from 'components/FilterList/filter';
import FilterText from 'components/FilterList/filterText';
import { Swiper, Slide } from 'react-dynamic-swiper';
import { MAIN_COLOR, SUB_COLOR } from 'containers/ChartPage/colors';
import 'react-dynamic-swiper/lib/styles.css';
import { SWIPER_OPTIONS } from 'containers/DrugUsagePage/constants';
const FilterList = ({ activeFilter, changeFilter, filtersData }) => {
  const [swiper, setSwiperInstance] = useState(null);
  useEffect(() => {
    if (swiper && filtersData && filtersData.length > 0)
      swiper.slideTo(activeFilter);
  }, [activeFilter, swiper, filtersData]);
  return (
    <>
      <Swiper
        swiperOptions={SWIPER_OPTIONS(filtersData.length)}
        pagination={false}
        navigation={false}
        onInitSwiper={instance => {
          setSwiperInstance(instance);
        }}
      >
        {filtersData.map((type, index) => (
          <Slide
            key={type}
            onClick={() => {
              changeFilter(index);
            }}
          >
            <Filter
              style={{
                backgroundColor:
                  index !== activeFilter ? SUB_COLOR : MAIN_COLOR,
                color: index !== activeFilter ? '#000' : '#fff',
              }}
            >
              <FilterText style={{fontSize:`1rem`}}>{type}</FilterText>
            </Filter>
          </Slide>
        ))}
      </Swiper>
    </>
  );
};
FilterList.propTypes = {
  activeFilter: PropTypes.number,
  changeFilter: PropTypes.func,
  filtersData: PropTypes.array,
};
FilterList.defaultProps = {
  activeFilter: 0,
  changeFilter: () => {},
  filtersData: [],
};
export default FilterList;
