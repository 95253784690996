import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal } from 'semantic-ui-react';
import Cookies from 'universal-cookie';
import request from '../../utils/request';

const userDeleteReducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return { ...state, open: true };
    case 'CLOSE_MODAL':
      return { ...state, open: false };
    default:
      throw new Error();
  }
};

const UserDeleteModal = ({ startLoading, finishLoading, logout }) => {
  const cookies = new Cookies();
  const [state, dispatch] = React.useReducer(userDeleteReducer, {
    closeOnEscape: true,
    closeOnDimmerClick: true,
    open: false,
    secondOpen: false,
    dimmer: undefined,
  });
  const { open, closeOnEscape, closeOnDimmerClick } = state;
  const deleteHandler = () => {
    startLoading({ value: true });
    const url = `${process.env.REACT_APP_API_URL}/api/v1/me`;
    const option = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        charset: 'UTF-8',
        Authorization: `Bearer ${cookies.get('user_token')}`,
      },
    };
    const promise = request(url, option);
    promise.then(() => logout());
    finishLoading({ value: true });
    dispatch({ type: 'CLOSE_MODAL' });
  };
  return (
    <>
      <Modal
        closeOnEscape={closeOnEscape}
        closeOnDimmerClick={closeOnDimmerClick}
        open={open}
        onOpen={() => dispatch({ type: 'OPEN_MODAL' })}
        onClose={() => dispatch({ type: 'CLOSE_MODAL' })}
        trigger={
          <Form.Button
            className="ninpath__form__container_sub"
            style={{
              minWidth: '120px',
              maxWidth: '200px',
              marginLeft: 8,
            }}
          >
            退会する
          </Form.Button>
        }
      >
        <Modal.Header>退会確認</Modal.Header>
        <Modal.Content>
          <p>本当に退会しますか？</p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => dispatch({ type: 'CLOSE_MODAL' })} color="red">
            キャンセル
          </Button>
          <Button onClick={deleteHandler} color="grey">
            退会する
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

UserDeleteModal.propTypes = {
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};
export default UserDeleteModal;
