import Cookies from 'universal-cookie';
import request from 'utils/request';

import { call } from 'redux-saga/effects';

import { buildClinicsBarData } from 'utils/chartHelper';
import { handleResponse } from 'utils/sagasHelpers';
import { BAR_MIXED_COLORS } from 'containers/ChartPage/colors';
import {
  FETCH_CLINIC_DATA_SUCCESSFUL,
  FETCH_CLINIC_DATA_FAILED,
  FETCH_CLINIC_STATS_DATA_SUCCESSFUL,
  FETCH_CLINIC_STATS_DATA_FAILED,
} from 'containers/ClinicDetails/constants';

import { CLINICS_BAR_URL, CLINICS_TABLE_URL, CLINICS_URL } from 'utils/urls';

export function* fetchClinicData(action) {
  const cookies = new Cookies();
  const url = `${process.env.REACT_APP_API_URL}${CLINICS_URL}/${action.params}`;
  const response = yield call(request, url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      charset: 'UTF-8',
      Authorization: `Bearer ${cookies.get('user_token')}`,
    },
  });
  yield handleResponse(
    response,
    FETCH_CLINIC_DATA_SUCCESSFUL,
    FETCH_CLINIC_DATA_FAILED,
  );
}

export function* fetchClinicStatsData(action) {
  const cookies = new Cookies();
  const urlTable = `${process.env.REACT_APP_API_URL}${CLINICS_TABLE_URL}/${action.params}`;
  const tableResponse = yield call(request, urlTable, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      charset: 'UTF-8',
      Authorization: `Bearer ${cookies.get('user_token')}`,
    },
  });
  const urlBar = `${process.env.REACT_APP_API_URL}${CLINICS_BAR_URL}/${action.params}`;
  const barResponse = yield call(request, urlBar, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      charset: 'UTF-8',
      Authorization: `Bearer ${cookies.get('user_token')}`,
    },
  });
  let barData = null;
  if (!barResponse.status && !barResponse.empty) {
    barData = buildClinicsBarData(barResponse, BAR_MIXED_COLORS);
  }
  const response = {
    graphs: { bar: barData || barResponse, table: tableResponse },
    status: barResponse.status || tableResponse.status,
    errors: barResponse.errors || tableResponse.errors,
  };
  yield handleResponse(
    response,
    FETCH_CLINIC_STATS_DATA_SUCCESSFUL,
    FETCH_CLINIC_STATS_DATA_FAILED,
  );
}
