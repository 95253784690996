export const SWIPER_OPTIONS = (sliderSize = 3) => ({
  slidesPerView: sliderSize > 3 ? 3.5 : sliderSize,
  freeMode: sliderSize > 3,
  allowTouchMove: sliderSize > 3,
});

export const FETCH_DRUG_USAGE_DATA =
  'ninpath/containers/DrugUsagePage/FETCH_DRUG_USAGE_DATA';

export const DRUG_USAGE_DATA_FETCH_SUCCESSFUL =
  'ninpath/containers/DrugUsagePage/DRUG_USAGE_DATA_FETCH_SUCCESSFUL';

export const DRUG_USAGE_DATA_FETCH_FAILED =
  'ninpath/containers/DrugUsagePage/DRUG_USAGE_DATA_FETCH_FAILED';
