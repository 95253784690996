import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import { J_ENG_DICTIONARY } from 'utils/dictionary';

const ClinicItem = ({ itemKey, itemValue }) => (
  <Grid.Row
    style={{
      borderBottom: '1px solid #E9E9E9',
    }}
  >
    <Grid.Column computer={4} mobile={6} tablet={5}>
      <p>{J_ENG_DICTIONARY[itemKey]}</p>
    </Grid.Column>
    <Grid.Column computer={12} mobile={10} tablet={11}>
      {itemKey !== 'url' ? (
        <p style={{ textAlign: 'right' }}>{itemValue}</p>
      ) : (
        <p
          style={{
            textAlign: 'right',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          <a href={itemValue} target="_blank" rel="noopener noreferrer">
            {itemValue}
          </a>
        </p>
      )}
    </Grid.Column>
  </Grid.Row>
);

ClinicItem.propTypes = {
  itemKey: PropTypes.string.isRequired,
  itemValue: PropTypes.string,
};
ClinicItem.defaultProps = {
  itemValue: '',
};
export default ClinicItem;
