/* Basic Layouts for pages */
import styled from 'styled-components';
import { Grid } from 'semantic-ui-react';
export const LayoutContainer = styled.div`
  width: 100%;
  height: 100vh;
`;
export const LayoutGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0px;
`;

export const ErrorLayoutContainer = styled.div`
  height: calc(100vh);
  background-color: ${props => props.bgColor};
  display: flex;
  padding: 14px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
