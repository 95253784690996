import React from 'react';
import PropTypes from 'prop-types';
import { Bar, Radar } from 'react-chartjs-2';
import { Message, Header } from 'semantic-ui-react';
import Chart from 'react-apexcharts';

import { ChartName } from 'components/ChartCard/CardElements';
import ClinicsTable from 'components/Graph/ClinicsTable';
import DrugTable from 'components/Graph/DrugTable';
import EtTable from 'components/Graph/EtTable';
import InfertilitySummary from 'components/Graph/InfertilitySummary';
import OpuTable from 'components/Graph/OpuTable';
import { Margin, Padding } from 'components/Atoms';
import SemenExaminationTable from 'components/Graph/SemenExaminationTable';

import { Chart as GoogleChart } from 'react-google-charts';
import {
  BLASTOCYST_TREEMAP_OPTIONS,
  EARLY_EMBRYOS_TREEMAP_OPTIONS,
} from 'containers/ChartPage/options';
import { CLINICS_BARS, INFERTILITY_SUMMARY_ITEMS } from 'utils/defaults';
import CycleDataNotRegistered from 'containers/ErrorPage/CycleDataNotRegistered';
import { HorizontalLine, TableUnit } from './graphElements';
import {
  BAR,
  BAR_V2,
  BAR_LINE,
  BAR_LABELED,
  CLINICS_TABLE,
  DRUG_TABLE,
  ET_MATRIX,
  RADAR,
  RESULT_MATRIX,
  OPU_TABLE,
  SEMEN_EXAMINATION_RADAR,
  SEMEN_EXAMINATION_TABLE,
  HEATMAP,
} from './constants';

const randomize = () => Math.random();
const isBar = type =>
  type === BAR_LABELED || type === BAR_LINE || type === BAR_V2;

const treemapEvents = [
  {
    eventName: 'select',
    callback({ chartWrapper }) {
      // trick to avoid going to children (!important)
      chartWrapper.getChart().setSelection();
    },
  },
];

const Graph = ({
  activeChartType,
  dataToPlot,
  parameters,
  showError,
  options,
  pageType,
  type,
  reference,
}) => (
  <div>
    {isBar(type) && !showError && dataToPlot && (
      <Bar
        data={dataToPlot}
        options={options}
        height={300}
        datasetKeyProvider={randomize}
        ref={reference}
      />
    )}
    {type === RADAR && !showError && dataToPlot && (
      <Radar data={dataToPlot} options={options} height={300} width={300} />
    )}
    {type === SEMEN_EXAMINATION_RADAR && !showError && dataToPlot && (
      <Radar data={dataToPlot} options={options} height={300} width={300} />
    )}
    {type === RESULT_MATRIX && !showError && parameters.length > 0 && (
      <InfertilitySummary
        filters={INFERTILITY_SUMMARY_ITEMS[parameters[activeChartType]]}
        activeSubFilters={dataToPlot}
      />
    )}
    {type === HEATMAP && !showError && dataToPlot && dataToPlot.length && (
      <div style={{ height: 320 }}>
        <Chart
          height="350"
          options={options}
          series={dataToPlot}
          type="heatmap"
        />
        <TableUnit>* 単位：人</TableUnit>
      </div>
    )}
    {type === ET_MATRIX && dataToPlot && !showError && (
      <EtTable tableData={dataToPlot} />
    )}
    {type === DRUG_TABLE && dataToPlot && !showError && (
      <DrugTable tableData={dataToPlot} />
    )}
    {type === OPU_TABLE && dataToPlot && !showError && (
      <OpuTable tableData={dataToPlot} />
    )}
    {type === SEMEN_EXAMINATION_TABLE && dataToPlot && !showError && (
      <SemenExaminationTable tableData={dataToPlot} />
    )}
    {type === BAR && activeChartType < 3 && dataToPlot && !showError && (
      <Bar
        data={dataToPlot}
        options={options}
        height={300}
        datasetKeyProvider={randomize}
        ref={reference}
      />
    )}
    {type === BAR && activeChartType === 3 && dataToPlot && !showError && (
      <div>
        {dataToPlot.early_embryo && dataToPlot.early_embryo.length > 2 && (
          <>
            <HorizontalLine />
            <Margin marginBottom="14px">
              <Padding paddingLeft="14px">
                <p style={{ fontSize: '1.2rem' }}>全体の内訳</p>
                <p>（胚盤胞の()は胚盤胞の発達段階です）</p>
              </Padding>
            </Margin>

            <GoogleChart
              width="100%"
              height="300px"
              chartType="TreeMap"
              data={dataToPlot.early_embryo}
              chartEvents={treemapEvents}
              options={EARLY_EMBRYOS_TREEMAP_OPTIONS}
              rootProps={{ 'data-testid': '1' }}
              formatters={[
                {
                  type: 'PatternFormat',
                  column: [0, 1, 2],
                  options: '{0} {2}人',
                },
              ]}
            ></GoogleChart>
          </>
        )}
        {dataToPlot.blastocyst && dataToPlot.blastocyst.length > 2 && (
          <>
            <Margin marginBottom="14px">
              <Padding paddingTop="14px" paddingLeft="14px">
                <p style={{ fontSize: '1.2rem' }}>胚盤胞の内訳</p>
              </Padding>
            </Margin>
            <GoogleChart
              width="100%"
              height="400px"
              chartType="TreeMap"
              chartEvents={treemapEvents}
              data={dataToPlot.blastocyst}
              options={BLASTOCYST_TREEMAP_OPTIONS}
              rootProps={{ 'data-testid': '2' }}
              formatters={[
                {
                  type: 'PatternFormat',
                  column: [0, 1, 2],
                  options: '{0} {2}人',
                },
              ]}
            ></GoogleChart>
          </>
        )}
      </div>
    )}
    {type === CLINICS_TABLE && dataToPlot && !showError && (
      <>
        <ChartName>{parameters[activeChartType]}</ChartName>
        <ClinicsTable tableData={dataToPlot} />
        <Padding paddingTop="14px" paddingLeft="14px" paddingRight="14px">
          <Message>
            <ul
              style={{
                paddingLeft: 14,
                textAlign: 'justify',
                listStyle: 'disc',
              }}
            >
              <li>
                この実績はninpath上で登録された患者数・周期数実績をカウントしているデータのため、実際の通院患者実績とは異なります。
              </li>
              <li>
                患者数は治療内容毎の集計のため、IVFとAIHの両方の治療を受けた場合はどちらの項目にもカウントされます。
              </li>
              <li>妊娠数は陽性判定を含めてカウントしています。</li>
              <li>
                年齢区分はユーザーの生年月より算出した現時点の年齢を用いています。
              </li>
              <li>
                現時点のデータは過去からのすべての期間を対象に集計しています。
              </li>
            </ul>
          </Message>
        </Padding>
      </>
    )}
    {type === CLINICS_BARS && dataToPlot && !showError && (
      <>
        <Header
          as="h3"
          style={{
            fontWeight: 400,
            paddingLeft: 14,
            paddingTop: 14,
            borderTop: '1px solid #E9E9E9',
          }}
        >
          IVF/ICSIの出産実績
        </Header>
        <Bar
          data={dataToPlot}
          options={options}
          height={300}
          datasetKeyProvider={randomize}
          ref={reference}
        />
        <Padding paddingTop="14px" paddingLeft="14px" paddingRight="14px">
          <Message style={{ paddingLeft: 14, textAlign: 'justify' }}>
            今後登録数の増加に伴いデータが蓄積されることで、クリニックの実績情報としてご活用いただける予定です。
          </Message>
        </Padding>
      </>
    )}
    {showError && (
      <CycleDataNotRegistered pageType={pageType} chartType={type} />
    )}
  </div>
);

Graph.defaultProps = {
  dataToPlot: {
    data: { datasets: [], labels: [] },
  },
  options: {},
  parameters: [],
  type: null,
  showError: false,
  activeChartType: 0,
  reference: null,
};
Graph.propTypes = {
  activeChartType: PropTypes.number,
  dataToPlot: PropTypes.any,
  options: PropTypes.any,
  parameters: PropTypes.array,
  pageType: PropTypes.string.isRequired,
  showError: PropTypes.bool,
  type: PropTypes.string,
  reference: PropTypes.any,
};

export default Graph;
