import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Icon } from 'semantic-ui-react';
import ReactPlayer from 'react-player';
import { withRouter } from 'react-router-dom';
import { hideLoader } from 'containers/TopPage/actions';
import styled from 'styled-components';
import News from 'components/News';
import ErrorPage from 'containers/ErrorPage';
import Cookies from 'universal-cookie';
import 'assets/css/layout.css';
import logo from 'assets/img/header/logo.png';
import catchCopy from 'assets/img/header/catch_copy.png';
import threePointHeading from 'assets/img/top/three_point_heading.png';
import appIcon from 'assets/img/top/appicon.png';
import point01 from 'assets/img/top/point01.png';
import point02 from 'assets/img/top/point02.png';
import point03 from 'assets/img/top/point03.png';
import comingSoon from 'assets/img/top/comming_soon.png';
import comingSoonSp from 'assets/img/top/comming_soon.sp.png';
import ninpathGray from 'assets/img/top/ninpath_gray.png';
import col01Image from 'assets/img/top/col01_image.png';
import col02Image from 'assets/img/top/col02_image.png';
import ninpathCareer from 'assets/img/top/ninpath_carrer.png';
import careerScreen from 'assets/img/top/carrer_screen.png';
import footerLogo from 'assets/img/footer/footer_logo.png';
import appAndroid from 'assets/img/app_android.png';
import appIos from 'assets/img/app_ios.png';
import insuranceImage from 'assets/img/hoken/hoken_image.png';
import insuranceBannerPc from 'assets/img/hoken/1440_200_v2.jpg';
import insuranceBannerSp from 'assets/img/hoken/864_200.jpg';

import { Link } from 'react-scroll';
import { Controller, Scene } from 'react-scrollmagic';

const TopPageContainer = styled.div`
  width: 100%;
`;

const TopPage = ({ errorCode, location, finishLoading, showLoading }) => {
  if (showLoading) {
    finishLoading({ value: true });
  }
  const userAgent = window.navigator.userAgent.toLowerCase();
  const isIphone = userAgent.indexOf('iphone') !== -1;
  const isAndroid = userAgent.indexOf('android') !== -1;
  const appStoreUrl = 'https://itunes.apple.com/jp/app/id1538735316?mt=8';
  const playStoreUrl =
    'https://play.google.com/store/apps/details?id=com.app.ninpath';
  const url = isIphone ? appStoreUrl : playStoreUrl;
  const [showAppBanner, setShowAppBanner] = React.useState(true);
  const cookies = new Cookies();
  const appBannerClosed = cookies.get('AppBannerClosed');
  React.useEffect(() => {
    if (appBannerClosed === 'true') {
      setShowAppBanner(false);
    }
  });
  const closeTopBanner = () => {
    cookies.set('AppBannerClosed', 'true');
    setShowAppBanner(false);
  };
  const videoUrl = 'https://www.youtube.com/watch?v=3HpWeolpgq8';
  const videoWidth = isIphone || isAndroid ? '100%' : 640;
  const videoHeight = isIphone || isAndroid ? 280 : 360;
  const insuranceBanner =
    isIphone || isAndroid ? insuranceBannerSp : insuranceBannerPc;
  return location.state && location.state.errorCode && errorCode ? (
    <ErrorPage
      errorCode={location.state.errorCode}
      message={
        location.state.errorCode === 503
          ? '現在メンテナンス中です。しばらしくしてから再度アクセスお願いいたします'
          : 'ただいま混み合っておりますしばらくしてから再度アクセスお願いいたします'
      }
    />
  ) : (
    <TopPageContainer>
      <Container>
        {showAppBanner ? (
          <div className="top-banner-frame sp">
            <div className="top-banner-inner">
              <a className="close" onClick={() => closeTopBanner()}>
                <Icon name="close" />
              </a>
              <a href={url} target="_blank" rel="noopener noreferrer">
                <img
                  src={appIcon}
                  alt="アプリアイコン"
                  className="app-icon"
                />
                <p className="description">
                  ninpathアプリ
                  <br />
                  今すぐ無料ダウンロード！
                </p>
              </a>
              <a
                className="btn"
                href={url}
                target="_blank"
                rel="noopener noreferrer"
              >
                ダウンロード
              </a>
            </div>
          </div>
        ) : (
          ''
        )}
        <div className="site-frame">
          <div className="site-frame-inner">
            <header className="header">
              <div className="header-inner">
                <div className="sumaho"></div>
                <div className="compass"></div>
                <div className="header-bar">
                  <div className="header-bar-inner">
                    <h1 className="logo">
                      <img src={logo} alt="ninpath" />
                    </h1>
                    <nav>
                      <ul className="navi">
                        <li
                          className="navi-item smoothscroll"
                          data-target="three-point"
                        >
                          <Link
                            activeClass="active"
                            to="three-point"
                            spy={true}
                            smooth={true}
                            offset={50}
                            duration={500}
                          >
                            ninpathについて
                          </Link>
                        </li>
                        <li
                          className="navi-item smoothscroll"
                          data-target="ninpath-intro"
                        >
                          <Link
                            activeClass="active"
                            to="ninpath-intro"
                            spy={true}
                            smooth={true}
                            offset={50}
                            duration={500}
                          >
                            私たちの想い
                          </Link>
                        </li>
                        <li
                          className="navi-item smoothscroll"
                          data-target="carrer"
                        >
                          <Link
                            activeClass="active"
                            to="carrer"
                            spy={true}
                            smooth={true}
                            offset={50}
                            duration={500}
                          >
                            ninpath careerの紹介
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  <div className="shine"></div>
                </div>

                <div className="regist-login sp-none">
                  <div className="regist-btn">
                    <a className="link" href="/registration">
                      新規登録
                    </a>
                  </div>
                  <div className="login-btn">
                    <a className="link" href="/login">
                      ログイン
                    </a>
                  </div>
                </div>

                <div className="main-visual">
                  <div className="visual"></div>
                  <div className="lead-area">
                    <div className="catch-copy">
                      <img
                        src={catchCopy}
                        alt="子どもを望む、ひとりひとりの道しるべ ninpath"
                      />
                    </div>
                    <div className="app-btn-top pc">
                      <a
                        href={appStoreUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="app-btn-and"
                          src={appIos}
                          alt="ios"
                          width="200px"
                        />
                      </a>
                      <a
                        href={playStoreUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="app-btn-and"
                          src={appAndroid}
                          alt="android"
                          width="200px"
                        />
                      </a>
                    </div>
                    <h2 className="lead-text">
                      「正解がない」からこそ、データを知り、
                      <br />
                      自分を、進むべき道を知る。
                    </h2>
                    <p className="text">
                      治療記録・管理はもちろん、ご自身の状況に近い第三者の治療データと比較することで
                      <br className="sp_none" />
                      治療に取り組むお二人の現状を客観的に捉える場と、最善の道を考えるきっかけを提供。
                      <br />
                      それが不妊治療サポートサービス「ninpath」(ニンパス)です。
                    </p>
                  </div>
                  <section className="app-video-area">
                    <div className="app-video">
                      <ReactPlayer
                        url={videoUrl}
                        width={videoWidth}
                        height={videoHeight}
                        controls
                      />
                    </div>
                  </section>
                  <div className="regist-login pc-none">
                    <div className="regist-btn">
                      <a className="link" href="/registration">
                        新規登録
                      </a>
                    </div>
                    <div className="login-btn">
                      <a className="link" href="/login">
                        ログイン
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            <main className="main">
              <News />
              <section className="three-point" name="three-point">
                <div className="bg01"></div>
                <div className="bg02"></div>
                <div className="inner">
                  <h2 className="heading">
                    <img
                      src={threePointHeading}
                      alt="ninpathの3つの特徴"
                      id="start"
                    />
                  </h2>
                  <ul className="point-list">
                    <Controller>
                      <Scene
                        triggerElement=".item01"
                        offset={-300}
                        reverse={false}
                        classToggle="is-active"
                      >
                        <li
                          className="point-item item01 js-anime"
                          data-anime="fade"
                        >
                          <div className="item-inner">
                            <div className="icon">
                              <img src={point01} alt="" />
                            </div>
                            <div className="ttl">記録する</div>
                            <p className="text">
                              治療した日付や方法など、必要な情報を一括で記録。
                              <br className="sp_none" />
                              周期単位で書き込めるので、治療方法や薬が変わった時も簡単に入力することができます。
                            </p>
                          </div>
                        </li>
                      </Scene>
                    </Controller>
                    <Controller>
                      <Scene
                        triggerElement=".item02"
                        offset={-300}
                        reverse={false}
                        classToggle="is-active"
                      >
                        <li
                          className="point-item item02 js-anime"
                          data-anime="fade"
                        >
                          <div className="item-inner">
                            <div className="icon">
                              <img src={point02} alt="" />
                            </div>
                            <div className="ttl">振り返る</div>
                            <p className="text">
                              入力した情報は、時系列に確認ができます。
                              <br className="sp_none" />
                              今まで行った治療や薬効を俯瞰して見返し、現在のステータスを把握することが可能です。
                            </p>
                          </div>
                        </li>
                      </Scene>
                    </Controller>
                    <Controller>
                      <Scene
                        triggerElement=".item03"
                        offset={-300}
                        reverse={false}
                        classToggle="is-active"
                      >
                        <li
                          className="point-item item03 js-anime"
                          data-anime="fade"
                        >
                          <div className="item-inner">
                            <div className="icon">
                              <img src={point03} alt="" />
                            </div>
                            <div className="ttl">比較する</div>
                            <p className="text">
                              自分の入力データだけでなく、ご自身に近い状況の他のユーザー情報を見て、自分の状況と比較検討することができます。
                            </p>
                          </div>
                        </li>
                      </Scene>
                    </Controller>
                  </ul>
                </div>
              </section>
              <section className="app-btn-center">
                <div className="app-btn-center-box">
                  <div className="app-ttl">今すぐ無料ダウンロード！</div>
                  <div className="app-btn">
                    {isAndroid === false ? (
                      <a
                        href={appStoreUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="app-btn-and"
                          src={appIos}
                          alt="ios"
                          width="200px"
                        />
                      </a>
                    ) : (
                      ''
                    )}
                    {isIphone === false ? (
                      <a
                        href={playStoreUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="app-btn-and"
                          src={appAndroid}
                          alt="android"
                          width="200px"
                        />
                      </a>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className="sumaho"></div>
              </section>
              <section className="comming-soon">
                <Controller>
                  <Scene
                    triggerElement=".item04"
                    offset={-300}
                    reverse={false}
                    classToggle="is-active"
                  >
                    <div className="inner js-anime item04" data-anime="fade">
                      <img className="pc" src={comingSoon} alt="" />
                      <img className="sp" src={comingSoonSp} alt="" />
                    </div>
                  </Scene>
                </Controller>
              </section>
              <section className="ninpath-intro" name="ninpath-intro">
                <div className="wave"></div>
                <div className="inner">
                  <h3 className="heading">
                    <img src={ninpathGray} alt="ninpath" />
                  </h3>
                  <Controller>
                    <Scene
                      triggerElement=".col01"
                      offset={-300}
                      reverse={false}
                      classToggle="is-active"
                    >
                      <div className="column col01">
                        <div className="white-box">
                          <div className="contents">
                            <div className="ttl">
                              納得してまっすぐに進むために。
                              <br />
                              先人の歩んだ道を選択の指針のひとつに。
                            </div>
                            <div className="text-wrapper">
                              <p className="text">
                                ninpathは、悩みや想いなどの「共感」を分かち合うのではなく、
                                <br className="pc" />
                                あえて客観的なデータによる「事実」のみを提供しています。
                                <br />
                                不妊治療は患者さんの負担が大きい「医療」であり、人生を左右する選択だからこそ、
                                <br className="pc" />
                                確固たる事実を指針にして欲しいと願うからです。
                              </p>
                              <p className="text">
                                ここに集まっているデータは、見えない誰かの記録の積み重ねです。
                                <br />
                                入力された治療記録は、自分自身の振り返りだけでなく、
                                <br className="pc" />
                                次の誰かの一歩を照らす道しるべとなります。
                                <br />
                                ここで可視化された「情報」も判断材料の一つとして、
                                <br className="pc" />
                                お一人でも多くの方が回り道や遠回りをすることなく、
                                <br className="pc" />
                                最適な治療方針を検討できるようサポートすることが私達の願いです。
                              </p>
                            </div>
                          </div>

                          <div className="image">
                            <div className="squre"></div>
                            <img src={col01Image} alt="" />
                          </div>
                        </div>
                      </div>
                    </Scene>
                  </Controller>

                  <Controller>
                    <Scene
                      triggerElement=".col02"
                      offset={-300}
                      reverse={false}
                      classToggle="is-active"
                    >
                      <div className="column col02">
                        <div className="white-box">
                          <div className="contents">
                            <div className="ttl">
                              ひとりで抱え込まないで。
                              <br />
                              妊娠に関する様々な問題を一緒に考えたい。
                            </div>
                            <div className="text-wrapper">
                              <p className="text">
                                出産・妊娠は身体だけでなく、人間関係やライフステージにも大きく影響します。
                                <br className="pc" />
                                精神面やお金の問題、今後のキャリアにライフプラン……
                                <br />
                                ——「欲しい」という思いだけでは、なかなかうまくいかないこともあります。
                              </p>
                              <p className="text">
                                特に不妊治療に関することは、とてもデリケートです。
                                <br />
                                個人の問題でありながら、時に社会の、コミュニティの問題として、
                                <br className="pc" />
                                治療に悩む人々に言葉にならないプレッシャーを与えます。
                                <br />
                                時には家族ですら、分かり合えないこともあるのが現状です。
                              </p>
                              <p className="text">
                                多岐にわたる悩みと向き合う解決の手立てを、
                                <br className="pc" />
                                あらゆる選択肢を必要としている方々により知って欲しい。
                                <br />
                                私たちはそう考え、新しい不妊治療サービスを立ち上げました。
                              </p>
                              <p className="text">
                                ninpathは治療方法に関する情報はもちろん、
                                <br className="pc" />
                                その先のライフプランに関わることまで、
                                <br className="pc" />
                                あなたの想いと悩みに寄り添い、解決のお手伝いをいたします。
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="image">
                          <img src={col02Image} alt="" />
                        </div>
                      </div>
                    </Scene>
                  </Controller>
                  <div className="registLoginBtn-wrapper">
                    <div className="regist-btn btn">
                      <a className="link" href="/registration">
                        新規登録
                      </a>
                    </div>
                    <div className="login-btn btn">
                      <a className="link" href="/login">
                        ログイン
                      </a>
                    </div>
                  </div>
                </div>
              </section>
              <section className="carrer" name="carrer">
                <div className="inner">
                  <div className="flex-wrapper">
                    <div className="block contents">
                      <div className="ttl">
                        治療と仕事、
                        <br className="pc_none" />
                        どちらも大切にする転職サポート
                      </div>
                      <div className="name">
                        <img src={ninpathCareer} alt="" />
                      </div>
                      <p className="text">
                        ひとりひとりの状況に合わせた働き方を、女性を大切にする転職エージェントがサポート。
                        <br />
                        フレックス制度やリモートワーク、時短勤務など、一般的な求人広告ではあまり掲載されていない就業形態の求人をご紹介します。
                        <br />
                        業種も多様なため、治療中も妊娠・出産後も働きやすい、自分にあった職場探しをお手伝いします。
                      </p>
                    </div>
                    <div className="block thumb">
                      <div className="image">
                        <img src={careerScreen} alt="" />
                      </div>
                      <div className="btn">
                        <a
                          className="link"
                          href="https://career.ninpath.com/lp/"
                        >
                          ninpath careerへ
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </main>
            <footer className="footer">
              <div className="footer-inner">
                <div className="logo">
                  <img src={footerLogo} alt="ninpath" />
                </div>
                <div className="address">
                  <p className="bold">会社概要</p>
                  <p className="text">
                    株式会社ninpath
                    <br />
                    東京都港区新橋1-12-9　A-PLACE新橋駅前 7F
                    <br />
                    お問い合わせ：info@ninpath.co.jp
                  </p>
                </div>
                <div className="terms">
                  <p className="text">
                    <a href="https://dist.ninpath.com/tou.html">利用規約</a>
                  </p>
                  <p className="text">
                    <a href="https://dist.ninpath.com/privacy_policy.html">
                      プライバシーポリシー
                    </a>
                  </p>
                </div>
              </div>
              <div className="app-btn-bottom pc">
                <a href={appStoreUrl} target="_blank" rel="noopener noreferrer">
                  <img
                    className="app-btn-and"
                    src={appIos}
                    alt="ios"
                    width="200px"
                  />
                </a>
                <a
                  href={playStoreUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="app-btn-and"
                    src={appAndroid}
                    alt="android"
                    width="200px"
                  />
                </a>
              </div>
            </footer>
          </div>
        </div>
      </Container>
    </TopPageContainer>
  );
};
const mapStateToProps = ({ AuthenticationReducer, HomeReducer }) => ({
  isLoggedIn: AuthenticationReducer.isLoggedIn,
  errorCode: AuthenticationReducer.errorCode,
  showLoading: HomeReducer ? HomeReducer.isLoading : false,
});

const mapDispatchToProps = dispatch => ({
  finishLoading: payload => dispatch(hideLoader(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(withRouter(TopPage)));
TopPage.propTypes = {
  errorCode: PropTypes.any,
  location: PropTypes.any.isRequired,
  finishLoading: PropTypes.func.isRequired,
  showLoading: PropTypes.bool.isRequired,
};
TopPage.defaultProps = {
  errorCode: null,
};
