import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: ${props => props.justifyContent};
  align-items: center;
  position: fixed;
  width: 100%;
  height: 70px;
  z-index: 900;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  background-color: ${props => props.backgroundColor};
  && div {
    position: relative;
  }
`;

export default Wrapper;
