import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'semantic-ui-react';
import { FormField, FormLabel } from 'containers/RegistrationPage/formElements';
import styled from 'styled-components';
import { TRANSFER_METHODS, TRANSFER_OPTIONS } from 'utils/defaultOptions';
import {
  BUILD_TRASPLANT_PAYLOAD,
  DEFAULT_TRANSPLANT_VALUES,
} from 'containers/TreatmentCyclePage/constants';
import { validateTransplantForm } from 'utils/errors';
import TransplantStep from './TransplantStep';

const StepButton = styled(Button)`
  text-align: right;
  display: inline-block;
  color: #0000ff !important;
  background-color: transparent !important;
  font-weight: 500 !important;
  margin-right: 0 !important;
  padding-right: 0 !important;
`;
const TransplantForm = ({ loadedData, isFormValid, updateFormValues }) => {
  const [formValues, setFormValues] = useState(
    DEFAULT_TRANSPLANT_VALUES().map(elm => elm),
  );
  const [cancelflag, setCancelFlag] = useState(false);
  const addStep = () => {
    const newValues = formValues.map(elm => elm);
    newValues[2].push({
      id: null,
      stage_of_transferred_embryo: '',
      grade_of_transferred_embryo_number: null,
      grade_of_transferred_embryo_icm: null,
      grade_of_transferred_embryo_te: null,
      day_of_et: '',
      pgt_a_result: null,
    });
    setFormValues(newValues);
    const isValid = validateTransplantForm(newValues);
    if (isValid) {
      const requestPayload = BUILD_TRASPLANT_PAYLOAD(...newValues);
      updateFormValues('et', requestPayload);
    }
    isFormValid(2, isValid);
  };
  const deleteStep = index => {
    const newValues = formValues.map(elm => elm);
    newValues[2].splice(index, 1);
    setFormValues(newValues);
    const isValid = validateTransplantForm(newValues);
    if (isValid) {
      const requestPayload = BUILD_TRASPLANT_PAYLOAD(...newValues);
      updateFormValues('et', requestPayload);
    }
    isFormValid(2, isValid);
  };
  const onUpdateField = (index, label, value) => {
    const newValues = formValues.map(elm => elm);
    newValues[2][index - 1][label] = value;
    setFormValues(newValues);
    const isValid = validateTransplantForm(newValues);
    if (isValid) {
      const requestPayload = BUILD_TRASPLANT_PAYLOAD(...newValues);
      updateFormValues('et', requestPayload);
    }
    isFormValid(2, isValid);
  };
  useEffect(() => {
    if (loadedData) {
      const newFormValues = [];
      newFormValues.push(loadedData.transfer_method);
      newFormValues.push(loadedData.transfer_options.map(elm => elm));
      newFormValues.push(loadedData.eggs_attributes.map(elm => elm));
      newFormValues.push(loadedData.endometrial_thickness || '');
      newFormValues.push(loadedData.memo);
      setFormValues(newFormValues);
    }
  }, [loadedData]);
  return (
    <>
      <Form.Group>
        <FormField inline style={{ paddingRight: 0 }}>
          <FormLabel htmlFor="transfer_methods" style={{ marginRight: 0 }}>
            移植方法
          </FormLabel>
          <Form.Select
            placeholder="選択してください"
            options={TRANSFER_METHODS}
            clearable
            value={formValues[0]}
            onChange={(evt, { value }) => {
              const newValues = formValues.map(formValue => formValue);
              newValues[0] = value;
              let isSelected = false;
              if (value === 3) {
                setCancelFlag(true);
                isSelected = true;
                newValues[2] = null;
              } else if (formValues[0] === 3) {
                const temp = [
                  {
                    id: null,
                    stage_of_transferred_embryo: '',
                    grade_of_transferred_embryo_number: null,
                    grade_of_transferred_embryo_icm: null,
                    grade_of_transferred_embryo_te: null,
                    day_of_et: '',
                    pgt_a_result: null,
                  },
                ];
                newValues[2] = temp.map(el => el);
                setFormValues(newValues);
                setCancelFlag(false);
                isSelected = false;
              }
              setFormValues(newValues);
              const isValid = validateTransplantForm(newValues, isSelected);
              if (isValid) {
                const requestPayload = BUILD_TRASPLANT_PAYLOAD(...newValues);
                updateFormValues('et', requestPayload);
              }
              isFormValid(2, isValid);
            }}
          />
        </FormField>
      </Form.Group>
      {!cancelflag && (
        <Form.Group>
          <FormField inline style={{ paddingRight: 0 }}>
            <FormLabel htmlFor="transfer_methods" style={{ marginRight: 0 }}>
              移植時のオプション
            </FormLabel>
            <Form.Select
              placeholder="選択してください"
              multiple
              options={TRANSFER_OPTIONS}
              value={formValues[1]}
              onChange={(evt, { value }) => {
                const newValues = formValues.map(formValue => formValue);
                newValues[1] = value.map(elm => elm);
                setFormValues(newValues);
                const isValid = validateTransplantForm(newValues);
                if (isValid) {
                  const requestPayload = BUILD_TRASPLANT_PAYLOAD(...newValues);
                  updateFormValues('et', requestPayload);
                }
                isFormValid(2, isValid);
              }}
            />
          </FormField>
        </Form.Group>
      )}

      {!cancelflag &&
        formValues[2] &&
        formValues[2].map((obj, index) => (
          <TransplantStep
            index={index + 1}
            deleteStep={deleteStep}
            form={JSON.parse(JSON.stringify(obj))}
            key={`${index + 1}`}
            onUpdateField={onUpdateField}
          />
        ))}
      {!cancelflag && (
        <FormField style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <StepButton
            type="button"
            onClick={() => {
              addStep();
            }}
          >
            <span>移植胚を複数入力する</span>
          </StepButton>
        </FormField>
      )}
      <Form.Group>
        <FormField style={{ paddingRight: 0 }}>
          <FormLabel htmlFor="endometrial_thickness">
            子宮内膜の厚み(mm)
          </FormLabel>
          <Form.Input
            fluid
            name="endometrial_thickness"
            type="number"
            placeholder="0"
            value={formValues[3]}
            onChange={(evt, { value }) => {
              const newValues = formValues.map(formValue => formValue);
              newValues[3] = value;
              setFormValues(newValues);
              const isValid = validateTransplantForm(newValues, cancelflag);
              if (isValid) {
                const requestPayload = BUILD_TRASPLANT_PAYLOAD(...newValues);
                updateFormValues('et', requestPayload);
              }
              isFormValid(2, isValid);
            }}
          />
        </FormField>
      </Form.Group>
      <Form.Group>
        <Form.Field>
          <FormLabel htmlFor="memo">メモ</FormLabel>
          <Form.TextArea
            name="memo"
            placeholder="メモ"
            value={formValues[4]}
            onChange={(evt, { value }) => {
              const newValues = formValues.map(formValue => formValue);
              newValues[4] = value;
              setFormValues(newValues);
              const isValid = validateTransplantForm(newValues, cancelflag);
              if (isValid) {
                const requestPayload = BUILD_TRASPLANT_PAYLOAD(...newValues);
                updateFormValues('et', requestPayload);
              }
              isFormValid(2, isValid);
            }}
          />
        </Form.Field>
      </Form.Group>
    </>
  );
};

export default TransplantForm;

TransplantForm.propTypes = {
  loadedData: PropTypes.object,
  isFormValid: PropTypes.func.isRequired,
  updateFormValues: PropTypes.func.isRequired,
};
TransplantForm.defaultProps = {
  loadedData: null,
};
