import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Card, Grid } from 'semantic-ui-react';
import { DataNotFoundMessage } from 'containers/ErrorPage/DataNotFound';
import { LayoutContainer, LayoutGrid } from 'utils/layouts';

import Header from 'containers/DrugUsagePage/header';
import DrugTable from 'containers/DrugUsagePage/drugTable';
import FilterList from 'components/FilterList';

import { DICTIONARY } from 'utils/dictionary';
import { DEFAULT_DRUG_TYPES } from 'utils/defaults';

import { fetchDrugUsageData } from 'containers/DrugUsagePage/actions';
import { Margin, Padding, TopLabel } from 'components/Atoms';

import { NestedBackIndicator } from 'components/Molecules';

import {
  makeSelectEmpty,
  makeSelectFetchedData,
  makeSelectErrors,
} from 'containers/DrugUsagePage/selectors';

const DrugUsagePage = ({ drugUsageData, fetchData, isEmpty, location }) => {
  const [activeDrugType, setActiveDrugType] = useState(0);
  const [dataWasNotFound, setDataWasNotFound] = useState(false);
  const [drugTypes] = useState(DEFAULT_DRUG_TYPES);
  const changeDrugType = newIndex => {
    if (newIndex !== activeDrugType) {
      setActiveDrugType(newIndex);
    }
  };
  useEffect(() => {
    const params = DICTIONARY[drugTypes[activeDrugType]];
    const payload = {
      age: DICTIONARY[location.state.activeSubFilters[0]],
      status: DICTIONARY[location.state.activeSubFilters[1]],
      history_of_ovarian_disease:
        DICTIONARY[location.state.activeSubFilters[3]],
      amh: DICTIONARY[location.state.activeSubFilters[2]],
      treatment_history: DICTIONARY[location.state.activeSubFilters[4]],
      opu_stimulation_graph: DICTIONARY[location.state.activeGraphType],
      range: location.state.range,
      stimulation_method: location.state.stimulation_method,
    };
    fetchData(payload, params);
  }, [fetchData, location, activeDrugType, drugTypes]);

  useEffect(() => {
    setDataWasNotFound(isEmpty);
  }, [isEmpty]);

  return (
    <LayoutContainer style={{ height: '100%' }}>
      <Padding
      paddingTop="14px"
      paddingBottom="14px"
      paddingLeft="14px"
    >
      <NestedBackIndicator/>
    </Padding>
      <LayoutGrid padded={false}>
        <Grid.Row columns={1} style={{ paddingTop: 0 }}>
          <Grid.Column mobile={16} tablet={10} computer={8}>
            <Card
              style={{ width: '100%', paddingTop: 14 }}
              className="ninpath__card"
            >
              <Card.Header>
                <Padding paddingLeft="14px" paddingBottom="14px">
                  <TopLabel>使用した薬剤リスト</TopLabel>
                </Padding>
              </Card.Header>
              <Card.Content
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  paddingLeft: 0,
                  paddingRight: 0,
                  paddingTop: 16,
                  paddingBottom: 0,
                }}
              >
                <Padding paddingLeft="14px" paddingRight="14px">
                  <Header
                    chartType={location.state.activeGraphType}
                    method={location.state.stimulation_method}
                    eggRecolletion={location.state.range}
                  />
                </Padding>
                <Margin marginTop="14px">
                  <FilterList
                    activeFilter={activeDrugType}
                    changeFilter={changeDrugType}
                    filtersData={[
                      '卵巣刺激薬剤',
                      '排卵抑制薬剤',
                      'LHサージ誘起薬剤',
                    ]}
                  />
                </Margin>

                {drugUsageData && <DrugTable tableData={drugUsageData} />}
                {dataWasNotFound && <DataNotFoundMessage />}
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </LayoutGrid>
    </LayoutContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  errors: makeSelectErrors(),
  drugUsageData: makeSelectFetchedData(),
  isEmpty: makeSelectEmpty(),
});
const mapDispatchToProps = dispatch => ({
  fetchData: (payload, params) => dispatch(fetchDrugUsageData(payload, params)),
});

DrugUsagePage.propTypes = {
  drugUsageData: PropTypes.object,
  fetchData: PropTypes.func.isRequired,
  isEmpty: PropTypes.bool,
  location: PropTypes.object.isRequired,
};

DrugUsagePage.defaultProps = {
  drugUsageData: null,
  isEmpty: false,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DrugUsagePage);
