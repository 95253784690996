import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, Form, Grid } from 'semantic-ui-react';
import { hideLoader, logoutSuccesful, showLoader } from 'containers/TopPage/actions';
import 'assets/css/layout.css';
import { LayoutContainer, LayoutGrid } from '../../utils/layouts';
import UserDeleteModal from '../../components/UserDeleteModal';

const WithdrawalPage = ({ startLoading, finishLoading, logout }) => {
  return (
    <LayoutContainer style={{ marginTop: '70px', height: '100%' }}>
      <LayoutGrid>
        <Grid.Row columns={1}>
          <Grid.Column mobile={16} tablet={10} computer={6}>
            <Card style={{ width: '100%' }}>
              <Card.Content>
                <Card.Header>ninpathを退会</Card.Header>
              </Card.Content>
              <Card.Content>
                <Card.Header>注意事項</Card.Header>
                <ul
                  style={{
                    paddingLeft: 14,
                    textAlign: 'justify',
                    listStyle: 'disc',
                  }}
                >
                  <li>
                    退会されますとマイデータ、みんなのデータ、クリニック情報などninpathに登録されたすべての情報が見られなくなります
                  </li>
                  <li>
                    一度退会されますと、再登録をしても退会前のデータを引き継ぐことはできません
                  </li>
                </ul>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Form.Button
                    className="ninpath__form__container"
                    style={{
                      minWidth: '120px',
                      maxWidth: '200px',
                      marginRight: 8,
                    }}
                    onClick={() => {
                      window.location.href = '/';
                    }}
                  >
                    利用を継続する
                  </Form.Button>
                  <UserDeleteModal
                    startLoading={startLoading}
                    finishLoading={finishLoading}
                    logout={logout}
                  />
                </div>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </LayoutGrid>
    </LayoutContainer>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  startLoading: payload => dispatch(showLoader(payload)),
  finishLoading: payload => dispatch(hideLoader(payload)),
  logout: (errorCode = null) => dispatch(logoutSuccesful(errorCode)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithdrawalPage);

WithdrawalPage.propTypes = {
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};
