import Cookies from 'universal-cookie';
import request from 'utils/request';
import {
  GET_USER_PROFILE_DATA_WAS_SUCCESSFUL,
  GET_USER_PROFILE_DATA_FAILED,
  USER_EDITED_SUCCESSFULLY,
  USER_EDITING_FAILED,
} from 'containers/ProfilePage/constants';
import { LOGGED_OUT, LOADING_ENDS } from 'containers/TopPage/constants';
import { call, put } from 'redux-saga/effects';

export function* editUserProfile(action) {
  const cookies = new Cookies();
  const url = `${process.env.REACT_APP_API_URL}/api/v1/me`;
  const response = yield call(request, url, {
    method: 'PATCH',
    body: JSON.stringify(action.payload),
    headers: {
      'Content-Type': 'application/json',
      charset: 'UTF-8',
      Authorization: `Bearer ${cookies.get('user_token')}`,
    },
  });
  yield put({
    type: LOADING_ENDS,
    payload: null,
  });
  if (response.status === 401)
    yield put({
      type: LOGGED_OUT,
    });
  else if (response.status === 503 || response.status === 500)
    yield put({
      type: LOGGED_OUT,
      errorCode: response.status,
    });
  else {
    yield put({
      type: !response.errors ? USER_EDITED_SUCCESSFULLY : USER_EDITING_FAILED,
      payload: !response.errors ? action.payload : { errors: response.errors },
    });
  }
}

export function* getUserProfile() {
  const cookies = new Cookies();
  const url = `${process.env.REACT_APP_API_URL}/api/v1/me`;
  const response = yield call(request, url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      charset: 'UTF-8',
      Authorization: `Bearer ${cookies.get('user_token')}`,
    },
  });
  yield put({
    type: LOADING_ENDS,
    payload: null,
  });
  if (response.status === 401)
    yield put({
      type: LOGGED_OUT,
    });
  else if (response.status === 503 || response.status === 500)
    yield put({
      type: LOGGED_OUT,
      errorCode: response.status,
    });
  else {
    yield put({
      type: !response.errors
        ? GET_USER_PROFILE_DATA_WAS_SUCCESSFUL
        : GET_USER_PROFILE_DATA_FAILED,
      payload: !response.errors ? response : { errors: response.errors },
    });
  }
}
