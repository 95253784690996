import React, { useEffect, useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Mask } from 'components/Atoms';
import { Table, Transition } from 'semantic-ui-react';
import { BOX_SHADOW } from 'utils/defaults';
import './clinic.css';

const ClinicsTable = ({ tableData }) => {
  const tableRef = useRef(null);
  const [initialWidth, setInitialWidth] = useState(null);
  const [initialWidthInPx, setInitialWidthInPx] = useState('100%');
  const [tableOffset, setTableOffset] = useState(0);
  const [showShadow, setShowShadow] = useState(false);
  const [tableWidth, setTableWidth] = useState(0);

  const onScroll = evt => {
    const newWidth = evt.target.scrollLeft + initialWidth;
    if (newWidth <= tableWidth) {
      setInitialWidthInPx(`${newWidth}px`);
    } else {
      setInitialWidthInPx(`${tableWidth}px`);
    }
    if (evt.target.scrollLeft >= tableOffset) {
      if (showShadow) setShowShadow(false);
    } else if (!showShadow) setShowShadow(true);
  };
  const parseName = useCallback(name => {
    if (name && name.includes('ninpath')) {
      const nameWithBreak = `${name.substr(0, 7)}\n${name.substr(
        7,
        name.length,
      )}`;
      return nameWithBreak;
    }
    return name;
  }, []);
  useEffect(() => {
    if (tableRef.current) {
      const originalWidth = tableRef.current.offsetWidth;
      const tableOriginalWidth = tableRef.current.firstChild.offsetWidth;
      setInitialWidth(originalWidth);
      setInitialWidthInPx(`${originalWidth}px`);
      setTableWidth(tableOriginalWidth);
      const offset = tableOriginalWidth - originalWidth;
      if (offset > 0) setShowShadow(true);
      setTableOffset(offset);
    }
  }, [tableRef]);
  if (!tableData || !tableData.columns) return <></>;
  return (
    <Transition animation="fade">
      <div
        style={{
          width: '100%',
          overflowX: 'scroll',
          boxShadow: showShadow ? BOX_SHADOW : 'none',
          position: 'relative',
        }}
        onScroll={evt => onScroll(evt)}
        ref={tableRef}
      >
        <Table
          structured
          collapsing
          unstackable
          columns={tableData.columns.length + 1}
          textAlign="center"
          style={{ borderLeft: 'none', marginBottom: 0 }}
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={{ width: '80px' }}></Table.HeaderCell>
              <Table.HeaderCell singleLine colSpan={tableData.columns.length}>
                年齢区分
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell style={{ width: '80px' }}></Table.HeaderCell>
              {tableData.columns.map(column => (
                <Table.HeaderCell singleLine key={column} width={2}>
                  {column}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body style={{ overflow: 'auto' }}>
            {tableData.items.map(item => (
              <React.Fragment key={item.name}>
                <Table.Row>
                  <Table.Cell
                    rowSpan={item.rows.length}
                    textAlign="left"
                    style={{ whiteSpace: 'pre' }}
                  >
                    {parseName(item.name)}
                  </Table.Cell>
                  {item.rows[0].map((col, index) => (
                    <Table.Cell
                      rowSpan={1}
                      key={`${col}${index + 1}`}
                      textAlign={index > 0 ? 'right' : 'left'}
                      style={col !== 0 ? {} : { color: '#E0E0E0' }}
                    >
                      {col}
                    </Table.Cell>
                  ))}
                </Table.Row>
                {item.rows.map((row, indexItem) => {
                  if (indexItem > 0) {
                    return (
                      <Table.Row key={`${indexItem * 10}`}>
                        {row.map((col, indexCol) => (
                          <Table.Cell
                            rowSpan={1}
                            key={`${col}${indexCol + 20}`}
                            textAlign={indexCol > 0 ? 'right' : 'left'}
                            style={col !== 0 ? {} : { color: '#E0E0E0' }}
                          >
                            {col}
                          </Table.Cell>
                        ))}
                      </Table.Row>
                    );
                  }
                  return <template key={`${indexItem * 10}`} />;
                })}
              </React.Fragment>
            ))}
          </Table.Body>
        </Table>

        <Mask
          style={{
            boxShadow: showShadow ? BOX_SHADOW : 'none',
            width: initialWidthInPx,
          }}
        />
      </div>
    </Transition>
  );
};

ClinicsTable.propTypes = {
  tableData: PropTypes.object.isRequired,
};

export default React.memo(ClinicsTable);
