import React from 'react';
import { Icon } from 'semantic-ui-react';

const AMH_MENU_ITEMS = [
  {
    name: 'ninpath-check-result',
    route: '/ninpath-check/result',
    content: (
      <>
        <Icon name="chart line" size="large"></Icon>
        <span>検査結果</span>
      </>
    ),
  },
  {
    name: 'ninpath-check-info',
    route: '/ninpath-check/info',
    content: (
      <>
        <Icon name="book" size="large"></Icon>
        <span>AMHについて</span>
      </>
    ),
  },
];

export default AMH_MENU_ITEMS;
