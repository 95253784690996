import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { Card, Form, Grid, Message, Transition } from 'semantic-ui-react';

import InfertilityHistory from 'containers/RegistrationPage/forms/InfertilityHistory';

import { LayoutGrid, LayoutContainer } from 'utils/layouts';
import { ErrorMessage } from 'utils/errors';
import { Padding } from 'components/Atoms';

import { MAIN_COLOR } from 'containers/ChartPage/colors';

import { logoutSuccesful, showLoader } from 'containers/TopPage/actions';
import {
  getExaminationResultsData,
  updateExaminationResultsData,
} from './actions';
import {
  makeSelectErrors,
  makeSelectFetchedData,
  makeSelectUpdateSuccessMessage,
} from './selectors';

const ExaminationResultsPage = ({
  defaultFormValues,
  errors,
  getExaminationData,
  startLoading,
  updateExaminationData,
  updateSuccessful,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formStatus, setStatus] = useState([true, true, true, true, true]);
  const [formGroupValues, setFormGroupValues] = useState({});
  const [hasNotChangeAnything, setHasNoChangeAnything] = useState(true);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    // startLoading({ value: true });
    setIsLoading(true);
    getExaminationData();
  }, [getExaminationData, startLoading]);

  useEffect(() => {
    if (defaultFormValues && defaultFormValues.data) {
      setFormGroupValues(
        JSON.parse(JSON.stringify(defaultFormValues.data)) || {},
      );
      setIsLoading(false);
    }
  }, [defaultFormValues]);

  const validateForm = (index, newStatus) => {
    const newStatuses = formStatus.map(status => status);
    newStatuses[index] = newStatus;
    setStatus(newStatuses);
  };
  const updateFormValues = (index, newValues) => {
    const prevValues = JSON.parse(JSON.stringify(formGroupValues));
    prevValues[index] = newValues;
    if (hasNotChangeAnything) {
      setHasNoChangeAnything(false);
    }
    setFormGroupValues(prevValues);
  };
  const sendExaminationResultsForm = () => {
    startLoading({ value: true });
    const payload = {
      user: formGroupValues,
    };
    payload.user.infertility_treatment_history.amh =
      payload.user.infertility_treatment_history.amh === ''
        ? null
        : payload.user.infertility_treatment_history.amh;
    updateExaminationData(payload);
  };
  useEffect(() => {
    setShowSuccessMessage(updateSuccessful);
    setIsLoading(false);
  }, [updateSuccessful]);
  if (
    !isLoading &&
    formGroupValues &&
    formGroupValues.infertility_treatment_history
  )
    return (
      <Padding paddingTop="1rem">
        <LayoutContainer style={{ height: '100%' }}>
          <LayoutGrid>
            <Grid.Row columns={1} style={{ paddingTop: 0 }}>
              <Grid.Column mobile={16} tablet={10} computer={10}>
                <Form
                  error
                  onSubmit={evt => {
                    evt.preventDefault();
                    sendExaminationResultsForm();
                  }}
                >
                  <Card
                    style={{ width: '100%', paddingTop: 14 }}
                    className="card__registration"
                  >
                    <Card.Header>
                      <h3
                        style={{
                          paddingLeft: 14,
                          paddingBottom: 14,
                          fontWeight: 500,
                        }}
                      >
                        治療歴・検査結果
                      </h3>
                    </Card.Header>
                    <Card.Content>
                      <div style={{ paddingTop: 14 }}>
                        <p style={{ paddingLeft: 14 }}>
                          <span style={{ color: MAIN_COLOR }}>*</span>
                          は入力必須項目です
                        </p>
                        <InfertilityHistory
                          isFormValid={validateForm}
                          updateFormValues={updateFormValues}
                          defaultFormValues={
                            formGroupValues.infertility_treatment_history
                          }
                        />
                        <div className="button_form_container">
                          <Form.Button
                            className="ninpath__form__container"
                            style={{ minWidth: '120px', maxWidth: '150px' }}
                            disabled={!formStatus[1] || hasNotChangeAnything}
                          >
                            保存
                          </Form.Button>
                          {hasNotChangeAnything && (
                            <ErrorMessage color={MAIN_COLOR}>
                              項目に変更のない場合はボタンが押せません
                            </ErrorMessage>
                          )}
                          {!formStatus[1] && (
                            <ErrorMessage color={MAIN_COLOR}>
                              必須項目が未入力の場合はボタンが押せません
                            </ErrorMessage>
                          )}
                          <Transition
                            visible={showSuccessMessage}
                            animation="fade"
                            duration={500}
                          >
                            <Message
                              width={{ style: '50%' }}
                              compact
                              floating
                              success
                              visible={showSuccessMessage}
                              content="治療歴・検査結果を更新しました"
                            />
                          </Transition>
                          {errors.length > 0 && (
                            <Transition>
                              <Message
                                negative
                                style={{ borderLeft: 0, borderRight: 0 }}
                              >
                                <Message.Header>エラー</Message.Header>
                                {errors.map(error => (
                                  <p key={error}>{error}</p>
                                ))}
                              </Message>
                            </Transition>
                          )}
                        </div>
                      </div>
                    </Card.Content>
                  </Card>
                </Form>
              </Grid.Column>
            </Grid.Row>
          </LayoutGrid>
        </LayoutContainer>
      </Padding>
    );
  return <div />;
};

const mapStateToProps = createStructuredSelector({
  defaultFormValues: makeSelectFetchedData(),
  errors: makeSelectErrors(),
  updateSuccessful: makeSelectUpdateSuccessMessage(),
});
const mapDispatchToProps = dispatch => ({
  getExaminationData: () => dispatch(getExaminationResultsData()),
  updateExaminationData: payload =>
    dispatch(updateExaminationResultsData(payload)),
  logout: (errorCode = null) => dispatch(logoutSuccesful(errorCode)),
  startLoading: payload => dispatch(showLoader(payload)),
});

ExaminationResultsPage.propTypes = {
  defaultFormValues: PropTypes.object,
  updateSuccessful: PropTypes.any,
  errors: PropTypes.any,
  getExaminationData: PropTypes.func.isRequired,
  updateExaminationData: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
};
ExaminationResultsPage.defaultProps = {
  defaultFormValues: null,
  errors: [],
  updateSuccessful: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExaminationResultsPage);
