import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { FormField, FormLabel } from 'containers/RegistrationPage/formElements';
import { PREFECTURE_OPTIONS } from 'utils/defaultOptions';
import debounce from 'lodash/debounce';

const SearchForm = ({
  fetchClinics,
  selectedPrefectureId,
  setSelectedPrefectureId,
  setSelectedPrefecture,
  searchByName,
  setIsLoading,
}) => {
  const fetchClinicsByPrefecture = prefectureId => {
    setSelectedPrefectureId(prefectureId);
  };
  const [prefecture, setPrefecture] = useState('');
  const [clinicName, setClinicName] = useState('');
  const debounceSearch = debounce(searchByName, 1000);

  useEffect(() => {
    if (selectedPrefectureId !== null) {
      setPrefecture(PREFECTURE_OPTIONS[selectedPrefectureId].text);
      setSelectedPrefecture(PREFECTURE_OPTIONS[selectedPrefectureId].text);
      fetchClinics({}, selectedPrefectureId);
    }
  }, [fetchClinics, setSelectedPrefecture, selectedPrefectureId]);
  return (
    <Form>
      <Form.Group>
        <FormField required style={{ paddingRight: 0, width: '100%' }}>
          <FormLabel htmlFor="prefectures" style={{ marginRight: 0 }}>
            都道府県
          </FormLabel>
          <Form.Select
            placeholder="都道府県"
            value={selectedPrefectureId}
            options={PREFECTURE_OPTIONS}
            onChange={(evt, { value }) => {
              setIsLoading(true);
              setClinicName('');
              fetchClinicsByPrefecture(value);
            }}
          />
        </FormField>
      </Form.Group>
      <Form.Group className="clinic__form">
        <FormField required style={{ paddingRight: 0 }}>
          <FormLabel htmlFor="clinic_name" style={{ marginRight: 0 }}>
            クリニック名
          </FormLabel>
          <Form.Input
            name="clinic_name"
            disabled={prefecture === ''}
            value={clinicName}
            onChange={evt => {
              setClinicName(evt.target.value);
              debounceSearch(evt.target.value);
            }}
          />
        </FormField>
      </Form.Group>
    </Form>
  );
};
SearchForm.propTypes = {
  fetchClinics: PropTypes.func.isRequired,
  selectedPrefectureId: PropTypes.any,
  setSelectedPrefectureId: PropTypes.func.isRequired,
  setSelectedPrefecture: PropTypes.func.isRequired,
  searchByName: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};

SearchForm.defaultProps = {
  selectedPrefectureId: null,
};

export default SearchForm;
