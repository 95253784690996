import React from 'react';
import PageMenu from 'components/MenuBar/PageMenu';
import MenuItem from 'components/MenuBar/MenuItem';
import history from 'utils/history';
import { connect } from 'react-redux';
import { DIMMER_COLOR } from 'utils/defaults';
import 'react-dynamic-swiper/lib/styles.css';
import PropTypes from 'prop-types';
import MENU_ITEMS from 'components/MenuBar/MenuItems';
import { checkIfRelatedRoutes } from 'utils/helper';

const MenuBar = ({ pathname, sidebarIsOpened }) => (
  <PageMenu
    pagination={false}
    borderless
    widths={4}
    fluid
    // swiperOptions={SWIPER_OPTIONS}
    bgColor={sidebarIsOpened ? DIMMER_COLOR : '#fff'}
  >
    {MENU_ITEMS.map(menuItem => (
      <MenuItem
        name={menuItem.name}
        active={
          checkIfRelatedRoutes(pathname, menuItem.route) && !sidebarIsOpened
        }
        // memo !
        data-active={
          checkIfRelatedRoutes(pathname, menuItem.route) && !sidebarIsOpened
        }
        key={menuItem.name}
        onClick={() => {
          history.push(menuItem.route);
        }}
      >
        {menuItem.content}
      </MenuItem>
    ))}
  </PageMenu>
);

const mapStateToProps = state => ({
  pathname: state.router.location.pathname,
});

MenuBar.propTypes = {
  pathname: PropTypes.string.isRequired,
  sidebarIsOpened: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(React.memo(MenuBar));
