/*
 * LoginConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Recommended format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const LOGIN = 'ninpath/TopPage/LOGIN';
export const LOGOUT = 'ninpath/TopPage/LOGOUT';
export const RESET_VALUES = 'ninpath/TopPage/RESET_VALUES';
export const LOGIN_SUCCEED = 'ninpath/TopPage/LOGIN_SUCCEED';
export const AMH_LOGIN_SUCCEED = 'ninpath/TopPage/AMH_LOGIN_SUCCEED';
export const LOGIN_FAILED = 'ninpath/TopPage/LOGIN_FAILED';
export const LOGOUT_SUCCEED = 'ninpath/TopPage/LOGOUT_SUCCEED';
export const LOGOUT_FAILED = 'ninpath/TopPage/LOGOUT_SUCCEED';
