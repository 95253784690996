import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, TextArea } from 'semantic-ui-react';
import {
  FormField,
  FormLabel,
  FormSelect,
} from 'containers/RegistrationPage/formElements';
import { HORMONE_DRUGS } from 'utils/defaultOptions';
import { BUILD_OFF_MEDICINE_PAYLOAD } from 'containers/TreatmentCyclePage/constants';

import { isValueInArray } from 'utils/helper';

const OffMedicineForm = ({ loadedData, updateFormValues }) => {
  const [formValues, setFormValues] = useState([[], '']);
  useEffect(() => {
    if (loadedData) {
      const newFormValues = [];
      newFormValues.push(loadedData.hormone_drugs.map(elm => elm));
      newFormValues.push(loadedData.other_medicine || '');
      newFormValues.push(loadedData.other_hormone_drugs || '');
      newFormValues.push(loadedData.memo || '');
      setFormValues(newFormValues);
    }
  }, [loadedData]);
  return (
    <>
      <Form.Group>
        <FormField
          style={{
            paddingRight: 0,
          }}
        >
          <FormLabel htmlFor="hormone_replacement">
            ピルなどのホルモン剤
          </FormLabel>
          <FormSelect
            placeholder="選択してください"
            options={HORMONE_DRUGS}
            search
            className="ninpath__select"
            value={formValues[0]}
            multiple
            onChange={(evt, { value }) => {
              const newValues = formValues.map(formValue => formValue);
              newValues[0] = value.map(elm => elm);
              if (isValueInArray(newValues[0], 14)) {
                newValues[2] = '';
              }
              setFormValues(newValues);
              const requestPayload = BUILD_OFF_MEDICINE_PAYLOAD(...newValues);
              updateFormValues('medicine', requestPayload);
            }}
          />
        </FormField>
      </Form.Group>
      {isValueInArray(formValues[0], 14) && (
        <Form.Group style={{ marginBottom: 14 }} id="drugs_usage_form">
          <Form.Field
            control={TextArea}
            placeholder=""
            label="その他のピルなどのホルモン剤"
            value={formValues[2]}
            onChange={(evt, { value }) => {
              const newValues = formValues.map(formValue => formValue);
              newValues[2] = value;
              setFormValues(newValues);
              const requestPayload = BUILD_OFF_MEDICINE_PAYLOAD(...newValues);
              updateFormValues('medicine', requestPayload);
            }}
          />
        </Form.Group>
      )}

      <Form.Group>
        <Form.Field style={{ paddingRight: 0 }}>
          <FormLabel htmlFor="other_stimulation">
            上記以外の種類の薬剤
          </FormLabel>
          <Form.TextArea
            name="other_stimulation"
            value={formValues[1]}
            onChange={(evt, { value }) => {
              const newValues = formValues.map(formValue => formValue);
              newValues[1] = value;
              setFormValues(newValues);
              const requestPayload = BUILD_OFF_MEDICINE_PAYLOAD(...newValues);
              updateFormValues('medicine', requestPayload);
            }}
          />
        </Form.Field>
      </Form.Group>

      <Form.Group>
        <Form.Field style={{ paddingRight: 0 }}>
          <FormLabel htmlFor="memo">メモ</FormLabel>
          <Form.TextArea
            name="memo"
            placeholder="メモ"
            value={formValues[2]}
            onChange={(evt, { value }) => {
              const newValues = formValues.map(formValue => formValue);
              newValues[2] = value;
              setFormValues(newValues);
              const requestPayload = BUILD_OFF_MEDICINE_PAYLOAD(...newValues);
              updateFormValues('medicine', requestPayload);
            }}
          />
        </Form.Field>
      </Form.Group>
    </>
  );
};

OffMedicineForm.propTypes = {
  loadedData: PropTypes.object,
  updateFormValues: PropTypes.func.isRequired,
};
OffMedicineForm.defaultProps = {
  loadedData: null,
};
export default OffMedicineForm;
