import { LOGGED_OUT, LOADING_ENDS } from 'containers/TopPage/constants';
import { put } from 'redux-saga/effects';

// getting a messages array means something is wrong!
export function* handleResponse(response, actionForSuccess, actionForFailure) {
  yield put({
    type: LOADING_ENDS,
    payload: null,
  });
  if (response.status === 401)
    yield put({
      type: LOGGED_OUT,
    });
  else if (response.status === 503 || response.status === 500)
    yield put({
      type: LOGGED_OUT,
      errorCode: response.status,
    });
  else {
    yield put({
      type: !response.errors ? actionForSuccess : actionForFailure,
      payload: !response.errors
        ? response
        : { errors: response.errors, messages: response.messages },
    });
  }
}
