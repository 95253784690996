/* To better separate the filters, this file only contains
 filters related to charts */
export const SUBFILTERS_AGE = [
  {
    text: '29歳以下',
    value: 'age_under_29',
    key: '29歳以下',
  },
  {
    text: '30～33歳',
    value: 'age_from_30_to_33',
    key: '30～33歳',
  },
  {
    text: '34～37歳',
    value: 'age_from_34_to_37',
    key: '34～37歳',
  },
  {
    text: '38～41歳',
    value: 'age_from_38_to_41',
    key: '38～41歳',
  },
  {
    text: '42歳以上',
    value: 'age_over_42',
    key: '42歳以上',
  },
];
export const SUBFILTERS_STATUS = [
  {
    text: '治療中',
    value: 'status_treatment_now',
    key: '治療中',
    active: false,
  },
  {
    text: '妊娠中・出産済み',
    value: 'status_pregnant_achieved',
    key: '妊娠中・出産済み',
    active: false,
  },
  {
    text: '終了・お休み中',
    value: 'status_inactive_finished',
    key: '終了・お休み中',
    active: false,
  },
];

export const SUBFILTERS_OVULATION = [
  { text: '特になし', value: 'nothing', key: '特になし', active: false },
  {
    text: '卵巣関連の病歴',
    value: 'disease_history_ovarian',
    key: '卵巣関連の病歴',
    active: false,
  },
  {
    text: '子宮関連の病歴',
    value: 'disease_history_uterine',
    key: '子宮関連の病歴',
    active: false,
  },
  {
    text: '甲状腺の異常',
    value: 'unusual_history_thyroid_function',
    key: '甲状腺の異常',
    active: false,
  },
];

export const SUBFILTERS_AMH = [
  { text: '3.0以上', value: 'amh_over_3', key: '3.0以上', active: false },
  {
    text: '1.0～3.0',
    value: 'amh_from_1_to_3',
    key: '1.0～3.0',
    active: false,
  },
  { text: '1.0以下', value: 'amh_under_1', key: '1.0以下', active: false },
  {
    text: '不明・未計測',
    value: 'amh_null',
    key: '不明・未計測',
    active: false,
  },
];

export const SUBFILTERS_TREATMENT = [
  {
    text: '人工受精まで',
    value: 'best_treatment_to_aih',
    key: '人工受精まで',
    active: false,
  },
  {
    text: '体外受精以上',
    value: 'best_treatment_ivf_icsi',
    key: '体外受精以上',
    active: false,
  },
];

export const SUBFILTERS_ARR = [
  SUBFILTERS_AGE,
  SUBFILTERS_STATUS,
  SUBFILTERS_AMH,
  SUBFILTERS_OVULATION,
  SUBFILTERS_TREATMENT,
];

export const SUBFILTERS_NAMES = [
  '治療開始年齢',
  'ステータス',
  'AMH',
  '卵巣子宮等の病歴',
  '不妊治療歴',
];
