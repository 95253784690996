import styled from 'styled-components';

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
  z-index: 900;
  && img {
    max-height: 50px;
  }
`;

export const HeaderTop = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
  z-index: 900;
  && img {
    max-height: 50px;
    transform: translateX(-19px);
  }
`;

export const AbnormalHeaderTop = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
  z-index: 900;
  && img {
    max-height: 50px;
    transform: translateX(-39px);
  }
`;
