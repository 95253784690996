/* Contains functions for validations of fields and forms */
import styled from 'styled-components';
export const ErrorMessage = styled.p`
  margin-bottom: 14px !important;
  text-align: center;
  color: ${props => props.color};
`;
export const isValidField = (field, value) => {
  switch (field) {
    case 'name':
      return value === '' || value.trim().length !== 0;
    case 'email':
      if (value === '') return true;
      const emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
      return emailValid === null ? false : emailValid;
    case 'password':
      if (value === true) return true;
      const passwordValid =
        (value.length >= 8 && value.match(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/)) ||
        value === '';
      return passwordValid;
    case 'receipt_number':
      return (
        value === '' ||
        (value.trim().length === 15 && value.match(/^[0-9]{15}$/))
      );
    default:
      return false;
  }
};
export const areSameValues = (value1, value2) => value1 === value2;
export const validatePersonalInfo = (formValues, showName, showEmail) =>
  (formValues.name !== '' || !showName) &&
  formValues.sex !== null &&
  formValues.prefectures !== '' &&
  formValues.profession !== '' &&
  (formValues.email !== '' || !showEmail) &&
  (formValues.emailConfirm !== '' || !showEmail) &&
  formValues.password !== '' &&
  formValues.passwordConfirm !== '' &&
  isValidField('password', formValues.password) &&
  areSameValues(formValues.password, formValues.passwordConfirm) &&
  (isValidField('email', formValues.email) || !showEmail) &&
  (areSameValues(formValues.email, formValues.emailConfirm) || !showEmail) &&
  formValues.birthday.year !== null &&
  formValues.birthday.month !== null &&
  formValues.spouses_birthday.month !== null &&
  formValues.spouses_birthday.year !== null;

export const validateRequiredFields = formValues => {
  const results = Object.keys(formValues).filter(
    key =>
    (formValues[key] === null ||
      formValues[key] === '' ||
      formValues[key].length === 0) &&
      key !== 'woman_age_stopped_treatment' &&
      key !== 'amh' &&
      key !== 'other_test_of_ovarian_function' &&
      key !== 'first_time_treatment' &&
      !(key === 'previous_pregnancy' && formValues.primary_infertility === 1) &&
      key !== 'uterine_shape' &&
      key !== 'treatment_stats' &&
      key !== 'current_treatment_problem' &&
      key !== 'number_of_miscarriage' &&
      !(key === 'number_of_miscarriage_lt_12w' && formValues.number_of_pregnancy === '0') &&
      !(key === 'number_of_miscarriage_gte_12w' && formValues.number_of_pregnancy === '0') &&
      !(key === 'number_of_childbirth' && formValues.number_of_pregnancy === '0')
  );
  return results.length === 0;
};

export const validateStimulationMedicines = formValues => {
  // const results = formValues
  //   .slice(0, 4)
  //   .filter(value => value === '' || value === null || value.length === 0);
  // return results.length === 0;
  return true;
};

export const validateOpuResults = formValues => {
  // const results = formValues
  //   .slice(1, 8)
  //   .filter(value => value === '' || value === null);
  // return results.length === 0;
  return true;
};

export const validateTreatmentResults = formValues =>
  formValues.startedAt !== '' &&
  formValues.prefecture_id !== null &&
  ((formValues.clinic_id !== null && formValues.clinic_id !== '') ||
    formValues.clinic_name !== '');

export const validateTimingDrugUsage = formValues => {
  const results = formValues.slice(0, 2).filter(value => value.length === 0);
  return results.length === 0;
};

export const validateTransplantForm = (formValues, isSelected) => {
  return true;
  // if (isSelected) {
  //   // transplant cancel
  //   return true;
  // }

  // const results = formValues
  //   .slice(0, 3)
  //   .filter(value => value === null || value.length === 0 || value === '');
  // let invalidSteps = [];
  // if (formValues[2]) {
  //   invalidSteps = formValues[2].filter(elm => {
  //     if (
  //       elm.stage_of_transferred_embryo >= 4 &&
  //       elm.stage_of_transferred_embryo < 6
  //     ) {
  //       return (
  //         elm.stage_of_transferred_embryo === '' ||
  //         elm.grade_of_transferred_embryo_number === null ||
  //         elm.grade_of_transferred_embryo_number === '' ||
  //         elm.grade_of_transferred_embryo_icm === null ||
  //         elm.grade_of_transferred_embryo_icm === '' ||
  //         elm.grade_of_transferred_embryo_te === null ||
  //         elm.grade_of_transferred_embryo_te === ''
  //       );
  //     }
  //     return (
  //       elm.stage_of_transferred_embryo === '' ||
  //       elm.grade_of_transferred_embryo_number === null ||
  //       elm.grade_of_transferred_embryo_number === ''
  //     );
  //   });
  // }

  // return results.length === 0 && invalidSteps.length === 0;
};
