import React from 'react';
import styled from 'styled-components';
// import { Swiper } from 'react-dynamic-swiper';
import { Menu } from 'semantic-ui-react';
const PageMenu = styled(({ bgColor, children, ...rest }) => (
  <Menu {...rest}>{children}</Menu>
))`
  && {
    position: fixed;
    width: 100%;
    margin-top: 70px;
    background-color: ${props => props.bgColor};
    z-index: 900;
    border-left: none;
    border-right: none;
  }
`;
export default PageMenu;
