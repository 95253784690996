import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, TextArea } from 'semantic-ui-react';
import { FormField, FormLabel } from 'containers/RegistrationPage/formElements';
import {
  INDUCER_MEDICINE,
  HORMONE_REPLACEMENT_MEDICINE,
} from 'utils/defaultOptions';
import { BUILD_DRUG_USAGE_PAYLOAD } from 'containers/TreatmentCyclePage/constants';
import { isValueInArray } from 'utils/helper';

const DrugUsageForm = ({ loadedData, isFormValid, updateFormValues }) => {
  const [formValues, setFormValues] = useState([[], [], '']);
  useEffect(() => {
    if (loadedData) {
      const newFormValues = [];
      newFormValues.push(
        loadedData.hormone_replacement_medicine.map(elm => elm),
      );
      newFormValues.push(loadedData.lh_surge_inducer_medicine.map(elm => elm));
      newFormValues.push(loadedData.other_medicine || '');
      newFormValues.push(loadedData.other_hormone_replacement_medicine || '');
      newFormValues.push(loadedData.other_lh_surge_inducer_medicine || '');
      setFormValues(newFormValues);
    }
  }, [loadedData]);
  return (
    <>
      <Form.Group>
        <FormField inline style={{ paddingRight: 0 }}>
          <FormLabel htmlFor="hormone_replacement">ホルモン補充薬剤</FormLabel>
          <Form.Select
            placeholder="選択してください"
            options={HORMONE_REPLACEMENT_MEDICINE}
            value={formValues[0]}
            multiple
            onChange={(evt, { value }) => {
              const newValues = formValues.map(formValue => formValue);
              newValues[0] = value.map(elm => elm);
              if (isValueInArray(newValues[0], 12)) {
                newValues[3] = '';
              }
              setFormValues(newValues);
              const requestPayload = BUILD_DRUG_USAGE_PAYLOAD(...newValues);
              updateFormValues('medicine', requestPayload);
              isFormValid(1, true);
            }}
          />
        </FormField>
      </Form.Group>
      {isValueInArray(formValues[0], 12) && (
        <Form.Group style={{ marginBottom: 14 }} id="drugs_usage_form">
          <Form.Field
            control={TextArea}
            placeholder=""
            label="その他のホルモン補充薬剤"
            value={formValues[3]}
            onChange={(evt, { value }) => {
              const newValues = formValues.map(formValue => formValue);
              newValues[3] = value;
              setFormValues(newValues);
              const requestPayload = BUILD_DRUG_USAGE_PAYLOAD(...newValues);
              updateFormValues('medicine', requestPayload);
              isFormValid(1, true);
            }}
          />
        </Form.Group>
      )}
      <Form.Group>
        <FormField inline style={{ paddingRight: 0 }}>
          <FormLabel htmlFor="treatment_period_results">
            LHサージ誘起薬剤
          </FormLabel>
          <Form.Select
            placeholder="選択してください"
            multiple
            value={formValues[1]}
            options={INDUCER_MEDICINE}
            onChange={(evt, { value }) => {
              const newValues = formValues.map(formValue => formValue);
              newValues[1] = value.map(elm => elm);
              setFormValues(newValues);
              if (isValueInArray(newValues[1], 4)) {
                newValues[4] = '';
              }
              const requestPayload = BUILD_DRUG_USAGE_PAYLOAD(...newValues);
              updateFormValues('medicine', requestPayload);
              isFormValid(1, true);
            }}
          />
        </FormField>
      </Form.Group>
      {isValueInArray(formValues[1], 4) && (
        <Form.Group style={{ marginBottom: 14 }} id="drugs_usage_form">
          <Form.Field
            control={TextArea}
            placeholder=""
            style={{ paddingRight: 0 }}
            label="その他のLHサージ誘起薬剤"
            value={formValues[4]}
            onChange={(evt, { value }) => {
              const newValues = formValues.map(formValue => formValue);
              newValues[4] = value;
              setFormValues(newValues);
              const requestPayload = BUILD_DRUG_USAGE_PAYLOAD(...newValues);
              updateFormValues('medicine', requestPayload);
              isFormValid(1, true);
            }}
          />
        </Form.Group>
      )}
      <Form.Group>
        <Form.Field style={{ paddingRight: 0 }}>
          <FormLabel htmlFor="other_stimulation">
            上記以外の種類の薬剤
          </FormLabel>
          <Form.TextArea
            name="other_stimulation"
            placeholder="血栓予防薬、ビタミン剤など"
            value={formValues[2]}
            onChange={(evt, { value }) => {
              const newValues = formValues.map(formValue => formValue);
              newValues[2] = value;
              setFormValues(newValues);
              const requestPayload = BUILD_DRUG_USAGE_PAYLOAD(...newValues);
              updateFormValues('medicine', requestPayload);
              isFormValid(1, true);
            }}
          />
        </Form.Field>
      </Form.Group>
    </>
  );
};

export default DrugUsageForm;
DrugUsageForm.propTypes = {
  loadedData: PropTypes.object,
  isFormValid: PropTypes.func.isRequired,
  updateFormValues: PropTypes.func.isRequired,
};
DrugUsageForm.defaultProps = {
  loadedData: null,
};
