import produce from 'immer';

import { isOnTheSamePage } from 'utils/helper';
import { LOCATION_CHANGE } from 'containers/TopPage/constants';
import {
  FETCH_CLINICS_SUCCEEDED,
  FETCH_CLINICS_FAILED,
} from 'containers/ClinicsPage/constants';

export const initialState = {
  clinicsData: null,
  errors: [],
  getDataWasSuccessful: false,
};

export const ClinicsReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case FETCH_CLINICS_SUCCEEDED:
        draft.clinicsData = JSON.parse(JSON.stringify(action.payload));
        draft.errors = [];
        draft.getDataWasSuccessful = false;
        break;
      case FETCH_CLINICS_FAILED:
        draft.clinicsData = null;
        draft.errors = action.payload.errors;
        draft.getDataWasSuccessful = false;
        break;
      case LOCATION_CHANGE:
        if (!isOnTheSamePage(action)) {
          draft.clinicsData = null;
          draft.errors = [];
          draft.getDataWasSuccessful = false;
        }
        break;
      default:
        draft.clinicsData = state.clinicsData;
        draft.errors = state.errors;
        draft.getDataWasSuccessful = state.getDataWasSuccessful;
    }
  });
