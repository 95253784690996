/* This file contains all the default options for
 ** the different form fields of the project */
export const GENDER_OPTIONS = [
  { key: '女性', text: '女性', value: 0 },
  { key: '男性', text: '男性', value: 1 },
];
export const MONTHS = [
  { key: '1月', value: '01', text: '1月' },
  { key: '2月', value: '02', text: '2月' },
  { key: '3月', value: '03', text: '3月' },
  { key: '4月', value: '04', text: '4月' },
  { key: '5月', value: '05', text: '5月' },
  { key: '6月', value: '06', text: '6月' },
  { key: '7月', value: '07', text: '7月' },
  { key: '8月', value: '08', text: '8月' },
  { key: '9月', value: '09', text: '9月' },
  { key: '10月', value: '10', text: '10月' },
  { key: '11月', value: '11', text: '11月' },
  { key: '12月', value: '12', text: '12月' },
];

export const PROFESSION_OPTIONS = [
  { key: ' 常勤職員・正規雇用', text: '常勤職員・正規雇用', value: 0 },
  {
    key: '非常勤職員・非正規雇用（派遣、契約、パート等）',
    text: '非常勤職員・非正規雇用（派遣、契約、パート等）',
    value: 1,
  },
  { key: '自営業・フリーランス', text: '自営業・フリーランス', value: 2 },
  { key: '専業主婦（主夫）', text: '専業主婦（主夫）', value: 5 },
  { key: '学生・無職', text: '学生・無職', value: 4 },
];

export const PREVIOUS_PREGNANCY = [
  { key: '自然妊娠', text: '自然妊娠', value: 0 },
  { key: 'タイミング法', text: 'タイミング法', value: 1 },
  { key: '人工授精（AIH/IUI）', text: '人工授精（AIH/IUI）', value: 2 },
  {
    key: '体外受精/顕微授精（IVF/ICSI）',
    text: '体外受精/顕微授精（IVF/ICSI）',
    value: 3,
  },
];

export const TREATMENT_TYPES = [
  { key: '不妊検査', text: '不妊検査', value: 0 },
  { key: 'タイミング法', text: 'タイミング法', value: 1 },
  { key: '人工授精（AIH/IUI）', text: '人工授精（AIH/IUI）', value: 2 },
  {
    key: '体外受精・顕微授精（IVF/ICSI）',
    text: '体外受精・顕微授精（IVF/ICSI）',
    value: 3,
  },
  {
    key: '非配偶者間人工授精（AID）',
    text: '非配偶者間人工授精（AID）',
    value: 4,
  },
  {
    key: '精子提供による体外受精・顕微授精',
    text: '精子提供による体外受精・顕微授精',
    value: 5,
  },
  {
    key: '卵子提供による体外受精・顕微授精',
    text: '卵子提供による体外受精・顕微授精',
    value: 6,
  },
];

export const STATUS_TYPES = [
  { key: '治療中', text: '治療中', value: 0 },
  { key: '妊娠中', text: '妊娠中', value: 1 },
  { key: '出産済み', text: '出産済み', value: 2 },
  { key: '治療終了', text: '治療終了', value: 3 },
  { key: 'お休み中', text: 'お休み中', value: 4 },
];

export const MENSTRUAL_TYPES = [
  { key: '安定している', text: '安定している', value: 0 },
  { key: '安定していない', text: '安定していない', value: 1 },
  { key: 'その他', text: 'その他', value: 2 },
];

export const OVARIAN_DISEASES_HISTORY = [
  { key: '特になし', text: '特になし', value: 0 },
  {
    key: '排卵障害(無月経を含む)',
    text: '排卵障害(無月経を含む)',
    value: 1,
  },
  {
    key: '高プロラクチン血症',
    text: '高プロラクチン血症',
    value: 2,
  },
  {
    key: '多嚢胞性卵巣症候群(PCOS)',
    text: '多嚢胞性卵巣症候群(PCOS)',
    value: 3,
  },
  { key: 'その他', text: 'その他', value: 4 },
];
export const UTERINE_DISEASES_HISTORY = [
  { key: '特になし', text: '特になし', value: 0 },
  {
    key: '子宮内膜症(チョコレート嚢胞)',
    text: '子宮内膜症(チョコレート嚢胞)',
    value: 1,
  },
  { key: '子宮筋腫', text: '子宮筋腫', value: 2 },
  { key: '子宮腺筋症', text: '子宮腺筋症', value: 3 },
  { key: '子宮ポリープ', text: '子宮ポリープ', value: 4 },
  { key: 'その他', text: 'その他', value: 5 },
];

export const TYROID_TYPES = [
  { key: '特になし', text: '特になし', value: 0 },
  {
    key: '橋本病（慢性甲状腺炎)',
    text: '橋本病（慢性甲状腺炎)',
    value: 1,
  },
  {
    key: '橋本病以外の甲状腺機能低下症',
    text: '橋本病以外の甲状腺機能低下症',
    value: 2,
  },
  {
    key: 'バセドウ病',
    text: 'バセドウ病',
    value: 3,
  },
  {
    key: 'バセドウ病以外の甲状腺機能亢進症',
    text: 'バセドウ病以外の甲状腺機能亢進症',
    value: 4,
  },
  {
    key: '経過観察中',
    text: '経過観察中',
    value: 5,
  },
  {
    key: 'その他',
    text: 'その他',
    value: 6,
  },
];
export const ULTRASONIC_ECHO_OPTIONS = [
  { key: '未検査', text: '未検査', value: 1 },
  { key: '異常なし', text: '異常なし', value: 0 },
  { key: '子宮形態', text: '子宮形態', value: 2 },
  { key: '子宮筋腫', text: '子宮筋腫', value: 3 },
  { key: '子宮腺筋症', text: '子宮腺筋症', value: 4 },
  { key: '卵巣嚢胞', text: '卵巣嚢胞', value: 5 },
  {
    key: 'チョコレート嚢胞（子宮内膜症）',
    text: 'チョコレート嚢胞（子宮内膜症）',
    value: 6,
  },
  { key: '卵管癒着', text: '卵管癒着', value: 7 },
  { key: '卵管留水腫', text: '卵管留水腫', value: 8 },
  { key: 'その他', text: 'その他', value: 9 },
];
export const HYSTEROSALPINGOGRAPHY_OPTIONS = [
  { key: '未検査', text: '未検査', value: 1 },
  { key: '異常なし', text: '異常なし', value: 0 },
  { key: '子宮形態', text: '子宮形態', value: 2 },
  { key: '卵管留癒着', text: '卵管留癒着', value: 3 },
  {
    key: '卵管狭窄（右/左のどちらか一方）',
    text: '卵管狭窄（右/左のどちらか一方）',
    value: 4,
  },
  { key: '卵管狭窄（両方）', text: '卵管狭窄（両方）', value: 5 },
  {
    key: '卵管閉塞（右/左のどちらか一方）',
    text: '卵管閉塞（右/左のどちらか一方）',
    value: 6,
  },
  { key: '卵管閉塞（両方）', text: '卵管閉塞（両方）', value: 7 },
  { key: '卵管留水腫', text: '卵管留水腫', value: 8 },
  { key: 'その他', text: 'その他', value: 9 },
];
export const SONOHYSTEROGRAPHY_OPTIONS = [
  { key: '未検査', text: '未検査', value: 1 },
  { key: '異常なし', text: '異常なし', value: 0 },
  { key: '卵管狭窄', text: '卵管狭窄', value: 2 },
  { key: '卵管閉塞', text: '卵管閉塞', value: 3 },
];
export const STATUS_OPTIONS = [
  { key: '未検査', text: '未検査', value: 2 },
  { key: '異常なし', text: '異常なし', value: 0 },
  { key: '異常あり', text: '異常あり', value: 1 },
  { key: 'その他', text: 'その他', value: 3 },
];

export const HYSTEROSCOPY_OPTIONS = [
  { key: '未検査', text: '未検査', value: 1 },
  { key: '異常なし', text: '異常なし', value: 0 },
  { key: '子宮形態', text: '子宮形態', value: 2 },
  { key: '子宮腔癒着', text: '子宮腔癒着', value: 3 },
  { key: '子宮内膜ポリープ', text: '子宮内膜ポリープ', value: 4 },
  { key: '子宮粘膜下筋腫', text: '子宮粘膜下筋腫', value: 5 },
  { key: '慢性子宮内膜炎', text: '慢性子宮内膜炎', value: 6 },
  { key: 'その他', text: 'その他', value: 7 },
];

export const LAPAROSCOPY_OPTIONS = [
  { key: '未検査', text: '未検査', value: 1 },
  { key: '異常なし', text: '異常なし', value: 0 },
  { key: '子宮形態', text: '子宮形態', value: 2 },
  { key: '卵管周囲癒着', text: '卵管周囲癒着', value: 3 },
  { key: '卵管狭窄', text: '卵管狭窄', value: 4 },
  { key: '卵管閉塞', text: '卵管閉塞', value: 5 },
  { key: 'その他', text: 'その他', value: 6 },
];

export const HORMONE_LEVELS = [
  { key: '特になし', text: '特になし', value: 0 },
  { key: 'ホルモン値（E2)', text: 'ホルモン値（E2)', value: 1 },
  {
    key: 'ホルモン値（FSH)',
    text: 'ホルモン値（FSH)',
    value: 2,
  },
  { key: 'ホルモン値（LH)', text: 'ホルモン値（LH)', value: 3 },
  { key: 'ホルモン値（P4)', text: 'ホルモン値（P4)', value: 4 },
];

export const FINDING_STATUS = [
  { key: '特になし', text: '特になし', value: 0 },
  {
    key: 'EDまたは射精障害/射精異常などの性機能不全',
    text: 'EDまたは射精障害/射精異常などの性機能不全',
    value: 1,
  },
  { key: '精索静脈瘤', text: '精索静脈瘤', value: 2 },
  { key: '無精子症', text: '無精子症', value: 3 },
  { key: '乏精子症', text: '乏精子症', value: 4 },
  { key: '運動無力症', text: '運動無力症', value: 5 },
  {
    key: '精管の狭窄・閉塞・欠損など',
    text: '精管の狭窄・閉塞・欠損など',
    value: 6,
  },
  {
    key: '精巣上体の狭窄・閉塞・欠損など',
    text: '精巣上体の狭窄・閉塞・欠損など',
    value: 7,
  },
  { key: 'その他', text: 'その他', value: 8 },
];

export const SPOUSE_EXPERIENCE = [
  { key: '特になし', text: '特になし', value: 0 },
  {
    key: '顕微鏡下低位結紮術',
    text: '顕微鏡下低位結紮術',
    value: 1,
  },
  { key: '精路再建手術', text: '精路再建手術', value: 2 },
  {
    key: 'TESE（精巣内精子生検採取法)',
    text: 'TESE（精巣内精子生検採取法)',
    value: 3,
  },
  {
    key: 'MESA（精巣上体内精子吸引採取法)',
    text: 'MESA（精巣上体内精子吸引採取法）',
    value: 4,
  },
  {
    key: 'PESA（経皮的精巣上体内精子吸引採取法)',
    text: 'PESA（経皮的精巣上体内精子吸引採取法)',
    value: 5,
  },
  {
    key: 'TESA（精巣内精子吸引採取法)',
    text: 'TESA（精巣内精子吸引採取法)',
    value: 6,
  },
];

export const SURGERY_EXPERIENCE = [
  { key: '受けたことはない', text: '受けたことはない', value: 0 },
  {
    key: 'FT（卵管鏡下卵管形成術）',
    text: 'FT（卵管鏡下卵管形成術）',
    value: 1,
  },
  {
    key: '卵管切除術（異所性妊娠等）',
    text: '卵管切除術（異所性妊娠等）',
    value: 2,
  },
  {
    key: '卵管線状切開術（異所性妊娠等）',
    text: '卵管線状切開術（異所性妊娠等）',
    value: 3,
  },
  {
    key: '付属器摘出術（卵巣卵管）',
    text: '付属器摘出術（卵巣卵管）',
    value: 6,
  },
  { key: '卵巣嚢腫摘出術', text: '卵巣嚢腫摘出術', value: 8 },
  { key: '上記以外の卵管の手術', text: '上記以外の卵管の手術', value: 9 },
  { key: '上記以外の卵巣の手術', text: '上記以外の卵巣の手術', value: 7 },
  { key: 'レーザー蒸散術', text: 'レーザー蒸散術', value: 5 },
  { key: '円錐切除術', text: '円錐切除術', value: 4 },
  {
    key: '流産・中絶手術（掻把法のみまたは吸引+掻把法）',
    text: '流産・中絶手術（掻把法のみまたは吸引+掻把法）',
    value: 10,
  },
  {
    key: '流産・中絶手術（吸引法のみ）',
    text: '流産・中絶手術（吸引法のみ）',
    value: 11,
  },
  {
    key: '上記以外の子宮（頸部含む）の手術',
    text: '上記以外の子宮（頸部含む）の手術',
    value: 12,
  },
  {
    key: '上記以外の開腹を伴う手術',
    text: '上記以外の開腹を伴う手術',
    value: 13,
  },
  {
    key: '上記以外の開腹を伴わない手術',
    text: '上記以外の開腹を伴わない手術',
    value: 14,
  },
  { key: 'その他', text: 'その他', value: 15 },
];

export const OTHER_TESTS = [
  { key: '特になし', text: '特になし', value: 0 },
  { key: 'ビタミンD値', text: 'ビタミンD値', value: 1 },
  { key: 'DHEA値', text: 'DHEA値', value: 2 },
  {
    key: 'テストステロン値',
    text: 'テストステロン値',
    value: 3,
  },
  { key: '銅亜鉛比率', text: '銅亜鉛比率', value: 5 },
  { key: 'その他', text: 'その他', value: 4 },
];

export const COAGULATION_FUNCTIONS = [
  { key: '未検査', text: '未検査', value: 5 },
  { key: '特になし', text: '特になし', value: 0 },
  { key: 'APTT', text: 'APTT', value: 1 },
  { key: '第XII因子活性', text: '第XII因子活性', value: 2 },
  { key: 'protein-S活性', text: 'protein-S活性', value: 3 },
  { key: 'protein-C活性', text: 'protein-C活性', value: 4 },
];

export const ANTIPHOSPHOLID_TYPES = [
  { key: '未検査', text: '未検査', value: 5 },
  { key: '特になし', text: '特になし', value: 0 },
  { key: '抗核抗体', text: '抗核抗体', value: 1 },
  {
    key: 'ループスアンチコアグラント(LAC)',
    text: 'ループスアンチコアグラント(LAC)',
    value: 2,
  },
  {
    key: '抗カルジオリピンβ2GPⅠ抗体',
    text: '抗カルジオリピンβ2GPⅠ抗体',
    value: 3,
  },
  {
    key: '抗カルジオリピン抗体IgG',
    text: '抗カルジオリピン抗体IgG',
    value: 4,
  },
];

export const ABNORMAL_TYPES = [
  { key: '未検査', text: '未検査', value: 3 },
  { key: '異常なし', text: '異常なし', value: 0 },
  { key: '空腹時血糖', text: '空腹時血糖', value: 1 },
  { key: 'HbA1c', text: 'HbA1c', value: 2 },
];
export const PREFECTURE_OPTIONS = [
  { key: '北海道', text: '北海道', value: 0 },
  { key: '青森県', text: '青森県', value: 1 },
  { key: '岩手県', text: '岩手県', value: 2 },
  { key: '宮城県', text: '宮城県', value: 3 },
  { key: '秋田県', text: '秋田県', value: 4 },
  { key: '山形県', text: '山形県', value: 5 },
  { key: '福島県', text: '福島県', value: 6 },
  { key: '茨城県', text: '茨城県', value: 7 },
  { key: '栃木県', text: '栃木県', value: 8 },
  { key: '群馬県', text: '群馬県', value: 9 },
  { key: '埼玉県', text: '埼玉県', value: 10 },
  { key: '千葉県', text: '千葉県', value: 11 },
  { key: '東京都', text: '東京都', value: 12 },
  { key: '神奈川県', text: '神奈川県', value: 13 },
  { key: '新潟県', text: '新潟県', value: 14 },
  { key: '富山県', text: '富山県', value: 15 },
  { key: '石川県', text: '石川県', value: 16 },
  { key: '福井県', text: '福井県', value: 17 },
  { key: '山梨県', text: '山梨県', value: 18 },
  { key: '長野県', text: '長野県', value: 19 },
  { key: '岐阜県', text: '岐阜県', value: 20 },
  { key: '静岡県', text: '静岡県', value: 21 },
  { key: '愛知県', text: '愛知県', value: 22 },
  { key: '三重県', text: '三重県', value: 23 },
  { key: '滋賀県', text: '滋賀県', value: 24 },
  { key: '京都府', text: '京都府', value: 25 },
  { key: '大阪府', text: '大阪府', value: 26 },
  { key: '兵庫県', text: '兵庫県', value: 27 },
  { key: '奈良県', text: '奈良県', value: 28 },
  { key: '和歌山県', text: '和歌山県', value: 29 },
  { key: '鳥取県', text: '鳥取県', value: 30 },
  { key: '島根県', text: '島根県', value: 31 },
  { key: '岡山県', text: '岡山県', value: 32 },
  { key: '広島県', text: '広島県', value: 33 },
  { key: '山口県', text: '山口県', value: 34 },
  { key: '徳島県', text: '徳島県', value: 35 },
  { key: '香川県', text: '香川県', value: 36 },
  { key: '愛媛県', text: '愛媛県', value: 37 },
  { key: '高知県', text: '高知県', value: 38 },
  { key: '福岡県', text: '福岡県', value: 39 },
  { key: '佐賀県', text: '佐賀県', value: 40 },
  { key: '長崎県', text: '長崎県', value: 41 },
  { key: '熊本県', text: '熊本県', value: 42 },
  { key: '大分県', text: '大分県', value: 43 },
  { key: '宮崎県', text: '宮崎県', value: 44 },
  { key: '鹿児島', text: '鹿児島', value: 45 },
  { key: '沖縄県', text: '沖縄県', value: 46 },
];

export const CLINIC_OPTIONS = [
  { key: '東京クリニく', text: '東京クリニく', value: 0 },
  { key: '京東クリニく', text: '京東クリニく', value: 1 },
  { key: '名古屋クリニく', text: '京東クリニく', value: 2 },
];

export const STIMULATION_METHODS = [
  { key: '自然周期', text: '自然周期', value: 0 },
  { key: '低刺激法', text: '低刺激法', value: 1 },
  {
    key: '(クロミフェン）+ hMG/rFSH法',
    text: '(クロミフェン）+ hMG/rFSH法',
    value: 2,
  },
  { key: 'ショート法', text: 'ショート法', value: 3 },
  {
    key: 'ロング法(ウルトラロング法など含む)',
    text: 'ロング法(ウルトラロング法など含む)',
    value: 4,
  },
  {
    key: 'アンタゴニスト法',
    text: 'アンタゴニスト法',
    value: 5,
  },
  {
    key: 'PPOS（黄体フィードバック）法',
    text: 'PPOS（黄体フィードバック）法',
    value: 8,
  },
  { key: '不明', text: '不明', value: 6 },
  { key: 'その他', text: 'その他', value: 7 },
];

export const OVARIAN_STIMULATION_MEDICINE = [
  { key: 'HMG「あすか」', text: 'HMG「あすか」', value: 4 },
  { key: 'HMGフェリング', text: 'HMGフェリング', value: 2 },
  { key: 'HMGフジ', text: 'HMGフジ', value: 3 },
  { key: 'HMG（上記以外)', text: 'HMG（上記以外)', value: 5 },
  {
    key: 'uFSH「あすか」/フォリルモン',
    text: 'uFSH「あすか」/フォリルモン',
    value: 10,
  },
  { key: 'オビドレル', text: 'オビドレル', value: 8 },
  { key: 'クロミッド', text: 'クロミッド', value: 0 },
  {
    key: 'ゴナールF(ペンタイプを含む)',
    text: 'ゴナールF(ペンタイプを含む)',
    value: 1,
  },
  { key: 'スプレキュア', text: 'スプレキュア', value: 7 },
  { key: 'セキソビツト', text: 'セキソビツト', value: 9 },
  { key: 'セロフェン', text: 'セロフェン', value: 13 },
  { key: 'ナサニール', text: 'ナサニール', value: 14 },
  {
    key: 'フェマーラ/レトロゾール',
    text: 'フェマーラ/レトロゾール',
    value: 12,
  },
  { key: 'フォリスチム', text: 'フォリスチム', value: 11 },
  { key: 'ブセレキュア', text: 'ブセレキュア', value: 6 },
  { key: 'レコベル', text: 'レコベル', value: 18 },
  { key: 'その他', text: 'その他', value: 15 },
  { key: '不明/忘れた', text: '不明/忘れた', value: 16 },
  { key: '未使用', text: '未使用', value: 17 },
];

export const OVARIAN_INHIBITORS = [
  { key: 'ガニレスト', text: 'ガニレスト', value: 0 },
  { key: 'セトロタイド', text: 'セトロタイド', value: 1 },
  { key: 'デュファストン', text: 'デュファストン', value: 7 },
  { key: 'ヒスロン', text: 'ヒスロン', value: 9 },
  {
    key: 'ボルタレン(座薬含む)',
    text: 'ボルタレン(座薬含む)',
    value: 3,
  },
  { key: 'ルトラール', text: 'ルトラール', value: 8 },
  { key: 'レルミナ', text: 'レルミナ', value: 2 },
  { key: 'その他', text: 'その他', value: 4 },
  { key: '不明/忘れた', text: '不明/忘れた', value: 5 },
  { key: '未使用', text: '未使用', value: 6 },
];

export const INDUCER_MEDICINE = [
  { key: 'HCG', text: 'HCG', value: 0 },
  { key: 'ゴナトロピン', text: 'ゴナトロピン', value: 7 },
  { key: 'スプレキュア', text: 'スプレキュア', value: 1 },
  { key: 'ナサニール', text: 'ナサニール', value: 3 },
  { key: 'ブセレキュア', text: 'ブセレキュア', value: 2 },
  { key: 'ルクリン', text: 'ルクリン', value: 8 },
  { key: 'その他', text: 'その他', value: 4 },
  { key: '不明/忘れた', text: '不明/忘れた', value: 5 },
  { key: '未使用', text: '未使用', value: 6 },
];

export const FERTILIZATION_METHODS = [
  { key: 'ふりかけ法', text: 'ふりかけ法', value: 0 },
  {
    key: 'スプリット法（ふりかけ法と顕微授精の両方)',
    text: 'スプリット法（ふりかけ法と顕微授精の両方)',
    value: 1,
  },
  { key: '顕微授精', text: '顕微授精', value: 2 },
  {
    key: 'Piezo-ICSI（ピエゾICSI)',
    text: 'Piezo-ICSI(ピエゾICSI)',
    value: 3,
  },
  { key: 'その他', text: 'その他', value: 4 },
  { key: '不明/忘れた', text: '不明/忘れた', value: 5 },
];

export const EMBRYO_CULTURE_OPTION = [
  { key: '特になし', text: '特になし', value: 0 },
  { key: 'IVM（未熟卵体外成熟）', text: 'IVM（未熟卵体外成熟）', value: 1 },
  { key: '凍結精子の使用', text: '凍結精子の使用', value: 2 },
  { key: '凍結卵子の使用', text: '凍結卵子の使用', value: 3 },
  {
    key: 'Piezo-ICSI（ピエゾICSI）',
    text: 'Piezo-ICSI（ピエゾICSI）',
    value: 4,
  },
  {
    key: '卵子活性化処理法（カルシウムイオノフォア）',
    text: '卵子活性化処理法（カルシウムイオノフォア）',
    value: 5,
  },
  { key: 'IMSI', text: 'IMSI', value: 6 },
  { key: 'PICSI（HA-ICSI）', text: 'PICSI（HA-ICSI）', value: 7 },
  { key: 'タイムラプス', text: 'タイムラプス', value: 8 },
  { key: 'その他', text: 'その他', value: 9 },
];

export const PREGNANCY_RESULTS = [
  { key: '陰性', text: '陰性', value: 0 },
  { key: '陽性', text: '陽性', value: 1 },
];

export const OPU_RESULTS = [
  { key: '陰性判定', text: '陰性判定', value: 0 },
  { key: '陽性判定', text: '陽性判定', value: 1 },
  {
    key: '胎嚢（妊娠）まで確認',
    text: '胎嚢（妊娠）まで確認',
    value: 2,
  },
  {
    key: '妊娠継続中',
    text: '妊娠継続中',
    value: 3,
  },
  { key: '出産済み', text: '出産済み', value: 4 },
  { key: '流産・死産', text: '流産・死産', value: 5 },
];

export const HORMONE_REPLACEMENT_MEDICINE = [
  { key: 'ウトロゲスタン', text: 'ウトロゲスタン', value: 11 },
  {
    key: 'エストラーナ(テープ)',
    text: 'エストラーナ(テープ)',
    value: 0,
  },
  { key: 'ジュリナ', text: 'ジュリナ', value: 1 },
  { key: 'ディビゲル', text: 'ディビゲル', value: 2 },
  { key: 'デュファストン', text: 'デュファストン', value: 6 },
  { key: 'ヒスロン', text: 'ヒスロン', value: 15 },
  { key: 'プレマリン', text: 'プレマリン', value: 3 },
  {
    key: 'プロゲストン/プロゲ',
    text: 'プロゲストン/プロゲ',
    value: 4,
  },
  { key: 'プロベラ', text: 'プロベラ', value: 7 },
  { key: 'ル・エストロジェル', text: 'ル・エストロジェル', value: 16 },
  { key: 'ルティナス', text: 'ルティナス', value: 8 },
  { key: 'ルテウム', text: 'ルテウム', value: 10 },
  { key: 'ルトラール', text: 'ルトラール', value: 5 },
  { key: 'ワンクリノン', text: 'ワンクリノン', value: 9 },
  { key: 'その他', text: 'その他', value: 12 },
  { key: '不明/忘れた', text: '不明/忘れた', value: 13 },
  { key: '未使用', text: '未使用', value: 14 },
];

export const TRANSFER_METHODS = [
  {
    key: '凍結胚移植(自然周期)',
    text: '凍結胚移植(自然周期)',
    value: 0,
  },
  {
    key: '凍結胚移植（ホルモン補充周期)',
    text: '凍結胚移植（ホルモン補充周期)',
    value: 1,
  },
  { key: '新鮮胚移植周期', text: '新鮮胚移植周期', value: 2 },
  { key: '移植キャンセル', text: '移植キャンセル', value: 3 },
];

export const TRANSFER_OPTIONS = [
  { key: '特になし', text: '特になし', value: 7 },
  {
    key: 'アシスティッドハッチング（AHA）',
    text: 'アシスティッドハッチング（AHA）',
    value: 0,
  },
  { key: 'SEET法', text: 'SEET法', value: 1 },
  { key: 'スクラッチ法', text: 'スクラッチ法', value: 2 },
  { key: '二個移植', text: '二個移植', value: 3 },
  { key: '二段階移植', text: '二段階移植', value: 4 },
  {
    key: 'エンブリオグルーなどの培養液使用',
    text: 'エンブリオグルーなどの培養液使用',
    value: 5,
  },
  {
    key: '子宮内膜再生増殖法（ERP）',
    text: '子宮内膜再生増殖法（ERP）',
    value: 8,
  },
  { key: 'その他', text: 'その他', value: 6 },
];

export const PGTA_RESULTS = [
  { key: '正常胚', text: '正常胚', value: 0 },
  { key: 'モザイク胚', text: 'モザイク胚', value: 1 },
  { key: '不明(未検査)', text: '不明(未検査)', value: 2 },
];

export const TRANSPLANTION_DATES = [
  { key: '2日目初期胚', text: '2日目初期胚', value: 0 },
  { key: '3日目初期胚', text: '3日目初期胚', value: 1 },
  { key: '桑実胚', text: '桑実胚', value: 2 },
  { key: 'その他初期胚', text: 'その他初期胚', value: 3 },
  { key: '5日目胚盤胞', text: '5日目胚盤胞', value: 4 },
  { key: '6日目胚盤胞', text: '6日目胚盤胞', value: 5 },
  { key: '7日目胚盤胞', text: '7日目胚盤胞', value: 6 },
  { key: 'その他胚盤胞', text: 'その他胚盤胞', value: 7 },
];

export const EMBRYO_GRADES = [
  { key: '7', text: '評価なし', value: 6 },
  { key: '1', text: '1', value: 0 },
  { key: '2', text: '2', value: 1 },
  { key: '3', text: '3', value: 2 },
  { key: '4', text: '4', value: 3 },
  { key: '5', text: '5', value: 4 },
];
export const BLASTOCYST_GRADES = [
  { key: '7', text: '評価なし', value: 6 },
  { key: '1', text: '1', value: 0 },
  { key: '2', text: '2', value: 1 },
  { key: '3', text: '3', value: 2 },
  { key: '4', text: '4', value: 3 },
  { key: '5', text: '5', value: 4 },
  { key: '6', text: '6', value: 5 },
];
export const GRADES = {
  0: EMBRYO_GRADES,
  1: EMBRYO_GRADES,
  2: EMBRYO_GRADES,
  3: EMBRYO_GRADES,
  4: BLASTOCYST_GRADES,
  5: BLASTOCYST_GRADES,
  6: BLASTOCYST_GRADES,
  7: BLASTOCYST_GRADES,
};

export const ICM_GRADES = [
  { key: 'A', text: 'A', value: 0 },
  { key: 'B', text: 'B', value: 1 },
  { key: 'C', text: 'C', value: 2 },
];

export const HORMONE_DRUGS = [
  { key: 'アンジュ', text: 'アンジュ', value: 7 },
  { key: 'オーソ', text: 'オーソ', value: 6 },
  { key: 'ソフィア', text: 'ソフィア', value: 2 },
  { key: 'デュファストン', text: 'デュファストン', value: 13 },
  { key: 'トライディオール', text: 'トライディオール', value: 11 },
  { key: 'トリキュラー', text: 'トリキュラー', value: 8 },
  { key: 'ノアルテン', text: 'ノアルテン', value: 12 },
  { key: 'フラバノール', text: 'フラバノール', value: 3 },
  { key: 'プレマリン', text: 'プレマリン', value: 0 },
  { key: 'プロベラ', text: 'プロベラ', value: 1 },
  { key: 'マーベロン', text: 'マーベロン', value: 5 },
  { key: 'ラベルフィーユ', text: 'ラベルフィーユ', value: 10 },
  { key: 'ルテジオン', text: 'ルテジオン', value: 4 },
  { key: 'ルナベル', text: 'ルナベル', value: 9 },
  { key: 'その他', text: 'その他', value: 14 },
];

export const YES_NO_QUESTION = [
  {
    text: 'はい',
    key: 'はい',
    value: 1,
  },
  {
    text: 'いいえ',
    key: 'いいえ',
    value: 0,
  },
];
