import styled from 'styled-components';
import { Form, Grid } from 'semantic-ui-react';

export const FormField = styled(Form.Field)`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 14px !important;
`;

export const FormLabel = styled.label`
  font-weight: 500 !important;
  width: 50% !important;
`;

export const TreatmentColumn = styled(Grid.Column)`
  padding-left: 0px !important;
  padding-right: 0px !important;
`;

export const CyclesPageMessage = styled.p`
  white-space: pre-line;
`;
