export const BUILD_TREATMENT_RESULTS_PAYLOAD = formValues => {
  const payload = {
    started_at: formValues.started_at,
    ended_at: formValues.ended_at,
    prefecture_id: formValues.prefecture_id,
    clinic_id: formValues.clinic_id,
    clinic_name: formValues.clinic_name,
  };
  if (formValues.treatment_result_of_the_cycle !== null)
    payload.treatment_result_of_the_cycle =
      formValues.treatment_result_of_the_cycle;
  if (formValues.pregnancy_test_result != null)
    payload.pregnancy_test_result = formValues.pregnancy_test_result;
  return payload;
};
