import React from 'react';
import { Button, Card } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
const CycleAdvice = () => (
  <Card style={{ width: '100%' }}>
    <Card.Content>
      <Card.Header>今はどの治療を行なっていますか？</Card.Header>
    </Card.Content>
    <Card.Content
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <p style={{ marginBottom: 14 }}>
        以下の情報を登録することでマイデータグラフが表示されます。まずは登録お願いします
      </p>
      <Button
        as={Link}
        to="/cycles/opuCycle/register"
        className="error_button ninpath__button__main"
      >
        体外受精・採卵
      </Button>
      <Button
        as={Link}
        to="/cycles/etCycle/register"
        className="error_button ninpath__button__main"
      >
        体外受精・移植
      </Button>
      <Button
        as={Link}
        to="/cycles/aihCycle/register"
        className="error_button ninpath__button__main"
      >
        人工受精
      </Button>
      <Button
        as={Link}
        to="/cycles/timingCycle/register"
        className="error_button ninpath__button__main"
      >
        タイミング法
      </Button>
      <Button
        as={Link}
        to="/cycles/offCycle/register"
        className="error_button ninpath__button__main"
      >
        おやすみ中
      </Button>
    </Card.Content>
  </Card>
);
export default CycleAdvice;
