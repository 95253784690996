import React from 'react';
import PropTypes from 'prop-types';
import { SUB_COLOR } from 'containers/ChartPage/colors';
import { ErrorLayoutContainer } from 'utils/layouts';
import { Link } from 'react-router-dom';

const tokenTypeToPath = tokenType => {
  if (tokenType === 'registration') {
    return '/registration';
  }
  if (tokenType === 'amh_registration') {
    return '/ninpath-check/registration';
  }
  if (tokenType === 'amh_password') {
    return '/ninpath-check/forgotPassword';
  }
  return '/forgotPassword';
};

export const ExpiredTokenPage = ({ errorMessage, tokenType }) => (
  <ErrorLayoutContainer bgColor={SUB_COLOR}>
    <div
      className="message_container"
      style={{ transform: 'translateY(-50%)' }}
    >
      <p style={{ fontSize: '2.5rem' }}>{errorMessage}</p>
      <p style={{ textAlign: 'center', fontSize: '1rem' }}>
        <Link to={tokenTypeToPath(tokenType)}>こちらからリンクを再送する</Link>
      </p>
    </div>
  </ErrorLayoutContainer>
);

ExpiredTokenPage.propTypes = {
  errorMessage: PropTypes.string,
  tokenType: PropTypes.string,
};
ExpiredTokenPage.defaultProps = {
  errorMessage: 'リンクの有効期限が切れています',
  tokenType: 'password',
};
