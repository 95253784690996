import 'react-app-polyfill/ie9'; // support above IE9
import 'core-js/es/set';
import 'core-js/es/weak-map';

import { Helmet, HelmetProvider } from 'react-helmet-async';

import React from 'react';
import ReactDOM from 'react-dom';
import 'index.css';

import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';

import TagManager from 'react-gtm-module';

import Cookies from "universal-cookie";

import history from 'utils/history';
import reducers from 'reducers';
import configureStore from 'configureStore';
import bugsnagClient from 'utils/bugsnag';
import * as serviceWorker from 'serviceWorker';
import App from 'App';

import { BrowserNotSupported } from './containers/ErrorPage/BrowserNotSupported';

// Create redux store with history
const initialState = {};

const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('root');

const cookies = new Cookies();

const tagManagerArgs = {
  gtmId: `${process.env.REACT_APP_GTM_API_KEY}`,
  dataLayer: {
    userId: cookies.get('user_id') || null,
  },
};

const ErrorBoundary = bugsnagClient.getPlugin('react');
const isIE = false || !!document.documentMode;
// const is

TagManager.initialize(tagManagerArgs);
ReactDOM.render(
  <Provider store={store} reducers={reducers}>
    <ConnectedRouter history={history}>
      <HelmetProvider>
        {process.env.REACT_APP_ENV === 'development' && (
          <Helmet>
            <meta name="robots" content="noindex,nofollow" />
          </Helmet>
        )}
        <ErrorBoundary>
          {!isIE ? <App /> : <BrowserNotSupported />}
        </ErrorBoundary>
      </HelmetProvider>
    </ConnectedRouter>
  </Provider>,
  MOUNT_NODE,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
