import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Grid } from 'semantic-ui-react';
import { FormField, FormLabel } from 'containers/RegistrationPage/formElements';
import { DateInput } from 'semantic-ui-calendar-react';

const ImplementationForm = ({
  mode,
  loadedData,
  isFormValid,
  updateFormValues,
}) => {
  const [formValues, setFormValues] = useState(['', '']);
  useEffect(() => {
    if (loadedData) {
      const newFormValues = [];
      newFormValues.push(
        loadedData.day_of_taiming || loadedData.day_of_aih || '',
      );
      newFormValues.push(loadedData.endometrial_thickness || '');
      if (mode === 0) {
        newFormValues.push(loadedData.memo || '');
      }
      setFormValues(newFormValues);
    }
  }, [loadedData, mode]);
  return (
    <Form.Group>
      <Grid>
        <Grid.Row>
          <Grid.Column mobile={16} computer={16} tablet={16} floated="left">
            <FormField style={{ paddingRight: 0 }}>
              <FormLabel htmlFor="implementation_date">実施日</FormLabel>
              <DateInput
                hideMobileKeyboard
                closable
                name="implementation_date"
                dateFormat="YYYY-MM-DD"
                placeholder="YYYY-MM-DD"
                animation="none"
                clearable
                autoComplete="off"
                iconPosition="right"
                startMode="year"
                localization="ja"
                value={formValues[0]}
                onChange={(evt, { value }) => {
                  const newValues = formValues.map(formValue => formValue);
                  newValues[0] = value;
                  setFormValues(newValues);
                  if (mode === 0) {
                    updateFormValues('timing', {
                      day_of_taiming: newValues[0],
                      endometrial_thickness: formValues[1],
                      memo: formValues[2],
                    });
                  } else if (mode === 1) {
                    updateFormValues('aih', {
                      day_of_aih: newValues[0],
                      endometrial_thickness: formValues[1],
                    });
                  }
                  isFormValid(2, true);
                }}
              ></DateInput>
            </FormField>
          </Grid.Column>
          <Grid.Column mobile={16} computer={16} tablet={16} floated="right">
            <FormField style={{ paddingRight: 0 }}>
              <FormLabel htmlFor="endometrial_thickness">
                子宮内膜の厚み(mm)
              </FormLabel>
              <Form.Input
                fluid
                name="endometrial_thickness"
                type="number"
                placeholder="0"
                value={formValues[1]}
                onChange={(evt, { value }) => {
                  const newValues = formValues.map(formValue => formValue);
                  newValues[1] = value;
                  setFormValues(newValues);
                  if (mode === 0) {
                    updateFormValues('timing', {
                      day_of_taiming: formValues[0],
                      endometrial_thickness: newValues[1],
                      memo: formValues[2],
                    });
                  } else if (mode === 1) {
                    updateFormValues('aih', {
                      day_of_aih: formValues[0],
                      endometrial_thickness: newValues[1],
                    });
                  }
                  isFormValid(2, true);
                }}
              />
            </FormField>
          </Grid.Column>
        </Grid.Row>
        {mode === 0 && (
          <Grid.Row>
            <Grid.Column mobile={16} computer={16} tablet={16} floated="left">
              <FormField style={{ paddingRight: 0 }}>
                <FormLabel htmlFor="memo">メモ</FormLabel>
                <Form.TextArea
                  name="memo"
                  placeholder="メモ"
                  value={formValues[2]}
                  onChange={(evt, { value }) => {
                    const newValues = formValues.map(formValue => formValue);
                    newValues[2] = value;
                    setFormValues(newValues);
                    updateFormValues('timing', {
                      day_of_taiming: newValues[0],
                      endometrial_thickness: formValues[1],
                      memo: formValues[2],
                    });
                    isFormValid(2, true);
                  }}
                />
              </FormField>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </Form.Group>
  );
};
export default ImplementationForm;
ImplementationForm.propTypes = {
  loadedData: PropTypes.object,
  mode: PropTypes.number,
  isFormValid: PropTypes.func.isRequired,
  updateFormValues: PropTypes.func.isRequired,
};
ImplementationForm.defaultProps = {
  loadedData: null,
  mode: 0,
};
