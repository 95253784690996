import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { FormField, FormLabel } from 'containers/RegistrationPage/formElements';
import {
  BUILD_SEMEN_RESULTS_PAYLOAD,
  DEFAULT_SEMEN_VALUES,
} from 'containers/TreatmentCyclePage/constants';

const OpuSemenResultsForm = ({ loadedData, isFormValid, updateFormValues }) => {
  const [formValues, setFormValues] = useState(DEFAULT_SEMEN_VALUES);
  useEffect(() => {
    if (loadedData) {
      const newFormValues = [];
      newFormValues.push(loadedData.semen_volume || '');
      newFormValues.push(loadedData.sperm_concentration || '');
      newFormValues.push(loadedData.sperm_motility || '');
      newFormValues.push(loadedData.pmsc || '');
      newFormValues.push(loadedData.normal_forms || '');
      newFormValues.push(loadedData.total_amount_of_sperm || '');
      newFormValues.push(loadedData.memo || '');
      setFormValues(newFormValues);
    }
  }, [loadedData]);
  return (
    <>
      <Form.Group>
        <FormField style={{ paddingRight: 0 }}>
          <FormLabel htmlFor="semen_volume">精液量(ml)</FormLabel>
          <Form.Input
            fluid
            name="semen_volume"
            placeholder="0"
            type="number"
            value={formValues[0]}
            onChange={(evt, { value }) => {
              const newValues = formValues.map(formValue => formValue);
              newValues[0] = value;
              setFormValues(newValues);
              const isValid = !isNaN(formValues[0]);
              if (isValid) {
                const requestPayload = BUILD_SEMEN_RESULTS_PAYLOAD(
                  ...newValues,
                );
                updateFormValues('semen', requestPayload);
              }
              isFormValid(3, isValid);
            }}
          />
        </FormField>
      </Form.Group>
      <Form.Group>
        <FormField style={{ paddingRight: 0 }}>
          <FormLabel htmlFor="sperm_concentration">
            精子濃度(10<sup>6</sup>/ml)
          </FormLabel>
          <Form.Input
            fluid
            name="sperm_concentration"
            type="number"
            placeholder="0"
            value={formValues[1]}
            onChange={(evt, { value }) => {
              const newValues = formValues.map(formValue => formValue);
              newValues[1] = value;
              setFormValues(newValues);
              const isValid = !isNaN(formValues[1]);
              if (isValid) {
                const requestPayload = BUILD_SEMEN_RESULTS_PAYLOAD(
                  ...newValues,
                );
                updateFormValues('semen', requestPayload);
              }
              isFormValid(3, isValid);
            }}
          />
        </FormField>
      </Form.Group>
      <Form.Group>
        <FormField style={{ paddingRight: 0 }}>
          <FormLabel htmlFor="sperm_motility">運動率(%)</FormLabel>
          <Form.Input
            fluid
            name="sperm_motility"
            type="number"
            placeholder="0"
            min="0"
            step="any"
            max="100"
            value={formValues[2]}
            onChange={(evt, { value }) => {
              const newValues = formValues.map(formValue => formValue);
              newValues[2] = value;
              setFormValues(newValues);
              const isValid = !isNaN(formValues[2]);
              if (isValid) {
                const requestPayload = BUILD_SEMEN_RESULTS_PAYLOAD(
                  ...newValues,
                );
                updateFormValues('semen', requestPayload);
              }
              isFormValid(3, isValid);
            }}
          />
        </FormField>
      </Form.Group>
      <Form.Group>
        <FormField style={{ paddingRight: 0 }}>
          <FormLabel htmlFor="pmsc">前進運動（直進）率(%)</FormLabel>
          <Form.Input
            fluid
            name="pmsc"
            type="number"
            placeholder="0"
            min="0"
            max="100"
            step="any"
            value={formValues[3]}
            onChange={(evt, { value }) => {
              const newValues = formValues.map(formValue => formValue);
              newValues[3] = value;
              setFormValues(newValues);
              const isValid = !isNaN(formValues[3]);
              if (isValid) {
                const requestPayload = BUILD_SEMEN_RESULTS_PAYLOAD(
                  ...newValues,
                );
                updateFormValues('semen', requestPayload);
              }
              isFormValid(3, isValid);
            }}
          />
        </FormField>
      </Form.Group>
      <Form.Group>
        <FormField style={{ paddingRight: 0 }}>
          <FormLabel htmlFor="normal_forms">正常形態率(%)</FormLabel>
          <Form.Input
            fluid
            name="normal_forms"
            type="number"
            placeholder="0"
            min="0"
            max="100"
            step="any"
            value={formValues[4]}
            onChange={(evt, { value }) => {
              const newValues = formValues.map(formValue => formValue);
              newValues[4] = value;
              setFormValues(newValues);
              const isValid = !isNaN(formValues[4]);
              if (isValid) {
                const requestPayload = BUILD_SEMEN_RESULTS_PAYLOAD(
                  ...newValues,
                );
                updateFormValues('semen', requestPayload);
              }
              isFormValid(3, isValid);
            }}
          />
        </FormField>
      </Form.Group>
      <Form.Group>
        <FormField style={{ paddingRight: 0 }}>
          <FormLabel htmlFor="total_amount_of_sperm">総精子数(万)</FormLabel>
          <Form.Input
            fluid
            name="total_amount_of_sperm"
            type="number"
            min="0"
            step="1"
            placeholder="0"
            value={formValues[5]}
            onChange={(evt, { value }) => {
              const newValues = formValues.map(formValue => formValue);
              newValues[5] = value;
              setFormValues(newValues);
              const isValid = !isNaN(formValues[5]);
              if (isValid) {
                const requestPayload = BUILD_SEMEN_RESULTS_PAYLOAD(
                  ...newValues,
                );
                updateFormValues('semen', requestPayload);
              }
              isFormValid(3, isValid);
            }}
          />
        </FormField>
      </Form.Group>
      <Form.Group>
        <Form.Field>
          <FormLabel htmlFor="memo">メモ</FormLabel>
          <Form.TextArea
            name="memo"
            placeholder="メモ"
            value={formValues[6]}
            onChange={(evt, { value }) => {
              const newValues = formValues.map(formValue => formValue);
              newValues[6] = value;
              setFormValues(newValues);
              const requestPayload = BUILD_SEMEN_RESULTS_PAYLOAD(
                ...newValues,
              );
              updateFormValues('semen', requestPayload);
              isFormValid(3, true);
            }}
          />
        </Form.Field>
      </Form.Group>
    </>
  );
};
OpuSemenResultsForm.propTypes = {
  isFormValid: PropTypes.func.isRequired,
  loadedData: PropTypes.object,
  updateFormValues: PropTypes.func.isRequired,
};
OpuSemenResultsForm.defaultProps = {
  loadedData: null,
};
export default OpuSemenResultsForm;
