import { GET_USER_PROFILE_DATA, EDIT_USER_PROFILE } from './constants';
export const editUserProfile = payload => ({
  type: EDIT_USER_PROFILE,
  payload,
});

export const getUserProfileData = () => ({
  type: GET_USER_PROFILE_DATA,
  payload: {},
});
