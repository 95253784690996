import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FormSelect } from '../formElements';

const MultipleSelectNothingSpecial = ({
  id,
  name,
  options,
  submitValue,
  changeFunction,
  additionalExclusionNumber,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <FormSelect
      id={id}
      className="ninpath__select"
      placeholder="選択してください"
      options={options}
      multiple
      open={open}
      value={submitValue}
      onBlur={() => {
        setOpen(false);
      }}
      onClick={() => {
        if (
          submitValue &&
          (submitValue.includes(0) ||
            submitValue.includes(additionalExclusionNumber))
        ) {
          setOpen(false);
        } else {
          setOpen(!open);
        }
      }}
      onChange={(evt, { value }) => {
        if (
          value &&
          (value.includes(0) || value.includes(additionalExclusionNumber))
        ) {
          setOpen(false);
        }

        changeFunction(name, value, false, true);
      }}
    />
  );
};

MultipleSelectNothingSpecial.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.any.isRequired,
  submitValue: PropTypes.array,
  changeFunction: PropTypes.func.isRequired,
  additionalExclusionNumber: PropTypes.number,
};

export default MultipleSelectNothingSpecial;
