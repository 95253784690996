import styled from 'styled-components';
const Filter = styled.div`
  padding: 10px;
  height: 36px;
  display: flex;
  align-items: center;
  border: none;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent !important;
`;
export default Filter;
