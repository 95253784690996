import { createSelector } from 'reselect';

const selectClinics = state => state.clinics;

const makeSelectFetchedData = () =>
  createSelector(
    selectClinics,
    clinicsState => clinicsState.clinicsData,
  );

const makeSelectErrors = () =>
  createSelector(
    selectClinics,
    clinicsState => clinicsState.errors,
  );
export { makeSelectFetchedData, makeSelectErrors };
