import { Form, Tab } from 'semantic-ui-react';
import styled from 'styled-components';
export const FormInput = styled.input`
  float: right;
  text-align: right;
`;

export const FormSelect = styled(Form.Select)`
  float: right;
  padding-left: 0px !important;
`;

export const FormField = styled(Form.Field)`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 14px !important;
`;

export const FormLabel = styled.label`
  font-weight: 500 !important;
`;

export const FormPane = styled(Tab.Pane)`
  padding-left: 0 !important;
  padding-right: 0 !important;
  border: none !important;
  box-shadow: none !important;
`;
