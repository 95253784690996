import React from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import { Padding } from 'components/Atoms';
import { logoutSuccesful, showLoader } from 'containers/TopPage/actions';
import AmhChartCard from '../../components/AmhChartCard';
import { LayoutContainer, LayoutGrid } from '../../utils/layouts';

const AmhUserResultPage = ({ isAmhLoggedIn, errorCode, logout }) => {
  if (errorCode) {
    return <Redirect push to={{ pathName: '/', state: { errorCode } }} />;
  }
  if (!isAmhLoggedIn) return <Redirect push to="/" />;
  return (
    <Padding paddingTop="6rem">
      <LayoutContainer style={{ height: '100%' }}>
        <LayoutGrid style={{ paddingTop: 56, paddingBottom: 14 }}>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={10} computer={6}>
              <AmhChartCard />
            </Grid.Column>
          </Grid.Row>
        </LayoutGrid>
      </LayoutContainer>
    </Padding>
  );
};

const mapStateToProps = ({ AuthenticationReducer }) => ({
  isAmhLoggedIn: AuthenticationReducer.isAmhLoggedIn,
});
const mapDispatchToProps = dispatch => ({
  logout: (errorCode = null) => dispatch(logoutSuccesful(errorCode)),
  startLoading: payload => dispatch(showLoader(payload)),
});

AmhUserResultPage.propTypes = {
  errorCode: PropTypes.any,
  isAmhLoggedIn: PropTypes.bool.isRequired,
  logout: PropTypes.func,
};

AmhUserResultPage.defaultProps = {
  errorCode: null,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(AmhUserResultPage));
