import produce from 'immer';
import { PAGE_NOT_FOUND } from 'containers/ErrorPage/constants';
import {
  NINPATH_CHECK_LOGGED_IN,
  LOADING_STARTS,
  LOADING_ENDS,
  LOGGED_IN,
  LOGGED_OUT,
} from 'containers/TopPage/constants';

export const initialState = {
  isLoading: false,
};

export const initialLoggedState = {
  isLoggedIn: undefined,
  isAmhLoggedIn: undefined,
  errorCode: null,
};
export const HomeReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOADING_STARTS:
        draft.isLoading = true;
        break;
      case LOADING_ENDS:
        draft.isLoading = false;
        break;
      case PAGE_NOT_FOUND:
        draft.errorCode = action.payload;
        break;
      default:
        break;
    }
  });

export const AuthenticationReducer = (state = initialLoggedState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOGGED_IN:
        draft.isLoggedIn = true;
        draft.isAmhLoggedIn = false;
        draft.errorCode = null;
        break;
      case NINPATH_CHECK_LOGGED_IN:
        draft.isLoggedIn = false;
        draft.isAmhLoggedIn = true;
        draft.errorCode = null;
        break;
      case LOGGED_OUT:
        draft.isLoggedIn = false;
        draft.isAmhLoggedIn = false;
        if (action.errorCode) draft.errorCode = action.errorCode;
        break;
      default:
        draft.isLoggedIn = state.isLoggedIn;
        draft.isAmhLoggedIn = state.isAmhLoggedIn;
        draft.errorCode = state.errorCode;
        break;
    }
  });
