import produce from 'immer';

import { isOnTheSamePage } from 'utils/helper';
import { LOCATION_CHANGE } from 'containers/TopPage/constants';
import {
  DRUG_USAGE_DATA_FETCH_SUCCESSFUL,
  DRUG_USAGE_DATA_FETCH_FAILED,
} from 'containers/DrugUsagePage/constants';

export const initialState = {
  drugUsageData: null,
  empty: false,
  errors: [],
  getDataWasSuccessful: false,
};

export const DrugUsageReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case DRUG_USAGE_DATA_FETCH_SUCCESSFUL:
        draft.drugUsageData = JSON.parse(JSON.stringify(action.payload));
        draft.empty = action.payload.empty;
        draft.errors = [];
        draft.getDataWasSuccessful = false;
        break;
      case DRUG_USAGE_DATA_FETCH_FAILED:
        draft.drugUsageData = null;
        draft.empty = null;
        draft.errors = action.payload.errors;
        draft.getDataWasSuccessful = false;
        break;
      case LOCATION_CHANGE:
        if (!isOnTheSamePage(action)) {
          draft.drugUsageData = null;
          draft.errors = [];
          draft.empty = null;
          draft.getDataWasSuccessful = false;
        }
        break;
      default:
        draft.drugUsageData = state.drugUsageData;
        draft.errors = state.errors;
        draft.empty = state.empty;
        draft.getDataWasSuccessful = state.getDataWasSuccessful;
    }
  });
