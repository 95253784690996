export const MAIN_COLOR = '#D65358';
export const SUB_COLOR = '#F2F2F2';
export const SUB_COLOR_2 = '#DBDBDB';
export const SUB_COLOR_3 = '#F9F9F9';
export const SUB_COLOR_4 = '#756F70';

export const POINT_COLOR = '#D8B500';
export const POINT_COLOR_2 = '#9ac106';
export const POINT_COLOR_3 = '#7FADFF';
export const POINT_COLOR_4 = '#F29B00';

export const AMH_COLORS = {
  upper: '#FFB6B9',
  median: '#8AC6D1',
  lower: '#BBDED6',
  fill: '#FFF0EA',
  me: '#D45357',
};

export const GRADIENT_COLORS = [
  '#D65358',
  '#D96165',
  '#Dd6e73',
  '#E07c80',
  '#E38a8d',
  '#E6989b',
  '#Eaa5a8',
  '#Edb3b5',
  '#F0c1c3',
  '#F3ced0',
];

export const BAR_COLORS = [
  {
    backgroundColor: MAIN_COLOR,
    borderColor: '#fff',
    hoverColor: MAIN_COLOR,
    color: '#fff',
  },
  {
    backgroundColor: SUB_COLOR,
    borderColor: '#fff',
    hoverColor: SUB_COLOR,
    color: '#000',
  },
  {
    backgroundColor: POINT_COLOR,
    borderColor: '#fff',
    hoverColor: POINT_COLOR_2,
    color: '#fff',
  },
];
export const BAR_MIXED_COLORS = {
  bar: [
    {
      backgroundColor: MAIN_COLOR,
      borderColor: MAIN_COLOR,
      hoverColor: MAIN_COLOR,
    },
    {
      backgroundColor: POINT_COLOR_2,
      borderColor: '#fff',
      hoverColor: POINT_COLOR_2,
    },
  ],
  line: [
    {
      backgroundColor: POINT_COLOR,
      borderColor: POINT_COLOR,
      hoverColor: POINT_COLOR,
    },
  ],
};
export const INFERTILITY_COLORS = [MAIN_COLOR, POINT_COLOR];

export const RADAR_COLORS = [MAIN_COLOR, POINT_COLOR, POINT_COLOR_2];
export const SUBFILTER_COLORS = {
  異常なし: POINT_COLOR_2,
  異常あり: MAIN_COLOR,
  その他: POINT_COLOR,
  未検査: SUB_COLOR,
};
