import Cookies from 'universal-cookie';
import request from 'utils/request';

import { call } from 'redux-saga/effects';

import {
  GET_EXAMINATION_RESULTS_WAS_SUCCESSFUL,
  GET_EXAMINATION_RESULTS_FAILED,
  EXAMINATION_RESULTS_UPDATED_SUCCESSFULLY,
  EXAMINATION_RESULTS_UPDATE_FAILED,
} from 'containers/ExaminationResultsPage/constants';

import { handleResponse } from 'utils/sagasHelpers';

export function* getExaminationData() {
  const cookies = new Cookies();
  const url = `${process.env.REACT_APP_API_URL}/api/v1/me`;
  const response = yield call(request, url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      charset: 'UTF-8',
      Authorization: `Bearer ${cookies.get('user_token')}`,
    },
  });
  yield handleResponse(
    response,
    GET_EXAMINATION_RESULTS_WAS_SUCCESSFUL,
    GET_EXAMINATION_RESULTS_FAILED,
  );
}

export function* updateExaminationData(action) {
  const cookies = new Cookies();
  const url = `${process.env.REACT_APP_API_URL}/api/v1/me`;
  const response = yield call(request, url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      charset: 'UTF-8',
      Authorization: `Bearer ${cookies.get('user_token')}`,
    },
    body: JSON.stringify(action.payload),
  });
  yield handleResponse(
    response,
    EXAMINATION_RESULTS_UPDATED_SUCCESSFULLY,
    EXAMINATION_RESULTS_UPDATE_FAILED,
  );
}
