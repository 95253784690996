import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import { LayoutGrid, LayoutContainer } from 'utils/layouts';
import { Card, Form, Grid, Message, Transition } from 'semantic-ui-react';
import {
  logoutSuccesful,
  showLoader,
  hideLoader,
} from 'containers/TopPage/actions';
import { connect } from 'react-redux';
import { isValidField } from 'utils/errors';
import request from 'utils/request';

const NinpathCheckForgotPasswordPage = ({
  logout,
  finishLoading,
  startLoading,
}) => {
  const [email, setEmail] = useState('');
  const recaptchaRef = React.createRef();
  const [captchaState, setCaptchaState] = useState(null);
  const [hasSucceded, setHasSucceeded] = useState(false);
  const [grecaptcha, setGrecaptcha] = useState(null);
  const [responseMessages, setResponseMessages] = useState([]);
  const handleCaptcha = key => {
    setCaptchaState({
      captcha: true,
      'g-recaptcha-response': key,
    });
  };
  const sendConfirmationMail = () => {
    startLoading({ value: true });
    const requestBody = {
      email,
    };
    const url = `${
      process.env.REACT_APP_API_URL
    }/api/v1/amh_password/reset_request?g-recaptcha-response=${
      captchaState['g-recaptcha-response']
    }`;
    const promise = request(url, {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json',
        charset: 'UTF-8',
      },
    });
    promise.then(response => {
      finishLoading({ value: true });
      if (response.status === 401) logout();
      if (response.status === 503 || response.status === 500) {
        logout(response.status);
      }
      if (response.email_sent) {
        grecaptcha.reset();
        setHasSucceeded(true);
      } else {
        grecaptcha.reset();
        setHasSucceeded(false);
      }
      setResponseMessages(response.messages);
    });
  };

  useEffect(() => {
    setGrecaptcha(window.grecaptcha);
  }, [setGrecaptcha]);
  return (
    <LayoutContainer
      style={{
        height: 'calc(100vh - 80px)',
      }}
    >
      <LayoutGrid>
        <Grid.Row>
          <Grid.Column
            mobile={16}
            computer={6}
            tablet={8}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Form
              error
              onSubmit={evt => {
                evt.preventDefault();
                sendConfirmationMail();
              }}
            >
              <Card style={{ width: '100%' }}>
                <Card.Content
                  style={{ display: 'flex', justifyContent: 'flex-start' }}
                >
                  <Card.Header>ninpathチェック　パスワードの再設定</Card.Header>
                </Card.Content>
                <Card.Content>
                  <p style={{ marginBottom: 14 }}>
                    登録済みのメールアドレスを入力してください。 <br />
                    再設定用メールをお送りします。
                  </p>
                  <Form.Input
                    label="メールアドレス"
                    placeholder="メールアドレス"
                    type="email"
                    width={16}
                    className="form__email"
                    style={{ marginBottom: 14 }}
                    required
                    onChange={(evt, { value }) => {
                      setEmail(value);
                    }}
                  />
                  {!isValidField('email', email) && (
                    <Message
                      error
                      header="エラー"
                      content="メールアドレスの形は'xxxxxx@xxxxx.xxxxx'"
                      style={{ marginBottom: 14 }}
                    />
                  )}
                  {grecaptcha && (
                    <div
                      style={{
                        marginBottom: 14,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <ReCAPTCHA
                        onExpired={() => setCaptchaState(null)}
                        onChange={key => handleCaptcha(key)}
                        ref={recaptchaRef}
                        sitekey={process.env.REACT_APP_RECAPTCHA_API_KEY}
                      />
                    </div>
                  )}

                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Form.Button
                      className="ninpath__form__container"
                      style={{ minWidth: '180px', maxWidth: '200px' }}
                      disabled={
                        email === '' ||
                        !isValidField('email', email) ||
                        !captchaState
                      }
                    >
                      再設定用のメールを送る
                    </Form.Button>
                  </div>
                  <Transition
                    visible={hasSucceded && responseMessages.length > 0}
                    animation="fade"
                    duration={500}
                  >
                    <Message
                      width={{ style: '50%' }}
                      compact
                      floating
                      success
                      visible={hasSucceded === true}
                      content={responseMessages[0]}
                    />
                  </Transition>
                  <Transition
                    animation="fade"
                    visible={!hasSucceded && responseMessages.length > 0}
                  >
                    <Message negative style={{ borderLeft: 0, borderRight: 0 }}>
                      <Message.Header>エラー</Message.Header>
                      {responseMessages.map(error => (
                        <p key={error}>{error}</p>
                      ))}
                    </Message>
                  </Transition>
                </Card.Content>
              </Card>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </LayoutGrid>
    </LayoutContainer>
  );
};
const mapDispatchToProps = dispatch => ({
  logout: (errorCode = null) => dispatch(logoutSuccesful(errorCode)),
  startLoading: payload => dispatch(showLoader(payload)),
  finishLoading: payload => dispatch(hideLoader(payload)),
});

NinpathCheckForgotPasswordPage.propTypes = {
  logout: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};

export default connect(
  null,
  mapDispatchToProps,
)(NinpathCheckForgotPasswordPage);
