import styled from 'styled-components';
import { Icon, Menu } from 'semantic-ui-react';
import menuStyles from './menuStyles';

export const AvatarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5px;
  width: 100%;
`;
export const HeaderText = styled.h5`
  font-weight: 100;
  color: #000;
  max-width: 50%;
  margin-top: 0px;
`;

export const ItemText = styled.h5`
  font-size: 1.2rem;
  font-weight: 100;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  color: #000;
  text-align: left;
`;

export const MenuIcon = styled(Icon)`
  font-size: 1.2rem;
  margin-left: 10px !important;
  outline: none !important;
  margin-right: 10px !important;
`;

export const ShowMoreIcon = styled(Icon)`
  position: absolute !important;
  right: 0px !important;
  bottom: 0px !important;
  margin-top: 0px !important;
`;

export const MenuHeader = styled.div`
  ${menuStyles}
`;

export const ItemContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
`;

export const SubItem = styled(Menu.Item)`
  display: flex !important;
  justify-content: flex-start !important;
  padding-left: 0px !important;
`;
