import {
  MIN_BIRTHDAY_YEAR,
  MAX_BIRTHDAY_YEAR,
  SPOUSE_MIN_BIRTHDAY_YEAR,
} from 'utils/defaults';
import { isLeapYear } from 'utils/helper';

export const REGISTER_USER =
  'ninpath/containers/RegistrationPage/REGISTER_USER';
export const REGISTRATION_SUCCEEDED =
  'ninpath/containers/RegistrationPage/REGISTER_SUCCEEDED';
export const AMH_REGISTRATION_SUCCEEDED =
  'ninpath/containers/RegistrationPage/AMH_REGISTER_SUCCEEDED';
export const REGISTRATION_FAILED =
  'ninpath/containers/RegistrationPage/REGISTER_FAILED';
export const SEND_CONFIRMATION_EMAIL =
  'ninpath/containers/RegistrationPage/SEND_CONFIRMATION_EMAIL';
export const SEND_AMH_CONFIRMATION_EMAIL =
  'ninpath/containers/RegistrationPage/SEND_AMH_CONFIRMATION_EMAIL';
export const CONFIRMATION_EMAIL_SENT_SUCCESSFULLY =
  'ninpath/containers/RegistrationPage/CONFIRMATION_EMAIL_SENT_SUCCESSFULLY';
export const CONFIRMATION_EMAIL_NOT_SENT =
  'ninpath/containers/RegistrationPage/CONFIRMATION_EMAIL_NOT_SENT';

export const DEFAULT_HISTORY_VALUES = ['', null, null, '', '', '', '', '', ''];
export const GET_VALID_DAYS = (month, year) => {
  if (month === '02')
    return Array.from(Array(isLeapYear(year))).map((e, i) => ({
      key: i + 1,
      value: i < 9 ? `0${i + 1}` : `${i + 1}`,
      text: i < 9 ? `0${i + 1}` : `${i + 1}`,
    }));
  if (month === '04' || month === '06' || month === '09' || month === '11')
    return Array.from(Array(30)).map((e, i) => ({
      key: i + 1,
      value: i < 9 ? `0${i + 1}` : `${i + 1}`,
      text: i < 9 ? `0${i + 1}` : `${i + 1}`,
    }));
  return Array.from(Array(31)).map((e, i) => ({
    key: i + 1,
    value: i < 9 ? `0${i + 1}` : `${i + 1}`,
    text: i < 9 ? `0${i + 1}` : `${i + 1}`,
  }));
};
export const GET_VALID_YEARS = () => {
  const years = [];
  for (let i = MIN_BIRTHDAY_YEAR; i < MAX_BIRTHDAY_YEAR; i += 1) {
    years.push({ key: i, value: `${i}`, text: i });
  }
  return years;
};
export const GET_SPOUSE_VALID_YEARS = () => {
  const years = [];
  for (let i = SPOUSE_MIN_BIRTHDAY_YEAR; i < MAX_BIRTHDAY_YEAR; i += 1) {
    years.push({ key: i, value: `${i}`, text: i });
  }
  return years;
};
export const DEFAULT_IMPLANTATION_VALUES = [
  null,
  null,
  null,
  null,
  null,
  null,
  [],
  [],
  [],
];

export const BUILD_PI_PAYLOAD = (formValues, showName, showEmail) => {
  const payload = {
    gender: formValues.gender,
    prefectures: formValues.prefectures,
    profession: formValues.profession,
    birthday: `${formValues.birthday.year}-${formValues.birthday.month}-${formValues.birthday.day}`,
    spouses_birthday: `${formValues.spouses_birthday.year}-${formValues.spouses_birthday.month}-${formValues.spouses_birthday.day}`,
  };
  if (showName) payload.name = formValues.name;
  if (showEmail) payload.email = formValues.email;
  if (formValues.password !== true) payload.password = formValues.password;
  return payload;
};
