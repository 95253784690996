import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, TextArea } from 'semantic-ui-react';
import { FormField, FormLabel } from 'containers/RegistrationPage/formElements';
import {
  INDUCER_MEDICINE,
  STIMULATION_METHODS,
  OVARIAN_STIMULATION_MEDICINE,
  OVARIAN_INHIBITORS,
} from 'utils/defaultOptions';
import {
  BUILD_STIMULATION_MEDICINE_PAYLOAD,
  DEFAULT_STIMULATION_MEDICINE_VALUES,
} from 'containers/TreatmentCyclePage/constants';
import { validateStimulationMedicines } from 'utils/errors';
import { isValueInArray } from 'utils/helper';

const OpuStimulationForm = ({ isFormValid, updateFormValues, loadedData }) => {
  const [formValues, setFormValues] = useState(
    DEFAULT_STIMULATION_MEDICINE_VALUES,
  );
  useEffect(() => {
    if (loadedData) {
      const newFormValues = [];
      newFormValues.push(loadedData.stimulation_method);
      newFormValues.push(loadedData.ovarian_stimulant_medicine.map(elm => elm));
      newFormValues.push(loadedData.ovarian_inhibitor_medicine.map(elm => elm));
      newFormValues.push(loadedData.lh_surge_inducer_medicine.map(elm => elm));
      newFormValues.push(loadedData.other_medicine || '');
      newFormValues.push(loadedData.other_ovarian_stimulant_medicine || '');
      newFormValues.push(loadedData.other_ovarian_inhibitor_medicine || '');
      newFormValues.push(loadedData.other_lh_surge_inducer_medicine || '');

      setFormValues(newFormValues);
    }
  }, [loadedData]);
  return (
    <>
      <Form.Group>
        <FormField inline style={{ paddingRight: 0 }}>
          <FormLabel htmlFor="treatment_period_results">刺激法</FormLabel>
          <Form.Select
            placeholder="選択してください"
            options={STIMULATION_METHODS}
            clearable
            value={formValues[0]}
            onChange={(evt, { value }) => {
              const newValues = formValues.map(formValue => formValue);
              newValues[0] = value;
              setFormValues(newValues);
              const isValid = validateStimulationMedicines(newValues);
              if (isValid) {
                const requestPayload = BUILD_STIMULATION_MEDICINE_PAYLOAD(
                  ...newValues,
                );
                updateFormValues('medicine', requestPayload);
              }
              isFormValid(1, isValid);
            }}
          />
        </FormField>
      </Form.Group>
      <Form.Group>
        <FormField inline style={{ paddingRight: 0 }}>
          <FormLabel htmlFor="treatment_period_results">卵巣刺激薬剤</FormLabel>
          <Form.Select
            placeholder="選択してください"
            value={formValues[1]}
            multiple
            options={OVARIAN_STIMULATION_MEDICINE}
            onChange={(evt, { value }) => {
              const newValues = formValues.map(formValue => formValue);
              newValues[1] = value.map(elm => elm);
              if (!isValueInArray(newValues[1], 15)) {
                newValues[5] = '';
              }
              setFormValues(newValues);
              const isValid = validateStimulationMedicines(newValues);
              if (isValid) {
                const requestPayload = BUILD_STIMULATION_MEDICINE_PAYLOAD(
                  ...newValues,
                );
                updateFormValues('medicine', requestPayload);
              }
              isFormValid(1, isValid);
            }}
          />
        </FormField>
      </Form.Group>
      {isValueInArray(formValues[1], 15) && (
        <Form.Group style={{ marginBottom: 14 }} id="drugs_usage_form">
          <Form.Field
            required
            control={TextArea}
            placeholder=""
            label="その他の卵巣刺激薬剤"
            value={formValues[5]}
            onChange={(evt, { value }) => {
              const newValues = formValues.map(formValue => formValue);
              newValues[5] = value;
              setFormValues(newValues);
              const isValid = validateStimulationMedicines(newValues);
              if (isValid) {
                const requestPayload = BUILD_STIMULATION_MEDICINE_PAYLOAD(
                  ...newValues,
                );
                updateFormValues('medicine', requestPayload);
              }
              isFormValid(1, isValid);
            }}
          />
        </Form.Group>
      )}
      <Form.Group>
        <FormField inline style={{ paddingRight: 0 }}>
          <FormLabel htmlFor="treatment_period_results">排卵抑制薬剤</FormLabel>
          <Form.Select
            placeholder="選択してください"
            multiple
            value={formValues[2]}
            options={OVARIAN_INHIBITORS}
            onChange={(evt, { value }) => {
              const newValues = formValues.map(formValue => formValue);
              newValues[2] = value.map(elm => elm);
              if (!isValueInArray(newValues[2], 4)) {
                newValues[6] = '';
              }
              setFormValues(newValues);
              const isValid = validateStimulationMedicines(newValues);
              if (isValid) {
                const requestPayload = BUILD_STIMULATION_MEDICINE_PAYLOAD(
                  ...newValues,
                );
                updateFormValues('medicine', requestPayload);
              }
              isFormValid(1, isValid);
            }}
          />
        </FormField>
      </Form.Group>
      {isValueInArray(formValues[2], 4) && (
        <Form.Group style={{ marginBottom: 14 }} id="drugs_usage_form">
          <Form.Field
            required
            control={TextArea}
            placeholder=""
            label="その他の排卵抑制薬剤"
            value={formValues[6]}
            onChange={(evt, { value }) => {
              const newValues = formValues.map(formValue => formValue);
              newValues[6] = value;
              setFormValues(newValues);
              const isValid = validateStimulationMedicines(newValues);
              if (isValid) {
                const requestPayload = BUILD_STIMULATION_MEDICINE_PAYLOAD(
                  ...newValues,
                );
                updateFormValues('medicine', requestPayload);
              }
              isFormValid(1, isValid);
            }}
          />
        </Form.Group>
      )}
      <Form.Group>
        <FormField inline style={{ paddingRight: 0 }}>
          <FormLabel htmlFor="treatment_period_results">
            LHサージ誘起薬剤
          </FormLabel>
          <Form.Select
            placeholder="選択してください"
            multiple
            value={formValues[3]}
            options={INDUCER_MEDICINE}
            onChange={(evt, { value }) => {
              const newValues = formValues.map(formValue => formValue);
              newValues[3] = value.map(elm => elm);
              if (!isValueInArray(newValues[3], 4)) {
                newValues[7] = '';
              }
              setFormValues(newValues);
              const isValid = validateStimulationMedicines(newValues);
              if (isValid) {
                const requestPayload = BUILD_STIMULATION_MEDICINE_PAYLOAD(
                  ...newValues,
                );
                updateFormValues('medicine', requestPayload);
              }
              isFormValid(1, isValid);
            }}
          />
        </FormField>
      </Form.Group>

      {isValueInArray(formValues[3], 4) && (
        <Form.Group style={{ marginBottom: 14 }} id="drugs_usage_form">
          <Form.Field
            required
            control={TextArea}
            placeholder=""
            label="その他のLHサージ誘起薬剤"
            value={formValues[7]}
            onChange={(evt, { value }) => {
              const newValues = formValues.map(formValue => formValue);
              newValues[7] = value;
              setFormValues(newValues);
              const isValid = validateStimulationMedicines(newValues);
              if (isValid) {
                const requestPayload = BUILD_STIMULATION_MEDICINE_PAYLOAD(
                  ...newValues,
                );
                updateFormValues('medicine', requestPayload);
              }
              isFormValid(1, isValid);
            }}
          />
        </Form.Group>
      )}
      <Form.Group>
        <Form.Field style={{ paddingRight: 0 }}>
          <FormLabel htmlFor="other_stimulation">
            上記以外の種類の薬剤
          </FormLabel>
          <Form.TextArea
            name="other_stimulation"
            placeholder="OHSS予防薬、ホルモン療法剤など"
            value={formValues[4]}
            onChange={(evt, { value }) => {
              const newValues = formValues.map(formValue => formValue);
              newValues[4] = value;
              setFormValues(newValues);
              const isValid = validateStimulationMedicines(newValues);
              if (isValid) {
                const requestPayload = BUILD_STIMULATION_MEDICINE_PAYLOAD(
                  ...newValues,
                );
                updateFormValues('medicine', requestPayload);
              }
              isFormValid(1, isValid);
            }}
          />
        </Form.Field>
      </Form.Group>
    </>
  );
};
OpuStimulationForm.propTypes = {
  loadedData: PropTypes.object,
  isFormValid: PropTypes.func.isRequired,
  updateFormValues: PropTypes.func.isRequired,
};
OpuStimulationForm.defaultProps = {
  loadedData: null,
};
export default OpuStimulationForm;
