/** Request handling functions
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
// import history from 'utils/history';

function parseJSON(response) {
  if (!response || response.statusText === 'No Content') return null;
  if (response.status === 400) return { errors: ['Bad Request'] };
  if (response.status === 500) {
    return {
      errors: ['Internal Server Error'],
      messages: 'Internal Server Error',
      status: 500,
    };
  }
  if (response.status === 401) {
    return {
      errors: ['Token error'],
      messages: 'token',
      status: 401,
    };
  }
  if (response.status === 503) {
    return {
      errors: ['Maintenance mode'],
      messages: 'Maintenance mode',
      status: 503,
    };
  }
  return response.json();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  if (response && response.status >= 200 && response.status < 300) {
    return response;
  }
  return response;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(url, options) {
  return fetch(url, options)
    .then(checkStatus)
    .catch(e => ({
      status: e,
    }))
    .then(parseJSON)
    .catch(e => ({
      status: e,
    }));
}
