import Cookies from 'universal-cookie';

import { DICTIONARY, J_ENG_DICTIONARY } from 'utils/dictionary';
import { SUBFILTERS_NAMES } from 'utils/chartFilters';
import { urls } from 'utils/urls';
import request from 'utils/request';
import { CHART_DATASET_LABELS } from 'components/ChartCard/constants';

import {
  BAR_COLORS,
  BAR_MIXED_COLORS,
  GRADIENT_COLORS,
  INFERTILITY_COLORS,
  RADAR_COLORS,
} from 'containers/ChartPage/colors';
import {
  BAR_LINE,
  BAR,
  BAR_V2,
  CLINICS_TABLE,
  CLINICS_BARS,
  HEATMAP,
  BAR_LABELED,
  RADAR,
  SEMEN_EXAMINATION_RADAR,
  RESULT_MATRIX,
} from 'components/Graph/constants';

/* This file contains functions to parse the data of the charts
 ** before plotting. */
export const buildStatsData = (datasets, colors, labels, datasetLabels) => {
  const newDatasets = datasets.map((dataset, index) => ({
    label: datasetLabels[index],
    backgroundColor: colors[index],
    borderColor: colors[index],
    borderWidth: 1,
    hoverBackgroundColor: colors[index],
    hoverBorderColor: colors[index],
    data: dataset,
  }));
  const data = {
    labels,
    datasets: newDatasets,
  };
  return data;
};

// const getRadarLabel = (type, index, keysList, labelList) => keysList[index];
export const buildResponseRadarData = (
  datasets,
  colors,
  type,
  keysList,
  labelList,
) => {
  const newDatasets = datasets.map((dataset, index) => ({
    label: labelList[index],
    backgroundColor: 'transparent',
    borderColor: colors[index],
    borderWidth: 1,
    hoverBackgroundColor: '#fff',
    hoverBorderColor: colors[index],
    pointBackgroundColor: colors[index],
    pointHoverBackgroundColor: colors[index],
    data: dataset,
    fill: true,
  }));
  const data = {
    labels: keysList,
    datasets: newDatasets,
  };
  return data;
};

export const buildStatsDatasets = response => {
  // IDEA:  : change API response , performance improvement
  const keyList = Object.keys(response);
  const categoryNumber = response[keyList[0]].length;
  const datasets = [...Array.from(Array(categoryNumber))].map((elm, index) => {
    const categoryValues = keyList.map(key => {
      const category = response[key];
      if (category) return Math.round(category[index] * 100, 0);
      return 0;
    });
    return categoryValues;
  });
  return { labels: keyList, datasets };
};

export const buildRadarData = (response, colors, type = 1) => {
  const responseList = Object.keys(response);
  const keysList = Object.keys(response[responseList[0]]);
  const datasets = responseList.map(key => {
    const attributes = Object.keys(response[key]);
    const data = attributes.map(attribute => response[key][attribute]);
    return data;
  });
  return buildResponseRadarData(datasets, colors, type, keysList, responseList);
};

export const buildHeatmapData = response => {
  const responseKeys = Object.keys(response);
  const datasets = responseKeys.map(key => {
    const data = Object.keys(response[key]).map(attrKey => ({
      x: attrKey,
      y: response[key][attrKey],
    }));
    return {
      name: key,
      data,
    };
  });
  return datasets.reverse();
};

export const buildMixedData = (
  response,
  colors,
  type = 'barMixed',
  firstAxis = 'line',
) => {
  const data = {};
  data.labels = type === 'barMixed' ? response.cycles : response.ranges;
  data.datasets = response.datasets.map(elm => elm);
  let indexLine = 0;
  let indexBar = 0;
  data.datasets.forEach((dataset, index) => {
    let chartTypeIndex = indexBar;
    if (dataset.datasetType === firstAxis) {
      data.datasets[index].data = dataset.data.map(elm =>
        Math.round(elm * 100),
      );
      chartTypeIndex = indexLine;
      data.datasets[index].yAxisID = 'y-axis-1';
    } else data.datasets[index].yAxisID = 'y-axis-2';
    data.datasets[index].xAxisID = 'x-axis';
    data.datasets[index].type = dataset.datasetType;
    data.datasets[index].color =
      colors[dataset.datasetType][chartTypeIndex].color;
    data.datasets[index].backgroundColor =
      colors[dataset.datasetType][chartTypeIndex].backgroundColor;
    data.datasets[index].borderColor =
      colors[dataset.datasetType][chartTypeIndex].borderColor;
    data.datasets[index].hoverColor =
      colors[dataset.datasetType][chartTypeIndex].hoverColor;
    if (dataset.datasetType === 'line') indexLine += 1;
    else indexBar += 1;
  });
  return data;
};

export const buildClinicsBarData = (response, colors) => {
  const data = {};
  data.labels = response.ranges;
  data.datasets = response.datasets.map(elm => elm);
  let indexBar = 0;
  data.datasets.forEach((dataset, index) => {
    const chartTypeIndex = indexBar;
    data.datasets[index].type = dataset.datasetType;
    data.datasets[index].color =
      colors[dataset.datasetType][chartTypeIndex].color;
    data.datasets[index].backgroundColor =
      colors[dataset.datasetType][chartTypeIndex].backgroundColor;
    data.datasets[index].borderColor =
      colors[dataset.datasetType][chartTypeIndex].borderColor;
    data.datasets[index].hoverColor =
      colors[dataset.datasetType][chartTypeIndex].hoverColor;
    indexBar += 1;
  });
  return data;
};

export const buildBarData = (response, colors) => {
  const data = {};
  data.labels = response.labels.map(elm => elm);
  data.datasets = response.datasets.map(elm => elm);
  data.datasets.forEach((dataset, index) => {
    data.datasets[index].data = dataset.data.map(elm => Math.round(elm * 100));
    data.datasets[index].backgroundColor = colors[index].backgroundColor;
    data.datasets[index].borderColor = colors[index].borderColor;
    data.datasets[index].hoverColor = colors[index].hoverColor;
  });
  return data;
};

export const buildResponsesBodies = (
  chartTypes,
  condition = '',
  activeSubFilters,
) =>
  chartTypes.map(chartType => {
    const requestBody = SUBFILTERS_NAMES.reduce(
      (newObject, subFilter, index) => {
        const key = DICTIONARY[subFilter];
        newObject[key] =
          activeSubFilters[index] !== ''
            ? DICTIONARY[activeSubFilters[index]]
            : '';
        return newObject;
      },
      {},
    );
    return {
      url: `${urls[chartType]}${condition}`,
      filterConditions: requestBody,
      chartType,
    };
  });

export const buildRequests = (
  activeChartParam,
  chartTypes,
  httpMethods,
  parameters,
  subFilters,
) => {
  let paramValue;
  if (chartTypes[0] === CLINICS_TABLE)
    paramValue = parameters[activeChartParam];
  else
    paramValue = parameters[activeChartParam]
      ? DICTIONARY[parameters[activeChartParam]]
      : '';
  const requestBodies = buildResponsesBodies(
    chartTypes,
    paramValue,
    subFilters,
  );
  const cookies = new Cookies();
  return httpMethods.map((method, index) => {
    const requestBody = requestBodies[index];
    if (method === 'GET') {
      return request(`${process.env.REACT_APP_API_URL}${requestBody.url}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          charset: 'UTF-8',
          Authorization: `Bearer ${cookies.get('user_token')}`,
        },
      });
    }
    return request(`${process.env.REACT_APP_API_URL}${requestBody.url}`, {
      method: 'POST',
      body: JSON.stringify(requestBody.filterConditions),
      headers: {
        'Content-Type': 'application/json',
        charset: 'UTF-8',
        Authorization: `Bearer ${cookies.get('user_token')}`,
      },
    });
  });
};

export const buildChartData = (response, chartType, activeChartType) => {
  switch (chartType) {
    case BAR:
      if (activeChartType < 3) {
        return buildBarData(response, BAR_COLORS);
      }
      return response;
    case BAR_V2:
      const processedBarData = buildStatsDatasets(response);
      const newLabels = processedBarData.labels.map(
        label => J_ENG_DICTIONARY[label],
      );
      return buildStatsData(
        processedBarData.datasets,
        GRADIENT_COLORS,
        newLabels,
        CHART_DATASET_LABELS[chartType],
      );
    case BAR_LABELED:
      const dataObject = buildStatsDatasets(response);
      const translatedLabels = dataObject.labels.map(
        label => J_ENG_DICTIONARY[label],
      );
      return buildStatsData(
        dataObject.datasets,
        INFERTILITY_COLORS,
        translatedLabels,
        activeChartType === 0 || activeChartType === 2
          ? CHART_DATASET_LABELS.stackedBarLabeled__1
          : CHART_DATASET_LABELS.stackedBarLabeled__2,
      );
    case BAR_LINE:
      return buildMixedData(response, BAR_MIXED_COLORS);
    case CLINICS_BARS:
      if (response && response.datasets) {
        const barDatasets = buildClinicsBarData(response, BAR_MIXED_COLORS);
        return barDatasets;
      }
      return response;
    case HEATMAP:
      return buildHeatmapData(response);
    case RESULT_MATRIX:
      const responseKeys = Object.keys(response);
      const infertilitySummary = responseKeys.map(
        responseKey => response[responseKey],
      );
      return infertilitySummary;
    case RADAR:
      return buildRadarData(response, RADAR_COLORS, 1);
    case SEMEN_EXAMINATION_RADAR:
      return buildRadarData(response, RADAR_COLORS, 2);
    default:
      return response;
  }
};
