import {
  ET_MATRIX,
  RADAR,
  BAR_LINE,
  DRUG_TABLE,
} from 'components/Graph/constants';
export const CHART_DATASET_LABELS = {
  stackedBarLabeled__1: ['受診', '未受診'],
  stackedBarLabeled__2: ['異常あり', '異常なし/その他'],
  stackedBarV2: [
    '0回',
    '1回',
    '2回',
    '3回',
    '4回',
    '5回',
    '6回',
    '7回',
    '8回',
    '9回',
    '10回以上',
  ],
};

export const checkInfertilityMode = chartType =>
  chartType !== ET_MATRIX &&
  chartType !== RADAR &&
  chartType !== BAR_LINE &&
  chartType !== DRUG_TABLE;

export const hasParamsOnCard = (key, chartType, filterNumber, sliderData) => {
  if (
    (key === 'infertility' &&
      chartType === 'stackedBarLabeled' &&
      filterNumber < sliderData.length) ||
    (key === 'etMatrix' &&
      chartType === 'stackedBar' &&
      filterNumber < sliderData.length) ||
    (key === 'stepup' &&
      chartType === 'radar' &&
      filterNumber < sliderData.length) ||
    (key === 'barMixed' &&
      chartType === 'heatmap' &&
      filterNumber < sliderData.length)
  )
    return true;
  return false;
};
