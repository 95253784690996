import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Grid, Tab } from 'semantic-ui-react';
import { validateRequiredFields } from 'utils/errors';
import {
  COAGULATION_FUNCTIONS,
  ANTIPHOSPHOLID_TYPES,
  ABNORMAL_TYPES,
  STATUS_OPTIONS,
} from 'utils/defaultOptions';
import { DEFAULT_IMPLANTATION_VALUES } from 'containers/RegistrationPage/constants';
import { FormField, FormLabel, FormPane, FormSelect } from '../formElements';

import MultipleSelectNothingSpecial from './MultipleSelectNothingSpecial';

const includedValues = [
  'blood_coagulation_function',
  'antiphospholipid_antibody_syndrome',
  'abnormal_glucose_tolerance',
];
const ImplantationForm = ({
  defaultFormValues,
  isFormValid,
  updateFormValues,
}) => {
  const [formValues, setFormValues] = useState(DEFAULT_IMPLANTATION_VALUES);
  const updateFormField = (
    label,
    value,
    isPrimitiveType = true,
    isMultipleSelect = false,
  ) => {
    const newFormValues = JSON.parse(JSON.stringify(formValues));
    if (isPrimitiveType) newFormValues[label] = value;
    else newFormValues[label] = value.map(elm => elm);
    if (
      isMultipleSelect &&
      label === includedValues[2] &&
      (newFormValues[label].includes(0) || newFormValues[label].includes(3))
    ) {
      if (newFormValues[label].includes(0)) {
        newFormValues[label] = [0];
      } else if (newFormValues[label].includes(3)) {
        newFormValues[label] = [3];
      }
    } else if (
      isMultipleSelect &&
      (label === includedValues[1] || label === includedValues[0]) &&
      (newFormValues[label].includes(0) || newFormValues[label].includes(5))
    ) {
      if (newFormValues[label].includes(0)) {
        newFormValues[label] = [0];
      } else if (newFormValues[label].includes(5)) {
        newFormValues[label] = [5];
      }
    }
    setFormValues(newFormValues);
    const isValid = validateRequiredFields(newFormValues);
    updateFormValues('implantation_problems', newFormValues);
    isFormValid(4, isValid);
  };
  useEffect(() => {
    if (defaultFormValues) {
      const newFormValues = JSON.parse(JSON.stringify(defaultFormValues));
      setFormValues(newFormValues);
    }
  }, [defaultFormValues]);
  return (
    <Form.Group>
      <Grid>
        <Grid.Row>
          <Grid.Column mobile={16} computer={7} tablet={16} floated="left">
            <FormField>
              <FormLabel htmlFor="implantation_window">
                子宮内膜受容能（着床の窓：ERA/ERPeak）
              </FormLabel>
              <FormSelect
                id="implantation_window"
                className="ninpath__select"
                placeholder="選択してください"
                options={STATUS_OPTIONS}
                value={formValues.implantation_window}
                onChange={(evt, { value }) => {
                  updateFormField('implantation_window', value);
                }}
              />
            </FormField>
          </Grid.Column>
          <Grid.Column mobile={16} computer={7} tablet={16} floated="right">
            <FormField inline>
              <FormLabel htmlFor="chronic_endometritis">
                慢性子宮内膜炎（BCE/CD138）
              </FormLabel>
              <FormSelect
                id="chronic_endometritis"
                className="ninpath__select"
                placeholder="選択してください"
                options={STATUS_OPTIONS}
                value={formValues.chronic_endometritis}
                onChange={(evt, { value }) => {
                  updateFormField('chronic_endometritis', value);
                }}
              />
            </FormField>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} computer={7} tablet={16} floated="left">
            <FormField inline>
              <FormLabel htmlFor="bacterial_environment">
                子宮内の細菌環境（EMA/ALICE）
              </FormLabel>
              <FormSelect
                id="bacterial_environment"
                className="ninpath__select"
                placeholder="選択してください"
                options={STATUS_OPTIONS}
                value={formValues.bacterial_environment}
                onChange={(evt, { value }) => {
                  updateFormField('bacterial_environment', value);
                }}
              />
            </FormField>
          </Grid.Column>
          <Grid.Column mobile={16} computer={7} tablet={16} floated="right">
            <FormField inline>
              <FormLabel htmlFor="intrauterine_flora">子宮内フローラ</FormLabel>
              <FormSelect
                id="intrauterine_flora"
                className="ninpath__select"
                placeholder="選択してください"
                options={STATUS_OPTIONS}
                value={formValues.intrauterine_flora}
                onChange={(evt, { value }) => {
                  updateFormField('intrauterine_flora', value);
                }}
              />
            </FormField>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} computer={7} tablet={16} floated="left">
            <FormField inline>
              <FormLabel htmlFor="uterine_contraction">
                黄体期の子宮収縮
              </FormLabel>
              <FormSelect
                id="uterine_contraction"
                className="ninpath__select"
                placeholder="選択してください"
                options={STATUS_OPTIONS}
                value={formValues.uterine_contraction}
                onChange={(evt, { value }) => {
                  updateFormField('uterine_contraction', value);
                }}
              />
            </FormField>
          </Grid.Column>
          <Grid.Column mobile={16} computer={7} tablet={16} floated="right">
            <FormField inline>
              <FormLabel htmlFor="abnormal_immune">
                免疫異常（Th1/Th2など）
              </FormLabel>
              <FormSelect
                id="abnormal_immune"
                className="ninpath__select"
                placeholder="選択してください"
                options={STATUS_OPTIONS}
                value={formValues.abnormal_immune}
                onChange={(evt, { value }) => {
                  updateFormField('abnormal_immune', value);
                }}
              />
            </FormField>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} computer={7} tablet={16} floated="left">
            <FormField inline>
              <FormLabel htmlFor="chromosome_examination">
                夫婦の染色体検査（転座など）
              </FormLabel>
              <FormSelect
                id="chromosome_examination"
                className="ninpath__select"
                placeholder="選択してください"
                options={STATUS_OPTIONS}
                value={formValues.chromosome_examination}
                onChange={(evt, { value }) => {
                  updateFormField('chromosome_examination', value);
                }}
              />
            </FormField>
          </Grid.Column>
          <Grid.Column mobile={16} computer={7} tablet={16} floated="right">
            <FormField inline>
              <FormLabel htmlFor="blood_coagulation">
                血液凝固機能異常
              </FormLabel>

              <MultipleSelectNothingSpecial
                id="blood_coagulation"
                name="blood_coagulation_function"
                options={COAGULATION_FUNCTIONS}
                submitValue={formValues.blood_coagulation_function}
                changeFunction={updateFormField}
                additionalExclusionNumber={5}
              />
            </FormField>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} computer={7} tablet={16} floated="left">
            <FormField inline>
              <FormLabel htmlFor="antiphospholipid_antibody_syndrome">
                抗リン脂質抗体症候群
              </FormLabel>

              <MultipleSelectNothingSpecial
                id="antiphospholipid_antibody_syndrome"
                name="antiphospholipid_antibody_syndrome"
                options={ANTIPHOSPHOLID_TYPES}
                submitValue={formValues.antiphospholipid_antibody_syndrome}
                changeFunction={updateFormField}
                additionalExclusionNumber={5}
              />
            </FormField>
          </Grid.Column>
          <Grid.Column mobile={16} computer={7} tablet={16} floated="right">
            <FormField inline>
              <FormLabel htmlFor="abnormal_glucose_tolerance">
                耐糖能異常
              </FormLabel>
              <MultipleSelectNothingSpecial
                id="abnormal_glucose_tolerance"
                name="abnormal_glucose_tolerance"
                options={ABNORMAL_TYPES}
                submitValue={formValues.abnormal_glucose_tolerance}
                changeFunction={updateFormField}
                additionalExclusionNumber={3}
              />
            </FormField>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} computer={7} tablet={16} floated="left">
            <FormField inline>
              <FormLabel htmlFor="memo">メモ</FormLabel>
              <Form.TextArea
                name="memo"
                placeholder="メモ"
                value={formValues.memo !== null ? formValues.memo : ''}
                onChange={(evt, { value }) => {
                  updateFormField('memo', value);
                }}
              />
            </FormField>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form.Group>
  );
};

const getForms = (defaultFormValues, isFormValid, updateFormValues) => [
  {
    menuItem: '着床不全・着床障害・不育症',
    render: () => (
      <FormPane attached={false}>
        <ImplantationForm
          isFormValid={isFormValid}
          updateFormValues={updateFormValues}
          defaultFormValues={defaultFormValues}
        />
      </FormPane>
    ),
  },
];
const Implantation = ({ isFormValid, updateFormValues, defaultFormValues }) => (
  <Tab
    panes={getForms(defaultFormValues, isFormValid, updateFormValues)}
    menu={{ secondary: true, pointing: true }}
  />
);

ImplantationForm.propTypes = {
  isFormValid: PropTypes.func.isRequired,
  defaultFormValues: PropTypes.any,
  updateFormValues: PropTypes.func.isRequired,
};
Implantation.propTypes = {
  defaultFormValues: PropTypes.any.isRequired,
  isFormValid: PropTypes.func.isRequired,
  updateFormValues: PropTypes.func.isRequired,
};
export default Implantation;
