import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { MAIN_COLOR } from 'containers/ChartPage/colors';
import { areSameValues, ErrorMessage, isValidField } from 'utils/errors';
import { Card, Checkbox, Form, Grid, Message } from 'semantic-ui-react'; // main component
import { LayoutGrid, LayoutContainer } from 'utils/layouts';
import { ExpiredTokenPage } from 'containers/ErrorPage/ExpiredToken';
import {
  showLoader,
  hideLoader,
  ninpathCheckLoginSuccessful,
} from 'containers/TopPage/actions';
import request from 'utils/request';
import history from 'utils/history';

const AmhRegistrationConfirmationPage = ({
  location,
  amhLogin,
  finishLoading,
  startLoading,
}) => {
  const cookies = new Cookies();
  const [pageErrorMessage, setPageErrorMessage] = useState('');
  const [hasTokenExpired, setShowErrorPage] = useState(undefined);
  const [
    registrationConfirmationToken,
    setRegisterConfirmationToken,
  ] = useState(null);

  const [termsAccepted, checkTerms] = useState(false);
  const [errors, setErrors] = useState([]);
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  // here will be the api that will have the user
  const onRegisterUser = () => {
    startLoading({ value: true });
    const obj = { user: { password } };
    const url = `${process.env.REACT_APP_API_URL}/api/v1/amh_users`;
    const promise = request(url, {
      method: 'POST',
      body: JSON.stringify(obj),
      headers: {
        'Content-Type': 'application/json',
        charset: 'UTF-8',
        'X-Confirmation-Token': registrationConfirmationToken,
      },
    });
    promise.then(response => {
      if (response.errors) {
        setErrors(response.errors);
      } else {
        cookies.set('user_token', response.auth_token, { path: '/' });
        cookies.set('user_name', response.amh_user.name, { path: '/' });
        cookies.set('amh_user_id', response.amh_user.id, { path: '/' });
        amhLogin();
        history.push('/ninpath-check/info');
      }
      finishLoading({ value: true });
    });
  };
  useEffect(() => {
    const matches = location.search.match('/?token=(.*)');
    const token = matches && matches.length > 1 ? matches[1] : null;
    const url = `${process.env.REACT_APP_API_URL}/api/v1/amh_email_confirmation/verify_token`;
    const promise = request(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        charset: 'UTF-8',
        'X-Confirmation-Token': token,
      },
    });
    promise.then(response => {
      if (!response.valid || !response) {
        setPageErrorMessage(
          response.errors && response.errors.length > 0
            ? response.errors[0]
            : 'エラー',
        );
        setShowErrorPage(true);
      } else {
        setRegisterConfirmationToken(token);
        setShowErrorPage(false);
      }
    });
  }, [location]);
  if (hasTokenExpired !== undefined && hasTokenExpired) {
    finishLoading({ value: true });
    return (
      <ExpiredTokenPage
        errorMessage={pageErrorMessage}
        tokenType="amh_registration"
      />
    );
  }
  if (hasTokenExpired === undefined) {
    startLoading({ loading: true });
  }
  if (hasTokenExpired !== undefined && !hasTokenExpired) {
    finishLoading({ value: true });

    return (
      <LayoutContainer style={{ marginTop: '70px', height: '100%' }}>
        <LayoutGrid>
          <Grid.Row columns={1}>
            <Grid.Column mobile={16} tablet={10} computer={10}>
              <Card
                style={{ width: '100%', paddingTop: 14 }}
                className="card__registration"
              >
                <Card.Content>
                  <Card.Header>
                    <h3
                      style={{
                        paddingLeft: 14,
                        paddingBottom: 14,
                        fontWeight: 500,
                      }}
                    >
                      ninpathチェック　本登録
                    </h3>
                  </Card.Header>
                  <Form error>
                    <Form.Group>
                      <Grid centered>
                        <Grid.Row>
                          <Grid.Column
                            mobile={16}
                            computer={16}
                            tablet={16}
                            floated="left"
                          >
                            <Form.Input
                              label="パスワード (英数字大文字含む, 8文字以上)"
                              placeholder="パスワード (英数字大文字含む, 8文字以上)"
                              type="password"
                              value={password}
                              onChange={evt => setPassword(evt.target.value)}
                              width={16}
                              className="form__password"
                              style={{
                                marginBottom: 14,
                              }}
                              required
                            />
                            {!isValidField('password', password) && (
                              <Message
                                error
                                header="エラー"
                                content="パスワード (英数字大文字含む, 8文字以上)"
                              />
                            )}
                          </Grid.Column>
                          <Grid.Column
                            mobile={16}
                            computer={16}
                            tablet={16}
                            floated="right"
                            id="password__confirmation"
                          >
                            <Form.Input
                              className="form__password form__password__confirmation"
                              placeholder="確認用パスワード"
                              type="password"
                              width={16}
                              value={passwordConfirm}
                              onChange={evt =>
                                setPasswordConfirm(evt.target.value)
                              }
                            />
                            {!areSameValues(password, passwordConfirm) && (
                              <Message
                                error
                                header="エラー"
                                className="confirmation__error"
                                content="パスワードが一致しません"
                              />
                            )}
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Form.Group>
                    <div className="terms" style={{ paddingTop: '14px' }}>
                      <Form.Field
                        label={
                          <label htmlFor="terms">
                            <a
                              href="https://dist.ninpath.com/tou.html"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="ninpath__form__terms__of__service"
                            >
                              利用規約
                            </a>
                            に同意する
                          </label>
                        }
                        checked={termsAccepted}
                        control={Checkbox}
                        style={{ textAlign: 'center' }}
                        onChange={(evt, { checked }) => {
                          checkTerms(checked);
                        }}
                      />
                    </div>

                    <div className="button_form_container">
                      <Form.Button
                        className="ninpath__form__container"
                        style={{ minWidth: '80px', maxWidth: '120px' }}
                        disabled={
                          !termsAccepted ||
                          !isValidField('password', password) ||
                          !areSameValues(password, passwordConfirm)
                        }
                        onClick={onRegisterUser}
                      >
                        登録する
                      </Form.Button>
                      {!termsAccepted && (
                        <ErrorMessage color={MAIN_COLOR}>
                          必須項目が未入力の場合はボタンが押せません
                        </ErrorMessage>
                      )}

                      {errors.length > 0 && (
                        <Message negative>
                          <Message.Header>
                            登録できませんでした。
                          </Message.Header>
                          {errors.map(error => (
                            <p key={error}>{error}</p>
                          ))}
                        </Message>
                      )}
                    </div>
                  </Form>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
        </LayoutGrid>
      </LayoutContainer>
    );
  }
  return <div />;
};
const mapDispatchToProps = dispatch => ({
  amhLogin: () => dispatch(ninpathCheckLoginSuccessful()),
  startLoading: payload => dispatch(showLoader(payload)),
  finishLoading: payload => dispatch(hideLoader(payload)),
});

export default connect(
  null,
  mapDispatchToProps,
)(React.memo(withRouter(AmhRegistrationConfirmationPage)));

AmhRegistrationConfirmationPage.propTypes = {
  location: PropTypes.object.isRequired,
  amhLogin: PropTypes.any.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};
