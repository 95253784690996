import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Form, Grid, Tab } from 'semantic-ui-react';
import { validateRequiredFields } from 'utils/errors';
import {
  FormField,
  FormInput,
  FormLabel,
  FormSelect,
  FormPane,
} from 'containers/RegistrationPage/formElements';
import {
  YES_NO_QUESTION,
  MENSTRUAL_TYPES,
  OVARIAN_DISEASES_HISTORY,
  PREVIOUS_PREGNANCY,
  STATUS_TYPES,
  TREATMENT_TYPES,
  TYROID_TYPES,
  UTERINE_DISEASES_HISTORY,
} from 'utils/defaultOptions';

import PopupInfo from 'components/PopupInfo';
import MultipleSelect from './MultipleSelect';
import MultipleSelectNothingSpecial from './MultipleSelectNothingSpecial';

const InfertilityHistoryForm = ({
  defaultFormValues,
  isFormValid,
  updateFormValues,
}) => {
  const [examinationStatus, setExaminationStatus] = useState(true);
  const [showMiscarriage, setShowMiscarriage] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [firstTimeTreatment, setFirstTimeTreatment] = useState(false);
  const updateFormField = (label, value, isPrimitiveType = true) => {
    const newFormValues = JSON.parse(JSON.stringify(formValues));
    if (isPrimitiveType) newFormValues[label] = value;
    else newFormValues[label] = value.map(elm => elm);
    setFormValues(newFormValues);

    // special validation for AMH, this value is not registered in the BD.
    // if its empty but will change to 未検査 then it's valid
    const newFormValuesTemp = JSON.parse(JSON.stringify(newFormValues));
    if (
      (newFormValuesTemp.amh === '' || newFormValuesTemp.amh === null) &&
      examinationStatus === false
    ) {
      newFormValuesTemp.amh = -1;
    }
    if (newFormValuesTemp.number_of_pregnancy > 0) {
      setShowMiscarriage(true);
    } else {
      setShowMiscarriage(false);
      newFormValuesTemp.number_of_miscarriage_lt_12w = null;
      newFormValuesTemp.number_of_miscarriage_gte_12w = null;
      newFormValuesTemp.number_of_childbirth = null;
      newFormValues.number_of_miscarriage_lt_12w = null;
      newFormValues.number_of_miscarriage_gte_12w = null;
      newFormValues.number_of_childbirth = null;
    }

    const isValid = validateRequiredFields(newFormValuesTemp);
    updateFormValues('infertility_treatment_history', newFormValues);
    isFormValid(1, isValid);
  };
  useEffect(() => {
    if (defaultFormValues) {
      const newFormValues = JSON.parse(JSON.stringify(defaultFormValues));
      if (newFormValues.amh > 0) setExaminationStatus(true);
      else if (newFormValues.amh === null) {
        newFormValues.amh = '';
        setExaminationStatus(false);
      }
      setFormValues(newFormValues);
      if (defaultFormValues.primary_infertility === 0) {
        setFirstTimeTreatment(false);
      } else {
        setFirstTimeTreatment(true);
      }
      if (defaultFormValues.number_of_pregnancy > 0) {
        setShowMiscarriage(true);
      } else {
        setShowMiscarriage(false);
      }
    }
  }, [defaultFormValues]);
  return (
    <Form.Group>
      {formValues && (
        <Grid centered>
          <Grid.Row>
            <Grid.Column mobile={16} computer={7} tablet={16} floated="left">
              <FormField inline required>
                <FormLabel htmlFor="first_time_treatment">
                  一人目の治療ですか？
                </FormLabel>
                <FormSelect
                  id="first_time_treatment"
                  placeholder="選択してください"
                  className="ninpath__select"
                  value={formValues.primary_infertility}
                  options={YES_NO_QUESTION}
                  onChange={(evt, { value }) => {
                    if (value === 1) {
                      setFirstTimeTreatment(true);
                      formValues.previous_pregnancy = null;
                      setFormValues(formValues);
                    } else {
                      setFirstTimeTreatment(false);
                    }
                    updateFormField('primary_infertility', value);
                  }}
                />
              </FormField>
            </Grid.Column>
            {!firstTimeTreatment && (
              <Grid.Column mobile={16} computer={7} tablet={16} floated="right">
                <FormField inline required>
                  <FormLabel htmlFor="previous_pregnancy">
                    以前のご出産の妊娠方法
                  </FormLabel>
                  <FormSelect
                    id="previous_pregnancy"
                    placeholder="選択してください"
                    className="ninpath__select"
                    value={formValues.previous_pregnancy}
                    options={PREVIOUS_PREGNANCY}
                    onChange={(evt, { value }) => {
                      updateFormField('previous_pregnancy', value);
                    }}
                  />
                </FormField>
              </Grid.Column>
            )}
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} computer={7} tablet={16} floated="left">
              <FormField inline required>
                <FormLabel htmlFor="age">
                  検査・治療を目的に通院を開始した時の女性側の年齢
                </FormLabel>
                <FormInput
                  id="age"
                  type="number"
                  min="16"
                  placeholder="16"
                  value={formValues.woman_age_started_to_go_clinic}
                  onChange={evt => {
                    updateFormField(
                      'woman_age_started_to_go_clinic',
                      evt.target.value,
                    );
                  }}
                />
              </FormField>
            </Grid.Column>
            <Grid.Column mobile={16} computer={7} tablet={16} floated="right">
              <FormField required>
                <FormLabel htmlFor="most_advanced_treatment_experience">
                  これまでに経験した治療
                </FormLabel>
                <MultipleSelect
                  id="most_advanced_treatment_experience"
                  name="most_advanced_treatment_experience"
                  options={TREATMENT_TYPES}
                  submitValue={formValues.most_advanced_treatment_experience}
                  changeFunction={updateFormField}
                />
              </FormField>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} computer={7} tablet={16} floated="left">
              <FormField required>
                <FormLabel htmlFor="status_types">
                  <span>現状のステータス</span>
                  <PopupInfo fieldName="treatmentStatus" />
                </FormLabel>
                <FormSelect
                  id="status_types"
                  placeholder="選択してください"
                  className="ninpath__select"
                  options={STATUS_TYPES}
                  value={formValues.current_status}
                  onChange={(evt, { value }) => {
                    updateFormField('current_status', value);
                  }}
                />
              </FormField>
            </Grid.Column>
            <Grid.Column mobile={16} computer={7} tablet={16} floated="right">
              <FormField>
                <FormLabel htmlFor="treatment_stats">
                  治療をストップ（卒業・中止・終了）した女性側の年齢
                </FormLabel>
                <FormInput
                  id="treatment_stats"
                  type="number"
                  min="16"
                  placeholder="16"
                  value={formValues.woman_age_stopped_treatment || ''}
                  onChange={evt => {
                    updateFormField(
                      'woman_age_stopped_treatment',
                      evt.target.value,
                    );
                  }}
                />
              </FormField>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} computer={7} tablet={16} floated="left">
              <FormField required>
                <FormLabel htmlFor="number_of_timing_method">
                  タイミング法実施回数
                </FormLabel>
                <FormInput
                  id="number_of_timing_method"
                  type="number"
                  min="0"
                  placeholder="0"
                  value={formValues.number_of_timing_method}
                  onChange={evt => {
                    updateFormField(
                      'number_of_timing_method',
                      evt.target.value,
                    );
                  }}
                />
              </FormField>
            </Grid.Column>
            <Grid.Column mobile={16} computer={7} tablet={16} floated="right">
              <FormField inline required>
                <FormLabel htmlFor="aih">人工授精（AIH/IUI）実施回数</FormLabel>
                <FormInput
                  id="aih"
                  type="number"
                  min="0"
                  placeholder="0"
                  value={formValues.number_of_aih}
                  onChange={evt => {
                    updateFormField('number_of_aih', evt.target.value);
                  }}
                />
              </FormField>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} computer={7} tablet={16} floated="left">
              <FormField required>
                <FormLabel htmlFor="numEggs">採卵回数</FormLabel>
                <FormInput
                  id="numEggs"
                  type="number"
                  placeholder="0"
                  min="0"
                  value={formValues.number_of_egg_collection}
                  onChange={evt => {
                    updateFormField(
                      'number_of_egg_collection',
                      evt.target.value,
                    );
                  }}
                />
              </FormField>
            </Grid.Column>
            <Grid.Column mobile={16} computer={7} tablet={16} floated="right">
              <FormField inline required>
                <FormLabel htmlFor="numTransplants">移植回数</FormLabel>
                <FormInput
                  id="numTransplants"
                  type="number"
                  placeholder="0"
                  min="0"
                  value={formValues.number_of_transfer}
                  onChange={evt => {
                    updateFormField('number_of_transfer', evt.target.value);
                  }}
                />
              </FormField>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} computer={7} tablet={16} floated="left">
              <FormField required>
                <FormLabel htmlFor="numEggs">化学流産の回数</FormLabel>
                <FormInput
                  id="numEggs"
                  type="number"
                  placeholder="0"
                  min="0"
                  value={formValues.number_of_chemical_miscarriage}
                  onChange={evt => {
                    updateFormField(
                      'number_of_chemical_miscarriage',
                      evt.target.value,
                    );
                  }}
                />
              </FormField>
            </Grid.Column>
            <Grid.Column mobile={16} computer={7} tablet={16} floated="right">
              <FormField inline required>
                <FormLabel htmlFor="numTransplants">妊娠の回数</FormLabel>
                <FormInput
                  id="numTransplants"
                  type="number"
                  placeholder="0"
                  min="0"
                  value={formValues.number_of_pregnancy}
                  onChange={evt => {
                    updateFormField('number_of_pregnancy', evt.target.value);
                  }}
                />
              </FormField>
            </Grid.Column>
          </Grid.Row>
          {showMiscarriage && (
            <>
              <Grid.Row>
                <Grid.Column mobile={16} computer={7} tablet={16} floated="left">
                  <FormField required>
                    <FormLabel htmlFor="numEggs">12週未満の流産回数</FormLabel>
                    <FormInput
                      id="numEggs"
                      type="number"
                      placeholder="0"
                      min="0"
                      value={formValues.number_of_miscarriage_lt_12w}
                      onChange={evt => {
                        updateFormField(
                          'number_of_miscarriage_lt_12w',
                          evt.target.value,
                        );
                      }}
                    />
                  </FormField>
                </Grid.Column>
                <Grid.Column mobile={16} computer={7} tablet={16} floated="right">
                  <FormField inline required>
                    <FormLabel htmlFor="numTransplants">12週以降の流産回数</FormLabel>
                    <FormInput
                      id="numTransplants"
                      type="number"
                      placeholder="0"
                      min="0"
                      value={formValues.number_of_miscarriage_gte_12w}
                      onChange={evt => {
                        updateFormField('number_of_miscarriage_gte_12w', evt.target.value);
                      }}
                    />
                  </FormField>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
              <Grid.Column mobile={16} computer={7} tablet={16} floated="left">
                <FormField required>
                  <FormLabel htmlFor="numEggs">出産回数</FormLabel>
                  <FormInput
                    id="numEggs"
                    type="number"
                    placeholder="0"
                    min="0"
                    value={formValues.number_of_childbirth}
                    onChange={evt => {
                      updateFormField(
                        'number_of_childbirth',
                        evt.target.value,
                      );
                    }}
                  />
                </FormField>
              </Grid.Column>
            </Grid.Row>
          </>
        )}
          <Grid.Row>
            <Grid.Column mobile={16} computer={7} tablet={16} floated="left">
              <FormField required>
                <FormLabel htmlFor="stability_of_menstrual_cycle">
                  <span>月経周期が安定しているか</span>
                  <PopupInfo fieldName="menstrualCycleStability" />
                </FormLabel>
                <FormSelect
                  id="stability_of_menstrual_cycle"
                  placeholder="選択してください"
                  className="ninpath__select"
                  options={MENSTRUAL_TYPES}
                  value={formValues.stability_of_menstrual_cycle}
                  onChange={(evt, { value }) => {
                    updateFormField('stability_of_menstrual_cycle', value);
                  }}
                />
              </FormField>
            </Grid.Column>
            <Grid.Column mobile={16} computer={7} tablet={16} floated="right">
              <FormField required>
                <FormLabel htmlFor="history_of_ovarian_disease">
                  卵巣・排卵機能に関する罹患歴
                </FormLabel>

                <MultipleSelectNothingSpecial
                  id="history_of_ovarian_disease"
                  name="history_of_ovarian_disease"
                  options={OVARIAN_DISEASES_HISTORY}
                  submitValue={formValues.history_of_ovarian_disease}
                  changeFunction={updateFormField}
                />
              </FormField>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} computer={7} tablet={16} floated="left">
              <FormField required>
                <FormLabel htmlFor="history_of_uterine_disease">
                  子宮に関する罹患歴
                </FormLabel>

                <MultipleSelectNothingSpecial
                  id="history_of_uterine_disease"
                  name="history_of_uterine_disease"
                  options={UTERINE_DISEASES_HISTORY}
                  submitValue={formValues.history_of_uterine_disease}
                  changeFunction={updateFormField}
                />
              </FormField>
            </Grid.Column>
            <Grid.Column mobile={16} computer={7} tablet={16} floated="right">
              <FormField required>
                <FormLabel htmlFor="thyroid_function">甲状腺機能</FormLabel>
                <MultipleSelectNothingSpecial
                  id="thyroid_function"
                  name="thyroid_function"
                  options={TYROID_TYPES}
                  submitValue={formValues.thyroid_function}
                  changeFunction={updateFormField}
                />
              </FormField>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column
              mobile={16}
              computer={7}
              tablet={16}
              floated="left"
              style={{
                display: 'flex',
                marginBottom: 14,
                alignItems: 'center',
              }}
            >
              <label htmlFor="no_examination" id="radio">
                <span>AMH(抗ミュラーリアンホルモン）値 (ng/ml.) </span>
                <PopupInfo fieldName="amh" />
                <sup style={{ color: '#db2828' }}>*</sup>
              </label>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                <Form.Field
                  name="no_examination"
                  label={<label htmlFor="no_examination">検査済み</label>}
                  control={Checkbox}
                  inline
                  radio
                  checked={examinationStatus === true}
                  onChange={() => {
                    // updateFormField('amh', '');
                    setExaminationStatus(true);
                  }}
                />
                <Form.Field
                  name="no_examination"
                  label={<label htmlFor="no_examination">未検査</label>}
                  control={Checkbox}
                  radio
                  inline
                  checked={examinationStatus === false}
                  onChange={() => {
                    formValues.amh = defaultFormValues.amh;
                    setExaminationStatus(false);
                    // updateFormField('amh', '');
                  }}
                />
              </div>
            </Grid.Column>
            {examinationStatus && (
              <Grid.Column
                mobile={16}
                computer={7}
                tablet={16}
                floated="left"
                id="amh__container"
                style={{
                  textAlign: 'right',
                  paddingBottom: 10,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <FormField inline required disabled={!examinationStatus}>
                  <FormInput
                    id="amh"
                    step="any"
                    type="number"
                    value={formValues.amh}
                    onChange={evt => {
                      updateFormField('amh', evt.target.value);
                    }}
                  />
                </FormField>
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      )}
    </Form.Group>
  );
};

const getForms = (defaultFormValues, isFormValid, updateFormValues) => [
  {
    menuItem: '不妊治療歴について',
    render: () => (
      <FormPane attached={false}>
        <InfertilityHistoryForm
          defaultFormValues={defaultFormValues}
          isFormValid={isFormValid}
          updateFormValues={updateFormValues}
        />
      </FormPane>
    ),
  },
];
const InfertilityHistory = ({
  defaultFormValues,
  isFormValid,
  updateFormValues,
}) => (
  <Tab
    panes={getForms(defaultFormValues, isFormValid, updateFormValues)}
    menu={{ secondary: true, pointing: true }}
  />
);

InfertilityHistoryForm.propTypes = {
  isFormValid: PropTypes.func.isRequired,
  defaultFormValues: PropTypes.any,
  updateFormValues: PropTypes.func.isRequired,
};
InfertilityHistory.propTypes = {
  defaultFormValues: PropTypes.any.isRequired,
  isFormValid: PropTypes.func.isRequired,
  updateFormValues: PropTypes.func.isRequired,
};

export default InfertilityHistory;
