import styled from 'styled-components';

const SidebarFooter = styled.p`
  font-size: 0.8rem;
  text-align: right;
  position: absolute;
  @media (min-height: 550px) {
    bottom: 10px;
    right: ${props => (props.position === 'absolute' ? '14px' : '14px')};
  }
  @media (max-width: 767px) {
    position: ${props => props.position};
  }
`;
export default SidebarFooter;
