import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Card, Container, Grid, Header, Segment } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { Padding, TopLabel } from '../Atoms';
import {
  AMH_STAT_VALUES,
  amhAgeToStat,
  amhAgeToStatIndex,
  LINE_OPTIONS,
} from '../../containers/AmhUserResultPage/constants';
import { AMH_COLORS } from '../../containers/ChartPage/colors';
import { AMH_RESULT } from '../../utils/urls';
import request from '../../utils/request';
import { logoutSuccesful, showLoader } from '../../containers/TopPage/actions';
import NinpathCheckResultBanner from '../../containers/AmhUserResultPage/banner';

const calcAge = (birthday, inspectionDate) => {
  const birthdayNumber =
    birthday.getFullYear() * 10000 +
    (birthday.getMonth() + 1) * 100 +
    birthday.getDate();
  const inspectionDateNumber =
    inspectionDate.getFullYear() * 10000 +
    (inspectionDate.getMonth() + 1) * 100 +
    inspectionDate.getDate();
  return Math.floor((inspectionDateNumber - birthdayNumber) / 10000);
};

const AmhChartCard = ({ logout }) => {
  const [amhValue, setAmhValue] = useState(null);
  const [status, setStatus] = useState(null);
  const [age, setAge] = useState(null);
  const [inspectionDate, setInspectionDate] = useState(null);
  const [stats, setStats] = useState(null);
  const [chartData, setChartData] = useState(null);

  const fetchAmhResult = async () => {
    const headerCookies = new Cookies();
    const userId = headerCookies.get('amh_user_id');
    const url = `${process.env.REACT_APP_API_URL}${AMH_RESULT}/${userId}`;
    const response = await request(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        charset: 'UTF-8',
        Authorization: `Bearer ${headerCookies.get('user_token')}`,
      },
    });
    if (response.errors && response.errors.length > 0) {
      logout();
    }

    const result = response;
    // const result = {
    //   inspection_date: '2021-01-01',
    //   birthday: '1989-01-01',
    //   amh: 3.45,
    //   status: 'success',
    //   // status: 'waiting',
    //   // status: 'nothing',
    // };
    if (result.status !== 'success') {
      setStatus(result.status);
      return;
    }
    const userAge = calcAge(
      new Date(result.birthday),
      new Date(result.inspection_date),
    );
    setAmhValue(result.amh);
    setAge(userAge);
    setInspectionDate(result.inspection_date.replaceAll('-', '.'));
    setStats(amhAgeToStat(userAge));

    const upper = AMH_STAT_VALUES.map(x => x.upper);
    const median = AMH_STAT_VALUES.map(x => x.median);
    const lower = AMH_STAT_VALUES.map(x => x.lower);
    const labels = AMH_STAT_VALUES.map(x => x.label);
    const labelIndex = amhAgeToStatIndex(userAge);
    const myData = {
      x: labels[labelIndex],
      y: result.amh,
      r: 5,
    };
    const myDataSet = Array(labels.length);
    myDataSet[labelIndex] = myData;
    setChartData({
      labels,
      datasets: [
        {
          type: 'bubble',
          label: 'あなた',
          data: myDataSet,
          borderColor: AMH_COLORS.me,
          backgroundColor: AMH_COLORS.me,
        },
        {
          type: 'line',
          label: '中央値',
          data: median,
          fill: false,
          borderColor: AMH_COLORS.median,
          backgroundColor: AMH_COLORS.fill,
        },
        {
          type: 'line',
          label: '95%区間上',
          data: upper,
          fill: false,
          borderColor: AMH_COLORS.upper,
          backgroundColor: AMH_COLORS.fill,
        },
        {
          type: 'line',
          label: '95%区間下',
          data: lower,
          fill: 2,
          borderColor: AMH_COLORS.lower,
          backgroundColor: AMH_COLORS.fill,
        },
      ],
    });
    setStatus(result.status);

    window.scrollTo(0, 0);
  };

  useEffect(() => {
    (async () => {
      await fetchAmhResult();
    })();
  }, []);

  return (
    <Card fluid className="ninpath__card">
      {status === 'success' && (
        <Card.Content
          className="ninpath__card__parameters"
          style={{ borderTop: 'none' }}
        >
          <Padding paddingLeft="14px" paddingRight="14px" paddingBottom="14px">
            <Container text style={{ paddingTop: 14, paddingBottom: 0 }}>
              <Header
                as="h3"
                style={{
                  fontWeight: 400,
                  border: 'none',
                  display: 'inline-flex',
                  borderBottom: '2px solid #000',
                  marginBottom: 14,
                }}
                attached="top"
              >
                今回の検査結果
              </Header>
              <Segment
                attached
                style={{ border: 'none', paddingBottom: 0 }}
                as={Grid}
              >
                <Grid.Row
                  style={{
                    borderBottom: '1px solid #E9E9E9',
                  }}
                >
                  <Grid.Column computer={4} mobile={6} tablet={5}>
                    <p style={{ textAlign: 'center' }}>検査日</p>
                  </Grid.Column>
                  <Grid.Column computer={12} mobile={10} tablet={11}>
                    <p style={{ textAlign: 'center' }}>
                      {inspectionDate} ({age}歳)
                    </p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row
                  style={{
                    borderBottom: '1px solid #E9E9E9',
                  }}
                >
                  <Grid.Column computer={4} mobile={6} tablet={5}>
                    <p style={{ textAlign: 'center' }}>AMH値</p>
                  </Grid.Column>
                  <Grid.Column computer={12} mobile={10} tablet={11}>
                    <p style={{ textAlign: 'center' }}>{amhValue}</p>
                  </Grid.Column>
                </Grid.Row>
              </Segment>
            </Container>
            <Container text style={{ paddingTop: 14, paddingBottom: 0 }}>
              <Header
                as="h3"
                style={{
                  fontWeight: 400,
                  border: 'none',
                  display: 'inline-flex',
                  borderBottom: '2px solid #000',
                  marginBottom: 14,
                }}
                attached="top"
              >
                あなたの年齢での基準値
              </Header>
              <Segment
                attached
                style={{ border: 'none', paddingBottom: 0 }}
                as={Grid}
              >
                <Grid.Row
                  style={{
                    borderBottom: '1px solid #E9E9E9',
                  }}
                >
                  <Grid.Column computer={4} mobile={6} tablet={5}>
                    <p style={{ textAlign: 'center' }}>基準値</p>
                  </Grid.Column>
                  <Grid.Column computer={12} mobile={10} tablet={11}>
                    <p style={{ textAlign: 'center' }}>
                      {stats.lower} ～ {stats.upper}
                    </p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row
                  style={{
                    borderBottom: '1px solid #E9E9E9',
                  }}
                >
                  <Grid.Column computer={4} mobile={6} tablet={5}>
                    <p style={{ textAlign: 'center' }}>中央値</p>
                  </Grid.Column>
                  <Grid.Column computer={12} mobile={10} tablet={11}>
                    <p style={{ textAlign: 'center' }}>{stats.median}</p>
                  </Grid.Column>
                </Grid.Row>
              </Segment>
            </Container>
            <Bar data={chartData} options={LINE_OPTIONS} height={300} />
            <div style={{ paddingTop: 14 }}>
              統計値の出典：平成 27～28 年度生殖・内分泌委員会
              生殖医療リスクマネージメント小委員会報告：抗ミュラー管ホルモン（AMH）の測定に関する留意事項：日産婦誌
              69：1721，2017．
            </div>
          </Padding>
          <NinpathCheckResultBanner />
        </Card.Content>
      )}
      {status === 'nothing' && (
        <>
          <Card.Header>
            <Padding paddingLeft="14px" paddingBottom="14px" paddingTop="14px">
              <TopLabel>今回の検査結果</TopLabel>
            </Padding>
          </Card.Header>
          <Card.Content>
            <div style={{ paddingTop: 14 }}>
              今回の検査では正しい結果が得られなかった為、検査結果を表示することができませんでした。
              <br />
              <br />
              大変お手数ですが、詳細につきましては
              <a
                href="https://forms.gle/jSctkbwfkShMz29z7"
                style={{ borderBottom: 'solid 2px', color: 'blue' }}
              >
                こちら
              </a>
              までお問い合わせくださいますようお願いいたします。
            </div>
          </Card.Content>
        </>
      )}
      {status === 'waiting' && (
        <>
          <Card.Header>
            <Padding paddingLeft="14px" paddingBottom="14px" paddingTop="14px">
              <TopLabel>検査結果がありません</TopLabel>
            </Padding>
          </Card.Header>
          <Card.Content>
            <div style={{ paddingTop: 14 }}>
              現在確認できる検査結果はありません。
              <br />
              検査後、結果反映までに最長7営業日ほどかかる場合があります。
            </div>
          </Card.Content>
        </>
      )}
    </Card>
  );
};

const mapStateToProps = ({ AuthenticationReducer }) => ({
  isAmhLoggedIn: AuthenticationReducer.isAmhLoggedIn,
});
const mapDispatchToProps = dispatch => ({
  logout: (errorCode = null) => dispatch(logoutSuccesful(errorCode)),
  startLoading: payload => dispatch(showLoader(payload)),
});

AmhChartCard.propTypes = {
  errorCode: PropTypes.any,
  isAmhLoggedIn: PropTypes.bool.isRequired,
  logout: PropTypes.func,
};

AmhChartCard.defaultProps = {
  errorCode: null,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(AmhChartCard));
