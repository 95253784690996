import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Mask } from 'components/Atoms';
import { SUB_COLOR_2 } from 'containers/ChartPage/colors';
import { BOX_SHADOW } from 'utils/defaults';

const DrugTable = ({ tableData }) => {
  const tableRef = useRef(null);
  const [tableOffset, setTableOffset] = useState(0);
  const [showShadow, setShowShadow] = useState(false);
  const [initialWidth, setInitialWidth] = useState(null);
  const [initialWidthInPx, setInitialWidthInPx] = useState('100%');
  const [tableWidth, setTableWidth] = useState(0);
  const onScroll = evt => {
    const newWidth = evt.target.scrollLeft + initialWidth;
    if (newWidth <= tableWidth) {
      setInitialWidthInPx(`${newWidth}px`);
    } else {
      setInitialWidthInPx(`${tableWidth}px`);
    }
    if (evt.target.scrollLeft >= tableOffset) {
      if (showShadow) setShowShadow(false);
    } else if (!showShadow) setShowShadow(true);
  };
  useEffect(() => {
    if (tableRef.current) {
      const originalWidth = tableRef.current.offsetParent.offsetWidth;
      const tableOriginalWidth = tableRef.current.offsetWidth;
      setInitialWidth(originalWidth);
      setInitialWidthInPx(`${originalWidth}px`);
      setTableWidth(tableOriginalWidth);
      const offset = tableOriginalWidth - originalWidth;
      if (offset > 0) setShowShadow(true);
      setTableOffset(offset);
    }
  }, [tableRef]);
  return (
    <div
      className="table-wraper"
      style={{
        overflowX: 'auto',
        overflowY: 'hidden',
        position: 'relative',
        boxShadow: showShadow ? BOX_SHADOW : 'none',
      }}
      onScroll={evt => onScroll(evt)}
    >
      <Mask
        style={{
          boxShadow: showShadow ? BOX_SHADOW : 'none',
          width: initialWidthInPx,
        }}
      />
      {tableData && tableData.columns && tableData.rows && (
        <table style={{ maxWidth: '100vw', borderTop: `1px solid ${SUB_COLOR_2}`, borderBottom: `1px solid ${SUB_COLOR_2}` }} ref={tableRef}>
          <thead>
            <tr>
              {tableData.columns.map((elm, index) => (
                <th
                  style={{
                    borderRight:
                      index === 0 ? `1px solid ${SUB_COLOR_2}` : 'none',
                  }}
                  key={elm}
                >
                  {elm}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.rows.map((row, index) => (
              <tr key={`${index + 1}`}>
                {row.map((col, indxCol) =>
                  indxCol === 0 ? (
                    <td
                      key={col}
                      style={{
                        borderRight: `1px solid ${SUB_COLOR_2}`,
                        paddingLeft: 17,
                      }}
                    >
                      {col}
                    </td>
                  ) : (
                    <td key={`${index + 1}${indxCol + 1} `}>
                      {col && col.map(elm => (
                        <p key={elm}>{elm}</p>
                      ))}
                    </td>
                  ),
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

DrugTable.propTypes = {
  tableData: PropTypes.object.isRequired,
};

export default DrugTable;
