/**
 * Combine all reducers in this file and export the combined reducers.
 */
import history from 'utils/history';

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import {
  ClinicDataReducer,
  ClinicStatsReducer,
} from 'containers/ClinicDetails/reducers';
import {
  AuthenticationReducer,
  HomeReducer,
} from 'containers/TopPage/reducers';
import { ClinicsReducer } from 'containers/ClinicsPage/reducers';
import { CyclesReducer } from 'containers/TreatmentCyclePage/reducers';
import { DrugUsageReducer } from 'containers/DrugUsagePage/reducers';
import { ExaminationResultsReducer } from 'containers/ExaminationResultsPage/reducers';
import { ProfileReducer } from 'containers/ProfilePage/reducers';
import {
  EmailConfirmationReducer,
  RegistrationReducer,
} from 'containers/RegistrationPage/reducers';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */

export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    AuthenticationReducer,
    CyclesReducer,
    clinic: ClinicDataReducer,
    clinicStats: ClinicStatsReducer,
    clinics: ClinicsReducer,
    drugUsage: DrugUsageReducer,
    emailConfirmation: EmailConfirmationReducer,
    HomeReducer,
    profile: ProfileReducer,
    examinationResults: ExaminationResultsReducer,
    RegistrationReducer,
    router: connectRouter(history),
    ...injectedReducers,
  });

  return rootReducer;
}
