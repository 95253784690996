import PropTypes from 'prop-types';
import React from 'react';
import { MAIN_COLOR } from 'containers/ChartPage/colors';
import { Button } from 'semantic-ui-react';

const NinpathButton = ({ disabled, text, onClickAction }) => (
  <Button
    style={{ backgroundColor: MAIN_COLOR, color: '#fff', width: '100%' }}
    onClick={() => onClickAction()}
    disabled={disabled}
  >
    {text}
  </Button>
);

NinpathButton.propTypes = {
  disabled: PropTypes.bool,
  text: PropTypes.string,
  onClickAction: PropTypes.func,
};

NinpathButton.defaultProps = {
  disabled: false,
  onClickAction: () => {},
};

NinpathButton.defaultProps = {
  disabled: false,
};
export default NinpathButton;
