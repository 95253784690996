import { createSelector } from 'reselect';

const selectClinicData = state => state.clinic;
const selectClinicBasicInfo = state =>
  state.clinic || state.clinic.basic_information;
const selectClinicStatsData = state => state.clinicStats;
const makeSelectFetchedData = () =>
  createSelector(
    selectClinicData,
    drugUsageState => drugUsageState.clinicData,
  );

const makeSelectClinicName = () =>
  createSelector(
    selectClinicBasicInfo,
    clinic => clinic.clinicName,
  );
const makeSelectErrors = () =>
  createSelector(
    selectClinicData,
    drugUsageState => drugUsageState.errors,
  );

const makeSelectFetchedStatsData = () =>
  createSelector(
    selectClinicStatsData,
    drugUsageState => drugUsageState.chartData,
  );

const makeSelectStatsErrors = () =>
  createSelector(
    selectClinicStatsData,
    drugUsageState => drugUsageState.errors,
  );

export {
  makeSelectFetchedData,
  makeSelectErrors,
  makeSelectFetchedStatsData,
  makeSelectClinicName,
  makeSelectStatsErrors,
};
