import styled from 'styled-components';
import { MAIN_COLOR } from 'containers/ChartPage/colors';
import { Menu } from 'semantic-ui-react';

const MenuItem = styled(Menu.Item)`
  background-color: ${props =>
    props['data-active'] ? `${MAIN_COLOR} !important` : null};
  && {
    border-radius: 0px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70px;
    font-size: 0.85rem;
    padding-right: 0;
    color: ${props => (props['data-active'] ? '#fff !important' : '#767676')};
    transition: background-color 0.4s;
    will-change: background-color;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent !important;
    /* -webkit-touch-callout: none; */
    /* user-select: none !important; */
  }
  && .icon {
    margin-bottom: 8px !important;
    margin-right: 0px !important;
    font-size: 1.71rem;
    outline: none !important;
    -webkit-tap-highlight-color: transparent !important;
  }
  && span {
    text-align: center;
    font-size: 0.82rem;
    color: ${props => (props['data-active'] ? '#fff' : '#000')};
  }
`;
export default MenuItem;
