import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FormSelect } from '../formElements';

const MultipleSelect = ({ id, name, options, submitValue, changeFunction }) => {
  const [open, setOpen] = useState(false);

  return (
    <FormSelect
      id={id}
      className="ninpath__select"
      placeholder="選択してください"
      options={options}
      multiple
      open={open}
      value={submitValue}
      onBlur={() => {
        setOpen(false);
      }}
      onClick={() => setOpen(!open)}
      onChange={(evt, { value }) => changeFunction(name, value, true)}
    />
  );
};

MultipleSelect.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.any.isRequired,
  submitValue: PropTypes.array,
  changeFunction: PropTypes.func.isRequired,
};

export default MultipleSelect;
