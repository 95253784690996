import produce from 'immer';
import { LOCATION_CHANGE } from 'containers/TopPage/constants';

import {
  GET_EXAMINATION_RESULTS_WAS_SUCCESSFUL,
  GET_EXAMINATION_RESULTS_FAILED,
  EXAMINATION_RESULTS_UPDATED_SUCCESSFULLY,
  EXAMINATION_RESULTS_UPDATE_FAILED,
} from './constants';

export const initialState = {
  getDataWasSuccessful: false,
  updateSuccessful: false,
  errors: [],
  examinationResultsData: null,
};

export const ExaminationResultsReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_EXAMINATION_RESULTS_WAS_SUCCESSFUL:
        draft.examinationResultsData = JSON.parse(
          JSON.stringify(action.payload),
        );
        break;
      case GET_EXAMINATION_RESULTS_FAILED:
        draft.examinationResultsData = null;
        draft.errors = action.payload.errors;
        break;
      case EXAMINATION_RESULTS_UPDATED_SUCCESSFULLY:
        draft.errors = [];
        draft.updateSuccessful = true;
        draft.examinationResultsData = JSON.parse(
          JSON.stringify(action.payload.data),
        );
        break;
      case EXAMINATION_RESULTS_UPDATE_FAILED:
        draft.errors = action.payload.errors;
        draft.updateSuccessful = false;
        break;
      case LOCATION_CHANGE:
        if (
          action.payload &&
          action.payload.location &&
          action.payload.location.pathname !== '/pathname'
        ) {
          draft.updateSuccessful = false;
          draft.errors = [];
          draft.examinationResultsData = {};
        }
        break;
      default:
        draft.getDataWasSuccessful = state.getDataWasSuccessful;
        draft.updateSuccessful = state.updateSuccessful;
        draft.errors = state.errors;
        draft.examinationResultsData = state.examinationResultsData;
        break;
    }
  });
