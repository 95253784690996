import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { LayoutContainer, LayoutGrid } from 'utils/layouts';
import { Card, Grid } from 'semantic-ui-react';

import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  makeSelectFetchedData,
  makeSelectErrors,
  makeSelectFetchedStatsData,
  makeSelectClinicName,
  makeSelectStatsErrors,
} from 'containers/ClinicDetails/selectors';
import { withRouter } from 'react-router-dom';
import { showLoader } from 'containers/TopPage/actions';
import {
  fetchClinicData,
  fetchClinicStatsData,
} from 'containers/ClinicDetails/actions';

import { NestedBackIndicator } from 'components/Molecules';

import ClinicGraphs from 'containers/ClinicDetails/ClinicGraphs';
import DetailsGrid from 'containers/ClinicDetails/DetailsGrid';
import FilterList from 'components/FilterList';
import { Padding, TopLabel } from 'components/Atoms';
const ClinicDetails = ({
  clinicData,
  clinicGraphs,
  clinicName,
  fetchData,
  fetchStatsData,
  location,
  startLoading,
}) => {
  const [activeFilter, setActiveFilter] = useState(0);
  const changeFilter = index => {
    setActiveFilter(index);
  };
  const checkPerformance = () => {
    setActiveFilter(0);
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    const matches = location.pathname.match('/clinics/details/(.*)');
    if (matches[1]) {
      fetchData({}, matches[1]);
      fetchStatsData({}, matches[1]);
    }
  }, [fetchData, fetchStatsData, location, startLoading]);
  return (
    <LayoutContainer style={{ height: '100%' }}>
      <Padding
      paddingTop="14px"
      paddingBottom="14px"
      paddingLeft="14px"
    >
      <NestedBackIndicator/>
    </Padding>
      <LayoutGrid>
        <Grid.Row columns={1} style={{ paddingTop: 0 }}>
          <Grid.Column mobile={16} tablet={10} computer={6}>
            <Card style={{ width: '100%' }} className="search_filter">
              <Card.Header style={{ padding: 14 }}>
                <Padding paddingLeft="14px">
                  <TopLabel>{clinicName}</TopLabel>
                </Padding>
              </Card.Header>
              <Card.Content
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: 0,
                  flexDirection: 'column',
                }}
              >
                <FilterList
                  activeFilter={activeFilter}
                  changeFilter={changeFilter}
                  filtersData={['実績', 'クリニックの基本情報']}
                />
                {activeFilter === 0 && clinicGraphs && (
                  <ClinicGraphs clinicGraphs={clinicGraphs.graphs} />
                )}
                {activeFilter === 1 && (
                  <Padding
                    paddingLeft="14px"
                    paddingRight="14px"
                    paddingBottom="14px"
                  >
                    <DetailsGrid
                      clinic={clinicData}
                      checkPerformance={checkPerformance}
                    />
                  </Padding>
                )}
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </LayoutGrid>
    </LayoutContainer>
  );
};

const mapDispatchToProps = dispatch => ({
  fetchData: (payload, params) => dispatch(fetchClinicData(payload, params)),
  fetchStatsData: (payload, params) =>
    dispatch(fetchClinicStatsData(payload, params)),
  startLoading: payload => dispatch(showLoader(payload)),
});

const mapStateToProps = createStructuredSelector({
  errors: makeSelectErrors(),
  clinicData: makeSelectFetchedData(),
  clinicGraphs: makeSelectFetchedStatsData(),
  clinicName: makeSelectClinicName(),
  statsErrors: makeSelectStatsErrors(),
});

ClinicDetails.propTypes = {
  clinicData: PropTypes.object,
  clinicGraphs: PropTypes.object,
  clinicName: PropTypes.string,
  fetchData: PropTypes.func.isRequired,
  fetchStatsData: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  startLoading: PropTypes.func.isRequired,
};
ClinicDetails.defaultProps = {
  clinicData: {},
  clinicGraphs: {},
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ClinicDetails));
