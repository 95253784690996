export const NUM_GRAPHS = 2;
export const CHART_DATA_FETCH_SUCESSFUL = 'CHART_DATA_FETCH_SUCESSFUL';
export const CHART_DATA_FETCH_FAILED = 'CHART_DATA_FETCH_FAILED';
export const UPDATE_CHART_DATA = 'UPDATE_CHART_DATA';
export const MESSAGES = {
  me: [
    'あなたの治療状況を確認することができます。\n 治療履歴を登録することで、グラフが更新され今まで歩んできた治療の道筋が可視化されます。',
  ],
  cycles: [
    'あなたの治療履歴を周期ごとに記録できます。\n 治療履歴を記録することで、よりあなたと近い状況の方の治療状況を知ることができます。',
  ],
  stats: [
    'アプリに登録したユーザーの不妊治療の状況を統計データとして確認することができます。\n 現在、治療開始年齢が近い方を表示していますが、あなたの状況に合わせた、より詳細な絞り込みが可能です。\n ',
    '※絞り込みすぎると対象者数が少なくなり、適切な表示がされない可能性があります。',
  ],
};
