/* Based on the atomic design model, this folder contains elements which can used in various
parts of the project */
import React from 'react';
import PropTypes from 'prop-types';
import { navigateBack } from 'utils/helper';
import { Icon } from 'semantic-ui-react';
import { IndicatorContainer, TopLabel } from 'components/Atoms';

export const NestedBackIndicator = ({ backLabel, title, location }) => (
  <IndicatorContainer
    onClick={() => {
      navigateBack(title, location);
    }}
  >
    <Icon size="large" name="arrow left" />
    {backLabel !== '' && (
      <TopLabel style={{ width: '100%', whiteSpace: 'nowrap' }}>
        {backLabel}
      </TopLabel>
    )}
  </IndicatorContainer>
);

export const NestedCancelIndicator = ({ cancelLabel, title, location }) => (
  <IndicatorContainer
    onClick={() => {
      navigateBack(title, location);
    }}
  >
    <Icon size="large" name="arrow left" />
    {cancelLabel !== '' && <TopLabel>{cancelLabel}</TopLabel>}
  </IndicatorContainer>
);

NestedBackIndicator.propTypes = {
  pageLabel: PropTypes.string,
  BackLabel: PropTypes.string,
  title: PropTypes.string,
  location: PropTypes.object,
};

NestedCancelIndicator.propTypes = {
  pageLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  title: PropTypes.string,
  location: PropTypes.object,
};

NestedBackIndicator.defaultProps = {
  backLabel: '戻る',
};

NestedCancelIndicator.defaultProps = {
  cancelLabel: 'キャンセル',
};
