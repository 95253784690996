import React from 'react';
import { PropTypes } from 'prop-types';
import { Grid, Message } from 'semantic-ui-react';

import InformationCard from 'containers/ClinicDetails/InformationCard';

const DetailsGrid = ({ clinic, checkPerformance }) => (
  <Grid style={{ width: '100%' }}>
    {clinic && clinic.basic_information && (
      <Grid.Row>
        <Grid.Column
          computer={16}
          tablet={16}
          mobile={16}
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <InformationCard
            informationHeader="基本情報"
            clinic={clinic.basic_information}
            mode="basic"
            checkPerformance={checkPerformance}
          />
        </Grid.Column>
      </Grid.Row>
    )}
    {clinic && clinic.schedule && (
      <Grid.Row style={{ paddingBottom: 0, paddingTop: 0 }}>
        <Grid.Column
          computer={16}
          tablet={16}
          phone={16}
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <InformationCard
            informationHeader="診療時間"
            clinic={clinic.schedule}
            mode="schedule"
            checkPerformance={checkPerformance}
          />
        </Grid.Column>
      </Grid.Row>
    )}
    {clinic && clinic.directions && (
      <Grid.Row style={{ paddingBottom: 0, paddingTop: 0 }}>
        <Grid.Column
          computer={16}
          tablet={16}
          phone={16}
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <InformationCard
            informationHeader="アクセス"
            clinic={clinic.directions}
            mode="address"
            checkPerformance={checkPerformance}
          />
        </Grid.Column>
      </Grid.Row>
    )}
    {clinic && clinic.registered_facility_information && (
      <Grid.Row style={{ paddingBottom: 0, paddingTop: 0 }}>
        <Grid.Column
          computer={16}
          tablet={16}
          phone={16}
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <InformationCard
            informationHeader="登録施設情報"
            clinic={clinic.registered_facility_information}
            mode="registered_facility_information"
            checkPerformance={checkPerformance}
          />
        </Grid.Column>
      </Grid.Row>
    )}
    {clinic && clinic.advanced_medical && (
      <Grid.Row style={{ paddingBottom: 0, paddingTop: 0 }}>
        <Grid.Column
          computer={16}
          tablet={16}
          phone={16}
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <InformationCard
            informationHeader="先進医療"
            clinic={clinic.advanced_medical}
            mode="advanced_medical"
            checkPerformance={checkPerformance}
          />
        </Grid.Column>
      </Grid.Row>
    )}
    {clinic && clinic.private_medical && (
      <Grid.Row style={{ paddingBottom: 0, paddingTop: 0 }}>
        <Grid.Column
          computer={16}
          tablet={16}
          phone={16}
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <InformationCard
            informationHeader="自費診療"
            clinic={clinic.private_medical}
            mode="private_medical"
            checkPerformance={checkPerformance}
          />
        </Grid.Column>
      </Grid.Row>
    )}
    {clinic && clinic.others && (
      <Grid.Row style={{ paddingBottom: 0, paddingTop: 0 }}>
        <Grid.Column
          computer={16}
          tablet={16}
          phone={16}
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <InformationCard
            informationHeader="その他"
            clinic={clinic.others}
            mode="others"
            checkPerformance={checkPerformance}
          />
        </Grid.Column>
      </Grid.Row>
    )}

    <Message>
      <p style={{ fontSize: '0.86rem' }}>
        クリニック情報は、ninpathの独自収集情報、厚生労働省または各都道府県の公開情報、医療機関からの情報提供等に基づくもので、内容を完全に保証するものではなく、また特定の治療行為や医療機関等を推奨するものではありません。
        <br />
        本サービス上ではこれらの情報をあくまで参考情報として掲載しているにとどまり、本サービスはこれらの事項についてアドバイスを行う性質のものではありません。
        <br />
        診療内容や受診については必要に応じて、直接医療機関にお問い合わせください。
        <br />
        当サービスの利用により生じた損害についてninpathは賠償の責任を一切負いません。
      </p>
    </Message>
  </Grid>
);

DetailsGrid.propTypes = {
  checkPerformance: PropTypes.func.isRequired,
  clinic: PropTypes.object.isRequired,
};

export default DetailsGrid;
