import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { LayoutGrid, LayoutContainer } from 'utils/layouts';
import { Card, Form, Grid, Message, Tab } from 'semantic-ui-react';
import { Redirect, withRouter } from 'react-router-dom';
import { ErrorMessage } from 'utils/errors';
import { Padding, TopLabel } from 'components/Atoms';
import { MAIN_COLOR } from 'containers/ChartPage/colors';
import { NestedCancelIndicator } from 'components/Molecules';

import Cookies from 'universal-cookie';
import { connect } from 'react-redux';
import request from 'utils/request';
import {
  logoutSuccesful,
  showLoader,
  hideLoader,
} from 'containers/TopPage/actions';
import DrugUsageForm from './forms/DrugUsageForm';
import TreatmentResultsForm from './forms/TreatmentResultsForm';
import TransplantForm from './forms/TransplantForm';
const resultPane = (
  validateForm,
  updateFormValues,
  loadedData = null,
  presetData = null,
) => [
  {
    menuItem: '治療結果',
    render: () => (
      <Tab.Pane
        style={{ border: 'none', boxShadow: 'none', padding: 5 }}
        attached={false}
      >
        <TreatmentResultsForm
          isFormValid={validateForm}
          updateFormValues={updateFormValues}
          showPregnancyDetermination
          loadedData={loadedData ? loadedData.cycle : null}
          presetData={presetData ? presetData : null}
        />
      </Tab.Pane>
    ),
  },
];

const drugUsagePane = (validateForm, updateFormValues, loadedData) => [
  {
    menuItem: '薬剤',
    render: () => (
      <Tab.Pane
        style={{ border: 'none', boxShadow: 'none', padding: 5 }}
        attached={false}
      >
        <DrugUsageForm
          isFormValid={validateForm}
          updateFormValues={updateFormValues}
          loadedData={loadedData ? loadedData.medicine : null}
        />
      </Tab.Pane>
    ),
  },
];

const transplantPane = (validateForm, updateFormValues, loadedData) => [
  {
    menuItem: '移植',
    render: () => (
      <Tab.Pane
        style={{ border: 'none', boxShadow: 'none', padding: 5 }}
        attached={false}
      >
        <TransplantForm
          isFormValid={validateForm}
          updateFormValues={updateFormValues}
          loadedData={loadedData ? loadedData.et : null}
        />
      </Tab.Pane>
    ),
  },
];

const TransplantCycle = ({ location, logout, finishLoading, startLoading }) => {
  const [registrationSuccess, setRegistrationSuccess] = useState(null);
  const [errors, setErrors] = useState([]);
  const [formGroupValues, setFormGroupValues] = useState({});
  const [formStatus, setStatus] = useState([true, true, false]);
  const [hasNotChangeAnything, setHasNoChangeAnything] = useState(true);
  const validateForm = (index, newStatus) => {
    const newStatuses = formStatus.map(status => status);
    newStatuses[index] = newStatus;
    setStatus(newStatuses);
  };
  const updateFormValues = (index, formValues) => {
    setHasNoChangeAnything(false);
    const newValues = JSON.parse(JSON.stringify(formGroupValues));
    newValues[index] = formValues;
    setFormGroupValues(newValues);
  };
  const cookies = new Cookies();
  const [cycleId, setCycleId] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [loadedData, setLoadedData] = useState(null);
  const [presetData, setPresetData] = useState(null);

  useEffect(() => {
    const cookiesTemp = new Cookies();
    window.scrollTo(0, 0);
    if (location.pathname.match('/cycles/etCycle/register')) {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/all_cycle`;
      const promise = request(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          charset: 'UTF-8',
          Authorization: `Bearer ${cookiesTemp.get('user_token')}`,
        },
      });
      promise.then(response => {
        if (response.status === 401) logout();
        if (response.status === 503 || response.status === 500) {
          logout(response.status);
        }
        if (!response.has_cycle) return;
        setPresetData(response.latest_input_clinic);
      });
    }
    if (location.pathname.match('/cycles/etCycle/edit/')) {
      const matches = location.pathname.match('/cycles/etCycle/edit/(.*)');
      if (matches.length > 1) {
        setEditMode(true);
        const id = matches[1];
        setCycleId(id);
        const url = `${process.env.REACT_APP_API_URL}/api/v1/et_cycles/${id}`;
        const promise = request(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            charset: 'UTF-8',
            Authorization: `Bearer ${cookiesTemp.get('user_token')}`,
          },
        });
        promise.then(response => {
          if (response.status === 401) logout();
          if (response.status === 503 || response.status === 500) {
            logout(response.status);
          }
          setLoadedData(response.data);
          setStatus([true, true, true]);
        });
      }
    }
  }, [location, logout]);
  if (registrationSuccess) {
    return <Redirect to="/cycles?type=1" push />;
  }

  if (!cookies.get('user_token')) return <Redirect push to="/" />;
  const onRegisterCycle = evt => {
    evt.preventDefault();
    startLoading({ value: true });
    if (!editMode) {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/et_cycles`;
      const promise = request(url, {
        method: 'POST',
        body: JSON.stringify({ et_cycle: formGroupValues }),
        headers: {
          'Content-Type': 'application/json',
          charset: 'UTF-8',
          Authorization: `Bearer ${cookies.get('user_token')}`,
        },
      });
      promise.then(response => {
        finishLoading({ value: true });
        if (response.status === 401) logout();
        if (response.status === 503 || response.status === 500) {
          logout(response.status);
        }
        if (response && !response.errors) setRegistrationSuccess(true);
        else {
          setErrors(response.errors);
        }
      });
    } else {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/et_cycles/${cycleId}`;
      const promise = request(url, {
        method: 'PATCH',
        body: JSON.stringify({ et_cycle: formGroupValues }),
        headers: {
          'Content-Type': 'application/json',
          charset: 'UTF-8',
          Authorization: `Bearer ${cookies.get('user_token')}`,
        },
      });
      promise.then(response => {
        finishLoading({ value: true });
        if (response.status === 401) logout();
        if (response.status === 503 || response.status === 500) {
          logout(response.status);
        }
        if (response && !response.errors) setRegistrationSuccess(true);
        else {
          setErrors(response.errors);
        }
      });
    }
  };
  return (
    <LayoutContainer style={{ height: '100%' }}>
      <Padding paddingTop="14px" paddingBottom="14px" paddingLeft="14px">
        <NestedCancelIndicator />
      </Padding>
      <LayoutGrid>
        <Grid.Row columns={1} style={{ paddingTop: 0 }}>
          <Grid.Column mobile={16} computer={6} tablet={10}>
            <Card style={{ width: '100%' }} className="opu__card">
              <Card.Header>
                <Padding
                  paddingLeft="14px"
                  paddingBottom="14px"
                  paddingTop="14px"
                >
                  <TopLabel>IVF/ICSI移植</TopLabel>
                </Padding>
              </Card.Header>
              <Card.Content>
                <Form
                  style={{ width: '100%' }}
                  onSubmit={evt => {
                    onRegisterCycle(evt);
                  }}
                >
                  <Tab
                    panes={resultPane(
                      validateForm,
                      updateFormValues,
                      loadedData,
                      presetData,
                    )}
                    menu={{ secondary: true, pointing: true }}
                  ></Tab>
                  <Tab
                    panes={drugUsagePane(
                      validateForm,
                      updateFormValues,
                      loadedData,
                    )}
                    menu={{ secondary: true, pointing: true }}
                  ></Tab>
                  <Tab
                    panes={transplantPane(
                      validateForm,
                      updateFormValues,
                      loadedData,
                    )}
                    menu={{ secondary: true, pointing: true }}
                  ></Tab>
                  {errors.length > 0 && (
                    <Message negative>
                      <Message.Header>エラー</Message.Header>
                      {errors.map(error => (
                        <p key={error}>{error}</p>
                      ))}
                    </Message>
                  )}
                  <div
                    className="button_form_container"
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <Form.Button
                      className="ninpath__form__container"
                      primary
                      style={{ minWidth: '80px', maxWidth: '120px' }}
                      disabled={!formStatus[0] || hasNotChangeAnything}
                    >
                      保存
                    </Form.Button>
                    {editMode && hasNotChangeAnything && (
                      <ErrorMessage color={MAIN_COLOR}>
                        項目に変更のない場合はボタンが押せません
                      </ErrorMessage>
                    )}
                    {!formStatus[0] && (
                      <ErrorMessage color={MAIN_COLOR}>
                        必須項目が未入力の場合はボタンが押せません
                      </ErrorMessage>
                    )}
                  </div>
                </Form>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </LayoutGrid>
    </LayoutContainer>
  );
};
const mapStateToProps = ({ CyclesReducer }) => ({
  registrationSuccess: CyclesReducer.registrationSuccess,
  errors: CyclesReducer.errors,
});
const mapDispatchToProps = dispatch => ({
  logout: (errorCode = null) => dispatch(logoutSuccesful(errorCode)),
  startLoading: payload => dispatch(showLoader(payload)),
  finishLoading: payload => dispatch(hideLoader(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(React.memo(TransplantCycle)),
);
TransplantCycle.propTypes = {
  location: PropTypes.any.isRequired,
  logout: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};
