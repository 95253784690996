import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import history from 'utils/history';
import request from 'utils/request';
import { Form, Grid, Message } from 'semantic-ui-react';
import { LayoutContainer, LayoutGrid } from 'utils/layouts';
import Cookies from 'universal-cookie';
import {
  ninpathCheckLoginSuccessful,
  loginSuccesful,
  showLoader,
  hideLoader,
} from 'containers/TopPage/actions';

import Logo from 'assets/H150.png';
import TagManager from 'react-gtm-module';

const LoginPage = ({ login, amhLogin, startLoading, finishLoading }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const cookies = new Cookies();
  const [errors, setErrors] = useState('');
  const attemptLogin = (userEmail, userPassword) => {
    startLoading({ value: true });
    const url = `${process.env.REACT_APP_API_URL}/api/v1/auth/login`;
    const obj = { email: userEmail, password: userPassword };
    const promise = request(url, {
      method: 'POST',
      body: JSON.stringify(obj),
      headers: {
        'Content-Type': 'application/json',
        charset: 'UTF-8',
      },
    });
    promise.then(response => {
      if (response.user !== undefined) {
        // 通常ユーザでログイン
        cookies.set('user_token', response.auth_token, { path: '/' });
        cookies.set('user_name', response.user.name, { path: '/' });
        cookies.set('user_id', response.user.id, { path: '/' });
        cookies.set('age_group', response.user.age_group, { path: '/' });
        // order matters!!!
        login();
        TagManager.dataLayer({
          userId: response.user.id,
        });
        history.push('/myData');
      } else {
        setErrors(response.messages);
      }
      finishLoading({ value: true });
    });
  };
  return (
    <LayoutContainer>
      <LayoutGrid centered columns={1}>
        <Grid.Row style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Grid.Column
            mobile={12}
            tablet={8}
            computer={6}
            style={{ margin: 'auto', transform: 'translateY(-10%)' }}
          >
            <img
              src={Logo}
              alt="ninpath_logo"
              style={{ marginBottom: '20px' }}
            />
            <Form
              onSubmit={evt => {
                evt.preventDefault();
                attemptLogin(email, password);
              }}
            >
              <Form.Field>
                <label htmlFor="email">メールアドレス</label>
                <input
                  name="email"
                  type="email"
                  value={email}
                  placeholder="aaa@example.com"
                  onChange={evt => setEmail(evt.target.value)}
                />
              </Form.Field>
              <Form.Field>
                <label htmlFor="password">パスワード</label>
                <input
                  name="password"
                  type="password"
                  placeholder="パスワード"
                  value={password}
                  onChange={evt => setPassword(evt.target.value)}
                />
              </Form.Field>
              <Form.Button
                primary
                className="ninpath__form__container login__page__button__container"
                disabled={email === '' || password === ''}
              >
                ログイン
              </Form.Button>
              <p style={{ textAlign: 'right' }}>
                <Link to="/forgotPassword">パスワードを忘れた方</Link>
              </p>
              <p style={{ textAlign: 'right' }}>
                <a
                  target="_blank"
                  href="https://forms.gle/fdqpVwMv3hS87HNi9"
                  rel="noopener noreferrer"
                >
                  お問い合わせ
                </a>
              </p>
              {errors && errors !== '' && (
                <Message negative>
                  <Message.Header>エラー</Message.Header>
                  <p>{errors}</p>
                </Message>
              )}
            </Form>
          </Grid.Column>
        </Grid.Row>
      </LayoutGrid>
    </LayoutContainer>
  );
};

const mapDispatchToProps = dispatch => ({
  login: () => dispatch(loginSuccesful()),
  amhLogin: () => dispatch(ninpathCheckLoginSuccessful()),
  startLoading: payload => dispatch(showLoader(payload)),
  finishLoading: payload => dispatch(hideLoader(payload)),
});
export default connect(
  null,
  mapDispatchToProps,
)(React.memo(LoginPage));

LoginPage.propTypes = {
  login: PropTypes.any.isRequired,
  amhLogin: PropTypes.any.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};
