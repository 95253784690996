import React from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Card, Grid } from 'semantic-ui-react';

import { Padding, SubTitleLabel } from 'components/Atoms';
import { showLoader } from 'containers/TopPage/actions';
import { LayoutContainer, LayoutGrid } from '../../utils/layouts';

const AmhInfoPage = ({ isAmhLoggedIn, errorCode }) => {
  if (errorCode) {
    return <Redirect push to={{ pathName: '/', state: { errorCode } }} />;
  }
  if (!isAmhLoggedIn) return <Redirect push to="/" />;
  return (
    <Padding paddingTop="6rem">
      <LayoutContainer style={{ height: '100%' }}>
        <LayoutGrid style={{ paddingTop: 56, paddingBottom: 14 }}>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={10} computer={6}>
              <Card style={{ width: '100%', paddingTop: 14 }}>
                <Card.Content>
                  <Padding paddingLeft="14px">
                    <SubTitleLabel>AMH測定に関するご留意点</SubTitleLabel>
                  </Padding>
                  <div>
                    <ol style={{ listStyleType: 'decimal' }}>
                      <li>AMH は卵子の質とは関連しません</li>
                      <li>
                        AMH
                        の測定値は個人差が大きく、若年女性でも低い場合や高齢女性でも高い場合があり、測定値からいわゆる
                        <span style={{ borderBottom: 'solid 1px' }}>
                          「卵巣年齢」の推定はできません
                        </span>
                      </li>
                      <li>
                        測定値と妊娠する可能性とは直接的な関連はなく、測定値から「妊娠できる可能性」を判定するのは不適切と考えられます
                      </li>
                      <li>
                        測定値が低い場合でも「閉経が早い」という断定はできません
                      </li>
                    </ol>
                    <ul style={{ listStyleType: 'none' }}>
                      <li>
                        <cite>
                          出典：平成 27～28 年度生殖・内分泌委員会
                          生殖医療リスクマネージメント小委員会報告：抗ミュラー管ホルモン（AMH）の測定に関する留意事項：日産婦誌
                          69：1721，2017．
                        </cite>
                      </li>
                    </ul>
                  </div>
                  <Padding paddingLeft="14px" paddingTop="14px">
                    <SubTitleLabel>解説</SubTitleLabel>
                  </Padding>
                  <ul style={{ listStyleType: 'disc' }}>
                    <li>
                      AMHは測定するタイミングによっても誤差が発生し得る検査です
                    </li>
                    <li>AMHはそのまま妊娠率・出産率とは相関しません</li>
                    <li>
                      AMHはあくまでも「卵子の残り数の目安」であり、卵子の質や老化（染色体異常率の増加、卵子の膜の硬化、胚発生や発育不良が生じると言われている）を反映したものではありません
                    </li>
                    <li>
                      女性の妊よう性（妊娠するための力、生殖機能）はAMHに関わらず、実年齢やその他の因子による部分が大きいことをご理解ください
                    </li>
                    <li>
                      AMHだけで診断される病気はなく、その他の様々な条件から複合的に診断が行われます
                    </li>
                    <li>
                      AMHは何らかの理由により体外受精・顕微授精等の不妊治療が必要となった場合に、治療法を選択する目安となることがあります
                    </li>
                    <li>
                      妊娠を希望されている場合は、結果に関わらず早めの専門医の受診をおすすめします
                    </li>
                  </ul>
                  <Padding paddingLeft="14px" paddingTop="14px">
                    <SubTitleLabel>値が基準範囲よりも高い場合</SubTitleLabel>
                  </Padding>
                  <ul style={{ listStyleType: 'disc' }}>
                    <li>
                      体外受精の際に多数の卵子を得られることが期待できますが、AMH値は高ければ「安心」「卵巣や卵子が若い」というものではありません
                    </li>
                    <li>
                      また、不妊症には「卵子の残り数」以外にも男女ともにさまざまな要因があり、別の不妊原因を見落としていないか注意が必要です
                    </li>
                    <li>
                      一般的にはAMH値が7.5ng/ml以上の場合、PCOS（多嚢胞性卵巣症候群）等の病気の可能性が考えられます
                    </li>
                    <li>
                      BMIが25以上の場合には、より低い値でも排卵障害等の可能性があります
                    </li>
                    <li>
                      ただし、測定値が高い場合でも病気と断定することはできません
                    </li>
                  </ul>
                  <Padding paddingLeft="14px" paddingTop="14px">
                    <SubTitleLabel>値が基準範囲よりも低い場合</SubTitleLabel>
                  </Padding>
                  <ul style={{ listStyleType: 'disc' }}>
                    <li>
                      AMH値が低いことは妊娠が難しいことと同義ではありません
                    </li>
                    <li>
                      排卵期や直近にピル（経口避妊薬）を服用している場合、低めに検出されることがあります
                    </li>
                    <li>
                      一般的には40歳未満でAMH値が1ng/ml以下の場合、POI（早発卵巣不全・早発閉経）の可能性があります
                    </li>
                    <li>
                      ただし、測定値が低い場合でも「閉経が早い」という断定はできません
                    </li>
                  </ul>
                </Card.Content>
                <Card.Content>
                  <span>監修：生殖医療専門医 菊地盤 医師</span>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
        </LayoutGrid>
      </LayoutContainer>
    </Padding>
  );
};

const mapStateToProps = ({ AuthenticationReducer }) => ({
  isAmhLoggedIn: AuthenticationReducer.isAmhLoggedIn,
});
const mapDispatchToProps = dispatch => ({
  startLoading: payload => dispatch(showLoader(payload)),
});

AmhInfoPage.propTypes = {
  errorCode: PropTypes.any,
  isAmhLoggedIn: PropTypes.bool.isRequired,
};

AmhInfoPage.defaultProps = {
  errorCode: null,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(AmhInfoPage));
