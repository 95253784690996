import history from 'utils/history';
import { MAIN_COLOR } from './colors';
export const STACKED_BAR_V2_OPTIONS = {
  legend: {
    display: true,
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
  maintainAspectRatio: false,
  scales: {
    xAxes: [
      {
        stacked: true,
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        stacked: true,
        gridLines: {
          display: false,
        },
        ticks: {
          callback: value => `${value}%`,
          max: 100,
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label: tooltipItem => {
        const label = Math.round(tooltipItem.value * 100) / 100;
        return `${label}%`;
      },
    },
  },
};

export const STACKED_BAR_OPTIONS = {
  legend: {
    display: true,
  },
  plugins: {
    datalabels: {
      display: context => context.dataset.data[context.dataIndex] > 1,
      fontSize: 12,
      color: context =>
        context.dataset.label === 'ホルモン補充周期' ||
        context.dataset.label === '実施していない'
          ? '#000'
          : '#fff',
      formatter: value =>
        value > 0 ? `${Math.round(value * 100, 2) / 100}%` : null,
    },
  },
  maintainAspectRatio: false,
  scales: {
    xAxes: [
      {
        stacked: true,
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        stacked: true,
        gridLines: {
          display: false,
        },
        ticks: {
          callback: value => ``,
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label: tooltipItem => {
        const label = Math.round(tooltipItem.value * 100) / 100;
        return `${label}%`;
      },
    },
  },
};

export const STACKED_BAR_LABELED_OPTIONS = {
  legend: {
    display: true,
  },
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      display: context => context.dataset.data[context.dataIndex] > 1,
      color: '#fff',
      font: {
        weight: 'bold',
        size: 10,
      },
      formatter: value =>
        value > 0 ? `${Math.round(value * 100, 2) / 100}%` : null,
    },
  },
  scales: {
    xAxes: [
      {
        stacked: true,
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        stacked: true,
        ticks: {
          callback: value => ``,
        },
        gridLines: {
          display: false,
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label: tooltipItem => {
        const label = Math.round(tooltipItem.value * 100) / 100;
        return `${label}%`;
      },
    },
  },
};
export const RADAR_BAR_OPTIONS = {
  legend: {
    display: true,
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
  maintainAspectRatio: false,
  scale: {
    ticks: {
      beginAtZero: true,
      min: 0,
      stepSize: 2,
    },
  },
  tooltips: {
    callbacks: {
      label: tooltipItem => {
        const label = Math.round(tooltipItem.yLabel * 100) / 100;
        return `${label}`;
      },
    },
  },
};

export const SEMEN_EXAMINATION_RADAR_OPTIONS = {
  legend: {
    display: true,
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
  maintainAspectRatio: true,
  scale: {
    ticks: {
      beginAtZero: true,
      min: 0,
      stepSize: 20,
    },
  },
  tooltips: {
    callbacks: {
      label: tooltipItem => {
        const label = Math.round(tooltipItem.yLabel * 100) / 100;
        return `${label}`;
      },
    },
  },
};

const HEATMAP_OPTIONS = {
  legend: {
    show: false,
    showForSingleSeries: true,
  },
  chart: {
    toolbar: {
      show: false,
    },
    events: {
      dataPointSelection: (event, chartContext, config) => {
        const yLabel = config.w.config.series[config.seriesIndex].name;
        const xLabel = config.w.globals.labels[config.dataPointIndex];
        const pointValue =
          config.w.config.series[config.seriesIndex].data[config.dataPointIndex]
            .y;
        if (pointValue !== 0) {
          history.push('/drugUsage', {
            activeGraphType: window.heatmapActiveGraphType,
            activeSubFilters: window.heatmapActiveSubFilters,
            range: xLabel,
            stimulation_method: yLabel,
          });
        }
      },
    },
  },
  tooltip: {
    enabled: true,
    followCursor: true,
    marker: {
      show: false,
    },
    onDatasetHover: {
      highlightDataSeries: false,
    },
  },
  dataLabels: {
    enabled: true,
    style: {
      colors: ['#000'],
    },
    formatter: val => `${val}`,
  },
  plotOptions: {
    heatmap: {
      distributed: true,
      enableshades: 2,
      shadeIntensity: 0.5,
      colorScale: {
        ranges: [
          {
            from: 1,
            to: 100,
            name: 'low',
            color: MAIN_COLOR,
          },
        ],
      },
    },
  },
  yaxis: {
    labels: {
      show: true,
      maxWidth: 105,
      align: 'right',
      style: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontSize: '10px',
      },
    },
  },
  xaxis: {
    position: 'top',
    labels: {
      trim: false,
      style: {
        fontSize: '10px',
      },
    },
    tooltip: {
      enabled: false,
    },
    axisTicks: {
      show: false,
    },
  },
};

export const BAR_MIXED_OPTIONS = {
  responsive: true,

  tooltips: {
    mode: 'label',
    callbacks: {
      label: (tooltipItem, data) => {
        const lineIndex = data.datasets.findIndex(
          dataset => dataset.datasetType === 'line',
        );
        const label = Math.round(tooltipItem.value * 100) / 100;
        return tooltipItem.datasetIndex === lineIndex ? `${label}%` : label;
      },
    },
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
  elements: {
    line: {
      fill: false,
      show: true,
    },
  },
  scales: {
    xAxes: [
      {
        display: true,
        gridLines: {
          display: false,
        },
        id: 'x-axis',
      },
    ],
    yAxes: [
      {
        type: 'linear',
        display: true,
        position: 'right',
        id: 'y-axis-1',
        gridLines: {
          display: false,
        },
        labels: {
          show: true,
        },
        ticks: {
          callback: value => `${value}%`,
          max: 100,
          beginAtZero: true,
        },
      },
      {
        type: 'linear',
        display: true,
        position: 'left',
        id: 'y-axis-2',
        gridLines: {
          display: false,
        },
        labels: {
          show: true,
        },
        scaleLabel: {
          display: true,
        },
        ticks: {
          mirror: true,
          padding: 30,
          callback: value =>
            value === 20 ? (value = `${value}～`) : (value = `${value}`),
          beginAtZero: true,
          max: 20,
        },
      },
    ],
  },
};

export const CLINICS_BARS_OPTIONS = {
  responsive: true,
  plugins: {
    datalabels: {
      display: context => context.dataset.data[context.dataIndex] > 1,
      color: '#fff',
      font: {
        weight: 'bold',
        size: 10,
      },
      formatter: value => (value > 0 ? `${Math.round(value, 2)}%` : null),
    },
  },
  scales: {
    xAxes: [
      {
        display: true,
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        display: true,
        position: 'left',
        gridLines: {
          display: false,
        },
        labels: {
          show: true,
        },
        ticks: {
          callback: value => ``,
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label: tooltipItem => {
        const label = Math.round(tooltipItem.value * 100) / 100;
        return `${label}%`;
      },
    },
  },
};
export const EARLY_EMBRYOS_TREEMAP_OPTIONS = {
  minColor: '#a8c0ff',
  midColor: '#6954c4',
  maxColor: '#3f2b96',
  headerHeight: 0,
  fontColor: 'white',
  allowHtml: true,
};

export const BLASTOCYST_TREEMAP_OPTIONS = {
  minColor: '#11998e',
  midColor: '#1fc45e',
  maxColor: '#38ef7d',
  headerHeight: 0,
  fontColor: 'black',
  showScale: false,
};
const CHART_OPTIONS = {
  stackedBar: STACKED_BAR_OPTIONS,
  stackedBarV2: STACKED_BAR_V2_OPTIONS,
  stackedBarLabeled: STACKED_BAR_LABELED_OPTIONS,
  radar: RADAR_BAR_OPTIONS,
  heatmap: HEATMAP_OPTIONS,
  barMixed: BAR_MIXED_OPTIONS,
  clinicsBars: CLINICS_BARS_OPTIONS,
  semenExaminationRadar: SEMEN_EXAMINATION_RADAR_OPTIONS,
};

export default CHART_OPTIONS;
