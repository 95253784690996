import { takeLatest } from 'redux-saga/effects';

import {
  GET_EXAMINATION_RESULTS,
  UPDATE_EXAMINATION_RESULTS,
} from 'containers/ExaminationResultsPage/constants';
import { FETCH_DRUG_USAGE_DATA } from 'containers/DrugUsagePage/constants';
import {
  GET_USER_PROFILE_DATA,
  EDIT_USER_PROFILE,
} from 'containers/ProfilePage/constants';
import {
  FETCH_CLINIC_DATA,
  FETCH_CLINIC_STATS_DATA,
} from 'containers/ClinicDetails/constants';
import { FETCH_CLINICS_BY_PREFECTURE } from 'containers/ClinicsPage/constants';
import {
  SEND_AMH_CONFIRMATION_EMAIL,
  SEND_CONFIRMATION_EMAIL,
} from 'containers/RegistrationPage/constants';

import {
  fetchClinicData,
  fetchClinicStatsData,
} from 'containers/ClinicDetails/sagas';
import { fetchDrugUsageData } from 'containers/DrugUsagePage/sagas';
import { fetchClinicsDataByPrefecture } from 'containers/ClinicsPage/sagas';
import {
  getExaminationData,
  updateExaminationData,
} from 'containers/ExaminationResultsPage/sagas';
import { editUserProfile, getUserProfile } from 'containers/ProfilePage/sagas';
import {
  sendAmhConfirmationEmail,
  sendConfirmationEmail,
} from 'containers/RegistrationPage/sagas';

export default function* rootSaga() {
  yield takeLatest(EDIT_USER_PROFILE, editUserProfile);
  yield takeLatest(GET_USER_PROFILE_DATA, getUserProfile);
  yield takeLatest(GET_EXAMINATION_RESULTS, getExaminationData);
  yield takeLatest(UPDATE_EXAMINATION_RESULTS, updateExaminationData);
  yield takeLatest(FETCH_CLINIC_DATA, fetchClinicData);
  yield takeLatest(FETCH_CLINIC_STATS_DATA, fetchClinicStatsData);
  yield takeLatest(FETCH_DRUG_USAGE_DATA, fetchDrugUsageData);
  yield takeLatest(FETCH_CLINICS_BY_PREFECTURE, fetchClinicsDataByPrefecture);
  yield takeLatest(UPDATE_EXAMINATION_RESULTS, updateExaminationData);
  yield takeLatest(SEND_CONFIRMATION_EMAIL, sendConfirmationEmail);
  yield takeLatest(SEND_AMH_CONFIRMATION_EMAIL, sendAmhConfirmationEmail);
}
