import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Modal } from 'semantic-ui-react';
const RegistrationModal = ({
  modalOpen,
  name,
  sex,
  birthday,
  onRegisterUser,
  uploadClosingState,
}) => {
  const checkBirthday = new Date(birthday);
  const handleClose = () => {
    uploadClosingState(false);
  };
  const handleRegister = () => {
    uploadClosingState(false);
    onRegisterUser();
  };
  return (
    <Modal open={modalOpen} onClose={handleClose}>
      <Modal.Header>登録内容の確認</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p>名前（ニックネーム)</p>
          <p style={{ marginLeft: 14 }}>{name}</p>
          <p>性別</p>
          <p style={{ marginLeft: 14 }}>{sex}</p>
          <p>生年月</p>
          <p style={{ marginLeft: 14 }}>
            {checkBirthday.getFullYear()}年{checkBirthday.getMonth() + 1}月
          </p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button className="cancel_button" size="small" onClick={handleClose}>
          <Icon name="remove" /> CANCEL
        </Button>
        <Button
          style={{
            width: '140px',
          }}
          className="confirmation_button"
          onClick={handleRegister}
        >
          <Icon name="checkmark" /> OK
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

RegistrationModal.propTypes = {
  name: PropTypes.string,
  sex: PropTypes.string,
  birthday: PropTypes.string,
  modalOpen: PropTypes.bool.isRequired,
  onRegisterUser: PropTypes.func.isRequired,
  uploadClosingState: PropTypes.func.isRequired,
};
RegistrationModal.defaultProps = {
  name: '',
  sex: '',
  birthday: '',
};
export default RegistrationModal;
