import React from 'react';
import PropTypes from 'prop-types';
const Header = ({ chartType, method, eggRecolletion }) => (
  <div>
    <p>以下の条件の方が使用した薬剤リストTOP30</p>
    <br />
    <p>刺激法: {method}</p>
    <p>
      {chartType}: {eggRecolletion}
    </p>
  </div>
);

Header.propTypes = {
  chartType: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
  eggRecolletion: PropTypes.string.isRequired,
};
export default Header;
