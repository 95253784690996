import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { PropTypes } from 'prop-types';

export const CommonRoute = ({
  component: Component,
  errorCode,
  isLoggedIn,
  isAmhLoggedIn,
  ...rest
}) => {
  if (isLoggedIn) {
    return <Route {...rest} render={props => <Component {...props} />} />;
  }
  if (isAmhLoggedIn) {
    return (
      <Route
        {...rest}
        render={() => (
          <Redirect
            to={{
              pathName: '/amhResult',
              state: errorCode ? { errorCode } : null,
            }}
          />
        )}
      />
    );
  }
  return (
    <Route
      {...rest}
      render={() => (
        <Redirect
          to={{
            pathName: '/',
            state: errorCode ? { errorCode } : null,
          }}
        />
      )}
    />
  );
};

export const AmhRoute = ({
  component: Component,
  errorCode,
  isLoggedIn,
  isAmhLoggedIn,
  ...rest
}) => {
  if (isAmhLoggedIn) {
    return <Route {...rest} render={props => <Component {...props} />} />;
  }
  if (isLoggedIn) {
    return (
      <Route
        {...rest}
        render={() => (
          <Redirect
            to={{
              pathName: '/myData',
              state: errorCode ? { errorCode } : null,
            }}
          />
        )}
      />
    );
  }
  return (
    <Route
      {...rest}
      render={() => (
        <Redirect
          to={{
            pathName: '/',
            state: errorCode ? { errorCode } : null,
          }}
        />
      )}
    />
  );
};

export const AuthenticatedRoute = ({
  component: Component,
  isLoggedIn,
  isAmhLoggedIn,
  ...rest
}) => {
  if (isLoggedIn) {
    return (
      <Route
        {...rest}
        render={() => (
          <Redirect
            to={{
              pathname: '/myData',
            }}
          />
        )}
      />
    );
  }
  if (isAmhLoggedIn) {
    return (
      <Route
        {...rest}
        render={() => (
          <Redirect
            to={{
              pathname: '/ninpath-check/info',
            }}
          />
        )}
      />
    );
  }
  return <Route {...rest} render={props => <Component {...props} />} />;
};

CommonRoute.propTypes = {
  errorCode: PropTypes.any,
  isLoggedIn: PropTypes.bool,
  isAmhLoggedIn: PropTypes.bool,
  component: PropTypes.any.isRequired,
};
CommonRoute.defaultProps = {
  isLoggedIn: false,
  isAmhLoggedIn: false,
  errorCode: null,
};
AmhRoute.propTypes = {
  errorCode: PropTypes.any,
  isLoggedIn: PropTypes.bool,
  isAmhLoggedIn: PropTypes.bool,
  component: PropTypes.any.isRequired,
};
AmhRoute.defaultProps = {
  isLoggedIn: false,
  isAmhLoggedIn: false,
  errorCode: null,
};
AuthenticatedRoute.propTypes = {
  isLoggedIn: PropTypes.bool,
  isAmhLoggedIn: PropTypes.bool,
  component: PropTypes.any.isRequired,
};
AuthenticatedRoute.defaultProps = {
  isLoggedIn: false,
  isAmhLoggedIn: false,
};
