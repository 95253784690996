import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Icon, Menu } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { FilterMenu } from './CardElements';

const CHART_LINKS = {
  radar: 'stepup',
  etMatrix: 'etMatrix',
  barMixed: 'barMixed',
  drugByPeriodTable: 'drugTable',
};
const CardFooter = ({
  activePadding,
  bgColor,
  chartType,
  infertilityMode,
  footerValues,
  sex,
  showTitle,
}) => (
  <>
    <Card.Content
      style={{ width: '100%' }}
    >
      {showTitle && (
        <span>
          {' '}
          <Icon name="pie chart" /> みんなのデータ
        </span>
      )}

      {infertilityMode && (
        <FilterMenu
          borderless
          vertical
          style={{ background: `${bgColor} !important` || '#fff' }}
        >
          <Menu.Item
            as={Link}
            to={`/stats?infertility=${sex === '女性' ? 0 : 2}`}
            key={`${sex}全体の受診状況`}
            style={{
              paddingLeft: 0,
              borderLeft: 0,
              borderRight: 0,
              borderBottom: '1px solid #f4f4f4',
            }}
          >
            <span>{sex}全体の受診状況</span>
            <Icon
              name="chevron right"
              color="grey"
              style={{ float: 'right' }}
            />
          </Menu.Item>
          <Menu.Item
            as={Link}
            to={`/stats?infertility=${sex === '女性' ? 1 : 3}`}
            key={`${sex}全体の検査結果`}
            style={{
              paddingLeft: 0,
              borderLeft: 0,
              borderRight: 0,
              borderBottom: '1px solid #f4f4f4',
            }}
          >
            <span>{sex}全体の検査結果</span>
            <Icon
              name="chevron right"
              color="grey"
              style={{ float: 'right' }}
            />
          </Menu.Item>
        </FilterMenu>
      )}

      {!infertilityMode && (
        <FilterMenu borderless vertical>
          {footerValues.map(footerElement => (
            <Menu.Item
              key={footerElement.text}
              as={Link}
              to={`/stats?${CHART_LINKS[chartType]}=${footerElement.value}`}
              style={{
                paddingLeft: activePadding ? '14px' : 0,
                borderLeft: 0,
                borderRight: 0,
                borderBottom: '1px solid #f4f4f4',
                background: bgColor !== null ? bgColor : '#fff',
              }}
            >
              <span>{footerElement.text}</span>
              <Icon
                name="chevron right"
                color="grey"
                style={{ float: 'right' }}
              />
            </Menu.Item>
          ))}
        </FilterMenu>
      )}
    </Card.Content>
  </>
);

CardFooter.propTypes = {
  activePadding: PropTypes.bool,
  bgColor: PropTypes.any,
  chartType: PropTypes.string,
  footerValues: PropTypes.array,
  infertilityMode: PropTypes.bool,
  sex: PropTypes.string,
  showTitle: PropTypes.bool,
};

CardFooter.defaultProps = {
  activePadding: false,
  footerValues: [],
  infertilityMode: false,
  showTitle: true,
  bgColor: null,
  sex: '',
  chartType: '',
};

export default CardFooter;
