import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Popup } from 'semantic-ui-react';
import { TUTORIAL_EXPLANATIONS } from 'utils/defaults';

const PopupInfo = ({ fieldName, size }) => (
  <Popup
    content={TUTORIAL_EXPLANATIONS[fieldName]}
    on="focus"
    trigger={
      <Icon
        name="question circle"
        style={{
          marginRight: 0,
          marginLeft: 5,
          fontSize: '1em',
        }}
        size={size || 'large'}
      />
    }
  ></Popup>
);

PopupInfo.propTypes = {
  fieldName: PropTypes.string.isRequired,
  size: PropTypes.string,
};
PopupInfo.defaultProps = {
  size: null,
};
export default PopupInfo;
