import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Mask } from 'components/Atoms';
import { SUB_COLOR_2 } from 'containers/ChartPage/colors';
import { BOX_SHADOW } from 'utils/defaults';

const OpuTable = ({ tableData }) => {
  const tableRef = useRef(null);
  const [tableOffset, setTableOffset] = useState(0);
  const [showShadow, setShowShadow] = useState(false);
  const [initialWidth, setInitialWidth] = useState(null);
  const [initialWidthInPx, setInitialWidthInPx] = useState('100%');
  const [tableWidth, setTableWidth] = useState(0);

  const onScroll = evt => {
    const newWidth = evt.target.scrollLeft + initialWidth;
    if (newWidth <= tableWidth) {
      setInitialWidthInPx(`${newWidth}px`);
    } else {
      setInitialWidthInPx(`${tableWidth}px`);
    }
    if (evt.target.scrollLeft >= tableOffset) {
      if (showShadow) setShowShadow(false);
    } else if (!showShadow) setShowShadow(true);
  };
  useEffect(() => {
    if (tableRef.current) {
      const originalWidth = tableRef.current.offsetParent.offsetWidth;
      const tableOriginalWidth = tableRef.current.offsetWidth;
      setInitialWidth(originalWidth);
      setTableWidth(tableOriginalWidth);
      setInitialWidthInPx(`${originalWidth}px`);
      const offset = tableOriginalWidth - originalWidth;
      if (offset > 0) setShowShadow(true);
      setTableOffset(offset);
    }
  }, [tableRef]);
  return (
    <div
      className="table-wraper"
      style={{
        overflowX: 'auto',
        overflowY: 'hidden',
        position: 'relative',
        marginTop: '14px',
        boxShadow: showShadow ? BOX_SHADOW : 'none',
      }}
      onScroll={evt => onScroll(evt)}
    >
      <Mask
        style={{
          boxShadow: showShadow ? BOX_SHADOW : 'none',
          width: initialWidthInPx,
        }}
      />
      <table style={{ maxWidth: '100vw', borderTop: `1px solid ${SUB_COLOR_2}`, borderBottom: `1px solid ${SUB_COLOR_2}` }} ref={tableRef}>
        <thead>
          <tr>
            <th
              style={{
                borderRight: `1px solid ${SUB_COLOR_2}`,
              }}
            ></th>
            {tableData.cycles.map(elm => (
              <th
                key={elm}
                style={{
                  borderTop: `1px solid ${SUB_COLOR_2}`,
                }}
              >
                {elm}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(tableData.datasets).map(key => (
            <tr key={key}>
              <td
                className="opu_column"
                style={{
                  borderRight: `1px solid ${SUB_COLOR_2}`,
                }}
              >
                {key}
              </td>
              {tableData.datasets[key].map((elm, index) => (
                <td
                  className="opu_column"
                  key={`elm${index + 1}`}
                  style={{ textAlign: 'right' }}
                >
                  {elm}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

OpuTable.propTypes = {
  tableData: PropTypes.object,
};

OpuTable.defaultProps = {
  tableData: {
    cycles: [],
  },
};
export default OpuTable;
