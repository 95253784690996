export const UPDATE_EXAMINATION_RESULTS =
  'ninpath/containers/ExaminationResultsPage/UPDATE_EXAMINATION_RESULTS';
export const GET_EXAMINATION_RESULTS =
  'ninpath/containers/ExaminationResultsPage/GET_EXAMINATION_RESULTS';
export const GET_EXAMINATION_RESULTS_WAS_SUCCESSFUL =
  'ninpath/containers/ExaminationResultsPage/GET_EXAMINATION_RESULTS_WAS_SUCCESSFUL';
export const GET_EXAMINATION_RESULTS_FAILED =
  'ninpath/containers/ExaminationResultsPage/GET_EXAMINATION_RESULTS_FAILED';
export const EXAMINATION_RESULTS_UPDATED_SUCCESSFULLY =
  'ninpath/containers/ExaminationResultsPage/EXAMINATION_RESULTS_UPDATED_SUCCESSFULLY';
export const EXAMINATION_RESULTS_UPDATE_FAILED =
  'ninpath/containers/ExaminationResultsPage/EXAMINATION_RESULTS_UPDATE_FAILED';
