export const BAR = 'stackedBar';
export const BAR_V2 = 'stackedBarV2';
export const RADAR = 'radar';
export const HEATMAP = 'heatmap';
export const BAR_LABELED = 'stackedBarLabeled';
export const CLINICS_TABLE = 'clinicsTable';
export const CLINICS_BARS = 'clinicsBars';
export const SUMMARY_CHART = 'summaryChart';
export const SUMMARY_CATEGORY = 'item_category';
export const SUMMARY_SUBCATEGORY = 'summary_subcategory';
export const BAR_LINE = 'barMixed';
export const ET_MATRIX = 'etMatrix';
export const RESULT_MATRIX = 'resultMatrix';
export const OPU_TABLE = 'eggCollectionTable';
export const DRUG_TABLE = 'drugByPeriodTable';
export const SEMEN_EXAMINATION_RADAR = 'semenExaminationRadar';
export const SEMEN_EXAMINATION_TABLE = 'semenExaminationTable';
export const TRANSPLANTED_EMBRYO_GRADE_TREEMAP =
  'transplantedEmbryoGradeTreemap';
