import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TUTORIAL_EXPLANATIONS, CHART_TITLES } from 'utils/defaults';

import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Card, Icon, Modal, Button, Header } from 'semantic-ui-react';
import { SUBFILTERS_NAMES, SUBFILTERS_ARR } from 'utils/chartFilters';

import { CLINICS_TABLE, HEATMAP } from 'components/Graph/constants';
import { J_ENG_DICTIONARY } from 'utils/dictionary';

import { MAIN_COLOR } from 'containers/ChartPage/colors';
import { logoutSuccesful } from 'containers/TopPage/actions';
import { buildRequests, buildChartData } from 'utils/chartHelper';

import Graph from 'components/Graph';
import { DataNotFoundMessage } from 'containers/ErrorPage/DataNotFound';
import { Margin } from 'components/Atoms';
import Cookies from 'universal-cookie';
import FilterList from 'components/FilterList';

import CHART_OPTIONS from 'containers/ChartPage/options';

import {
  checkInfertilityMode,
  hasParamsOnCard,
} from 'components/ChartCard/constants';

import { FilterContainer, FilterNamesList, ChartName } from './CardElements';

import CardFooter from './CardFooter';
import FilterMenu from './FilterMenu';

import './card.css';

const cookies = new Cookies();

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ChartCard = ({
  cardType,
  chartName,
  chartTypes,
  chartDescription,
  chartParameters,
  footerValues,
  httpMethods,
  location,
  logout,
  showTutorial,
  showFilters,
  placeholder,
  userClinicNames,
  userClinicIds,
}) => {
  // contains the data of all the charts for a given card
  const [charts, setChartsData] = useState([]);
  const [chartsStates, setChartsStates] = useState([]);
  const [chartHasInitialData, setChartHasInitialData] = useState([]);
  const [parameters, setParameters] = useState(undefined);
  const [activeChartParam, setActiveChartParam] = useState(0);
  const [toggleSubfilters, setToggleSubfilters] = useState(false);
  const [activeSubFilters, setActiveSubfilters] = useState([]);
  const [subFilterChain, setSubFilterChain] = useState('');
  const [showPlaceholder, setShowPlaceholder] = useState(false);
  // プレースホルダ用のメッセージ出し分けフラグ
  const [isNewbie, setIsNewbies] = React.useState(false);

  const changeParameter = index => {
    setActiveChartParam(index);
    if (chartTypes[0] === HEATMAP && cardType === 'stats') {
      // this will send the elements to the function for the heatmap
      window.heatmapActiveSubFilters = activeSubFilters.map(elm => elm);
      window.heatmapActiveGraphType = parameters[index];
    }
  };
  // update subfilters
  const onUpdateSubfilters = (subFilterIndex, value) => {
    const subFiltersTemp = activeSubFilters.map(elm => elm);
    subFiltersTemp[subFilterIndex] = value;
    const temp = subFiltersTemp.join(' ');
    setSubFilterChain(temp.trim() === '' ? '絞り込み' : temp);
    setActiveSubfilters(subFiltersTemp);
    // necessary for the click event on the heatmap
    if (chartTypes[0] === HEATMAP && cardType === 'stats') {
      window.heatmapActiveSubFilters = subFiltersTemp.map(elm => elm);
      window.heatmapActiveGraphType = parameters[activeChartParam];
    }
  };
  // init subfilters array and chain and mount time
  useEffect(() => {
    const activeSubFiltersTemp = SUBFILTERS_ARR.map(arr => {
      const activeFilter = arr.find(elm => elm.active);
      return activeFilter ? activeFilter.text : '';
    });
    activeSubFiltersTemp[0] = J_ENG_DICTIONARY[cookies.get('age_group')] || '';
    setActiveSubfilters(activeSubFiltersTemp);
    const subFilterChainTemp = activeSubFiltersTemp.join('');
    setSubFilterChain(
      subFilterChainTemp !== '' ? subFilterChainTemp : '絞り込み',
    );
    window.heatmapActiveSubFilters = activeSubFiltersTemp.map(elm => elm);
  }, []);

  useEffect(() => {
    setParameters(chartParameters.map(elm => elm));
    if (chartTypes[0] === HEATMAP && cardType === 'stats') {
      [window.heatmapActiveGraphType] = chartParameters;
    }
  }, [chartParameters, cardType, chartTypes]);

  // Side-effect special to clinics, set the parameters to clinic_ids
  useEffect(() => {
    if (chartName === 'あなたのクリニックの実績')
      setParameters(userClinicNames.map(clinic => clinic));
  }, [chartName, userClinicNames]);

  useEffect(
    () => {
      if (chartTypes[0] === CLINICS_TABLE && userClinicIds.length === 0) {
        setChartsData([{ empty: true }]);
        setChartsStates([true]);
        setChartHasInitialData([true]);
        return;
      }
      if (parameters !== undefined) {
        const requests = buildRequests(
          activeChartParam,
          chartTypes,
          httpMethods,
          chartTypes[0] === CLINICS_TABLE ? userClinicIds : parameters,
          activeSubFilters,
        );
        if (cardType === 'stats' && chartName === '不妊検査') {
          const resultMatrixRequest = buildRequests(
            0,
            ['resultMatrix'],
            ['POST'],
            ['女性', '男性'],
            [],
          );
          Promise.all(resultMatrixRequest).then(results => {
            results.map(result => {
              // 超音波エコー項目が空の場合は必須項目が入力されていない状態
              if (result['超音波エコー'] === '') {
                setShowPlaceholder(true);
                if (result['卵管造影'] === '') {
                  // 卵管造影が空の場合は新規登録ユーザ
                  // そうでない場合は既存ユーザかつ 2021/06 新規追加項目未入力ユーザ
                  setIsNewbies(true);
                }
              }
              return null;
            });
          });
        }
        if (
          parameters.length > 0 ||
          (parameters.length === 0 && chartTypes[0] !== CLINICS_TABLE)
        ) {
          Promise.all(requests).then(responses => {
            const states = [];
            const chartsInitialState = [];
            const chartsData = responses.map((response, index) => {
              if (response.status >= 400) {
                states.push(false);
                chartsInitialState.push(false);
                logout();
              } else if (response.status === 500 || response.status === 503) {
                states.push(false);
                chartsInitialState.push(false);
                logout(response.status);
              } else if (
                response.empty &&
                chartTypes[index] !== CLINICS_TABLE
              ) {
                // clinics table always appears (required by the client)
                states.push(false);
                chartsInitialState.push(false);
              } else if (response.messages) {
                /* this is the case when there is not registered data. Only applicable in
                 myData ! */
                states.push(true);
                chartsInitialState.push(true);
              } else if (!response.messages) {
                states.push(true);
                chartsInitialState.push(false);
                if (
                  cardType === 'me' &&
                  chartName === '不妊検査' &&
                  response['超音波エコー'] === ''
                ) {
                  setShowPlaceholder(true);
                  if (response['卵管造影'] === '') {
                    // 卵管造影が空の場合は新規登録ユーザ
                    // そうでない場合は既存ユーザかつ 2021/06 新規追加項目未入力ユーザ
                    setIsNewbies(true);
                  }
                }
                return buildChartData(
                  response,
                  chartTypes[index],
                  activeChartParam,
                );
              }
              return null; // dummy
            });
            setChartsStates(states);
            setChartsData(chartsData);
            setChartHasInitialData(chartsInitialState);
          });
        }
      }
    },
    // eslint-disable-next-line
    [activeChartParam, activeSubFilters, httpMethods, logout],
  );

  // check which parameter to set by default given an url
  useEffect(() => {
    if (location.search && location.search !== '') {
      const urlParam = location.search.replace('?', '');
      const matches = urlParam.match('(.*)=(.*)');
      if (matches.length > 2) {
        const key = matches[1];
        const value = matches[2];
        if (!isNaN(value) && parameters !== undefined) {
          const filterNumber = parseInt(value, 10);
          if (hasParamsOnCard(key, chartTypes[0], filterNumber, parameters)) {
            setActiveChartParam(filterNumber);
          }
        }
      }
    }
  }, [location, chartTypes, parameters]);
  return (
    <Card
      fluid
      className={
        chartTypes[0] !== CLINICS_TABLE ? 'ninpath__card' : 'clinics__card'
      }
    >
      <Card.Header className="ninpath__card__header">
        <div style={{ display: `flex` }}>
          <p style={{ display: 'inline-block', marginBottom: 0 }}>
            {chartName}
          </p>
          {showTutorial && (
            <p style={{ marginLeft: `auto` }}>
              <Modal
                trigger={
                  <Button style={{ fontSize: `0.856rem`, padding: 10 }}>
                    グラフ説明
                  </Button>
                }
                closeIcon
              >
                <Header>{chartName}グラフの見方</Header>
                <Modal.Content>
                  <p
                    style={{
                      whiteSpace: 'pre-line',
                      fontSize: 18,
                      marginTop: 16,
                      marginBottom: 16,
                    }}
                  >
                    {TUTORIAL_EXPLANATIONS[chartDescription[0]]}
                  </p>
                </Modal.Content>
              </Modal>
            </p>
          )}
        </div>
      </Card.Header>

      {!showPlaceholder && parameters && parameters.length > 0 && (
        <Card.Content
          className="ninpath__card__parameters"
          style={{ borderTop: 'none' }}
        >
          <FilterList
            activeFilter={activeChartParam}
            filtersData={parameters}
            changeFilter={changeParameter}
          />
          {CHART_TITLES[parameters[activeChartParam]] && (
            <ChartName style={{ marginTop: 14, paddingLeft: 14 }}>
              {CHART_TITLES[parameters[activeChartParam]][cardType]
                ? CHART_TITLES[parameters[activeChartParam]][cardType]
                : CHART_TITLES[parameters[activeChartParam]]}
            </ChartName>
          )}
          {chartTypes[0] === HEATMAP && (
            <p
              className="drug_usage_advice"
              style={{
                color: MAIN_COLOR,
                textAlign: 'left',
                fontSize: '1rem',
                paddingLeft: 14,
                paddingRight: 14,
                paddingTop: 0,
              }}
            >
              数字をクリックすると使用した薬剤を確認できます
              <Icon name="hand point down" />
            </p>
          )}
        </Card.Content>
      )}

      {!showPlaceholder ? (
        <Card.Content
          style={{ borderTop: 'none', padding: 0, paddingBottom: 14 }}
        >
          {!charts.empty &&
            charts.map((chartData, index) => (
              <React.Fragment key={`${index + 1}`}>
                {chartsStates[index] ? (
                  <Graph
                    activeChartType={activeChartParam}
                    dataToPlot={chartData}
                    parameters={parameters}
                    options={CHART_OPTIONS[chartTypes[index]]}
                    type={chartTypes[index]}
                    showError={chartHasInitialData[index]}
                    pageType={cardType}
                    inlineStyles={{
                      justifyContent: 'center',
                    }}
                  />
                ) : (
                  <DataNotFoundMessage />
                )}

                {chartTypes[0] === CLINICS_TABLE && index === 0 && (
                  <Margin
                    marginTop="14px"
                    marginBottom="14px"
                    marginRight="14px"
                  >
                    <p
                      style={{
                        textAlign: 'right',
                      }}
                    >
                      <Link to="/clinics">他のクリニックを実績を見る</Link>
                    </p>
                  </Margin>
                )}
              </React.Fragment>
            ))}
        </Card.Content>
      ) : (
        <ErrorContainer>
          <img
            src={placeholder}
            alt="data not found"
            style={{ maxWidth: '100%' }}
          />
          <p>
            {isNewbie
              ? '以下の情報を登録することでグラフ・表が表示されます。'
              : '検査項目の変更に伴い、未入力の項目があります。お手数ですが再度ご登録をお願いします。'}
          </p>
          <Button
            as={Link}
            to="/womanAndManExaminationResults"
            className="error_button ninpath__button__main"
          >
            不妊検査結果を入力する
          </Button>
        </ErrorContainer>
      )}
      {showFilters && (
        <Card.Content
          onClick={() => setToggleSubfilters(!toggleSubfilters)}
          style={{ zIndex: 100 }}
        >
          <FilterContainer>
            <FilterNamesList>{subFilterChain}</FilterNamesList>
            <Icon
              name="filter"
              color="grey"
              size="large"
              style={{ marginRight: 0, verticalAlign: 'top' }}
            />
          </FilterContainer>
        </Card.Content>
      )}
      {toggleSubfilters && (
        <Card.Content style={{ padding: '0px', zIndex: 1000 }}>
          <FilterMenu
            className="ninpath__menu"
            subFilters={SUBFILTERS_ARR}
            filterNames={SUBFILTERS_NAMES}
            activeFilters={activeSubFilters}
            onUpdateSubfilters={onUpdateSubfilters}
          />
        </Card.Content>
      )}
      {footerValues.length > 0 && (
        <CardFooter
          sex={activeChartParam === 0 ? '女性' : '男性'}
          infertilityMode={checkInfertilityMode(chartTypes[0])}
          footerValues={footerValues}
          chartType={chartTypes[0]}
        />
      )}
    </Card>
  );
};

ChartCard.propTypes = {
  cardType: PropTypes.string.isRequired,
  chartName: PropTypes.string.isRequired,
  chartTypes: PropTypes.array.isRequired,
  chartDescription: PropTypes.array.isRequired,
  chartParameters: PropTypes.array.isRequired,
  location: PropTypes.any.isRequired,
  httpMethods: PropTypes.array.isRequired,
  logout: PropTypes.func.isRequired,
  footerValues: PropTypes.array.isRequired,
  showTutorial: PropTypes.bool.isRequired,
  showFilters: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  userClinicIds: PropTypes.array.isRequired,
  userClinicNames: PropTypes.array,
};

ChartCard.defaultProps = {
  userClinicNames: [],
};

const mapDispatchToProps = dispatch => ({
  logout: (errorCode = null) => dispatch(logoutSuccesful(errorCode)),
});

export default connect(
  null,
  mapDispatchToProps,
)(withRouter(ChartCard));
