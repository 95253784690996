import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { MAIN_COLOR, SUB_COLOR } from 'containers/ChartPage/colors';
import { ErrorLayoutContainer } from 'utils/layouts';
import { connect } from 'react-redux';
import { errorHasOcurred } from 'containers/ErrorPage/actions';

const ErrorPage = ({ errorCode, message, sendErrorCode }) => {
  useEffect(() => {
    sendErrorCode(errorCode);
  }, [errorCode, sendErrorCode]);
  return (
    <ErrorLayoutContainer bgColor={SUB_COLOR}>
      <div
        className="message_container"
        style={{ transform: 'translateY(-50%)' }}
      >
        <h1
          style={{ color: MAIN_COLOR, fontSize: '5rem', textAlign: 'center' }}
        >
          {errorCode}
        </h1>
        <p style={{ fontSize: '1.5rem' }}>{message}</p>
      </div>
    </ErrorLayoutContainer>
  );
};
const mapDispatchToProps = dispatch => ({
  sendErrorCode: errorCode => dispatch(errorHasOcurred(errorCode)),
});
ErrorPage.propTypes = {
  errorCode: PropTypes.number,
  message: PropTypes.string,
  sendErrorCode: PropTypes.func.isRequired,
};

ErrorPage.defaultProps = {
  errorCode: 404,
  message: 'ページが見つかりませんでした',
};
export default connect(
  null,
  mapDispatchToProps,
)(ErrorPage);
