import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Message, Transition } from 'semantic-ui-react';

import ButtonContainer from 'components/ButtonContainer';
import NinpathButton from 'components/NinpathButton';
import { areSameValues, isValidField } from 'utils/errors';

const AmhUserRegistrationForm = ({
  startLoading,
  sendEmail,
  errorMessages,
}) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [emailConfirmation, setEmailConfirmation] = useState('');
  const [receiptNumber, setReceiptNumber] = useState('');
  return (
    <Form
      error
      onSubmit={evt => {
        evt.preventDefault();
        startLoading();
        const payload = {
          name,
          email,
          receipt_number: receiptNumber,
        };
        sendEmail(payload);
      }}
    >
      <Form.Group>
        <Form.Input
          type="text"
          style={{ width: '100%' }}
          label="名前（ニックネーム)"
          id="form__name"
          className="form__email"
          placeholder="名前（ニックネーム)"
          value={name}
          required
          onChange={evt => setName(evt.target.value)}
        />
      </Form.Group>
      <Form.Group style={{ marginTop: 14 }}>
        <Form.Input
          type="text"
          style={{ width: '100%' }}
          label="検査問合せ番号（数字15桁）"
          id="form__receipt_number"
          className="form__email"
          placeholder="検査問合せ番号（数字15桁）"
          value={receiptNumber}
          required
          onChange={evt => setReceiptNumber(evt.target.value)}
        />
      </Form.Group>
      {!isValidField('receipt_number', receiptNumber) && (
        <Message
          error
          header="エラー"
          content="検査申し込み用紙に記載された数字15桁を入力してください"
          style={{ marginBottom: 14 }}
        />
      )}
      <Form.Group style={{ marginTop: 14 }}>
        <Form.Input
          name="form__email"
          label="メールアドレス"
          className="form__email"
          placeholder="メールアドレス"
          type="email"
          width={16}
          value={email}
          required
          onChange={evt => setEmail(evt.target.value)}
          id="user__form__email"
        />
      </Form.Group>
      {!isValidField('email', email) && (
        <Message
          error
          header="エラー"
          content="メールアドレスの形は'xxxxxx@xxxxx.xxxxx'"
          style={{ marginBottom: 14 }}
        />
      )}
      <Form.Group style={{ marginTop: 0, marginBottom: 14 }}>
        <Form.Input
          className="form__email form__email__confirmation"
          placeholder="確認用メールアドレス"
          type="email"
          width={16}
          value={emailConfirmation}
          onChange={evt => setEmailConfirmation(evt.target.value)}
        />
      </Form.Group>
      {!areSameValues(email, emailConfirmation) && (
        <Message
          error
          header="エラー"
          content="メールアドレスが一致しません"
          className="confirmation__error"
        />
      )}
      <Form.Group
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <ButtonContainer>
          <NinpathButton
            text="登録メール送信"
            onClickAction={() => {}}
            disabled={
              !areSameValues(email, emailConfirmation) ||
              !isValidField('email', email) ||
              name === '' ||
              email === ''
            }
          />
        </ButtonContainer>
      </Form.Group>

      {errorMessages.length > 0 && (
        <Transition>
          <Message negative style={{ borderLeft: 0, borderRight: 0 }}>
            <Message.Header>エラー</Message.Header>
            {errorMessages.map(error => (
              <p key={error}>{error}</p>
            ))}
          </Message>
        </Transition>
      )}
    </Form>
  );
};

AmhUserRegistrationForm.propTypes = {
  errorMessages: PropTypes.array.isRequired,
  startLoading: PropTypes.func.isRequired,
  sendEmail: PropTypes.func.isRequired,
};
export default AmhUserRegistrationForm;
