import React from 'react';
import PropTypes from 'prop-types';
import { SUB_COLOR_2, MAIN_COLOR } from 'containers/ChartPage/colors';
const DrugTable = ({ tableData }) => (
  <div
    className="table-wraper"
    style={{
      overflowX: 'auto',
      overflowY: 'hidden',
      borderTop: `1px solid ${SUB_COLOR_2}`,
    }}
  >
    {tableData && tableData.columns && tableData.rows && (
      <table style={{ maxWidth: '100vw' }}>
        <thead>
          <tr>
            {tableData.columns.map(elm => (
              <th key={elm}>{elm}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.rows.map((row, index) => (
            <tr
              key={`${index + 1}`}
              style={{ borderTop: `1px solid ${SUB_COLOR_2}` }}
            >
              {row.map((col, indxCol) =>
                indxCol === 1 ? (
                  <td
                    style={{ textAlign: 'right', paddingRight: 10 }}
                    key={`${col} ${indxCol + 1}`}
                  >
                    {col}
                  </td>
                ) : (
                  <td
                    className="column_multiline"
                    key={`${index + 1}${indxCol + 1}`}
                  >
                    <p style={{ textAlign: 'left', paddingLeft: 6 }}>
                      {col.map((elm, indx) => (
                        <span
                          key={`${elm} ${indx + 1}`}
                          style={{ textAlign: 'left' }}
                        >
                          {elm}
                          {indx !== col.length - 1 && (
                            <span style={{ color: MAIN_COLOR }}> X </span>
                          )}
                        </span>
                      ))}
                    </p>
                  </td>
                ),
              )}
            </tr>
          ))}
        </tbody>
      </table>
    )}
  </div>
);

DrugTable.propTypes = {
  tableData: PropTypes.object.isRequired,
};

export default DrugTable;
