import React from 'react';
import PropTypes from 'prop-types';
import {
  FormField,
  FormLabel,
} from 'containers/TreatmentCyclePage/formElements';
import { DateInput } from 'semantic-ui-calendar-react';
import { Icon, Form } from 'semantic-ui-react'; // add icon
import {
  GRADES,
  ICM_GRADES,
  PGTA_RESULTS,
  TRANSPLANTION_DATES,
} from 'utils/defaultOptions';
import { BLASTOCYST } from 'containers/TreatmentCyclePage/constants';

import PopupInfo from 'components/PopupInfo';

// add deleteStep
const TransplantStep = ({ deleteStep, form, index, onUpdateField }) => (
  <>
    <Form.Group>
      <FormField style={{ position: 'relative' }}>
        <label
          htmlFor="transfer_methods"
          style={{ marginRight: 0, fontWeight: 700 }}
        >
          移植胚 {index}
        </label>
        {index > 1 && (
          <Icon
            name="close"
            onClick={() => deleteStep(index - 1)}
            style={{ position: 'absolute', right: 5, top: 0 }}
          />
        )}
      </FormField>
    </Form.Group>
    <Form.Group>
      <FormField inline style={{ paddingRight: 0 }}>
        <FormLabel htmlFor="embryo_stage" style={{ marginRight: 0 }}>
          胚の段階
        </FormLabel>
        <Form.Select
          placeholder="選択してください"
          clearable
          options={TRANSPLANTION_DATES}
          value={form.stage_of_transferred_embryo}
          onChange={(evt, { value }) => {
            onUpdateField(index, 'stage_of_transferred_embryo', value);
          }}
        />
      </FormField>
    </Form.Group>

    {form.stage_of_transferred_embryo !== '' && (
      <Form.Group>
        <FormField inline style={{ paddingRight: 0 }}>
          <FormLabel htmlFor="embryo_grade" style={{ marginRight: 0 }}>
            <span>グレード</span>
            {form.stage_of_transferred_embryo >= BLASTOCYST && (
              <PopupInfo fieldName="eggGrades" />
            )}
          </FormLabel>
          <Form.Select
            placeholder="選択してください"
            options={GRADES[form.stage_of_transferred_embryo]}
            clearable
            value={form.grade_of_transferred_embryo_number}
            onChange={(evt, { value }) => {
              onUpdateField(index, 'grade_of_transferred_embryo_number', value);
            }}
          />
        </FormField>
      </Form.Group>
    )}

    {form.stage_of_transferred_embryo >= BLASTOCYST ? (
      <Form.Group>
        <FormField inline style={{ paddingRight: 0 }} required>
          <FormLabel htmlFor="inner_cell_mass" style={{ marginRight: 0 }}>
            内細胞塊(ICM)
          </FormLabel>
          <Form.Select
            placeholder="選択してください"
            clearable
            options={ICM_GRADES}
            value={form.grade_of_transferred_embryo_icm}
            onChange={(evt, { value }) => {
              onUpdateField(index, 'grade_of_transferred_embryo_icm', value);
            }}
          />
        </FormField>
      </Form.Group>
    ) : null}
    {form.stage_of_transferred_embryo >= BLASTOCYST ? (
      <FormField inline style={{ paddingRight: 0 }} required>
        <FormLabel htmlFor="nutritional_ectoderm" style={{ marginRight: 0 }}>
          栄養外胚葉(TE)
        </FormLabel>
        <Form.Select
          placeholder="選択してください"
          options={ICM_GRADES}
          clearable
          value={form.grade_of_transferred_embryo_te}
          onChange={(evt, { value }) => {
            onUpdateField(index, 'grade_of_transferred_embryo_te', value);
          }}
        />
      </FormField>
    ) : null}
    <Form.Group>
      <FormField inline style={{ paddingRight: 0 }}>
        <FormLabel htmlFor="et_day" style={{ marginRight: 0 }}>
          移植日
        </FormLabel>
        <DateInput
          hideMobileKeyboard
          autoComplete="off"
          name="et_day"
          startMode="year"
          clearable
          closable
          animation="none"
          dateFormat="YYYY-MM-DD"
          placeholder="YYYY-MM-DD"
          iconPosition="right"
          localization="ja"
          value={form.day_of_et}
          onChange={(evt, { value }) => {
            onUpdateField(index, 'day_of_et', value);
          }}
        ></DateInput>
      </FormField>
    </Form.Group>
    <Form.Group>
      <FormField inline style={{ paddingRight: 0 }}>
        <FormLabel htmlFor="embryo_stage" style={{ marginRight: 0 }}>
          PGT-Aによる診断結果
        </FormLabel>
        <Form.Select
          placeholder="選択してください"
          options={PGTA_RESULTS}
          clearable
          value={form.pgt_a_result}
          onChange={(evt, { value }) => {
            onUpdateField(index, 'pgt_a_result', value);
          }}
        />
      </FormField>
    </Form.Group>
  </>
);

TransplantStep.propTypes = {
  deleteStep: PropTypes.func,
  form: PropTypes.object,
  index: PropTypes.number,
  onUpdateField: PropTypes.func,
};
TransplantStep.defaultProps = {
  deleteStep: () => {},
  form: {},
  index: 1,
  onUpdateField: () => {},
};
export default TransplantStep;
