export const AMH_STAT_VALUES = [
  { label: '27以下', age: 27, lower: 0.76, median: 4.69, upper: 14.18 },
  { label: '28', age: 28, lower: 0.84, median: 4.27, upper: 12.44 },
  { label: '29', age: 29, lower: 0.86, median: 4.14, upper: 11.97 },
  { label: '30', age: 30, lower: 0.79, median: 4.02, upper: 12.74 },
  { label: '31', age: 31, lower: 0.44, median: 3.85, upper: 13.08 },
  { label: '32', age: 32, lower: 0.62, median: 3.54, upper: 13.87 },
  { label: '33', age: 33, lower: 0.4, median: 3.32, upper: 12.76 },
  { label: '34', age: 34, lower: 0.38, median: 3.14, upper: 11.16 },
  { label: '35', age: 35, lower: 0.37, median: 2.62, upper: 10.18 },
  { label: '36', age: 36, lower: 0.33, median: 2.5, upper: 9.93 },
  { label: '37', age: 37, lower: 0.24, median: 2.27, upper: 8.5 },
  { label: '38', age: 38, lower: 0.11, median: 1.9, upper: 7.81 },
  { label: '39', age: 39, lower: 0.13, median: 1.8, upper: 7.45 },
  { label: '40', age: 40, lower: 0.08, median: 1.47, upper: 6.13 },
  { label: '41', age: 41, lower: 0.06, median: 1.3, upper: 5.52 },
  { label: '42', age: 42, lower: 0.05, median: 1, upper: 5.81 },
  { label: '43', age: 43, lower: 0.03, median: 0.72, upper: 4.49 },
  { label: '44', age: 44, lower: 0.03, median: 0.66, upper: 3.98 },
  { label: '45', age: 45, lower: 0.03, median: 0.41, upper: 3.43 },
  { label: '46以上', age: 46, lower: 0.02, median: 0.3, upper: 1.67 },
];

export const amhAgeToStatIndex = age => {
  const diff = age - AMH_STAT_VALUES[0].age;
  return diff < 0 ? 0 : (diff >= AMH_STAT_VALUES.length ? AMH_STAT_VALUES.length - 1 : diff)
};

export const amhAgeToStat = age => AMH_STAT_VALUES[amhAgeToStatIndex(age)];

export const LINE_OPTIONS = {
  legend: {
    display: true,
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
  scales: {
    xAxes: [
      {
        stacked: true,
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        stacked: false,
        gridLines: {
          display: false,
        },
        ticks: {
          callback: value => `${value}`,
          min: 0,
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label: tooltipItem => `${tooltipItem.value} [ng/ml]`,
    },
  },
};
